import {Calendar} from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {getGlobal, setGlobal} from 'reactn';
import {getDate} from './getDate';
import {EventRender} from './eventRender';
import {ColumnHear} from './columnHear';
import {checkElement} from '../calendar';
import moment from 'moment';
import {EventResize} from './eventResize';
import {eventAltCopy, eventShiftCopy, onRestore} from '../../../actions/calendar';
import {EventReceive} from './eventReceive';
import {EventDrop} from './eventDrop';
// import {refetchSource} from "../../../actions/refetchSourcce";
import {v4 as uuidv4} from 'uuid';
import {concat, filter, findIndex, uniqBy} from 'lodash';
import momentPlugin from '@fullcalendar/moment';
import {refetchAll} from '../../../actions/refetchSourcce';
import {isInView} from '../../../common/calendarView/calInView';
import gtm from '../../../common/gtm';
import {Button, notification} from 'antd';
import React from "react";
// import {gerenateBackgroundEvents, getWorkTime} from "../actions/cal2";


const getSelectedEvents = (events = {}, start, end) => {
    const {projects} = getGlobal()
    let result = []
    projects.forEach(pro => {
        if (pro.selected && !pro.is_sync && !pro.is_deleted)
            result = concat(result, getEventsInRange(events[pro.id]?.events || [], start, end))
    })
    return uniqBy(result, "id")
}
const getGoogleSelectedEvents = (events = {}, start, end) => {
    const {googleCalendarList} = getGlobal()
    let result = []
    googleCalendarList.forEach(pro => {
        if (pro.selected)
            result = concat(result, getEventsInRange(events[pro.id]?.events || [], start, end))
    })
    return uniqBy(result, "id")
}
const getEventsInRange = (events, start, end) => {
    let result = []
    events.forEach(event => {
        if (moment(event.start).isBetween(start, end, null, "[]") ||
            moment(event.end).isBetween(start, end, null, "[]") ||
            (moment(event.start).isBefore(start) && moment(event.end).isAfter(end))) {
            result.push(event)
        }
    })
    return result
}
const changeTime = (event) => {
    const {ggEvents, geEvents} = getGlobal()
    const {project_uuid, is_google, id, googleEvent} = event
    if (is_google || googleEvent) {
        let idx = findIndex(ggEvents[project_uuid]?.events || [], {id})
        if (idx !== -1) {
            ggEvents[project_uuid].events[idx].start = event.start
            ggEvents[project_uuid].events[idx].end = event.end
            ggEvents[project_uuid].events[idx].allDay = event.allDay
            setGlobal({
                ggEvents: {...ggEvents}
            }, () => {
                // refetchSource("google")
            })
        }
        return
    }
    if (geEvents[project_uuid]?.events?.length) {
        let idx = findIndex(geEvents[project_uuid].events, {id})
        if (idx !== -1) {
            geEvents[project_uuid].events[idx].start = event.start
            geEvents[project_uuid].events[idx].end = event.end
            geEvents[project_uuid].events[idx].allDay = event.allDay
            setGlobal({
                geEvents: {...geEvents}
            }, () => {
                // refetchSource("geniam")
            })
        }
        return;
    }
}

const openConfirm = (key = `updatable`, placement = 'bottomLeft') => {
    const onCancel = async (k) => {
        notification.close(k)
        await onRestore()
    }
    notification.success({
        message: `Đang lưu...`,
        bottom: 0,
        key,
        placement,

    });
    setTimeout(() => {
        notification.success({
            message: 'Đã lưu sự kiện',
            duration: 10,
            description: <div className={'popNotification'}><Button onClick={() => onCancel(key)}
                                                                    type='text'>Hủy</Button></div>,
            placement,
            bottom: 0,
            key,
        });
    }, 1000);
};


export function CalendarInit(numberId, classes, setEditInfo, setVotesSelect) {
    const {times, view, height, thickLine} = getGlobal()
    var calendarEl = document.getElementById(`calendarDiv${numberId}`)
    window['calendar' + numberId] = new Calendar(calendarEl, {
        plugins: [timeGridPlugin, interactionPlugin, momentPlugin],
        height: "auto",
        contentHeight: 'auto',
        handleWindowResize: true,
        locale: 'is-is',
        timeZone: 'local',
        slotDuration: '01:00:00',
        snapDuration: '00:30:00',
        minTime: times?.minTime?.label || '07:00',
        maxTime: times?.maxTime?.label || '24:00',
        titleFormat: {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric'
        },
        slotLabelFormat: "HH:mm",
        eventDurationEditable: true,
        slotEventOverlap: false,
        defaultView: `${view}`,
        defaultDate: getDate(numberId).date,
        columnHeaderFormat: {day: '2-digit', weekday: 'short', meridiem: false, omitCommas: false},
        header: false,
        views: {
            CustomMonth: {
                type: 'timeGrid',
                duration: {month: 1},
            },
            CustomWeek: {
                type: 'timeGrid',
                duration: {week: 1},
            },
            CustomDay: {
                type: "timeGrid",
                duration: {day: 1}
            }
        },
        firstDay: 1,
        progressiveEventRendering: true,
        nowIndicator: true,
        droppable: true,
        selectable: true,
        selectMirror: true,
        editable: true,
        weekends: true,
        allDaySlot: true,
        allDayText: gtm(),
        eventTextColor: 'black',
        displayEventTime: true,
        eventResizableFromStart: false,
        eventSources: [
            {
                events: async function (fetchInfo, successCallback, failureCallback) {
                    const {start, end} = fetchInfo
                    try {
                        // console.log("refetch google event")
                        const {ggEvents} = getGlobal()
                        let ggevents = getGoogleSelectedEvents(ggEvents, start, end)
                        successCallback(ggevents)
                        headerHeight(numberId)
                        // window['calendar' + numberId].rerenderEvents()
                        checkElement(numberId)
                        headerHeight(numberId)
                    } catch (e) {
                        console.log(e)
                        failureCallback(e)
                    }

                },
                id: 'google'
            },
            {
                events: async function (fetchInfo, successCallback, failureCallback) {
                    const {start, end} = fetchInfo
                    try {
                        const {geEvents} = getGlobal()
                        let geniamEvents = getSelectedEvents(geEvents, start, end)
                        successCallback(geniamEvents)
                        // window['calendar' + numberId].rerenderEvents()
                        checkElement(numberId)
                        headerHeight(numberId)
                    } catch (e) {
                        failureCallback(e)
                    }

                },
                id: 'geniam'
            },
            {
                events: function (fetchInfo, successCallback, failureCallback) {
                    // const { start, end } = fetchInfo
                    try {
                        const {voteEvents} = getGlobal()

                        // let geniamEvents = await getCalendarInfo(start, end)
                        successCallback(filter(voteEvents, v => !v.isSync))
                        // window['calendar' + numberId].rerenderEvents()
                        checkElement(numberId)
                        headerHeight(numberId)
                    } catch (e) {
                        failureCallback(e)
                    }

                },
                id: 'vote'
            },
            // {
            //     events: function (fetchInfo, successCallback, failureCallback) {
            //         try {
            //             const {start, end} = fetchInfo
            //             const events = gerenateBackgroundEvents(start, end)
            //             successCallback(events)
            //
            //         } catch (e) {
            //             console.log(e);
            //             // failureCallback(e)
            //         }
            //
            //     },
            //     id: 'background'
            // },
            // {
            //     events: function (fetchInfo, successCallback, failureCallback) {
            //         try {
            //             const {start, end} = fetchInfo
            //             const events = getWorkTime(start, end)
            //             successCallback(events)
            //             // checkElement(numberId)
            //             // headerHeight(numberId)
            //         } catch (e) {
            //             console.log(e);
            //             // failureCallback(e)
            //         }
            //     },
            //     id: 'timeWork'
            // },
        ],
        eventReceive: async (info) => {
            window['i'] = numberId
            EventReceive(info, changeTime, numberId)
            setGlobal({isDragging: false})
        },

        eventLeave: (info) => {
            try {
                info.event.remove()
            } catch (e) {
                console.log(e)
            }

        },
        eventResize: async (info) => {
            window['i'] = numberId
            // setResize(info)
            EventResize(info, changeTime, numberId)
        },
        eventDrop: async (info) => {
            window['i'] = numberId
            if (info.jsEvent.altKey) {
                // copy
                return eventAltCopy(info, numberId)
            }
            if (info.jsEvent.shiftKey) {
                return eventShiftCopy(info, numberId)
            }
            openConfirm()
            return EventDrop(info, changeTime, numberId)
        },
        select: (info) => {
            window['i'] = numberId
            // EventSelect(info, numberId)
            const {clickInfo} = getGlobal()
            let uuid = 'evt_' + uuidv4()

            if (clickInfo) {
                //get old id if exists
                uuid = clickInfo.id
            }

            if (clickInfo && moment(clickInfo.start).isSame(info.start)) {
                console.log("more click double or three ....")
                return null
            }

            if (!info.allDay && isVoteBoxSelect(info).length !== 0) {
                return setVotesSelect({
                    voteInfo: isVoteBoxSelect(info),
                    clickInfo: {
                        ...info, id: uuid,
                        elementXY: true
                    }
                })
            }

            setGlobal({
                clickInfo: {...info, id: uuid}
            })

        },
        eventDragStart: (info) => {
            window['i'] = numberId
            setGlobal({EventEditting: info.event, isDragging: true})
        },
        eventClick: async (info) => {
            if (info.event.source?.id === "background" || !info?.event?.title) {
                return;
            }
            if (info.event.extendedProps.source !== 'metamor' || info.event.extendedProps.isEventVote || info.event.extendedProps.isEventVote || info.event.extendedProps.isAccepted) {
                window['i'] = numberId
                setEditInfo(info)
            }
            const {clickInfo} = getGlobal()
            if (clickInfo) {
                setGlobal({
                    clickInfo: null
                })
                refetchAll().catch(console.log)
            }
        },
        // eventMouseEnter: info => {
        //     if (info.event.extendedProps.background || info.event.extendedProps.timeWork) {
        //         return setGlobal({
        //             hoverDay: info.event.start
        //         })
        //     }
        //     setGlobal({
        //         hoverTitle: {
        //             title: info.event.title,
        //             start: info.event.start,
        //             end: info.event.end,
        //             isAccepted: info.event.extendedProps?.isAccepted,
        //             eventInfo: info.event
        //         },
        //         hoverEvent: info.el,
        //         hoverDay: info.event.start
        //     })
        // },
        // eventMouseLeave: info => {
        //     setGlobal({
        //         hoverTitle: {
        //             title: '',
        //             start: '',
        //             end: '',
        //             isAccepted: false
        //         },
        //         hoverEvent: null
        //     })
        // },
        eventRender: (info) => {
            if (info.event.extendedProps.background || info.event.extendedProps.timeWork) {
                return
            }
            EventRender(info, classes, setEditInfo, numberId)
        },
        columnHeaderHtml: (info) => {
            return ColumnHear(info, classes)
        },
        eventPositioned: (info) => {
            if (info.el && info.id && !info.el.clientHeight) {
                console.log(info)
                console.log("need rerender")
                window['calendar' + numberId].rerenderEvents()
            }
        }
    })
    const container = document.getElementById("calendar-container" + numberId)
    if (container && isInView(container)) {
        window['calendar' + numberId].render()
    }
    // thickLine render
    LineThick(numberId, thickLine)
    let element = window['calendar' + numberId]?.view?.el.querySelector('.fc-time-grid-container')
    if (element) {
        element.style.minHeight = height
        window['calendar' + numberId].updateSize()
        checkElement(numberId)
    }
    const widget = window['calendar' + numberId]?.view?.el.querySelector('.fc-axis')
    if (widget) {
        widget.innerHTML = getHeader(numberId)
        widget.style.color = "#000"
        widget.style.fontWeight = "bold"
        widget.style.fontSize = "10px"
        // widget.style.backgroundColor = "#000"
        widget.style.verticalAlign = "middle"
        widget.style.textAlign = "center"
    }
    // header listener
    const els = window['calendar' + numberId]?.view?.el.querySelectorAll("th[data-date]")

    if (els?.length) {
        els.forEach(el => {
            el.removeEventListener('mousedown', e => {

            })
            el.removeEventListener('mouseenter', e => {

            })
            el.addEventListener('mousedown', e => {
                e.preventDefault()
                window.headerSelect = el.getAttribute('data-date')
                // el.innerHTML =''
                el.style.backgroundColor = 'red'
            })
            el.addEventListener('mouseenter', e => {
                e.preventDefault()
                const date = el.getAttribute('data-date')

                if (window.headerSelect) {
                    let values = []
                    els.forEach(el => {
                        if (moment(el.getAttribute('data-date') || null).isBetween(window.headerSelect, date, null, "[]") ||
                            moment(el.getAttribute('data-date') || null).isBetween(date, window.headerSelect, null, "[]")
                        ) {
                            el.style.backgroundColor = 'red'
                            values.push(el.getAttribute('data-date'))
                        } else {
                            el.style.backgroundColor = ''
                        }
                    })
                    window.focusColor = values
                }
            })
            el.addEventListener('mouseup', e => {
                e.preventDefault()
                els.forEach(el => {
                    el.style.backgroundColor = ''
                })
                if (window.focusColor?.length) {
                    // setGlobal({
                    //     openFocusColor: [...window.focusColor]
                    // })
                }
                window.focusColor = []
                window.headerSelect = ''
                // if(window.headerSelect){
                //     el.backgroundColor ='red'
                // }
            })
        })

    }
}

export const LineThick = (numberId, thickLine) => {
    if (window['calendar' + numberId]) {
        try {
            let trTag = window['calendar' + numberId].view?.el.getElementsByTagName('tr')
            if (trTag && trTag.length !== 0) {
                for (let item of trTag) {
                    if (item.dataset.time && thickLine.includes(parseInt(item.dataset.time.split(':')[0]))) {
                        item.children[0].style.color = 'red'
                        item.children[1].style.borderTop = '2px solid #616161'
                        // item.children[1].style.borderTopWidth = 'thick'
                    }
                }
            }
        } catch (e) {

        }
    }
}

export const isVoteBoxSelect = (info) => {
    const {voteEvents} = getGlobal()
    let start = moment(info.start).format()
    let end = moment(info.end).format()
    let voteData = filter(voteEvents, vote => !vote.isAccepted && ((moment(start).isSameOrBefore(vote.start) && moment(end).isAfter(vote.start)) ||
        (moment(start).isBefore(vote.end) && moment(end).isSameOrAfter(vote.end)))
    )
    return voteData
}

export const headerHeight = (numberId) => {
    // const tab = getGlobal().tab
    // const targetId = getTargetId(numberId)
    // if (window['calendar' + numberId] && window['calendar' + targetId] && tab === 1) {
    //     const el = window['calendar' + numberId]?.el?.querySelector(".fc-day-grid")
    //     if (!el)
    //         return
    //     const height = el.offsetHeight
    //     const target = window['calendar' + targetId].el.querySelector(".fc-day-grid")
    //     const targetheight = target.offsetHeight
    //     const maxHeight = targetheight > height ? targetheight : height
    //
    //     if (height < maxHeight) {
    //         el.style.height = maxHeight + "px"
    //     }
    //     if (targetheight < maxHeight) {
    //         // target.style.minHeight = maxHeight + "px"
    //         target.style.height = maxHeight + "px"
    //     }
    // }
    if (window['calendar' + numberId]) {
        const el = window['calendar' + numberId]?.el.querySelector(".fc-day-grid")
        if (!el)
            return
        const height = el.offsetHeight
        const content = window['calendar' + numberId].el.querySelector(".fc-time-grid-container")
        if (!content) {
            return;
        }
        content.style.maxHeight = `calc(100% - ${height + 14}px)`
        window['calendar' + numberId].updateSize()

    }
}
const getHeader = (numberId) => {
    const {view, refetchDateNow, user} = getGlobal()
    if (view === "CustomWeek") {
        let week = moment(refetchDateNow).add(numberId, "week").weeks();
        if (user?.language === "ja-jp")
            return week + " 週目 "
        if (user?.language === "vi-vn")
            return "Tuần " + week
        return "Week " + week
    } else {
        if (view === 'CustomMonth') {
            return moment(refetchDateNow).add(numberId, "months").format('MMMM')
        }
        return moment(refetchDateNow).add(numberId, "days").format('Do')
    }
};
// const getTargetId = numberId => {
//     if (numberId % 2 === 1) {
//         // 1,3,5,7
//         return numberId - 1
//     } else {
//         return numberId + 1
//     }
// }
