import React, {useEffect, useState} from 'react';
import {Grid, TextField, Typography} from '@material-ui/core'
import SelectProjectColor from "./SelectColor";
import {db} from '../../../firebaseConfig'
import {useGlobal} from 'reactn'
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {toast} from "react-toastify";
import {ggTokenChecked} from "../../Calendar/google/auth";
import {findIndex} from 'lodash'
import {useCalendarEvents} from "../../../context/CalendarEventsContext";
import TextInputCustom from "../../Custom/TextInputCustom";
import TextInputURLCustom from "../../Custom/TextInputURLCustom";
import useIntlId from "../../../hooks/useIntlId";
import {milestoneSettingCollRef} from "../../../actions/group";
import {msgError} from "../../../utils/msg";
import CancelButton from "../../Dialog/CancelButton";
import SaveButton from "../../Dialog/SaveButton";
import {Spin} from "antd";
import {
    myEvents,
    myUserCalendarCollRef,
    myUserCalendarDocRef,
    myUserCalendarEventDocRef,
    myUserCalendarProjectDocRef
} from "../../../common/firebaseRef/calendar";
import {checkUrl} from "../../../common/checkUrl";
import {ReactComponent as IconEdit} from "../../../asSvg/ic_editColor.svg";

const linkItems = [{
    name: "Slack",
    logo: "/icons/slackIcon.svg",
    id: "slack"
}, {
    name: "Trello",
    logo: "/icons/trelloIcon.svg",
    id: "trello"
}
]

function EditProject({project, setProject, setIsEdit}) {
    const [edited, setEdited] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [user] = useGlobal("user")
    const [calendarUser] = useGlobal("calendarUser")
    const [list, setList] = useGlobal("googleCalendarList")
    const {reLoadEvents} = useCalendarEvents()
    const [otherURL, setOtherURL] = useState([])
    const [projects] = useGlobal("projects")
    const [allMilestoneProject] = useGlobal('allMilestoneProject');
    const [invalidUrl, googleAuthError, updateColorError, nameEmpty, colorIntl] = useIntlId([
        'appy.noticeLink.invalid',
        'event.toast.googleAuthError',
        'event.toast.updateColorError',
        'event.toast.nameEmpty',
        'view.color'
    ])

    let checkErrorUrl = 0

    useEffect(() => {
        setEdited({...project})

        // eslint-disable-next-line
    }, [project])


    useEffect(() => {
        if (project?.otherURL) {
            setOtherURL([...project.otherURL])
            return;
        }
        setOtherURL(linkItems)

        // eslint-disable-next-line
    }, [project])

    const onCancel = () => {
        setIsEdit(false)
    }

    useEffect(() => {
        if (!allMilestoneProject.length)
            return null;
        updateProjectSetting().then()

        // eslint-disable-next-line
    }, [project, allMilestoneProject])


    const updateProjectSetting = async () => {
        const ref = await milestoneSettingCollRef(user.user_id).get()
        const data = ref.docs || []
        const batch = db.batch()

        try {
            if (!data.length) return
            data.forEach((setting) => {
                let listProject = setting.data().listProjectSetting
                const idx = findIndex(listProject, {id: edited.id})
                if (idx !== -1 && edited.is_google) {
                    listProject[idx].summary = edited.name
                    listProject[idx].name = edited.name
                    listProject[idx].backgroundColor = edited.color
                    listProject[idx].color = edited.color
                } else if (idx !== -1) {
                    listProject[idx] = {...listProject[idx], ...edited};
                }

                batch.set(setting.ref, {listProjectSetting: listProject}, {merge: true})
            })

            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    const onUpdate = async () => {
        // validate project name , code
        if (!edited.name) {
            return toast.warn(nameEmpty)
        }
        try {
            setLoading(true)
            // valid icon
            if (!edited.is_google) {
                // update geniam project
                if (edited.color !== project.color) {
                    const batch = db.batch()
                    const eventSnap = await myEvents()
                        .where("project_uuid", "==", project.id)
                        .where("isDeleted", "==", false)
                        .get()
                    eventSnap.docs.forEach(doc => {
                        const eventRef = myUserCalendarEventDocRef(doc.id)
                        batch.set(eventRef, {
                            borderColor: edited.color
                        }, {merge: true})
                    })
                }

                const refProject = myUserCalendarProjectDocRef(project.id)
                await refProject.set({
                        otherURL: otherURL || [],
                        color: edited.color || '',
                        name: edited.name || ''
                    },
                    {merge: true})
                edited.otherURL = otherURL
                return
            }

            // google Project update
            if (edited.name !== project.name) {
                // update data to google calendar
                const data = await ggTokenChecked()
                if (!data) {
                    return msgError(googleAuthError)
                }
                const request = window.gapi.client.calendar.calendars.patch({
                    calendarId: project.id,
                    summary: edited.name,
                })
                const result = await request.then()
                if (result.status !== 200) {
                    return msgError(updateColorError)
                }
            }
            if (edited.color !== project.color) {
                // update data to google calendar
                const data = await ggTokenChecked()
                if (!data) {
                    return msgError(googleAuthError)
                }
                const request = window.gapi.client.calendar.calendarList.update({
                    calendarId: project.id,
                    "colorRgbFormat": true,
                    // summary: edited.name,
                    backgroundColor: edited.color,
                    foregroundColor: "#000000",
                    selected: Boolean(edited.selected)
                })
                const result = await request.then()
                if (result.status !== 200) {
                    return msgError(updateColorError)
                }
            }

            // other info stored on firebase
            const googleSettings = calendarUser?.googleSettings || {}
            googleSettings[edited.id] = {
                otherURL: otherURL || []
            }
            edited.otherURL = otherURL
            const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
            await userRef.set({googleSettings}, {merge: true})
            // update local calendar List
            let idx = findIndex(list, {id: edited.id})
            if (idx !== -1) {
                list[idx].backgroundColor = edited.color
                list[idx].summary = edited.name
                setList([...list])
                reLoadEvents()
            }
        } catch (e) {
            msgError(googleAuthError)
        } finally {
            setLoading(false)
            setProject({...edited})
            setIsEdit(false)
        }

    }

    const delUrl = async (item) => {
        let sliceItem
        sliceItem = otherURL.filter((e) => e.id !== item.id)
        try {
            if (project.is_google) {
                let googleSettings = calendarUser?.googleSettings || {}
                googleSettings[project.id].otherURL = sliceItem
                const ref = myUserCalendarDocRef()
                await ref.set({googleSettings}, {merge: true})
                return;
            }
            let idx = findIndex(projects, {id: project.id})
            if (idx === -1) return;
            const ref = myUserCalendarCollRef(project.id)
            await ref.set({otherURL: sliceItem}, {merge: true})
        } catch (e) {
            console.log(e);
        } finally {
            setOtherURL(sliceItem)
        }
    }


    return (
        <div className={'bg-white mt-[40px] p-[30px_10px] rounded-[20px] relative'}>
            <div className={"flex justify-between"}>
                <TextInputCustom
                    name={'Name'}
                    contentName={project.name}
                    isEditComponent={true}
                    editComponent={
                        <div className={'clf-flex-center w-[95%]'}>
                            <TextField
                                autoFocus
                                value={edited.name || ''}
                                onChange={e => {
                                    setEdited({...edited, name: e.target.value})
                                }}
                                disabled={edited?.accessRole === "reader"}
                                fullWidth
                            />
                            {
                                edited?.accessRole === "reader" ?
                                    <Typography color={"secondary"}>reader access role</Typography>
                                    :
                                    null
                            }
                        </div>
                    }/>

                <div className={'w-full items-end max-w-[120px] pr-[5px]'}>
                    <div
                        className={'relative cursor-pointer uppercase text-white font-bold flex pl-[10px] items-center w-full h-[40px] rounded-[24px] p-0 m-auto border-[#D5D5D5]'}
                        style={{backgroundColor: edited.color}}
                        onClick={event => setAnchorEl(event.currentTarget)}
                    >
                        {colorIntl}
                        <IconEdit
                            className={"w-[28px] h-[28px] bg-white rounded-[50%] p-[4x] absolute right-[3px]"}
                            style={{fill: edited.color}}
                        />
                    </div>
                    <SelectProjectColor anchorEl={anchorEl} setAnchorEl={setAnchorEl} editInfo={edited}
                                        setEditInfo={setEdited}/>
                </div>


            </div>
            <div className={'mt-[20px]'}>
                {
                    otherURL.map((item, index) => {
                        if (item.url && !checkUrl(item.url)) {
                            checkErrorUrl++
                        }
                        return (
                            <Grid container key={index} className={'mb-[15px]'}>
                                <TextInputURLCustom
                                    contentName={`URL: ${project[item.id] ? project[item.id] : ''}`}
                                    urlImg={item.logo}
                                    isURL={true}
                                    item={item}
                                    project={project}
                                    setProject={setProject}
                                    otherURL={otherURL}
                                    setOtherURL={setOtherURL}
                                    isEditComponent={true}
                                    delUrl={delUrl}
                                    iconDel={true}
                                    editComponent={
                                        <div className={'clf-flex-center w-[95%]'}>
                                            <TextField
                                                error={item.url ? !checkUrl(item.url) : ''}
                                                helperText={item.url ? (!checkUrl(item.url) ? invalidUrl : '') : ''}
                                                value={item.url || ''}
                                                onChange={(e) => {
                                                    otherURL[index].url = e.target.value || ''
                                                    setOtherURL([...otherURL])
                                                }}
                                                fullWidth
                                            />
                                        </div>
                                    }
                                    titleMaxWidth={110}
                                />
                            </Grid>
                        )
                    })
                }
            </div>
            <div className={'mt-[20px] flex justify-center'}>
                <CancelButton
                    onClick={onCancel}
                />
                <Spin spinning={loading}>
                    <SaveButton
                        onClick={onUpdate}
                        disable={loading || checkErrorUrl}
                    />
                </Spin>
            </div>
        </div>
    );
}

export default EditProject;
