import {db} from '../../firebaseConfig'
import {CALENDAR_COLLECTION, MEETING_COLLECTION} from "../../config/constants";

export const getMeetingDocRef = (meetingId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}`)
}

export const getMeetingCollRef = () => {
    return db.collection(`${MEETING_COLLECTION}`)
}


export const getVoteBoxCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl`)
}
export const getVoteBoxDocRef = (meetingId, voteBoxId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl/${voteBoxId}`)
}
export const getUserVoteCollRef = (meetingId, voteBoxId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl/${voteBoxId}/voteColl`)
}
export const getUserVoteDocRef = (meetingId, voteBoxId, userId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/voteBoxColl/${voteBoxId}/voteColl/${userId}`)
}

export const getUsersCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/users`)
}
export const getUserDocRef = (meetingId, userId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/users/${userId}`)
}

export const scheduleAdjustmentRef = (userId) => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/scheduleAdjustment`)
}

export const getBusyCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/busyColl`)
}
export const getBusyDocRef = (meetingId, busyId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/busyColl/${busyId}`)
}


export const getAnonymousCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/anonymousColl`)
}
export const getAnonymousDocRef = (meetingId, email) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/anonymousColl/${email}`)
}


export const getMessagesCollRef = (meetingId) => {
    return db.collection(`${MEETING_COLLECTION}/${meetingId}/messages`)
}

export const getMessagesDocRef = (meetingId, messageId) => {
    return db.doc(`${MEETING_COLLECTION}/${meetingId}/messages/${messageId}`)
}

export const getEventCalendarDocRef = (userId, voteId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting/${voteId}`)
}

export const getEventCalendarCollRef = (userId) => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting`)
}

export const getUserProjectsColl = userId => {
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/projects`)
}

export const getScheduleAdjustmentRef = (userId, eventId) => {
    return db.doc(`${CALENDAR_COLLECTION}/${userId}/eventBoxMeeting/${eventId}`)
}
