import React from 'react';
import {makeStyles} from "@material-ui/styles";
import styled from "styled-components";
import {findIndex} from 'lodash'
import faviconFetch from 'favicon-fetch'
import {ReactComponent as IconDel} from "../../asSvg/delUrlLink.svg";

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.props.primaryColor,
        fontWeight: "bold",
        width: "100%",
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    img: {
        width: 22,
        height: 22,
        marginLeft: 10
    },
    textFieldName: {
        border: "none",
        display: "block",
        width: "100%",
        padding: "6px 0",
        fontWeight: 'bold',
        // marginBottom: 12,
        backgroundColor: 'unset',
        "&:focus": {
            outline: "none",
            borderBottomColor: theme.props.primaryColor
        },
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    closeIcon: {
        backgroundColor: theme.props.primaryColor,
        width: 25,
        height: 25,
        marginRight: 10,
        marginLeft: 7,
        '&:hover': {
            backgroundColor: theme.props.primaryColor,
        },
        '&:focus': {
            backgroundColor: theme.props.primaryColor,
        }
    },
    divContentStyle: {
        width: '100%',
    },
    titleURL: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    delUrlIcon: {
        position: 'absolute',
        top: 0,
        right: 1,
        cursor: 'pointer',
        fill: `${theme.props.primaryColor} !important`,
    }
}));

const DivTextInputs = styled.div(props => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: "center",
    fontSize: '1.2rem',
    borderBottom: '1px solid #f1f1f1',
    minHeight: 40,
    marginBottom: props.marginBottom
}))

const DivTitleIMG = styled.div(props => {
    return {
        color: props.color,
        fontWeight: "bold",
        width: "100%",
        maxWidth: props.titleMaxWidth,
        display: 'flex',
        alignItems: 'center'
    }
})

const DivContent = styled.div(props => ({
    color: '#000',
    fontSize: '1.2rem',
    background: '#FFF',
    minHeight: '34px',
    borderRadius: '20px',
    textAlign: 'left',
    paddingLeft: '10px',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'bold',
    width: `calc(100% - ${props.titleMaxWidth + 5}px)`,
    position: 'relative',
    paddingRight: 23
}))

const DivColor = styled.div(props => ({
    backgroundColor: props.backgroundColor,
    width: 25,
    minWidth: 25,
    height: 25,
    borderRadius: '50%',
    padding: 0,
    border: '1px solid #D5D5D5',
    margin: "auto"
}))

function TextInputURLCustom({
                                item,
                                project,
                                contentName,
                                isColor,
                                isURL,
                                setOtherURL,
                                otherURL = [],
                                editComponent,
                                isEditComponent,
                                titleMaxWidth = 90,
                                marginBottom = null,
                                setProject,
                                delUrl,
                                iconDel
                            }) {
    const classes = useStyles();

    const onChangeName = (e) => {
        let idx = findIndex(otherURL, o => o.id === item.id);
        if (idx === -1) return;
        otherURL[idx].name = e.target.value;
        setOtherURL([...otherURL]);
        setProject({...project, otherURL})
    }


    return (
        <DivTextInputs marginBottom={marginBottom}>
            <DivTitleIMG titleMaxWidth={titleMaxWidth}>
                {
                    isURL &&
                    <img src={item?.url ? faviconFetch({uri: item?.url}) : item?.logo} alt='' className={classes.img}/>
                }
                {
                    isEditComponent ?
                        <div className={classes.title}>
                            <input
                                value={item?.name || ''}
                                className={classes.textFieldName}
                                type="text" autoFocus={true}
                                onChange={(e) => onChangeName(e)}
                            />
                        </div>
                        :
                        <div className={classes.title}>
                            <div>{item?.name}</div>
                        </div>
                }

            </DivTitleIMG>
            <DivContent titleMaxWidth={titleMaxWidth}>
                <div className={classes.divContentStyle}>
                    {
                        isEditComponent ?
                            editComponent
                            :
                            <div className={classes.titleURL}>
                                <a href={`${item.url}`} target="_blank" rel={'noreferrer'}>
                                    {
                                        isColor ?
                                            <DivColor backgroundColor={contentName}/>
                                            :
                                            item.url
                                    }
                                </a>
                            </div>
                    }
                </div>
                {
                    iconDel &&
                    <div
                        onClick={() => delUrl(item)}
                        className={'cursor-pointer'}
                    >
                        <IconDel/>
                    </div>
                }
            </DivContent>

        </DivTextInputs>
    );
}

export default TextInputURLCustom;
