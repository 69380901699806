import React, {useEffect, useState} from 'react';
import MenuItemContainer from '../MenuItemContainer';
import {useGlobal} from 'reactn';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import NumberComponent from './NumberComponent';
import {ReactComponent as NumOfLine} from '../../../asSvg/num.svg';
import {ReactComponent as NumCalendar} from '../../../asSvg/numMonitor.svg';
import {ReactComponent as FontIcon} from '../../../asSvg/font.svg';
import ColorPickerComponent from './ColorPickerComponent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {ReactComponent as OneImage} from '../../../asSvg/one.svg';
import {ReactComponent as TwoImage} from '../../../asSvg/two.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import useIntlId from '../../../hooks/useIntlId';
import {TextField} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {ReactComponent as IdentityImage} from '../../../asSvg/hubman.svg';
import Button from '@material-ui/core/Button';
import {refetchAllEvents} from '../../../actions/refetchSourcce';
import DialogCustom from '../../DialogCustom/DialogCustom';
import ListTheme from './ListTheme';
import {useAnnouncement} from '../../../context/AnnouncementContext';
import {findIndex} from 'lodash';
import ListAllProject from './ListAllProject';
import {ReactComponent as EditWorkSpace} from '../../../asSvg/icons/editWorkSpace.svg';
import {milestoneSettingCollRef, milestoneSettingDocRef} from '../../../actions/group';
import {toast} from 'react-toastify'
import {db} from "../../../firebaseConfig";

function Views({
                   setMonthValue,
                   handleBlur,
                   numLines,
                   setNumLines,
                   handleNumOfLine,
                   editWorkSpace,
                   onClose,
               }) {
    const classes = useStyles()
    const [tab] = useGlobal('tab')
    const [view] = useGlobal('view')
    // const [user] = useGlobal('user')
    const [idCustom, setIdCustom] = useState(null)
    const [listCustom, setListCustom] = useState([])
    const [settingList,] = useGlobal('settingList')
    const [colorPrimary] = useState("#1790FF")
    const {lock,} = useAnnouncement()

    // setting view state
    const [settingName, setSettingName] = useGlobal('settingName');
    const [colors] = useGlobal("colors")
    const [sort] = useGlobal('projectSortSetting');
    const [colorShow,] = useGlobal('identityShow');
    const [calendarTab1] = useGlobal('calendarTab1')
    const [calendarTab2] = useGlobal('calendarTab2')
    const [fontSize,] = useGlobal('fontSize')
    const [showTitleEvent,] = useGlobal('showTitleEvent')
    const [segment] = useGlobal('segment')
    const [code, setCode] = useGlobal('codeSetting')
    const [mySetting] = useGlobal('mySetting');
    const [milestoneView] = useGlobal('milestoneView');
    const [focusColor] = useGlobal('focusColor');
    const [milestoneColor] = useGlobal('milestoneColor')

    const [visible, setVisible] = useState(false)
    const [projectsSelect, setProjectSelect] = useState([])

    const [tab1, setTab1] = useState(calendarTab1)
    const [tab2, setTab2] = useState(calendarTab2)
    const [fSize, setFSize] = useState(fontSize)
    const [showIdentities, setShowIdentities] = useState(colorShow)
    const [showTitle, setShowTitle] = useState(showTitleEvent)
    const [projectSort, setProjectSort] = useState(sort)
    const [color, setColor] = useState(colors)
    const [segments, setSegments] = useState(segment)
    const [month, setMonth] = useState(tab === 2 ? calendarTab2 : tab === 1 ? calendarTab1 : '')
    const [selectSetting, setSelectSetting] = useState('')
    const [showMilestoneView, setShowMilestoneView] = useState(milestoneView)
    const [focusC, setFocusC] = useState(focusColor)
    const [milestoneClr, setMilestoneClr] = useState(milestoneColor)
    const [timeView, setTimeView] = useState(sort?.timeView || 6);

    const [
        projectView,
        calendarView,
        titleButtonSwitch,
        titleButtonShowColor,
        titleView,
        saveButtonIntl,
        settingIntl,
        noticeProject,
        noticeToast,
        saveSettingSuccess,
        projectIntl,
        identityIntl,
        focusIntl,
        oneLine,
        twoLine,
        showMilestone,
        milestoneColorIntl,
        useSettingIntl,
    ] = useIntlId(
        ['milestone.setting.calendarView',
            'milestone.setting.projectView',
            'milestone.viewSetting.titleDisplay',
            'milestone.viewSetting.identityColor',
            'milestone.viewSetting.project',
            'edit.workspace.save',
            'milestone.settingView',
            'milestone.viewSetting.noticeProject',
            'milestone.viewSetting.',
            'app.notification.saveSettingSuccess',
            'appy.tooltip',
            'app.profile.identities',
            'milestone.focusColor',
            'milestone.displayOneLine',
            'milestone.displayTwoLine',
            'setting.showMilestone',
            'milestone.viewSetting.milestoneColor',
            'edit.workspace.useSetting',
        ])

    useEffect(() => {
        if (!mySetting?.id)
            return null;

        setIdCustom(mySetting?.id)
        setSelectSetting(mySetting?.id)
        setSettingName(mySetting?.name)
        setCode(mySetting?.code)
        setProjectSort(mySetting?.projectSortSetting)
        setProjectSelect(mySetting?.listProjectSetting)
        // eslint-disable-next-line
    }, [mySetting])

    const mergeArray = (listDefault, listTheme) => {
        let newArray = []
        if (listTheme.length === 0)
            return listDefault
        listDefault.forEach(item => {
            let idx = findIndex(listTheme, {id: item.id})
            if (idx === -1) {
                newArray.push(item)
            } else {
                newArray.push(listTheme[idx])
            }
        })
        return newArray
    }

    const onChangeShowColor = () => {
        setShowIdentities(!showIdentities)
    }

    const onChangeTypeTitle = () => {
        setShowTitle(!showTitle, () => {
            refetchAllEvents()
        });
    }

    const onChangeMilestoneView = (value) => {
        setShowMilestoneView(value)
    }

    const onChangeFocusColor = (value) => {
        setFocusC(value)
    }

    const onChangeMilestoneColor = (value) => {
        setMilestoneClr(value)
    }

    const changeFontSize = (type) => {
        let dataCheck = type && type === "add" ? Number(fSize) + 1 : type && type === "remove" ? Number(fSize) - 1 : Number(fSize);

        if (dataCheck < 5) {
            setFSize(5)
            return null
        }
        if (dataCheck > 15) {
            setFSize(15)
            return null
        }

        setFSize(dataCheck)
    }

    const removeCal = (number) => {
        for (let i = 35; i >= number; i--) {
            if (window['calendar' + i] && typeof window['calendar' + i].destroy === 'function') {
                window['calendar' + i].destroy()
                window['calendar' + i] = null
            }
        }
    }

    const handleMonth = (type) => {
        let dataCheck = type && type === "add" ? Number(month) + 1 : type && type === "remove" ? Number(month) - 1 : Number(month);

        if (dataCheck < 1 || dataCheck === '' || !dataCheck) {
            setMonth(1)
            if (tab === 2) {
                setTab2(1)
                removeCal(1)
                // getNumOfProject()
            } else if (tab === 1) {
                setTab1(1)
                removeCal(1)
            }
            return
        }
        if (tab === 2) {
            const Calnumber2 = dataCheck > 36 ? 36 : (dataCheck < 1 ? 0 : dataCheck)
            setMonth(Calnumber2)
            setTab2(Calnumber2)
            removeCal(Calnumber2)
        } else if (tab === 1) {
            const Calnumber1 = dataCheck > 36 ? 36 : (dataCheck < 1 ? 0 : dataCheck)
            setMonth(Calnumber1)
            setTab1(Calnumber1)
            removeCal(Calnumber1)
        }
    }


    const onChangeSort = (bool) => {
        setProjectSort({isSort: bool, timeView: timeView});
    }

    const handleChange = (value) => {
        if (value < 1 || value > 12) return;
        setTimeView(Number(value))
        projectSort.timeView = Number(value)
        setProjectSort(projectSort)
    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleBlur()
        }
    }

    const handleSegment = (value) => {
        setSegments(value)
    }

    useEffect(() => {
        setListCustom(mergeArray(listDefault, settingList))
    }, [settingList, colors.primary])

    const onSave = async (idSetting) => {

        if (!projectsSelect?.length) {
            toast.warning(noticeProject)
            return;
        }

        if (!settingName && !code) {
            toast.warning(noticeToast)
            return;
        }

        let id = idSetting || (settingList.length + 1)

        if (idSetting > settingList.length){
            projectsSelect.forEach((project) => {
                project.isShowMilestone = true
                project.isShowAllDay = true
            })
        }

        setProjectSelect([...projectsSelect])

        const settingMilestone = {
            id: id,
            name: settingName,
            code: code,
            colors: color,
            identityShow: showIdentities,
            showTitleEvent: showTitle,
            calendarTab1: tab1,
            calendarTab2: tab2,
            fontSize: fSize,
            projectSortSetting: projectSort,
            segment: segments,
            monthValue: month,
            listProjectSetting: projectsSelect,
            focusColor: focusC,
            milestoneView: showMilestoneView,
            milestoneColor: milestoneClr
        }

        try {
            const batch = db.batch();

            if (id === 999) {
                const snap = await milestoneSettingCollRef().get()
                snap.docs.forEach(doc => {
                    const docUseRef = milestoneSettingDocRef(doc.id)
                    if (doc.id === '999') {
                        batch.set(docUseRef, {useSetting: true}, {merge: true})
                    } else {
                        batch.set(docUseRef, {useSetting: false}, {merge: true})
                    }
                })
            }

            const docRef = milestoneSettingDocRef(`${id}`)

            if (id === 999) {
                const checkDocRef = await docRef.get();
                if (!checkDocRef.exists) {
                    batch.set(docRef, {useSetting: true}, {merge: true})
                }
            }

            batch.set(docRef, settingMilestone, {merge: true})

            await batch.commit()
            onClose?.()
            toast.success(saveSettingSuccess)

        } catch (e) {
            console.log(e);
        }
    }

    const setSetting = (data) => {
        setSelectSetting(data.id)

        if (selectSetting !== data.id && data.colors) {
            setTab1(data.calendarTab1)
            setTab2(data.calendarTab2)
            setFSize(data.fontSize)
            setShowIdentities(data.identityShow)
            setShowTitle(data.showTitleEvent)
            setProjectSort(data.projectSortSetting)
            setColor(data.colors)
            setSegments(data.segment)
            setMonth(data.monthValue)
            setShowMilestoneView(data.milestoneView)
            setFocusC(data.focusColor)
            setMilestoneClr(data.milestoneColor || 'project')
            if (data.listProjectSetting) {
                // setProjectList([...data.listProjectSetting])
                setProjectSelect([...data.listProjectSetting])
            }
        }

        if (data.name && data.code) {
            setCode(data.code)
            setSettingName(data.name)
        }

        setIdCustom(data?.id)
    }


    return (
        <DialogCustom className={classes.dialog} open={editWorkSpace} onClose={onClose}>
            <div style={{padding: 5}}>
                <div className={classes.divTitle}>{settingIntl}</div>
                <div className={'flex justify-center mb-3.5 mx-1'}>
                    <Grid item xs={12} className={classes.gridItem}>
                        <MenuItemContainer justifyContent={'space-around'}>
                            <Button className={!projectSort.isSort ? classes.switchButton : classes.switchButton1}
                                    variant="text"
                                    onClick={() => {
                                        onChangeSort(false)
                                    }}>{projectView}</Button>
                            <Button className={projectSort.isSort ? classes.switchButton : classes.switchButton1}
                                    variant="text"
                                    onClick={() => onChangeSort(true)}>{calendarView}</Button>
                        </MenuItemContainer>
                    </Grid>
                    {
                        projectSort.isSort &&
                        <div className={classes.textFieldBody}>
                            <div className={classes.titleContentLeft}>{titleView}</div>
                            <TextField
                                className={classes.textField}
                                type={'number'}
                                variant="outlined"
                                value={timeView}
                                onChange={(e) => handleChange(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment
                                        position="end">{view === "CustomMonth" ? "月" : view === "CustomWeek" ? "週目" : "日"}</InputAdornment>
                                }}
                                disabled={false}
                                inputProps={{
                                    step: 1
                                }}
                                // onBlur={handleBlurTextField}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                    }
                </div>
                <div className={'flex m-1'}>
                    {
                        !projectSort?.isSort &&
                        <Grid item xs={6} className={classes.gridItem}>
                            <MenuItemContainer>
                                <NumberComponent
                                    imgComponent={
                                        <NumOfLine className={`${classes.imgSvg} clf-right-10 clf-padding3`}/>
                                    }
                                    value={month}
                                    setValue={setMonthValue}
                                    handleBlur={handleMonth}
                                />
                            </MenuItemContainer>
                        </Grid>
                    }
                    {
                        !projectSort?.isSort &&
                        <Grid item xs={6} className={classes.gridItem}>
                            <MenuItemContainer>
                                <NumberComponent
                                    imgComponent={<NumCalendar className={`${classes.imgSvg} clf-right-10`}/>}
                                    value={numLines}
                                    setValue={setNumLines}
                                    handleBlur={handleNumOfLine}
                                />
                            </MenuItemContainer>
                        </Grid>
                    }
                    <Grid item xs={6} className={classes.gridItem}>
                        <MenuItemContainer>
                            <NumberComponent
                                imgComponent={<FontIcon className={`${classes.imgSvg} clf-right-10`}/>}
                                value={fSize}
                                setValue={setFSize}
                                handleBlur={changeFontSize}
                            />
                        </MenuItemContainer>
                    </Grid>
                    {
                        window.location.pathname === '/' && tab === 1 &&
                        <Grid item xs={6} className={classes.gridItem}>
                            <MenuItemContainer>
                                <Tabs value={segments}
                                      onChange={(event, value) => {
                                          handleSegment(value)
                                      }}
                                      className={classes.handleView}
                                >
                                    <Tab className={classes.tabIndex} id={'calendar-clf-one'} value={'one'}
                                         label={<OneImage
                                             style={{fill: segments === 'two' ? '#444444' : '#FFFFFF'}}
                                         />}/>
                                    <Tab className={classes.tabIndex} id='calendar-clf-two' value={'two'}
                                         label={<TwoImage
                                             style={{fill: segments === 'one' ? '#444444' : '#FFFFFF',}}
                                         />}/>
                                </Tabs>
                            </MenuItemContainer>
                        </Grid>
                    }
                </div>
                <div className={'flex'}>
                    <div className={classes.marginBottom}>
                        <ColorPickerComponent color={color} setColor={setColor}/>
                        <Grid item xs={12} className={classes.gridItem}
                              style={{marginLeft: 5, width: '100%', marginTop: 15}}>
                            <MenuItemContainer justifyContent={'space-around'}>
                                <IdentityImage className={'svg-identity'}/>
                                <FormControlLabel
                                    className={classes.switchView}
                                    value="top"
                                    control={
                                        <Switch
                                            color="secondary"
                                            checked={showIdentities}
                                            onChange={onChangeShowColor}
                                        />
                                    }
                                    label={titleButtonShowColor}
                                    labelPlacement="start"
                                />
                            </MenuItemContainer>
                        </Grid>
                        <Grid item xs={12} className={classes.gridItem} style={{marginLeft: 5, width: '100%'}}>
                            <MenuItemContainer justifyContent={'space-around'}>
                                <FormControlLabel
                                    className={classes.switchView}
                                    value="top"
                                    control={
                                        <Switch
                                            color="secondary"
                                            checked={showTitle}
                                            onChange={onChangeTypeTitle}
                                        />
                                    }
                                    label={titleButtonSwitch}
                                    labelPlacement="start"
                                />
                            </MenuItemContainer>
                        </Grid>

                    </div>
                    <div className={'w-2/4'}>
                        <Grid item xs={12} className={classes.gridItem}>
                            <div className={classes.divColor}>{milestoneColorIntl}</div>
                            <MenuItemContainer justifyContent={'space-around'}>
                                <Button
                                    className={milestoneClr === 'project' ? classes.switchButton : classes.switchButton1}
                                    variant="text"
                                    onClick={() => {
                                        onChangeMilestoneColor('project')
                                    }}>{projectIntl}</Button>
                                <Button
                                    className={milestoneClr === 'identity' ? classes.switchButton : classes.switchButton1}
                                    variant="text"
                                        onClick={() => onChangeMilestoneColor('identity')}>{identityIntl}</Button>
                            </MenuItemContainer>
                        </Grid>
                        <div style={{width: '100%', marginLeft: 5, marginBottom: 10}}>
                            <div className={classes.divColor}>{showMilestone}</div>
                            <div
                                style={{backgroundColor: '#fff'}}
                                className={classes.divButton}
                            >
                                <div
                                    style={{marginLeft: 5}}
                                    className={showMilestoneView === 1 ? classes.divA : classes.divB}
                                    onClick={() => {
                                        onChangeMilestoneView(1)
                                    }}
                                >
                                    <div className={classes.divText}>
                                        <div>{oneLine}</div>
                                    </div>
                                </div>
                                <div
                                    style={{marginRight: 5}}
                                    className={showMilestoneView === 2 ? classes.divA : classes.divB}
                                    onClick={() => {
                                        onChangeMilestoneView(2)
                                    }}
                                >
                                    <div className={'flex w-full mx-1'}>
                                        <div className={classes.divText1}>
                                            {twoLine}
                                        </div>
                                        <div style={{width: '50%', backgroundColor: '#fff', borderRadius: 15}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Grid item xs={12} className={classes.gridItem}>
                            <div className={classes.divColor}>{focusIntl}</div>
                            <MenuItemContainer justifyContent={'space-around'}>
                                <Button className={focusC === 'project' ? classes.switchButton : classes.switchButton1}
                                        variant="text"
                                        onClick={() => {
                                            onChangeFocusColor('project')
                                        }}>{projectIntl}</Button>
                                <Button className={focusC === 'identity' ? classes.switchButton : classes.switchButton1}
                                        variant="text"
                                        onClick={() => onChangeFocusColor('identity')}>{identityIntl}</Button>
                            </MenuItemContainer>
                        </Grid>
                    </div>
                </div>
                <ListAllProject
                    projectsSelect={projectsSelect}
                    setProjectSelect={setProjectSelect}
                />
                <div className={classes.btnAction}>
                    <Button
                        onClick={() => onSave(999)}
                        type="primary"
                        className={classes.btnSaveWorkSpace}>
                        <EditWorkSpace className={classes.iconSave}/>
                        {useSettingIntl}
                    </Button>
                </div>
                <ListTheme listCustom={listCustom}
                           idCustom={idCustom}
                           setSetting={setSetting}
                           lock={lock}
                           colorPrimary={colorPrimary}
                           onSave={onSave}
                           visible={visible}
                           setVisible={setVisible}
                />
                <div className={classes.btnAction}>
                    <Button
                        disabled={mySetting.id > 8 && selectSetting === ''}
                        onClick={() => onSave(selectSetting)}
                        type="primary"
                        className={classes.btnSaveWorkSpace}>
                        <EditWorkSpace className={classes.iconSave}/>
                        {saveButtonIntl}
                    </Button>
                </div>
            </div>
        </DialogCustom>
    )
}

const useStyles = makeStyles(theme => ({
    handleView: {
        width: '100%',
        height: '100%',
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0 5px",
        color: "#fff",
        display: 'grid',
        justifyContent: 'center',
        "&.MuiTabs-root": {
            minHeight: "unset !important",
        },
        "& .MuiTab-root": {
            color: "#000",
            minWidth: "auto",
            padding: "5px 15px",
            minHeight: "auto",
            fontWeight: "bold",
            fontSize: "1.2rem",
            borderRadius: "20px"
        },
        "& .Mui-selected": {
            background: theme.props.primaryColor,
            color: "#fff",
            height: '100%',
            minHeight: '29px',
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "center",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: 'initial'
        }
    },
    gridItem: {
        marginBottom: 15,
        "&:hover": {
            opacity: 0.8,
        },
        "& .svg-identity": {
            width: 20,
            marginLeft: 15
        },
    },
    tabIndex: {
        minWidth: '65px !important'
    },
    imgSvg: {
        width: 20,
        padding: 0,
        fill: theme.props.primaryColor,
        cursor: 'pointer'
    },
    textField: {
        width: "100px",
        "& .MuiOutlinedInput-input": {
            // padding: "13px 0 13px 10px"
        },
        "& ::-webkit-inner-spin-button": {
            width: 30,
            height: 50,
        },
        "& .MuiOutlinedInput-adornedEnd": {
            height: 35
        },
        "&& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid black'
        }
    },
    textFieldBody: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 10
    },
    titleContentLeft: {
        color: theme.props.primaryColor,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "80%",
        height: 35,
        display: "flex",
        alignItems: "center",
        marginRight: "10px",
        textTransform: 'uppercase',
        alignContent: 'center',
        marginLeft: 10
    },
    switchView: {
        color: theme.props.primaryColor,
        textTransform: 'uppercase',
        fontWeight: 'bold !important',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingRight: 10,
    },

    switchButton: {
        background: theme.props.primaryColor,
        alignItems: 'center',
        variant: "raised",
        color: "white",
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: theme.props.primaryColor,
        },
        margin: '5px',
        width: '50%',
        fontSize: 12
    },

    switchButton1: {
        alignItems: 'center',
        variant: "raised",
        color: "black",
        '&:hover': {
            backgroundColor: '#fff',
        },
        borderRadius: '20px',
        width: '50%',
        fontSize: 12
    },

    marginBottom: {
        maxWidth: '50%',
        paddingRight: 4,
        paddingTop: 5,
        marginBottom: 15,
    },

    dialog: {
        "& .MuiDialog-paperWidthSm": {
            backgroundColor: "#f1f1f1",
            maxWidth: "60%",
            overflow: "unset"
        }
    },

    btnSaveWorkSpace: {
        position: "relative",
        fontWeight: 700,
        textTransform: "uppercase",
        color: "#fff",
        border: "none",
        outline: "none",
        height: 37,
        borderRadius: "7px",
        backgroundColor: theme.props.primaryColor,
        minWidth: 300,
        '&:hover': {
            border: "none",
            outline: "none",
            backgroundColor: theme.props.primaryColor,
        },
        '&:focus': {
            border: "none",
            outline: "none",
        },
        "& svg": {
            position: "absolute",
            left: 5,
            top: "50%",
            marginTop: -15
        }
    },

    divTitle: {
        textAlign: "center",
        width: '100%',
        paddingTop: 10,
        marginBottom: 20,
        fontWeight: 700,
        color: theme.props.primaryColor,
        textTransform: 'uppercase'
    },

    imgLock: {
        position: 'absolute',
        top: '37%',
        left: '40.4%',
        width: 30
    },

    btnAction: {
        marginTop: 10,
        textAlign: "center"
    },

    iconSave: {
        fill: theme.props.primaryColor,
    },

    label: {
        width: 33,
        height: 15,
        viewBox: '0 0 33 15'
    },

    contentText: {
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: 20,
        color: '#000',
        fontWeight: 'bold',
        cursor: 'pointer'
    },

    divText: {
        width: '100%',
        textAlign: "center",
        backgroundColor: '#fff',
        borderRadius: 15,
        margin: '0 5px'
    },
    divText1: {
        textAlign: "center",
        backgroundColor: '#fff',
        width: '50%',
        borderRadius: 15
    },

    divColor: {
        color: theme.props.primaryColor,
        width: '100%',
        textAlign: 'center'
    },

    divButton: {
        height: 40,
        borderRadius: 8,
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    divItem: {
        width: '100%',
        height: 30,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 5,
        borderRadius: 10
    },

    divA: {
        width: '100%',
        height: 30,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 15,
        backgroundColor: theme.props.primaryColor,
        cursor: 'pointer'
    },

    divB: {
        width: '100%',
        height: 30,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 15,
        backgroundColor: '#f1f1f1',
        cursor: 'pointer'
    }

}))

export const listDefault = [
    {
        id: 1,
    },
    {
        id: 2,
    },
    {
        id: 3,
    },
    {
        id: 4,
    },
    {
        id: 5,
    },
    {
        id: 6,
    },
    {
        id: 7,
    },
    {
        id: 8,
    },
]

export default Views;
