import React, {useEffect, useState} from 'react'
import {Input, Switch} from 'antd';
import {makeStyles} from '@material-ui/core/styles';
import {findIndex} from 'lodash';
import {CALENDAR_COLLECTION} from '../../../config/constants';
import {msgError} from '../../../utils/msg';
import {getGlobal, useGlobal} from 'reactn';
import {useAnnouncement} from '../../../context/AnnouncementContext';
import {barRef} from '../../../common/firebaseRef/annoucement';
import getFocusKey from "../../Application/getFocusKey";
import LoadCalendar from "../../Application/LoadCalendar";
import {db} from "../../../firebaseConfig";

function TargetHighLight({fieldName, checked, setchecked, checkName, showTime, Intl, fieldFocusHighLight}) {
    const classes = useStyles()
    const [focusText, setFocusText] = useGlobal('focusText')
    const [calendarUser] = useGlobal("calendarUser")
    const {bar, lock} = useAnnouncement()
    const [edit, setEdit] = useState(false)
    const [refetchDateNow] = useGlobal('refetchDateNow')
    const [textTarget, setTextTarget] = useState('')
    const {user} = getGlobal()
    const [view] = useGlobal('view')
    const [focusHighLight, setFocusHighLight] = useGlobal('focusHighLight')

    useEffect(() => {
        let forcusTarget = getFocusKey(showTime, refetchDateNow, LoadCalendar({calendarUser}))
        let index = findIndex(focusText, {'date': forcusTarget})
        if (index !== -1 && focusText[index].focusText) {
            setTextTarget(focusText[index].focusText)
        } else {
            setTextTarget('')
        }
        // eslint-disable-next-line
    }, [focusText, refetchDateNow, view])

    const handleUpdateTarget = async (fieldName) => {
        let date
        switch (fieldName) {
            case 'Today':
                date = getFocusKey("CustomDay", refetchDateNow, LoadCalendar({calendarUser}))
                break;
            case 'Week':
                date = getFocusKey("CustomWeek", refetchDateNow, LoadCalendar({calendarUser}))
                break;
            default :
                date = getFocusKey("", refetchDateNow, LoadCalendar({calendarUser}))
                break;
        }
        setFocusHighLight({})

        try {
            let idx = findIndex(focusText, {"date": date})
            if (idx === -1) {
                focusText.push({
                    "focusText": textTarget,
                    "date": date
                })
                setFocusText([...focusText])
            } else {
                focusText[idx] = {
                    "focusText": textTarget,
                    "date": date
                }
                setFocusText([...focusText])
            }
            await db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .set({
                        focusText: focusText
                    },
                    {merge: true}
                )
            setEdit(false)
        } catch (e) {
            console.log(e);
            msgError(e.toString())
        }

    }

    const handleCheckedHighLight = async (fieldName, value) => {
        setchecked(value)
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                [fieldName]: value
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={(edit || focusHighLight?.target === fieldFocusHighLight) ? classes.itemFocus : classes.item}>
            <div className={classes.listText}>
                <div className={classes.content}>
                    {
                        (edit || focusHighLight?.target === fieldFocusHighLight) ?
                            <div className={`${classes.wrapHighLight}`}>
                                <div
                                    className={classes.showTime}>{getFocusKey(showTime, refetchDateNow, LoadCalendar({calendarUser}))}</div>
                                <Input
                                    disabled={lock}
                                    onPressEnter={() => handleUpdateTarget(fieldName)}
                                    onBlur={() => handleUpdateTarget(fieldName)}
                                    className={classes.input} autoFocus={true} value={textTarget}
                                    onChange={(e) => setTextTarget(e.target.value)}
                                />
                                <Switch
                                    className={classes.iconChecked}
                                    checked={checked}
                                    disabled={lock}
                                    onChange={() => handleCheckedHighLight(checkName, !checked)}
                                />
                            </div>
                            :
                            <div className={`${classes.wrapHighLight}`}>
                                <div
                                    className={classes.showTime}>{getFocusKey(showTime, refetchDateNow, LoadCalendar({calendarUser}))}</div>
                                <div onClick={() => {
                                    if (lock)
                                        return;
                                    setEdit(!edit)

                                }}
                                     className={classes.textHighLight}>{textTarget || Intl}</div>
                                <Switch
                                    className={classes.iconChecked}
                                    checked={checked}
                                    disabled={lock}
                                    onChange={() => handleCheckedHighLight(checkName, !checked)}
                                />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    item: {
        backgroundColor: "#fff",
        borderRadius: "13px",
        marginBottom: 7,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    itemFocus: {
        backgroundColor: theme.props.primaryColor,
        borderRadius: "13px",
        marginBottom: 7,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    content: {
        backgroundColor: "#e5e5e5",
        borderRadius: "10px",
        fontSize: 12,
        color: "#444444",
        margin: 2,
        width: "100%",
        padding: "8px 45px 8px 5px",
        "& .ant-input:focus, .ant-input-focused": {
            border: "none",
            boxShadow: "none"
        }
    },
    listText: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
    },
    wrapHighLight: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    showTime: {
        background: "#fff",
        color: theme.props.primaryColor,
        padding: "3px 10px",
        borderRadius: 9,
        fontWeight: 600,
        fontSize: 14,
        minWidth: 110,
        textAlign: "center"
    },
    input: {
        border: "none",
        backgroundColor: "transparent",
        padding: "0 0 0 10px",
        outline: "none",
        fontSize: 12,
        color: "#444444",
    },
    iconChecked: {
        // width: 28,
        // height: 28,
        position: "absolute",
        right: "-40px",
        display: "flex",
        alignItems: "center",
        // borderRadius: "50%",
        // border: `2px solid ${theme.props.primaryColor}`,
        // background: "#fff",
        // cursor: "pointer",
    },
    textHighLight: {
        cursor: "pointer",
        paddingLeft: 10,
        width: "100%"
    },
}))

export default TargetHighLight
