import {get} from 'lodash';
import {getGlobal} from 'reactn';
import {db} from "../../firebaseConfig";
import {CALENDAR_COLLECTION, USER_COLLECTION} from "../../config/constants";

export const myPrjGECollRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.collection(`${CALENDAR_COLLECTION}/${user_id}/projects`)
}

export const myPrjGEDocRef = (projectId) => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${CALENDAR_COLLECTION}/${user_id}/projects/${projectId}`)
}

export const myPrjGGCollRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.collection(`${USER_COLLECTION}/${user_id}/projects`)
}

export const myPrjGGDocRef = (projectId) => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${USER_COLLECTION}/${user_id}/projects/${projectId}`)
}
