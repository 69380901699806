import React from 'react';
import {Col, Popover, Row} from "antd";
import {makeStyles} from "@material-ui/core/styles";
import {publicIcon} from "../../Calendar/handlers/getIcon";
import PopperSetting from "./PopperSetting";
import {useAnnouncement} from "../../../context/AnnouncementContext";
import styled from 'styled-components'

function ListTheme({listCustom, idCustom, setSetting, onSave}) {
    const classes = useStyles()

    const {plan} = useAnnouncement()
    const Item = styled.div``


    return (
        <div className={classes.root}>
            <Row gutter={[4, 4]}>
                {
                    listCustom.map((i, index) => {
                        if (i.id > 8) return;
                        const ContainerItem = plan.numOfView <= index ? Item : Popover
                        return (
                            <Col key={index} span={6}>
                                <div className={classes.myWorkSpace}>
                                    <div className={classes.myWorkSpaceIndex}
                                         style={{color: "#9B939E"}}>{i?.id}</div>

                                    <button
                                        className={classes.myWorkSpaceRow}
                                        onClick={() => {
                                            setSetting(i)
                                        }}
                                        style={{border: idCustom === i?.id ? `solid 1px #ff0000` : "solid 1px #fff"}}
                                        disabled={plan.numOfView <= index}
                                    >
                                        {
                                            <ContainerItem
                                                id={i?.id}
                                                zIndex={9999}
                                                trigger="click"
                                                placement={"right"}
                                                destroyTooltipOnHide={{keepParent: false}}
                                                content={
                                                    <PopperSetting
                                                        onSave={() => {
                                                            onSave(i.id)
                                                        }}
                                                        setting={i}
                                                    />
                                                }

                                            >
                                                {
                                                    (plan.numOfView > index && i.colors) ?
                                                        <div>
                                                            <div
                                                                style={{backgroundColor: i.colors.primary,}}
                                                                className={classes.divSt}
                                                            >
                                                                <div className={classes.text}>{i.code}</div>
                                                            </div>

                                                            <div
                                                                style={{color: i.colors.primary}}
                                                                className={classes.textOver}
                                                            >
                                                                {i.name}
                                                            </div>
                                                        </div>
                                                        : <div style={{display: 'flex', justifyContent: 'center'}}>
                                                            <img src={publicIcon('view/plusBig.svg')} alt=""/>
                                                        </div>
                                                }
                                            </ContainerItem>
                                        }

                                        {
                                            plan.numOfView <= index &&
                                            <img className={classes.imgLock} src="/icons/iconKey.svg" alt=""/>
                                        }
                                    </button>

                                </div>
                            </Col>
                        )
                    })
                }
            </Row>

        </div>
    );
}

export default ListTheme;
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        padding: 10,
        borderRadius: "10px",
        margin: 10
    },
    myWorkSpace: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 5,
    },
    myWorkSpaceIndex: {
        width: 50,
        fontWeight: 700,
        fontSize: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    myWorkSpaceRow: {
        width: "calc(100% - 50px)",
        alignItems: "center",
        backgroundColor: "#fff",
        padding: 4,
        borderRadius: "5px",
        height: 100,
        overflow: "hidden",
        cursor: "pointer",
        position: "relative"
    },
    viewCalendar: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: 35,
        fontWeight: 700,
        WebkitTextStrokeWidth: "1px",
        WebkitTextStrokeColor: "#fff",
    },
    imgLock: {
        position: 'absolute',
        top: '37%',
        left: '40.4%',
        width: 30
    },
    colorUser: {
        backgroundColor: theme.props.primaryColor
    },

    popover: {
        "& .MuiPopover-paper": {
            overflow: "inherit"
        }
    },

    divSt: {
        width: '100%',
        height: 75,
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    text: {
        WebkitTextStrokeWidth: "1px",
        WebkitTextStrokeColor: "#fff",
        fontSize: 50,
        textTransform: 'uppercase'
    },

    textOver: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
}))
