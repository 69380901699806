import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import toast from '../../utils/toast';
import {useGlobal} from 'reactn'
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import {removeEvent} from "../../actions/refetchSourcce";
import {db} from "../../firebaseConfig";
import {API_ZOOM, CALENDAR_COLLECTION, DELETED_ACTION} from "../../config/constants";
import moment from "moment";
// import {getVoteBoxDocRef} from "../../common/firebaseRef/meetings";
import {ggTokenChecked} from "./google/auth";
import updateWatchChannelMyself from "../../common/updateWatchChannelMyself";
import useIntlId from "../../hooks/useIntlId";
import {useCalendarEvents} from "../../context/CalendarEventsContext";
import axios from "axios";
import {handleDeleteGoogleTasksSchedule} from "../../actions/googleTasks";

function DeleteEvent({open, setOpen, event, setEditInfo}) {

    // const [deleted, setDeleted] = useGlobal('deleted')
    const [prevAction, setPrevAction] = useGlobal('prevAction')
    const [isDelete] = useKeyboardJs('backspace')
    const [user] = useGlobal('user')
    const [DialogTitle, DialogDelete, DialogCancel] = useIntlId(['milestone.isDelete', 'dialog.action.delete', 'dialog.action.cancel'])
    const {deleteEvent} = useCalendarEvents()
    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (isDelete && open) {
            onDelete()
        }
        // eslint-disable-next-line
    }, [isDelete])

    const onDelete = async () => {
        if (event?.conferenceData) {
            console.log('delete');
            axios.post(API_ZOOM + '/delete', {
                type: 'meetings',
                meetingId: event?.zoomMeetingId,
            }).catch(e => console.log(e))
        }
        if (event?.googleTasksID) {
            handleDeleteGoogleTasksSchedule(event.googleTasksID).catch(e => console.log(e))
        }
        setOpen(false)
        setEditInfo(null)
        removeEvent(event.id)
        if (event.is_google || event.googleEvent)
            return deleteGoogleEvent(event)

        return deleteGeniamEvent(event)
    }
    const deleteGoogleEvent = async (event) => {
        try {
            await ggTokenChecked()
            const request = window.gapi.client.calendar.events.delete({
                "calendarId": event.project_uuid,
                "eventId": event.id
            })
            if (event?.isEventVote || event?.meetingId) {
                await deleteVoted(event)
            }
            await request.then()
            prevAction.push({
                type: DELETED_ACTION,
                event
            })
            setPrevAction([...prevAction])
            updateWatchChannelMyself(event.project_uuid)
            deleteEvent(event)
        } catch (e) {

            toast.error(e.toString())
        }
    }
    const deleteGeniamEvent = async (event) => {
        try {
            const time = moment().utc().format()
            await db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection('events')
                .doc(event.id)
                .set({isDeleted: true, updated_at: time}, {merge: true})

            if (event?.isEventVote || event?.meetingId) {
                await deleteVoted(event)
            }
            prevAction.push({
                type: DELETED_ACTION,
                event
            })
            setPrevAction([...prevAction])
            deleteEvent(event)
        } catch (e) {
            console.log(e);
            toast.error(e.toString())
        }
    }

    // const deleteMilestone = async (event) => {
    //     try {
    //         const time = moment().utc().format()
    //         const eventRef = db.doc(`${MILESTONE_COLLECTION}/${event.id}`)
    //         await eventRef.set({isDeleted: true, updated_at: time}, {merge: true})
    //         refetchSource("milestone")
    //     } catch (e) {
    //         console.log(e.toString())
    //     }
    // }
    const deleteVoted = async (event) => {
        try {
            const eventDocRef = db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection('eventBoxMeeting')
                .doc(event.id)

            await eventDocRef.set({
                isDeleted: true
            }, {merge: true})
            // const voteDocRef = getVoteBoxDocRef(event?.meetingId, event.id)
            // const voteSnapshot = await voteDocRef.get()
            // if (voteSnapshot.exists) {
            //     let voted = voteSnapshot.data().voted || {}
            //     voted[user.user_id] = 'no'
            //     batch.set(voteDocRef, {isAccepted: false, voted, updatedAt: moment().format()}, {merge: true})
            // }
            console.log('deleted')
            prevAction.push({
                type: DELETED_ACTION,
                event
            })
            setPrevAction([...prevAction])
        } catch (e) {
            console.log(e);
            toast.error(e.toString())
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogContentText>
                        {DialogTitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {DialogCancel}
                    </Button>
                    <Button
                        onClick={onDelete}
                        color="primary"
                        autoFocus
                    >
                        {DialogDelete}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default (DeleteEvent)
