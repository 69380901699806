import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Switch} from 'antd';
import AntdInput from './AntdInput';


function AnnouncementCheck({
                               name = "",
                               check,
                               setCheck,
                               value,
                               setValue,
                               label = "",
                               lock = false,
                               isOnlyCheck = false
                           }) {
    const classes = useStyles()
    const onChange = (checked) => {
        setCheck(checked)
    }
    return (
        <div className={`${lock ? classes.rootLock : classes.root} ${isOnlyCheck ? classes.borderNone : ''}`}>
            <div className={classes.name}>{name}</div>
            {
                check === undefined ?
                    <div className={classes.input}>
                        <AntdInput size={23} width={100} value={value} steps={1} min={1} label={label}
                                   onChange={(va) => {
                                       if (typeof va === "number") {
                                           setValue(va)
                                       }
                                   }}
                                   disabled={lock}/>
                        {
                            lock &&
                            <img src="/icons/iconKey.svg" alt=""/>
                        }
                    </div>
                    :
                    <div>
                        <Switch defaultChecked={check} onChange={onChange} disabled={lock}/>
                        {
                            lock &&
                            <img src="/icons/iconKey.svg" alt=""/>
                        }

                    </div>

            }
        </div>
    );
}

export default AnnouncementCheck;
const useStyles = makeStyles(theme => ({
    rootLock: {
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "row",
        padding: "10px 0 10px 0",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#F1F1F1",
        borderBottom: "solid 1px #fff",
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    root: {
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "row",
        padding: "10px 0 10px 0px",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#F1F1F1",
        borderBottom: "solid 1px #fff",
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    iconKey: {
        position: "absolute",
        left: 0,
        width: 14,
        top: "50%",
        transform: "translateY(-50%)"
    },
    name: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444"
    },
    input: {
        display: "flex",
    },
    borderNone: {
        borderBottom: "none !important",
    }
}))
