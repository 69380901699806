import {useCollection, useCollectionData} from "react-firebase-hooks/firestore";
import {useEffect} from "react";
import {getGlobal, setGlobal, useGlobal} from "reactn";
import {milestoneSettingCollRef} from "../actions/group";
import {remove} from "lodash/array";
import {myPrjGECollRef, myPrjGGCollRef} from "./firebaseRef/user";

export const useMySettingList = (userID) => {
    const [settingList, loading,] = useCollectionData(milestoneSettingCollRef())
    const [allMilestoneProject] = useGlobal('allMilestoneProject');

    useEffect(() => {
        if (!userID)
            return null;

        if (!loading && !settingList?.length && allMilestoneProject?.length) {
            addNewSettingDefault()
        }

        if (!loading && settingList) {
            setGlobal({
                settingList: settingList
            })
        }
        // eslint-disable-next-line
    }, [userID, settingList, loading, allMilestoneProject])

    const addNewSettingDefault = async () => {
        try {
            if (!allMilestoneProject?.length)
                return null;

            const list = []

            allMilestoneProject.forEach(project => {
                project.msSelected = true
                list.push(project)
            })

            let data = {
                id: 1,
                name: 'default setting',
                code: 'DF',
                colors: {primary: '#1790FF', secondary: '#7EE100'},
                identityShow: true,
                showTitleEvent: true,
                calendarTab1: 2,
                calendarTab2: 2,
                fontSize: 9,
                projectSortSetting: {isSort: false, timeView: 6},
                segment: 'one',
                monthValue: 2,
                focusColor: 'project',
                milestoneColor: 'project',
                milestoneView: 1,
                listProjectSetting: list,
                useSetting: true,
            }

            const ref = milestoneSettingCollRef(userID)

            await ref.doc('1')
                .set(data, {merge: true})

        } catch (e) {
            console.log(e);
        }
    }

}


export const useMyGEProjects = () => {
    const [projects, setProjects] = useGlobal('projects');
    const [list] = useCollection(myPrjGECollRef().where("is_deleted", "==", false))

    useEffect(() => {
        if (list) {
            let data = list.docs.map(pro => ({
                ...pro.data(),
                id: pro.id,
                msSelected: pro.data().msSelected !== undefined ? pro.data().msSelected : true,
            }));
            remove(data, d => d?.is_deleted)
            setProjects([...data])
        }
        // eslint-disable-next-line
    }, [list])

    return [projects]
}

export const useMyGGProjects = () => {
    const [projectsSyncWithGoogle, setProjectsSyncWithGoogle] = useGlobal("projectsSyncWithGoogle");
    const [list] = useCollection(myPrjGGCollRef())

    useEffect(() => {
        if (list) {
            const projects = list.docs.map(snap => ({...snap.data(), id: snap.id}))
            // remove old value selected appointment
            remove(projects, p => p?.id?.includes('pro_selected_'))
            setProjectsSyncWithGoogle(projects)
            const {syncLoad} = getGlobal()
            if (syncLoad)
                setGlobal({
                    syncLoad: false
                })
        }
        // eslint-disable-next-line
    }, [list])

    return [projectsSyncWithGoogle]
}
