import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import {ReactComponent as CloseSVG} from '../../asSvg/close.svg';
import React from 'react';
import useIntlId from '../../hooks/useIntlId';
import commonStyles from "./commonStyles";

export default function CancelButton({onClick, ...others}) {
    const classes = commonStyles()
    const [cancelIntl] = useIntlId(['dialog.cancel'])
    return (
        <CLFButtonSVG
            minHeight={"31px"}
            className={classes.secondary}
            name={cancelIntl}
            iconComponent={<CloseSVG/>}
            onClick={onClick}
            {...others}
        />
    )
}
