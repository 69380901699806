import {makeStyles} from "@material-ui/styles";

const commonStyles = makeStyles(theme => ({
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            maxWidth: "25px !important"
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
            maxWidth: "25px !important"
        }
    },
    iconSvg: {
        fill: theme.props.primaryColor,
        marginLeft: -20,
        marginRight: 5,
        cursor: 'pointer',
        maxWidth: "25px !important"
    },
}))


export default commonStyles
