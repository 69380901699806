import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ButtonAdd from './ButtonAdd';
import {DatePicker, Input} from 'antd';
import moment from 'moment';
import {ReactComponent as IconCalendarSVG} from '../../../asSvg/icons/iconCalendar.svg';
import {useAnnouncement} from '../../../context/AnnouncementContext';
import {cloneDeep} from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import {CloseCircleFilled} from '@ant-design/icons';
import useIntlId from '../../../hooks/useIntlId';
import {useGlobal} from 'reactn';

function Remind({setReminds}) {
    const classes = useStyles()
    const {lock, bar} = useAnnouncement()
    const [listRemind, setListRemind] = useState(bar?.reminds || [])
    const [, setDisabled] = useState(false)
    const [remindIntl] = useIntlId(['event.remind'])
    const [focusRemind, setFocusRemind] = useGlobal('focusRemind')

    useEffect(() => {
        setListRemind(cloneDeep(bar?.reminds))
        // eslint-disable-next-line
    }, [bar?.reminds])

    useEffect(() => {
        checkDisabled()
        // eslint-disable-next-line
    }, [listRemind])

    const checkDisabled = () => {
        listRemind.forEach(item => {
            if (item?.content === "" || item?.time === "") {
                return setDisabled(true)
            }
            return setDisabled(false)
        })
    }

    const onChange = (date, dateStr, index) => {
        let newReminds = cloneDeep(listRemind) || []
        newReminds[index].time = moment(dateStr).format('YYYY/MM/DD')
        setListRemind([...newReminds])
        setReminds([...newReminds])
        setFocusRemind(null)
    }
    const onChangeText = (e, index) => {
        let newReminds = cloneDeep(listRemind) || []
        newReminds[index].content = e.target.value
        setListRemind([...newReminds])
    }
    const handleAddRemind = () => {
        let newReminds = cloneDeep(listRemind) || []
        newReminds.push({content: "", time: moment(Date.now()).format('YYYY/MM/DD')})
        setReminds([...newReminds])
    }


    const onSaveContent = (index) => {
        setFocusRemind(null)
        let newReminds = cloneDeep(listRemind) || []
        if (newReminds[index].content === "" || newReminds[index].content === undefined) {
            setListRemind(cloneDeep(bar?.reminds))
            return
        }
        setReminds(cloneDeep(listRemind) || [])


    }
    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <div className={classes.title}>{remindIntl}</div>
                <ButtonAdd onClick={handleAddRemind} disabled={lock}/>
            </div>
            {
                listRemind?.length > 0 && listRemind.map((remind, index) => {
                    return (
                        <div key={index} className={classes.box}>
                            <Input
                                disabled={lock}
                                id={'remind' + index}
                                onPressEnter={() => {
                                    onSaveContent(index)
                                    const ipElement = document.getElementById('remind' + index)
                                    if (ipElement) {
                                        ipElement.blur()
                                    }
                                }}
                                onBlur={() => {
                                    onSaveContent(index)
                                }}
                                className={classes.valueRemind}
                                onChange={(e) => onChangeText(e, index)}
                                placeholder={remindIntl}
                                autoFocus={!remind?.content || remind?.time === focusRemind?.time}
                                value={remind?.content}/>
                            <div className={classes.datePicker}>
                                <DatePicker disabled={lock} defaultValue={moment(remind?.time, 'YYYY/MM/DD')}
                                            onChange={(date, dateStr) => onChange(date, dateStr, index)}/>
                                <IconCalendarSVG className={classes.iconCalendar}/>
                            </div>
                            <IconButton
                                disabled={lock}
                                onClick={() => {
                                    setFocusRemind(null)
                                    listRemind.splice(index, 1)
                                    setReminds([...listRemind]);
                                }}
                                className={classes.remove}>
                                <CloseCircleFilled/>
                            </IconButton>
                        </div>
                    )
                })
            }
            {/*{*/}
            {/*    (listRemind?.length !== bar?.reminds.length) &&*/}
            {/*    <div className={classes.btnGroup}>*/}
            {/*        <CancelButton onClick={handleCancel}/>*/}
            {/*        <SaveButton disable={disabled} onClick={onSave}/>*/}
            {/*    </div>*/}
            {/*}*/}


        </div>
    );
}

export default Remind;
const useStyles = makeStyles(theme => ({
    root: {
        padding: "15px 0",
        borderBottom: "solid 1px #fff",
    },
    box: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 8,
    },
    title: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444"
    },
    datePicker: {
        "& .ant-picker-input": {
            flexDirection: "row-reverse"
        },
        "& .ant-picker-suffix": {
            display: "none"
        },
        "& .ant-picker": {
            border: "none",
            padding: 0
        },
        '& .ant-picker-input > input': {
            fontWeight: 700,
            fontSize: 14,
            color: "#444444",
            width: "unset",
            textAlign: "center",
            background: "#fff"
        },
        '& .ant-picker-input:hover .ant-picker-clear': {
            display: "none"
        },
        position: "relative",
        padding: "5px 10px 5px 30px",
        backgroundColor: "#fff",
        borderRadius: "15px"

    },
    iconCalendar: {
        position: "absolute",
        left: 10,
        top: "50%",
        transform: "translateY(-50%)",
        fill: theme.props.primaryColor
    },
    valueRemind: {
        border: "none",
        borderRadius: "13px",
        fontSize: 14,
        padding: "5px 20px",
        marginRight: 6
    },
    btnGroup: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    remove: {}

}))
