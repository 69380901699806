import React, {createContext, useContext, useEffect, useState} from 'react'
import {getGlobal, setGlobal, useGlobal} from 'reactn'
import moment from "moment";
import {getGeniamEventsFromReccrence, getGoogleEvents} from "./common/getGoogleEvents";
import {concat, filter, findIndex, groupBy, remove} from 'lodash'
import {getGeniamEvents} from "./common/getGeniamEvents";
import {toast} from "react-toastify";
import {refetchSource} from "../actions/refetchSourcce";
import {scheduleAdjustmentRef} from "../common/firebaseRef/meetings"
import {getCalendarInfo} from "../common/calendarInfo";
import {getGeniamProjectColor} from "./common/getProjects";
// import {getGoogleEventsSync} from "./common/getGoogleEventSync";
// import {eventDiff} from "./common/eventDiff";
import {geEvs, ggEvs} from "../store/allEvents"
import {setProxyData} from "../store/setProxy";

export const CalendarEventsContext = createContext({})

export const useCalendarEvents = () => {
    return useContext(CalendarEventsContext)
}

export const CalendarEventsProvider = ({children}) => {
    const value = useEvents()
    return (
        <CalendarEventsContext.Provider value={value}>
            {children}
        </CalendarEventsContext.Provider>
    )
}

let refetchTimeout = null

const useEvents = () => {
    const [refetchDateNow] = useGlobal("refetchDateNow")
    const [calendarUser] = useGlobal("calendarUser")
    const [calendarTab1] = useGlobal("calendarTab1")
    const [calendarTab2] = useGlobal("calendarTab2")
    const [tab] = useGlobal("tab")
    const [user] = useGlobal("user")
    const [googleCalendarList] = useGlobal("googleCalendarList")
    const [geEvents, setGeEvents] = useGlobal("geEvents")
    const [ggEvents, setGGEvents] = useGlobal("ggEvents")
    const [, setNextSyncToken] = useGlobal("nextSyncTokens")
    const [projectSortSetting] = useGlobal("projectSortSetting")
    const [googleList, setList] = useState([])
    const [GoogleColor] = useGlobal("GoogleColor")
    const [googleIdentities, setGoogleIdentities] = useState([])
    const [groupsMs] = useGlobal('groupsMs')

    useEffect(() => {
        const {identitySettings = []} = calendarUser
        if (identitySettings.length) {
            setGoogleIdentities(filter(identitySettings, i => !i.isDeleted && !i.isArchive))
            return
        }
        const nextData = GoogleColor.map((color, index) => {
            return {
                id: index + 1,
                color,
                name: "",
                isArchive: false,
                isDeleted: false
            }
        })
        setGoogleIdentities(nextData)
        // eslint-disable-next-line
    }, [calendarUser])


    useEffect(() => {
        if (isListChange(googleList, googleCalendarList)) {
            setList([...googleCalendarList])
        }
        // eslint-disable-next-line
    }, [googleCalendarList])

    useEffect(() => {
        if (refetchTimeout) {
            clearTimeout(refetchTimeout)
        }
        refetchTimeout = setTimeout(() => {
            reLoadEvents()
        }, 300)
        // reLoadEvents()
        // eslint-disable-next-line
    }, [refetchDateNow, calendarTab1, googleList, projectSortSetting?.isSort, projectSortSetting?.timeView])

    const reLoadEvents = async () => {
        let numOfCal = calendarTab1

        if (projectSortSetting.isSort) {
            numOfCal = (projectSortSetting?.timeView) || 6
        }
        const {start, end} = getDateRange(refetchDateNow, numOfCal)

        if (!googleCalendarList.length) {
            setGGEvents({})
            return
        }
        try {
            const {events, nextSyncToken} = await getGoogleEvents(start, end)
            setGlobal({
                ggEventLoaded: true
            })
            if (!events)
                return
            setNextSyncToken(nextSyncToken)
            setProxyData(ggEvs, events)
            setGGEvents(events, () => {
                refetchSource("google")
            })
        } catch (e) {
            console.log(e);
            toast.error("load event google error")
            setGlobal({
                ggEventLoaded: true
            })
        }
    }

    useEffect(() => {
        // if (!projects.length)
        //     return
        if (!user?.user_id)
            return null

        getCalendarInfo(user.user_id)

        const sub = getGeniamEvents((snapshot) => {
            setGlobal({
                geEventLoaded: true
            })
            let numOfCal = tab === 1 ? calendarTab1 : calendarTab2

            if (projectSortSetting.isSort) {
                numOfCal = (projectSortSetting?.timeView) || 6
            }
            const {end} = getDateRange(refetchDateNow, numOfCal)
            let data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
            data = groupBy(data, "project_uuid")
            let result = {}
            Object.keys(data).forEach(key => {
                let value = data[key].map(event => ({...event, borderColor: getGeniamProjectColor(event.project_uuid)}))
                // let idx = findIndex(projects, {id: key})
                // if (idx !== -1)
                const recurrence = remove(value, item => item.recurrence)
                // console.log({recurrence, value});
                const recurenceEdited = remove(value, e => e.recurringEventId)
                // console.log({recurenceEdited});
                const recurring = getGeniamEventsFromReccrence(recurrence, recurenceEdited, end)
                // console.log({recurring});
                result[key] = {
                    events: concat(value, recurring),
                    id: key,
                    // name: projects[idx].name
                }
            })
            setProxyData(geEvs, result)
            // console.log({result});
            setGeEvents(result, () => {
                //refetchSource("geniam")
            })
        })

        const sub2 = scheduleAdjustmentRef(user.user_id)
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                let arr = []
                data.forEach(item => {
                    if (item?.nameSetting && item?.nameSetting?.replace(/\s+/g, '') !== "" && !item?.isDelete) {
                        arr.push(item)
                    }
                })
                setGlobal({listSettingShareUrl: arr})

            })

        return () => {
            if (sub)
                sub()
            if (sub2)
                sub2()
        }
        // eslint-disable-next-line
    }, [user?.user_id, groupsMs])

    const isListChange = (prevList, nextList) => {
        if (prevList.length !== nextList.length)
            return true
        let result = false
        prevList.forEach((oldvalue) => {
            let idx = findIndex(nextList, {id: oldvalue.id})
            if (idx === -1) {
                result = true
            }
        })
        return result
    }

    const refresh = () => {
        setGeEvents({...geEvents})
    }

    const createEvent = (event) => {
        const {ggEvents, geEvents} = getGlobal()
        const {project_uuid, is_google, googleEvent} = event
        if (is_google || googleEvent) {
            let idx = findIndex(googleCalendarList, {id: project_uuid})
            if (idx === -1)
                return;
            if (ggEvents[project_uuid]) {
                ggEvents[project_uuid].events.push(event)
            } else {
                ggEvents[project_uuid] = {
                    id: project_uuid,
                    events: [event],
                    name: googleCalendarList[idx].summary,
                    color: googleCalendarList[idx].backgroundColor
                }
            }
            // let _geEvents = snapshot(ggEvs)
            // if (_geEvents[project_uuid]) {
            //     _geEvents[project_uuid].events.push(event)
            // } else {
            //     _geEvents[project_uuid] = {
            //         id: project_uuid,
            //         events: [event]
            //     }
            // }
            // geEvs[project_uuid] = geEvents[project_uuid]
            // setGlobal({
            //         ggEvents: {...ggEvents}
            //     },
            //     () => {
            //         // console.log({ggEvents})
            //     }
            // )
            return
        }
        if (geEvents[project_uuid]) {
            geEvents[project_uuid].events.push(event)
        } else {
            geEvents[project_uuid] = {
                id: project_uuid,
                events: [event]
            }
        }

        setGlobal({
            geEvents: {...geEvents}
        }, () => {
            //console.log({geEvents})
        })

    }

    const deleteEvent = (event, callback) => {
        const {ggEvents, geEvents, voteEvents} = getGlobal()
        const {is_google, id, googleEvent} = event
        if (!event.project_uuid) {
            remove(voteEvents, {id})
            setGlobal({
                voteEvents: [...voteEvents]
            }, () => {
                refetchSource('vote')
            })
            return;
        }
        if (is_google || googleEvent) {
            Object.keys(ggEvents).forEach(key => {
                if (ggEvents[key]?.events?.length) {
                    remove(ggEvents[key].events, {id: id})

                }
            })
            setGlobal({
                ggEvents: {...ggEvents}
            }, () => {
                if (callback && typeof callback === "function") {
                    callback(event)
                }
            })
            return
        }
        Object.keys(geEvents).forEach(key => {
            if (geEvents[key]?.events?.length) {
                remove(geEvents[key].events, {id: id})

            }
        })
        setGlobal({geEvents: {...geEvents}}, () => {
            if (callback && typeof callback === "function") {
                callback(event)
            }
        })
    }

    const updatedEvent = (event, oldEvent) => {
        const {project_uuid, is_google, id, googleEvent} = event
        if (!event || !oldEvent)
            return;
        if (oldEvent.project_uuid !== project_uuid) {
            console.log('move calendar')
            deleteEvent(oldEvent, () => {
                createEvent(event)
            })
            return
        }
        if ((is_google || googleEvent) && ggEvents[project_uuid]?.events?.length) {
            let idx = findIndex(ggEvents[project_uuid].events, {id})
            if (idx !== -1) {
                ggEvents[project_uuid].events[idx] = event
                setGGEvents({...ggEvents})
            }
            return;
        }
        if (geEvents[project_uuid]?.events?.length) {
            let idx = findIndex(geEvents[project_uuid].events, {id})
            if (idx !== -1) {
                geEvents[project_uuid].events[idx] = event
                setGeEvents({...geEvents})
            }
        }

    }
    const changeTime = (event) => {
        const {ggEvents, geEvents} = getGlobal()
        const {project_uuid, is_google, id, googleEvent} = event
        if (is_google || googleEvent) {
            let idx = findIndex(ggEvents[project_uuid]?.events || [], {id})
            if (idx !== -1) {
                ggEvents[project_uuid].events[idx].start = event.start
                ggEvents[project_uuid].events[idx].end = event.end
                ggEvents[project_uuid].events[idx].allDay = event.allDay
                setGlobal({
                    // googleEvents: {...ggEvents},
                    ggEvents: {...ggEvents}
                })
            }
            return
        }
        if (geEvents[project_uuid]?.events?.length) {
            let idx = findIndex(geEvents[project_uuid].events, {id})
            if (idx !== -1) {
                geEvents[project_uuid].events[idx].start = event.start
                geEvents[project_uuid].events[idx].end = event.end
                geEvents[project_uuid].events[idx].allDay = event.allDay
                setGlobal({
                    geEvents: {...geEvents}
                })
            }
        }

    }
    const revertEvent = (event, oldEvent) => {
        deleteEvent(event)
        createEvent(oldEvent)
    }

    return {
        geEvents,
        ggEvents,
        createEvent,
        deleteEvent,
        updatedEvent,
        revertEvent,
        changeTime,
        refresh,
        reLoadEvents,
        googleIdentities
    }
}

export const getDateRange = (date, num) => {
    const type = 'month'
    const start = moment(date).startOf(type).subtract(1, type).utc().format()
    const end = moment(start).add(num + 2, type).utc().format()
    return {
        start,
        end
    }
}

