import React, {useState} from 'react';
import {toast} from "react-toastify";
import gerenatorGoogleRefreshToken from "../Calendar/google/gerenatorGoogleRefreshToken";
import MenuItemContainer from "./MenuItemContainer";
import {makeStyles} from "@material-ui/core/styles";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {Typography} from '@material-ui/core'
import useIntlId from "../../hooks/useIntlId";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 5
    },
    error: {
        width: '90%',
        margin: "auto",
        color: "red",
        marginTop: 20,
        "& p": {
            fontSize: 15
        }
    },
    buttonAntd: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`
    },
    email: {
        color: theme.props.primaryColor,
        fontSize: '1.3rem',
        fontWeight: 'bold',
        marginLeft: 10
    }
}))

function LoginTab(props) {
    const classes = useStyles();
    const [error, setError] = useState('')
    const [email, loginwithgoogle] = useIntlId([
        'settings.email',
        'app.auth.loginwithgoogle',
    ])
    return (
        <div>
            <div className={classes.root}>
                <MenuItemContainer width={252} height={39} justifyContent={'space-between'} alignItems={'center'}>
                    <div className={classes.email}>{email}</div>
                    <CLFButtonSVG
                        name={loginwithgoogle}
                        onClick={async () => {
                            try {
                                setError('')
                                const res = await window.gapi.auth2.getAuthInstance().grantOfflineAccess()
                                let code = res.code
                                const user = window.gapi.auth2.getAuthInstance().currentUser.get()
                                await user.reloadAuthResponse()
                                if (!user.hasGrantedScopes("https://www.googleapis.com/auth/calendar")) {
                                    setError("Geniam needs access to your google calendar to be able to sync events! Click login to re-grant permission")
                                    return
                                }

                                const userProfile = {
                                    email: user.getBasicProfile().getEmail(),
                                    name: user.getBasicProfile().getName(),
                                }
                                gerenatorGoogleRefreshToken(code, userProfile)
                            } catch (e) {
                                console.log(e);
                                toast.error('Login Fail: ' + e?.error)
                            }
                        }}
                        className={classes.buttonAntd}

                    />
                </MenuItemContainer>

            </div>
            <div className={classes.error}>
                {
                    error ?
                        <Typography> {error} </Typography>
                        :
                        null
                }
            </div>
        </div>
    );
}

export default LoginTab
