import React, {memo, Suspense, useContext, useEffect, useState} from 'react';
import './App.css';
import {onRestore,} from "./actions/calendar";
import {getGlobal, setGlobal, useGlobal} from 'reactn'
import {Route, Switch} from 'react-router-dom'
import {ListSetting, Milestone} from './components/splitting'
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import ExpDateCheckDialog from "./components/PackageDialog/ExpDateCheckDialog";
import moment from "moment";
import 'moment/locale/ja'
import {IntlProvider} from "react-intl";
import {LanguageMessageContext} from "./context/LanguageMessageContext";
import usePushNotifications from "./hooks/notifications/usePushNotifications";
import {CalendarEventsProvider} from "./context/CalendarEventsContext";
import {swapProjectByOrder} from "./components/GroupCalendar/common";
import Search from "./components/Search"
import {concat, findIndex, isString} from 'lodash'
import useGoogleCalendarWatch2 from "./hooks/useGoogleCalendarWatch2";
import {getEventsSync} from "./context/common/getEventsSync";
import ClfLinearProgress from "./components/Custom/CLFLinearProgress";
import NotFound from "./components/NotFound/NotFound";
import {milestoneSettingCollRef, milestoneSettingDocRef, projectsCalendarCollRef} from "./actions/group";
import {IS_PRODUCTION} from "./config/constants";
import {filter} from "lodash/collection";
import {db} from "./firebaseConfig";
import {useAnnouncement} from "./context/AnnouncementContext";
import {v4 as uuidv4} from "uuid";
import {gerenateCategories, gerenatePriorities} from "./components/TaskListApp/AddProjectPopup/constant";
import {isEmpty} from "lodash/lang";

function App() {
    const [user] = useGlobal('user')
    const [openEditingEvent] = useGlobal('openEditingEvent')
    const [openNewEvent] = useGlobal('openNewEvent')
    const [isRestored] = useKeyboardJs('ctrl + z')
    const [projects] = useGlobal('projects')
    const [groupsMs, setGroupsMs] = useGlobal('groupsMs')
    const [googleCalendarList] = useGlobal('googleCalendarList')
    const [calendarUser] = useGlobal("calendarUser")
    const [projectsSyncWithGoogle] = useGlobal("projectsSyncWithGoogle");
    const languageMessages = useContext(LanguageMessageContext)
    const [settingList] = useGlobal('settingList');
    const [, setColors] = useGlobal('colors');
    const [projectList, setProjectList] = useGlobal('projectList');
    const [allMilestoneProject] = useGlobal('allMilestoneProject');
    const [, setProjectMilestone] = useGlobal('projectMilestone');
    const [mySetting, setMySetting] = useGlobal('mySetting');
    const {plan, loadingPlan} = useAnnouncement()
    const [openSetting, setOpenSetting] = useGlobal('openSetting')
    const [, setSettingTab] = useGlobal("settingTab")
    const [googleStatus] = useGlobal('googleStatus')
    const [checkAllProject, setCheckAllProject] = useState(false)

    useEffect(() => {
        if (loadingPlan)
            return

        if (plan?.info?.serviceId < 2) {
            setOpenSetting(true)
            setSettingTab(10)
        }
        // eslint-disable-next-line
    }, [plan?.info, loadingPlan])

    usePushNotifications(user);

    useGoogleCalendarWatch2((data) => {
        getEventsSync(data.email)
    })

    useEffect(() => {
        if (isRestored && !openNewEvent && !openEditingEvent.open)
            onRestore()
        // eslint-disable-next-line
    }, [isRestored])

    useEffect(() => {
        document.title = IS_PRODUCTION ? document.title : `[${process.env.REACT_APP_ENV}] ${document.title}`
    }, [])

    useEffect(() => {
        if (user.user_id) {
            document.body.addEventListener('click', function (evt) {
                try {
                    if (window.location.pathname === "/shareUrl" && !window.location.search && evt.path && evt.path.length)
                        evt.path.forEach(item => {
                            if (item.tagName === "TH" && item.dataset) {
                                const {dateSelections} = getGlobal()
                                if (dateSelections.includes(item.dataset.date)) {
                                    dateSelections.splice(dateSelections.indexOf(item.dataset.date), 1)
                                } else {
                                    dateSelections.push(item.dataset.date)
                                }
                                setGlobal({dateSelections: [...dateSelections]})
                            }
                        })
                } catch (e) {
                    console.log(e);
                }

            }, false);
        }
        document.body.addEventListener('mousemove', function (evt) {
            if (window.location.pathname === '/') return null
            const {hoverPos} = getGlobal()
            if (!evt?.target || !isString(evt.target?.className))
                return null
            if (evt.target?.id !== "hover-create-event" && !evt.target?.className?.includes("fc-bgevent")) {
                return setGlobal({
                    hoverPos: null
                })
            }
            if (hoverPos && (evt.target.id === "hover-create-event" && evt.layerY > hoverPos.heightDuration) &&
                !moment(hoverPos.timeEnd).isSame(hoverPos.event.end)
            ) {
                setGlobal({
                    hoverPos: null
                })
            }
        }, false)
        window.addEventListener('scroll', function (e) {
            const {hoverPos} = getGlobal()
            if (hoverPos) {
                setGlobal({
                    hoverPos: null
                })
            }
        });
        return () => {
            document.body.removeEventListener("click", () => {
                console.log('remove listener')
            })
        }
        // eslint-disable-next-line
    }, [user])

    const getSelected = (id, value) => {
        let index = findIndex(projectsSyncWithGoogle, {projectUuid: id})
        if (value) {
            if (value === 'isShowAllDay')
                return projectsSyncWithGoogle[index]?.isShowAllDay === undefined ? true : projectsSyncWithGoogle[index]?.isShowAllDay
            if (value === 'isShowMilestone')
                return projectsSyncWithGoogle[index]?.isShowMilestone === undefined ? true : projectsSyncWithGoogle[index]?.isShowMilestone
        }
        return projectsSyncWithGoogle[index]?.msSelected || false
    }

    useEffect(() => {
        let groupMs = [];
        projects.forEach(project => {
            if (!project.is_sync) {
                const idx = findIndex(projectList, {id: project.id})
                groupMs.push({
                    ...project,
                    msSelected: idx > -1,
                    isShowAllDay: project?.isShowAllDay === undefined ? true : project.isShowAllDay,
                    isShowMilestone: project?.isShowMilestone === undefined ? true : project.isShowMilestone
                })
            }
        })
        setGroupsMs(groupMs)
        // eslint-disable-next-line
    }, [projects])

    useEffect(() => {
        setCheckAllProject(false)
        let ggProjectsMs = [];
        const googleSettings = calendarUser?.googleSettings || {};
        const googleList = calendarUser?.googleList || [];

        if (googleStatus.is_login) {
            if (googleCalendarList?.length === 0) {
                return;
            }
        }

        googleCalendarList.forEach(g => {
            if (!g.id.includes("#holiday")) {
                const linkInfo = googleSettings[g.id] || {}
                ggProjectsMs.push({
                    ...g, ...linkInfo,
                    is_google: true,
                    uuid: g.id,
                    color: g.backgroundColor,
                    name: g.summary,
                    msSelected: getSelected(g.id, null),
                    isShowAllDay: getSelected(g.id, 'isShowAllDay'),
                    isShowMilestone: getSelected(g.id, 'isShowMilestone'),
                })
            }
        });

        const nextListMilestone = swapProjectByOrder(concat(groupsMs, ggProjectsMs), googleList);
        const nextAllListGoogleProjects = swapProjectByOrder(ggProjectsMs, googleList);

        setGlobal({
            allMilestoneProject: nextListMilestone,
            ggAllProject: nextAllListGoogleProjects
        })

        setCheckAllProject(true)

        // eslint-disable-next-line
    }, [groupsMs, googleCalendarList, calendarUser, projectsSyncWithGoogle, projects])

    const updateSetting = (mySelectedSetting) => {
        let projectList = mySelectedSetting.listProjectSetting;

        setColors(mySelectedSetting.colors);
        setProjectList(projectList || [])
        setProjectMilestone(projectList || [])
        setMySetting(mySelectedSetting)

        setGlobal({
            fontSize: mySelectedSetting.fontSize,
            showTitleEvent: mySelectedSetting.showTitleEvent,
            identityShow: mySelectedSetting.identityShow,
            calendarTab1: mySelectedSetting.calendarTab1,
            calendarTab2: mySelectedSetting.calendarTab2,
            projectSortSetting: mySelectedSetting.projectSortSetting,
            segment: mySelectedSetting.segment,
            monthValue: mySelectedSetting.monthValue,
            milestoneView: mySelectedSetting.milestoneView,
            focusColor: mySelectedSetting.focusColor,
            milestoneColor: mySelectedSetting.milestoneColor || 'project'
        })
    }

    useEffect(() => {
        if (settingList?.length) {
            let settingSelected = filter(settingList, setting => setting?.useSetting)
            if (settingSelected?.length) {
                updateSetting(settingSelected[0])
            } else {
                updateSetting(settingList[0])
            }
        }
        // eslint-disable-next-line
    }, [settingList])

    const removeProjectSetting = async () => {
        if (!allMilestoneProject?.length) return;

        const batch = db.batch()
        const snap = await milestoneSettingCollRef(user.user_id).get()

        try {
            snap.docs.forEach((setting) => {
                let list = []
                const projects = setting.data().listProjectSetting;
                for (const pro of projects) {
                    if (!googleStatus.is_login && pro.is_google) {
                        list.push(pro);
                        continue;
                    }
                    const idx = findIndex(allMilestoneProject, {id: pro.id})
                    if (idx !== -1) {
                        list.push(pro)
                    }
                }
                batch.set(setting.ref, {listProjectSetting: list}, {merge: true})
            })
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    const updateProjectSetting = async () => {
        if (!allMilestoneProject.length || !projects.length)
            return;

        try {
            const ref = await milestoneSettingCollRef(user.user_id).get()
            const data = ref.docs || []
            const batch = db.batch()
            if (!data.length) return;
            data.forEach((st, idx) => {
                let listProject = st.data()?.listProjectSetting || []
                allMilestoneProject.forEach(setting => {
                    let idx = findIndex(listProject, {id: setting.id})
                    if (idx !== -1 && setting.is_google) {
                        listProject[idx].summary = setting.name
                        listProject[idx].name = setting.name
                        listProject[idx].backgroundColor = setting.color
                        listProject[idx].color = setting.color
                    } else if (idx !== -1) {
                        listProject[idx] = {...listProject[idx], ...setting};
                    }
                    batch.set(st.ref, {listProjectSetting: listProject}, {merge: true})
                })
            })

            await batch.commit()

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (checkAllProject) updateProjectSetting().then()
        // eslint-disable-next-line
    }, [allMilestoneProject?.length, groupsMs, projects])

    useEffect(() => {
        if (checkAllProject) removeProjectSetting().then();
    }, [groupsMs?.length, allMilestoneProject?.length])

    const automaticallySelectProject = async () => {

        if (googleStatus.is_login || !mySetting) return;
        let isCheck = false;
        const listProjectSetting = mySetting?.listProjectSetting
        if (!listProjectSetting) return;

        for (let i = 0, length = listProjectSetting.length; i < length; i++) {
            const pro = listProjectSetting[i];
            if (pro.msSelected && !pro.is_google) {
                isCheck = true;
                break;
            }
        }

        if (isCheck) return;

        try {
            if (groupsMs.length !== 0) {
                groupsMs[0].msSelected = true;
                listProjectSetting.push(groupsMs[0])
            } else {
                const uid = 'pro_' + uuidv4();
                const categories = gerenateCategories(uid);
                const priorities = gerenatePriorities(uid);
                const time = moment().utc().format()
                const project = {
                    id: uid,
                    uuid: uid,
                    name: 'Calendar 1',
                    ja_name: "",
                    color: "#3A3A3A",
                    author: user.email,
                    selected: true,
                    categories,
                    priorities,
                    is_deleted: false,
                    created_at: time,
                    updated_at: time,
                    is_sync: false,
                    msSelected: true,
                }

                await projectsCalendarCollRef()
                    .doc(uid)
                    .set(project);

                project.isShowAllDay = true;
                project.isShowMilestone = true;

                listProjectSetting.push(project);
            }

            await milestoneSettingDocRef(`${mySetting.id}`)
                .set({listProjectSetting: [...listProjectSetting]}, {merge: true})

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        automaticallySelectProject()
        // eslint-disable-next-line
    }, [googleStatus, mySetting])

    useEffect(() => {
        addNewSettingDefault();
    }, [allMilestoneProject?.length, !mySetting])

    const addNewSettingDefault = async () => {
        try {
            if (!allMilestoneProject?.length || !isEmpty(mySetting))
                return null;

            const list = []

            allMilestoneProject.forEach(project => {
                project.msSelected = true
                list.push(project)
            })

            let data = {
                id: 1,
                name: 'default setting',
                code: 'DF',
                colors: {primary: '#1790FF', secondary: '#7EE100'},
                identityShow: true,
                showTitleEvent: true,
                calendarTab1: 2,
                calendarTab2: 2,
                fontSize: 9,
                projectSortSetting: {isSort: false, timeView: 6},
                segment: 'one',
                monthValue: 2,
                focusColor: 'project',
                milestoneColor: 'project',
                milestoneView: 1,
                listProjectSetting: list,
                useSetting: true,
            }

            const ref = milestoneSettingCollRef(user.user_id)

            await ref.doc('1')
                .set(data, {merge: true})

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <IntlProvider locale={user?.language}
                      key={user?.language}
                      messages={languageMessages}
                      timeZone={user?.time_zone}
        >
            <CalendarEventsProvider>
                <Suspense fallback={<SuspenseLoader/>}>

                    <div className="App">
                        <Switch>
                            <Route exact path={'/'} component={Milestone}/>
                            <Route path={'/search'} component={Search}/>
                            <Route path={'/notFound'} component={NotFound}/>
                        </Switch>
                        <ExpDateCheckDialog/>
                    </div>

                    {
                        openSetting && <ListSetting/>
                    }
                </Suspense>
            </CalendarEventsProvider>
        </IntlProvider>
    )
}

export default App

const SuspenseLoader = memo(() => {
    return (<ClfLinearProgress/>)
})
