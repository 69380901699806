import {getGlobal, setGlobal} from "reactn";
import {remove} from "lodash";
import {db} from "../../firebaseConfig";
import {refetchSource} from "../../actions/refetchSourcce";
import store from 'store'

const googleStoredKey = ["LChannel", "GChannel", "calendarSelected", "googleAccessToken", "googleUser", "googleCalendars", "ggToken"]
export const removeStorageGoogleUser = () => {
    googleStoredKey.forEach(key => {
        store.remove(key)
    })
}
export const RemoveGoogleCalendar = () => {
    const {groups} = getGlobal()
    let nextGroups = [...groups]
    remove(nextGroups, g => {
        return g.is_google === true
    })
    setGlobal({groups: nextGroups})

}

export const onLogOut = () => {
    try {
        RemoveGoogleCalendar()
        removeStorageGoogleUser()
        const {googleStatus} = getGlobal()
        const email = googleStatus?.userProfile?.email
        setGlobal({
            googleStatus: {
                is_login: false,
                userProfile: {},
                initialed: true
            },
            googleCalendarList: []
        }, () => {
            refetchSource("google")
        })
        removeStoredToken(email)
        window.gapi.auth2.getAuthInstance().disconnect()
    } catch (e) {

    }
}
const removeStoredToken = async (email) => {
    try {
        if (!email)
            return
        // remove prod
        const batch = db.batch()
        const prodSnap = await db.collection("calendar")
            .where("googleAuth.email", "==", email)
            .get()
        prodSnap.docs.forEach(doc => {
            const docRef = db.doc(`calendar/${doc.id}`)
            batch.set(docRef, {
                googleAuth: null
            }, {merge: true})
        })
        const stgSnap = await db.collection("calendar-stg")
            .where("googleAuth.email", "==", email)
            .get()
        stgSnap.docs.forEach(doc => {
            const docRef = db.doc(`calendar-stg/${doc.id}`)
            batch.set(docRef, {
                googleAuth: null
            }, {merge: true})
        })
        await batch.commit()

    } catch (e) {
        console.log(e);
    }
}
