import React, {useEffect, useState} from 'react';
import getPaymentList from '../../common/getPaymentList';
import {Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Radio} from '@material-ui/core';
import {Elements} from 'react-stripe-elements';
import {makeStyles} from '@material-ui/core/styles';
import currencyFormat from '../../common/currencyFormat';
import StripeAddCardDialog from './StripeAddCardDialog';
import {Button} from 'antd'
import {msgError, msgSuccess} from '../../utils/msg';
import axios from 'axios'
import {API_GENIAM, SUBSCRIPTION_SERVICES} from '../../config/constants';
import initAnnouncement from '../../common/initAnnouncement';
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import useIntlId from '../../hooks/useIntlId';
import {useAnnouncement} from '../../context/AnnouncementContext';
import {findIndex} from 'lodash'

function PaymentList({open, setOpen}) {
    const {getSubs, plan, data} = useAnnouncement()
    const classes = useStyles();
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openAddMethod, setOpenAddMethod] = useState(false)
    const [
        paymentIntntl,
        addpaymentMethodIntl,
        confirmPurchaseIntl,
        cancelIntl,
        expireIntl,
        changePlanNotice
    ] = useIntlId([
        'announcement.payment.method',
        'announcement.payment.addPaymentMethod',
        'annoucement.confirmPurchase',
        'settings.archiveDialogCancel',
        'event.shareExpirationDate',
        'calenview.add.geniamCoin'
    ])

    useEffect(() => {
        getPaymentList().then(data => {
            setList(data);
            if (data?.length)
                setSelected(data[0].id);
        });
    }, []);

    const onChangePlan = async (plan, price) => {
        let idx = findIndex(data, item => item?.plan.id === plan.price)
        if (idx === -1)
            return
        const sub = data[idx]

        await axios.post(`${API_GENIAM}/subscriptions/update`, {
            card: selected,
            product: {
                price
            },
            subId: sub.id
        })
        msgSuccess("charge successfully")
        setOpen(false)
    }

    const handlePurchase = async () => {
        try {
            if (!selected || !open?.id)
                return
            const price = open.price[open.plan]
            if (!price)
                return
            if (plan?.price === price) {
                return;
            }
            setLoading(true)
            if (plan?.price) {
                // change plan
                await onChangePlan(plan, price)
                await getSubs()
                return
            }
            await axios.post(`${API_GENIAM}/subscriptions/create`, {
                card: selected,
                product: {
                    price
                }
            })
            // const subRef = getMySubsDocRef(data.id)
            // await subRef.set(data)
            await initAnnouncement()
            await getSubs()
            msgSuccess("charge successfully")
            setOpen(false)
        } catch (e) {
            console.log(e);
            msgError(e.toString())
        } finally {
            setLoading(false)
        }

    };
    const onUpdateCard = () => {
        getPaymentList().then(data => {
            setList(data);
            if (data?.length)
                setSelected(data[0].id);
        });
    }
    if (!open)
        return null
    return (
        <Dialog open={Boolean(open)}
                onClose={() => {
                    setOpen(null)
                }}
                maxWidth={'md'}
                fullWidth={true}
                className={`${classes.root} fontMontserrat`}
        >
            <div className={classes.center}>
                <p> {open?.prod?.name}</p>
                <p> {open?.plan === "month" ? "月間サブスクリプション・プラン" : "年間サブスクリプション・プラン"}</p>
                <p>ご購入いただくと、すぐにサービスをご利用になれます。<br/>更新のキャンセルなどのサブスクリプションプランの設定は、<br/>
                    <a href={SUBSCRIPTION_SERVICES} target={"_blank"} rel="noreferrer">こちら</a>からいつでもできます。
                </p>
                {
                    plan?.price ?
                        <p>
                            {changePlanNotice}
                        </p>
                        : null
                }

            </div>
            <DialogTitle className={classes.titleDialog}>
                <div className={classes.leftHeader}/>
                <div className={`${classes.titlePopover} fontMontserrat`}>{paymentIntntl}</div>
                <div className={classes.rightHeader}/>
            </DialogTitle>
            <DialogContent>
                <LoadingOverlay>
                    <Loader loading={loading} text={''}/>
                    <div className={classes.center}>
                        <div className={classes.container}>
                        <span className={classes.numOfTickets}>
                            {currencyFormat.format(open.amount[open.plan])}
                        </span>
                            <span className={classes.perMonth}>/{open.plan === 'month' ? "月" : "年"}</span>
                        </div>
                        <p>
                            （税込{Math.ceil(open.amount[open.plan] * 1.1)}円/{open.plan === 'month' ? "月" : "年"})
                        </p>
                    </div>
                    <Button
                        type="primary"
                        onClick={() => setOpenAddMethod(true)}
                        icon={<img src={"/image/listCard/addpayment.svg"} alt={'payment'}/>}
                        className={classes.addPayment}>
                        {addpaymentMethodIntl}
                    </Button>

                    <Grid container spacing={2}>
                        {
                            list.map((item, index) => {
                                return (
                                    <Grid item xs={12} md={4} key={index}>
                                        <Card className={classes.card}
                                              onClick={() => setSelected(item.id)}
                                        >
                                            <Radio
                                                checked={item.id === selected}
                                                disabled={loading}
                                                className={classes.radio}
                                            />
                                            <div className={classes.titlePayment}>{paymentIntntl} {index + 1}</div>
                                            <div className={classes.brand}>
                                                <img src={'/image/listCard/' + item.brand + '.png'}
                                                     height={70} alt=""/>
                                                {/*<div*/}
                                                {/*    className={classes.bold}>{item.brand}</div>*/}
                                            </div>
                                            <div className={classes.label}>下4桁 <span
                                                className={classes.bold}>{item.last4}</span></div>
                                            <div className={classes.label}>{expireIntl} <span
                                                className={classes.bold}>{item.exp_year}/{item.exp_month} </span></div>
                                        </Card>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                    <Elements>
                        <StripeAddCardDialog open={openAddMethod} setOpen={setOpenAddMethod} onUpdate={onUpdateCard}/>
                    </Elements>
                </LoadingOverlay>
            </DialogContent>
            <DialogActions>
                <Button
                    className={classes.action}
                    disabled={loading}
                    onClick={() => {
                        setOpen(null)
                    }}
                >{cancelIntl}</Button>
                <Button
                    className={`${classes.action} ${classes.buyBtn}`}
                    disabled={loading}
                    onClick={handlePurchase}
                >{confirmPurchaseIntl}</Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: 700,
    },
    brand: {
        display: 'flex', alignItems: 'center',
        justifyContent: "center",
        marginBottom: 10,
    },
    addMethod: {
        display: 'flex',
        alignItems: 'center',
    },
    helperText: {
        textAlign: "center",
        fontWeight: 700,
        textTransform: "capitalize"
    },
    leftHeader: {
        display: "inline-block",
        width: 328,
        height: 60,
        background: "#ffffff",
        position: "absolute",
        top: -35,
        left: 0,
        borderTopLeftRadius: "10px",
    },
    rightHeader: {
        display: "inline-block",
        width: 328,
        height: 60,
        background: "#ffffff",
        position: "absolute",
        top: -35,
        right: 0,
        borderTopRightRadius: "10px",
    },
    titlePopover: {
        padding: "5px 15px",
        width: 290,
        background: "#fff",
        color: "#158FFF",
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translate(-50%,calc(-100% - 8px))",
        borderRadius: "10px 10px 0px 0px",
        border: "8px solid transparent",
        textAlign: "center",
        textTransform: "uppercase",
        fontWeight: 700,
    },
    root: {
        "& .MuiDialog-paper": {
            overflowY: "unset"
        },
        "& .MuiDialogActions-root": {
            justifyContent: "center"
        }
    },
    titleDialog: {
        padding: 0,
    },
    addPayment: {
        padding: "2px 3px !important",
        height: "unset !important",
        fontWeight: '700 !important',
        textTransform: "uppercase",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        marginBottom: 20,
        "& span": {
            padding: "0 17px",
        }
    },
    container: {
        display: "inline-block",
        fontWeight: 700,
        fontSize: 16,
        borderRadius: '5px',
        margin: "15px 0",
        "& > span": {
            display: "inline-block",
            verticalAlign: "middle",
            padding: " 0 5px",
        },
        background: "#FF0000",
        color: "#fff",
        padding: "5px"
    },
    center: {
        textAlign: "center"
    },
    inputNumber: {
        borderRadius: "5px",
        border: "none",
        "& input": {
            fontWeight: 700,
            color: "#158FFF",
            height: 35,
            fontSize: 16,
        }
    },
    numOfTickets: {
        fontWeight: "700 !important",
        padding: "0px !important",
        borderRadius: "5px,0,0,5px",
        display: "inline-block"
    },
    card: {
        boxShadow: "none",
        backgroundColor: "#F1F1F1",
        borderRadius: "10px",
        textAlign: "center",
        padding: 10,
        cursor: "pointer"
    },
    titlePayment: {
        color: "#158FFF",
        fontSize: 18,
        fontWeight: 700,
        textTransform: "uppercase"
    },
    radio: {
        "& .MuiRadio-colorSecondary, &.Mui-checked": {
            color: "#158FFF !important",
        }
    },
    action: {
        fontWeight: "700 !important",
        borderRadius: "5px !important",
        "&:hover": {
            background: "#158FFF !important",
            color: "#fff !important",
        }
    },
    label: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase"
    },
    buyBtn: {
        background: "#158FFF !important",
        color: "#fff !important",
        border: "none"
    },
    perMonth: {
        fontWeight: 700,
        padding: "0px !important",
        borderRadius: "0,5px,5px,0",
        display: "inline-block"
    }
}));

export default PaymentList
