import React, {useEffect, useState} from 'react';
import {IconButton} from '@material-ui/core';
import {setGlobal, useGlobal} from 'reactn'
import "./Settings/styles.css"
import MenuIcon from "@material-ui/icons/Menu";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _, {findIndex} from "lodash"
import ListSetting from "./ListSetting";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import {refetchAllEvents} from "../../actions/refetchSourcce";
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SettingSearch from "../Search/SettingSearch";
import {useDebounce} from "react-use";
import useIntlId from "../../hooks/useIntlId";
import DialogChangeTime from "../Meeting/NewMeeting/DialogChangeTime";
import {useHistory} from "react-router-dom";
import store from 'store'
import EditEvent from "../../components/MileStone/EditEvent"

function Setting(props) {
    const {
        openSearch, setOpenSearch, onChangeSearch, handleKeyDown,
        timeSearch, setTimeSearch, checkTimeSearch, setCheckTimeSearch,
    } = props
    const [openSetting, setOpenSetting] = useGlobal('openSetting')
    const classes = useStyles();
    const [openMenu, setOpenMenu] = useState(null)
    const open = Boolean(openMenu);
    const id = open ? 'menuright-popover' : undefined;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [listSearch,] = useGlobal("listSearch")
    const [searchKeyHistory] = useGlobal("searchKeyHistory")
    const [searchText, setSearchText] = useState("")
    const [settingSearch, setSettingSearch] = useState(null)
    const [search] = useIntlId(['event.meeting.search'])
    const [openChangeDate, setOpenChangeDate] = useState(false)
    const [edit, setEdit] = useState(null)
    const history = useHistory()

    const onOpenChangeDate = () => {
        if (!checkTimeSearch)
            return setOpenChangeDate(true)
    }

    const openSettingSearch = (event) => {
        setSettingSearch(event.currentTarget);
    };

    const closeSettingSearch = () => {
        setSettingSearch(null);
    };


    function goToSetting(event) {
        // props.history.push('/setting')
        setOpenSetting(true)
        setOpenMenu(null)
    }

    const handleClick = event => {
        setOpenMenu(event.currentTarget);
    };

    const handleClose = () => {
        setOpenMenu(null);
    };

    const handleDelete = () => {
        setSearchText("")
        setGlobal({
            searchKey: "",
        })
        refetchAllEvents()
        // setOpenSearch(false)
    }

    useEffect(() => {
        setOpenMenu(null)
    }, [matches])

    useDebounce(() => {
        if (_.trim(searchText) !== "") {
            onChangeSearch(_.trim(searchText).toLowerCase())
        }
    }, 500, [searchText])


    const onRemoveKey = (event) => {
        if (!event) return
        let idx = findIndex(searchKeyHistory, s => s.title === event.title)
        if (idx !== -1) {
            searchKeyHistory.splice(idx, 1)
        }
        setGlobal({searchKeyHistory: searchKeyHistory})
        store.set('searchKeyHistory', JSON.stringify(searchKeyHistory))
    }

    return (
        <div className={classes.root} data-html2canvas-ignore={true}>
            <div className={window.location.pathname === '/' ? classes.btnMenu : classes.btnMenuEvent}>
                <Autocomplete
                    freeSolo
                    options={listSearch.length > 0 ? listSearch : searchKeyHistory}
                    className={classes.searchInput}
                    getOptionLabel={(event) => event.title}
                    disableListWrap={window.location.pathname === '/search'}
                    noOptionsText={''}
                    renderOption={(event, {selected}) => {
                        return (
                            <div className={classes.divOption}>
                                {
                                    event?.isSearchKeyHistory &&
                                    <div className={classes.removeSearchKey}>
                                        <IconButton
                                            disableRipple={true}
                                            onClick={() => onRemoveKey(event)}
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                    </div>
                                }
                                <ListItemText
                                    onClick={() => {
                                        if (searchKeyHistory.length > 0) {
                                            setGlobal({
                                                searchKey: event.title.toLowerCase(),
                                            })
                                            setSearchText(event.title.toLowerCase())
                                            history.push(`/search?searchkey=${event.title.toLowerCase()}`)
                                        } else {
                                            const editInfo = {event:{...event,extendedProps:{...event}}}
                                            setEdit(editInfo)
                                            // setRefetchDateNow(event.start)
                                            // let idEvent = `milestone-0-${event.id}`
                                            // setGlobal({
                                            //     editDialog: true
                                            // })

                                            // function click() {
                                            //     if (document.getElementById(idEvent)) {
                                            //         document.getElementById(idEvent).click()
                                            //     }
                                            // }

                                            // setTimeout(click, 2000)
                                        }
                                    }}
                                    primary={event.title}
                                    secondary={listSearch.length > 0 && `${moment(event.start).format("YYYY-MM-DD HH:mm")} ~ ${moment(event.end).format("YYYY-MM-DD HH:mm")}`}
                                />
                            </div>
                        )
                    }}
                    inputValue={searchText}
                    renderInput={(params) => <TextField{...params}
                                                       autoFocus={true}
                                                       onKeyDown={(e) => setTimeout(handleKeyDown(e), 3000)}
                                                       onChange={(e) => {
                                                           setSearchText(e.target.value)
                                                       }
                                                       }
                                                       placeholder={searchText !== '' ? searchText : search}
                                                       variant="outlined"/>}
                />
                <IconButton
                    disableRipple={true}
                    onClick={handleClick}>
                    <MenuIcon/>
                </IconButton>
            </div>

            <div className={window.location.pathname === '/' ? classes.content : classes.contentEvent}>
                {(openSearch || window.location.pathname === '/search')
                    &&
                    <div className={classes.boxSearch}>
                        <Autocomplete
                            freeSolo
                            options={listSearch.length > 0 ? listSearch : searchKeyHistory}
                            className={classes.searchInput}
                            getOptionLabel={(event) => event.title}
                            disableListWrap={window.location.pathname === '/search'}
                            noOptionsText={''}
                            renderOption={(event, {selected}) => {
                                return (
                                    <div className={classes.divOption}>
                                        {
                                            event?.isSearchKeyHistory &&
                                            <div className={classes.removeSearchKey}>
                                                <IconButton
                                                    disableRipple={true}
                                                    onClick={() => onRemoveKey(event)}
                                                >
                                                    <ClearIcon/>
                                                </IconButton>
                                            </div>
                                        }
                                        <ListItemText
                                            onClick={() => {
                                                if (searchKeyHistory.length > 0) {
                                                    setGlobal({
                                                        searchKey: event.title.toLowerCase(),
                                                    })
                                                    setSearchText(event.title.toLowerCase())
                                                    history.push(`/search?searchkey=${event.title.toLowerCase()}`)
                                                } else {
                                                    const eventInfo = {event:{...event,extendedProps: {...event}}}
                                                    setEdit(eventInfo)
                                                    // setRefetchDateNow(event.start)
                                                    // let idEvent = `milestone-0-${event.id}`
                                                    // setGlobal({
                                                    //     editDialog: true
                                                    // })

                                                    // function click() {
                                                    //     if (document.getElementById(idEvent)) {
                                                    //         document.getElementById(idEvent).click()
                                                    //     }
                                                    // }

                                                    // setTimeout(click, 2000)
                                                }
                                            }}
                                            primary={event.title}
                                            secondary={listSearch.length > 0 && `${moment(event.start).format("YYYY-MM-DD HH:mm")} ~ ${moment(event.end).format("YYYY-MM-DD HH:mm")}`}
                                        />
                                    </div>
                                )
                            }}
                            inputValue={searchText}
                            renderInput={(params) => <TextField{...params}
                                                               autoFocus={true}
                                                               onKeyDown={(e) => setTimeout(handleKeyDown(e), 3000)}
                                                               onChange={(e) => {
                                                                   setSearchText(e.target.value)
                                                               }
                                                               }
                                                               placeholder={searchText !== '' ? searchText : search}
                                                               variant="outlined"/>}
                        />
                        <div className={classes.absolute}>
                            {
                                !checkTimeSearch &&
                                <div className={classes.timeSearch}>
                                    <span>{moment(timeSearch.start).format('MM/DD',)}</span>
                                    -
                                    <span>{moment(timeSearch.end).format('MM/DD',)}</span>
                                </div>
                            }
                            <IconButton
                                disableRipple={true}
                                onClick={openSettingSearch}
                            >
                                <ArrowDropDownIcon/>
                            </IconButton>
                            {
                                searchText !== "" && <IconButton
                                    disableRipple={true}
                                    onClick={handleDelete}
                                >
                                    <ClearIcon/>
                                </IconButton>
                            }
                        </div>
                    </div>
                }

                <IconButton disableRipple={true} className={classes.whiteText}
                            onClick={() => setOpenSearch(!openSearch)}>
                    {
                        openSearch ? <img src={process.env.PUBLIC_URL + '/icons/searchActive.svg'} alt=""/>
                            :
                            <img src='/imgs/newSvgs/search.svg' alt=''/>
                    }
                </IconButton>

                <IconButton
                    disableRipple={true}
                    aria-owns={'menu-appbar'}
                    aria-haspopup="true"
                    onClick={goToSetting}
                    color="inherit"
                >
                    <img src='/imgs/newSvgs/setting.svg' alt=''
                         className={window.location.pathname === '/' ? classes.SettingIcon : classes.SettingIconEvent}/>
                </IconButton>

                {/*<GlobalNavi/>*/}

            </div>
            {
                settingSearch && <SettingSearch settingSearch={settingSearch}
                                                setSettingSearch={setSettingSearch}
                                                closeSettingSearch={closeSettingSearch}
                                                onOpenChangeDate={onOpenChangeDate}
                                                startDateSearch={timeSearch.start}
                                                endDateSearch={timeSearch.end}
                                                setCheckTimeSearch={setCheckTimeSearch}
                                                checkTimeSearch={checkTimeSearch}
                />
            }
            {
                openChangeDate && !checkTimeSearch &&
                <DialogChangeTime
                    setEventTime={setTimeSearch}
                    setOpen={setOpenChangeDate}
                    open={openChangeDate}
                    startDate={timeSearch.start}
                    endDate={timeSearch.end}
                    checkTimeSearch={checkTimeSearch}
                />
            }
            <Popover
                id={id}
                open={open}
                anchorEl={openMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.popoverAccount}
            >
                <IconButton
                    disableRipple={true}
                    aria-owns={'menu-appbar'}
                    aria-haspopup="true"
                    onClick={goToSetting}
                    color="inherit"
                >
                    <img src='/imgs/newSvgs/setting.svg' alt=''
                         className={window.location.pathname === '/' ? classes.SettingIcon : classes.SettingIconEvent}/>
                </IconButton>

                {/*<GlobalNavi/>*/}
            </Popover>
            {edit && <EditEvent editInfo={edit} setEditInfo={setEdit}/>}
            {
                openSetting && <ListSetting setOpenSetting={setOpenSetting} openSetting={openSetting}/>
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    btnMenu: {
        display: "none",
        color: "#fff",
        [theme.breakpoints.down('md')]: {
            display: "flex",
            color: '#000'
        }
    },
    btnMenuEvent: {
        display: "none",
        // color: "#fff",
        [theme.breakpoints.down('sm')]: {
            display: "flex"
        }
    },
    content: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    },
    contentEvent: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    SettingIcon: {
        color: '#000',
        [theme.breakpoints.down('md')]: {
            color: "#000"
        }
    },
    SettingIconEvent: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            color: "#000"
        }
    },
    root: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    whiteText: {
        color: "black",
        fill: theme.props.primaryColor
    },
    blackText: {
        color: "black"
    },
    boxSearch: {
        width: "100%",
        maxWidth: "535px",
        // marginRight: -45,
        position: "relative",
    },
    searchInput: {
        fontWeight: "bold",
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "& .MuiFormControl-root": {
            background: "#fff",
            padding: "3px",
            borderRadius: "25px"
        },
        "& .MuiOutlinedInput-root": {
            padding: 0
        },
        "& .MuiSvgIcon-root": {
            display: "none"
        },
        "& input": {
            fontWeight: "bold",
            fontSize: 14,
            fontFamily: 'Montserrat'
        }
    },
    searchInputMobile: {
        minWidth: 300,
        fontWeight: "bold",
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "& .MuiFormControl-root": {
            background: "#fff",
            padding: "3px",
            borderRadius: "25px"
        },
        "& .MuiOutlinedInput-root": {
            padding: 0
        },
        "& .MuiSvgIcon-root": {
            display: "none"
        },
        "& input": {
            fontWeight: "bold",
            fontSize: 14,
            fontFamily: 'Montserrat'
        }
    },
    popoverAccount: {
        "& .MuiPaper-rounded": {
            minWidth: 160,
        },
        "& .MuiPaper-root ": {
            [theme.breakpoints.down('md')]: {
                flexDirection: "row",
                display: "flex",
                maxWidth: "95vw"
            }
        }
    },

    iconDeleteSearch: {
        position: "absolute",
        right: "40px",
        top: "50%",
        transform: "translateY(-50%)",
    },
    notificationICon: {
        width: 24,
        height: 24,
        color: "#000"
    },
    badge: {
        "& .MuiBadge-badge": {
            color: "#fff",
            background: theme.props.primaryColor,
            // right:3,
            // top:2,
            // width:15, height:15,
            // minWidth:15
        }
    },
    timeSearch: {
        fontSize: 12
    },
    absolute: {
        position: "absolute",
        right: "3px",
        top: "50%",
        transform: "translateY(-50%)",
        display: "flex",
        alignItems: "center"
    },
    buttonAntd: {
        textTransform: 'uppercase',
        fontWeight: 700,
        "& svg": {
            marginLeft: 5
        }
    },
    removeSearchKey: {
        position: "absolute",
        right: 5,
        top: '50%',
        transform: 'translateY(-50%)'
    },
    divOption: {
        position: "relative",
        width: '100%'
    },
}));

export default Setting
