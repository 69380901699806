import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {Collapse,} from '@material-ui/core';
import {CALENDAR_COLLECTION} from '../../../config/constants';
import ArchivedIdentityDialog from './ArchivedIdentityDialog';
import DeletedIdentityDialog from './DeletedIdentityDialog';
import UnArchiveIdentityDialog from './UnArchiveIdentityDialog';
import {Col, Row} from 'antd';
import DialogAddIdentity from './DialogAddIdentity';
import TabGoogleIdentities from './TabGoogleIdentities';
import {ReactComponent as RowDownSVG} from '../../../asSvg/arowDown.svg';
import {ReactComponent as AddBGWhiteSVG} from '../../../asSvg/addBGWhite.svg';
import {filter, findIndex} from 'lodash';
import UnArchiveIdentities from './UnArchiveIdentities';
import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import styled from 'styled-components';
import useIntlId from '../../../hooks/useIntlId';
import {msgSuccess} from '../../../utils/msg';
import {makeStyles} from "@material-ui/core/styles";
import {db} from "../../../firebaseConfig";

const RowUpSVG = styled(RowDownSVG)`
    transform: rotate(180deg)
`;


function IdentitySettings(props) {
    const [googleColor] = useGlobal("GoogleColor")
    const [calendarUser, setCalendarUser] = useGlobal("calendarUser")
    const [user] = useGlobal("user")
    const [openArchive, setOpenArchive] = useState(false)
    const [identities, setIdentities] = useState([])
    const [archives, setArchives] = useState([])
    const [edit, setEdit] = useState(null)
    const [loading, setLoading] = useState(false)
    const [deleted, setDeleted] = useState(null)
    const [archiveIdentity, setArchiveIdentity] = useState(null)
    const [unArchive, setUnArchive] = useState(null)
    const [openAddIdentity, setOpenAddIdentity] = useState(false)
    const classes = useStyles()
    const [titleAddIdentityIntl, archiveIntl, savedIntl, deletedIntl, identityTitle, unArchiveIntl] = useIntlId([
        'app.profile.identities',
        'app.notification.archive',
        'common.saved',
        'common.deleted',
        'setting.identityTitle',
        'setting.unarchive'
    ])

    useEffect(() => {
        const {identitySettings = []} = calendarUser
        if (identitySettings.length) {
            setIdentities(filter(identitySettings, i => !i.isDeleted && !i.isArchive))
            setArchives(filter(identitySettings, i => !i.isDeleted && i.isArchive))
            return
        }
        const nextData = googleColor.map((color, index) => {
            return {
                id: index + 1,
                color,
                name: "",
                isArchive: false,
                isDeleted: false
            }
        })
        setIdentities(nextData)
        // eslint-disable-next-line
    }, [calendarUser])

    const onUpdate = async (identity) => {
        setLoading(true)
        const identitySettings = calendarUser.identitySettings || identities
        try {
            let idx = findIndex(identitySettings, {id: identity.id})
            if (idx === -1)
                return
            identitySettings[idx] = identity
            setCalendarUser({
                ...calendarUser,
                identitySettings
            })

            //save to db
            const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
            await userRef.set({
                identitySettings: identitySettings
            }, {merge: true})

            if (edit)
                msgSuccess(savedIntl)
            if (deleted)
                msgSuccess(deletedIntl)
            if (unArchive)
                msgSuccess(savedIntl)
            if (archiveIdentity)
                msgSuccess(savedIntl)

        } catch (e) {

        } finally {
            if (edit)
                setEdit(null)
            if (deleted)
                setDeleted(null)
            if (unArchive)
                setUnArchive(null)
            if (archiveIdentity)
                setArchiveIdentity(null)
            setLoading(false)
        }
    }
    // useEffect(() => {
    //     setLists(calendarUser?.identitySettings || [].filter(item => item.id <= 11))
    //     // eslint-disable-next-line
    // }, [identities])

    const canAdd = () => {
        const deleted = filter(calendarUser?.identitySettings || [], {isDeleted: true})
        return !!deleted.filter(x => x.id < 12).length
    }
    return (
        <div className={classes.container}>
            <div className={classes.titleTop}>{identityTitle}</div>
            <TabGoogleIdentities
                classes={classes}
                identities={identities}
                archives={archives}
                edit={edit}
                setEdit={setEdit}
                setArchiveIdentity={setArchiveIdentity}
                loading={loading}
                onUpdate={onUpdate}
            />

            <Row>
                <Col span={12}>
                    <CLFButtonSVG
                        iconComponent={openArchive ? <RowDownSVG/> :
                            <RowUpSVG/>}
                        // size={'default'}
                        onClick={() => setOpenArchive(!openArchive)}
                        name={archiveIntl}
                        className={classes.primary}
                        width={'150px'}
                        minHeight={"31px"}
                    />
                </Col>
                <Col span={12}>
                    {
                        canAdd() && <CLFButtonSVG
                            iconComponent={<AddBGWhiteSVG/>}
                            onClick={() => setOpenAddIdentity(!openAddIdentity)}
                            name={titleAddIdentityIntl}
                            className={`${classes.floatRight} ${classes.primary}`}
                            width={'200px'}
                            minHeight={"31px"}
                        />
                    }

                </Col>
            </Row>
            <Collapse in={openArchive} timeout={"auto"} unmountOnExit>
                <UnArchiveIdentities
                    archives={archives}
                    classes={classes}
                    setUnArchive={setUnArchive}
                    loading={loading}
                    setDeleted={setDeleted}
                    unArchiveIntl={unArchiveIntl}
                />
            </Collapse>
            {
                Boolean(archiveIdentity) &&
                <ArchivedIdentityDialog
                    identity={archiveIdentity}
                    setIdentity={setArchiveIdentity}
                    onSave={onUpdate}
                    loading={loading}
                    classes={classes}
                />
            }
            {
                Boolean(deleted) &&
                <DeletedIdentityDialog
                    identity={deleted}
                    setIdentity={setDeleted}
                    onSave={onUpdate}
                    loading={loading}
                    classes={classes}
                />
            }
            {
                Boolean(unArchive) &&
                <UnArchiveIdentityDialog
                    identity={unArchive}
                    setIdentity={setUnArchive}
                    onSave={onUpdate}
                    loading={loading}
                    classes={classes}
                />
            }
            {
                Boolean(openAddIdentity) &&
                <DialogAddIdentity
                    open={openAddIdentity}
                    setOpen={setOpenAddIdentity}
                    classes={classes}
                />
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: '20px 10px'
    },
    colInput: {
        height: "33px",
        background: "#FFF",
        borderRadius: "24px",
        display: 'flex',
        alignItems: 'center',
        cursor: "pointer",
        "& .ant-typography": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }
    },
    colInputArchive: {
        height: "36px",
        background: "#FFF",
        borderRadius: "24px",
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: "column",
        overflow: "hidden",
        "& .groupIdentiti": {
            display: "block",
            fontSize: 11,
            fontWeight: "bold",
        },
        "& span": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }
    },
    itemText: {
        fontWeight: "bold",
        fontSize: 15
    },
    floatRight: {
        float: 'right'
    },
    iconAntd: {
        fontSize: '1.5rem',
        fill: theme.props.primaryColor,
        cursor: 'pointer',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    iconAntd2: {
        width: 33,
        height: 33,
        borderRadius: '50%',
        background: theme.props.primaryColor,
        padding: 6,
        '& svg': {
            fill: '#FFF',
            width: 20,
            height: 20,
        }
    },
    spaceAntd: {
        width: '100%',
        // justifyContent: 'center',
        alignItems: 'center',
        gap: "unset !important",
        '& .ant-space-item': {
            marginRight: '1px !important'
        }
    },
    textInput: {
        padding: '0px !important',
        "& .ant-input": {
            borderRadius: 50,
            border: "none"
        }
    },
    tabsAntd: {
        position: 'relative',
        '& .ant-tabs-tab': {
            padding: 12
        }
    },
    archiveList: {
        marginTop: '1rem'
    },

    router: {
        backgroundColor: "#f1f1f1",
        minWidth: 250,
        padding: 8,
        paddingLeft: 0,
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            minWidth: 210,
            // background: "#fff"
        },
        display: "flex",
    },
    tabBtn: {
        height: 40
    },
    ListItem: {
        padding: '20px 10px 5px 10px',
        background: '#FFF',
        margin: '10px 0px',
        borderRadius: '20px'
    },
    divGrid: {
        width: '100%'
    },
    divRowAntd: {
        backgroundColor: "#F1F1F1",
        borderRadius: 20,
        padding: '0 10px',
        margin: '5px'
    },
    tab: {
        width: "50%",
    },
    width100: {
        width: '100%'
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            maxWidth: "25px !important"
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
            maxWidth: "25px !important"
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    rightBtn: {
        margin: "10px 0px 10px auto !important",
    },
    shadow: {
        boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 8px 16px rgba(0,0,0,.30)',
        margin: "20px 0px 10px auto !important",
    },
    boxDnD: {
        width: "100%", background: '#fff', borderRadius: 20
    },
    titleTop: {
        fontSize: '1.3rem',
        color: '#000',
        textAlign: "center"
    },
}))

export default IdentitySettings;
