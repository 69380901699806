import axios from 'axios'
import {getGlobal, setGlobal} from 'reactn'
import {API_GENIAM, GET_USER_INFO, LOGIN_URL, myCal} from "../config/constants";
import {removeURLParameter} from "./calendar";
import Cookies from 'js-cookie'
import {
    COOKIE_GENIAM_ACCESS_TOKEN_KEY,
    COOKIE_GENIAM_REFRESH_TOKEN_KEY,
    getCookie,
    removeCookie,
} from "../common/cookies";
import qs from "query-string";
import {auth} from "../firebaseConfig";
import store from "store";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {useEffect} from "react";

export const getUserData = async () => {
    const {accessToken, firebaseToken} = getGlobal();
    const query = qs.parse(window.location.search)
    const path = window.location.pathname
    // const token = store.get('accessToken')
    store.remove('userInfo');
    store.remove('refreshToken');
    store.remove('zoomConnectData');
    store.remove('accessToken');
    store.remove('firebaseToken');
    // store.remove('refreshToken');
    // const firebaseToken = store.get('firebaseToken')
    if (!accessToken && !firebaseToken && path === '/event' && query.id && query.from && query.tokenMeeting)
        return window.location.assign(`/eventAnonymous?id=${query.id}&from=${query.from}&tokenMeeting=${query.tokenMeeting}`)
    if (!accessToken && !firebaseToken && path === '/eventAnonymous' && query.id && query.from && query.tokenMeeting)
        return null;
    if (!accessToken && path === '/scheduleAdjustment' && query.uid && query.usr && query.timeExp) {
        try {
            await refreshToken()
            // return window.location.assign(`/scheduleAdjustment?uid=${query.uid}&usr=${query.usr}&timeExp=${query.timeExp}`)
        } catch (e) {
            return null;
        }
    }
    if (!accessToken || !firebaseToken) await refreshToken()

    try {
        const {data} = await axios.get(GET_USER_INFO)
        setGlobal({user: data});
        // store.set('userInfo', JSON.stringify(data))
        return data
    } catch (e) {
        console.log(e)
        return null
    }
}

export const checkUrlToken = async () => {
    let result = true
    const accessToken = getCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
    const refreshToken = getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
    if (accessToken || refreshToken) {
        let uri = window.location.href;
        uri = removeURLParameter("token", uri);
        uri = removeURLParameter("refreshToken", uri);
        window.history.pushState({}, document.title, uri);
    } else {
        result = false
    }
    return result
}

export const refreshToken = async () => {
    const refreshToken = Cookies.get(COOKIE_GENIAM_REFRESH_TOKEN_KEY) || getGlobal().refreshToken
    // || store.get('refreshToken')
    // console.log(refreshToken)
    const query = qs.parse(window.location.search)
    const path = window.location.pathname
    if (!refreshToken && path === "/scheduleAdjustment")
        return null
    if (!refreshToken && path === '/event' && query.id && query.from && query.tokenMeeting)
        return null

    if (!refreshToken && path === '/eventAnonymous' && query.id && query.from)
        return null

    if (!refreshToken) {
        window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
    }
    try {
        const res = await axios.post(API_GENIAM + '/refresh', {refreshToken});
        const data = res.data;
        setGlobal({
            accessToken: data.accessToken,
            firebaseToken: data.firebaseToken
        });

        // store.set('accessToken', data.accessToken);
        // store.set('firebaseToken', data.firebaseToken);
        // store.set('refreshToken', refreshToken)
        return data;
    } catch (e) {
        // console.log(e)
        removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
        removeCookie(COOKIE_GENIAM_ACCESS_TOKEN_KEY)
        window.location.assign(LOGIN_URL + '?redirect_url=' + encodeURIComponent(window.location.href))
        return null;
    }
}

export const signInFirebase = async () => {
    try {
        const data = await refreshToken()
        if (!data?.firebaseToken)
            return;

        await auth.signInWithCustomToken(data.firebaseToken)
        // console.log(res);
    } catch (e) {
        console.log(e);
    }
}

export const useMyThemeList = () => {
    const [themeList] = useCollectionData(myCal()?.collection(`listTheme`))
    useEffect(() => {
        if (themeList) {
            setGlobal({listTheme: themeList})
        }
    }, [themeList])

}
