import getUserTimeZone from "./getUserTimeZone";
import moment from 'moment'
import momentTz from "moment-timezone";

const zoneObj = (time, isLocal = false) => {
    if (isLocal)
        return moment(time).local()

    return momentTz.tz(time, getUserTimeZone())
}

export default zoneObj
