import {db} from "../firebaseConfig";
import {CALENDAR_COLLECTION, IDENTITY_COLLECTION, IS_PRODUCTION} from "../config/constants";
import {getGlobal, setGlobal} from 'reactn'
import {toast} from "react-toastify";
import moment from "moment";
import {v4 as uuidv4} from 'uuid'
import {gerenateCategories, gerenatePriorities} from "../components/TaskListApp/AddProjectPopup/constant";

export const getCalendarInfo = async (userId) => {
    try {
        const collection = IS_PRODUCTION ? "calendar" : "calendar-stg"
        const infoSnap = await db.collection(collection)
            .doc(userId)
            .get()
        if (!infoSnap.exists) {
            await initCalendar()
            return null
        }

        const data = infoSnap.data()
        setGlobal({
            focusText: data.focusText || [],
            tasks: data.tasks || [],
            times: data.times || {
                minTime: {
                    value: 6,
                    label: '06:00'
                },
                maxTime: {
                    value: 24,
                    label: '24:00'
                }
            },
        })

    } catch (e) {
        console.log(e);
    }
}

export const getProjects = (userId, callback) => {
    try {
        return db.collection(CALENDAR_COLLECTION)
            .doc(userId)
            .collection("projects")
            .where("is_deleted", "==", false)
            .onSnapshot(querySnapshot => {
                    if (callback) {
                        callback(null, querySnapshot)
                    }
                },
                error => {
                    if (callback) {
                        callback(error, null)
                    }
                }
            )
    } catch (e) {
        console.log(e);
        return null
    }
}

const initCalendar = async () => {
    try {
        const {user} = getGlobal()
        const batch = db.batch()
        const userRef = db.collection(CALENDAR_COLLECTION)
            .doc(user.user_id)
        batch.set(userRef, {
            focusText: [],
            tasks: tasksDefault,
            times: timeWork,
            uuid: user.user_id
        })
        arrProject.map(p => {
            const uid = 'pro_' + uuidv4()
            const projectRef = db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection('projects')
                .doc(uid)
            const categories = gerenateCategories(uid)
            const priorities = gerenatePriorities(uid)
            batch.set(projectRef, {
                ...p,
                uuid: uid,
                author: user.email,
                categories,
                priorities,
                is_deleted: false,
                isDefault: true
            })
            return p
        })
        // identities
        const identityRef = db.collection(IDENTITY_COLLECTION)
            .doc(user.user_id)
        const identitySnap = await identityRef
            .get()
        if (!identitySnap.exists) {
            batch.set(identityRef, {
                identities
            })
        }
        await batch.commit()

    } catch (e) {
        console.log(e);
        toast.error(e.toString())
    } finally {
        window.location.reload()
    }
}
const timeWork = {
    minTime: {
        value: 6,
        label: '06:00'
    },
    maxTime: {
        value: 24,
        label: '24:00'
    }
}
const tasksDefault = {
    data: [
        {
            icon: "AccountCircle",
            index: 0,
            is_active: 1,
            is_default: 0,
            name: "AccountCircle",
        },
        {
            icon: "AssignmentTurnedIn",
            index: 1,
            is_active: 1,
            is_default: 0,
            name: "AssignmentTurnedIn"
        },
        {
            icon: "Group",
            index: 2,
            is_active: 1,
            is_default: 1,
            name: "Group"
        },
        {
            icon: "AssignmentInd",
            index: 3,
            is_active: 1,
            is_default: 0,
            name: "AssignmentInd"
        }
    ],
    default: 1
}
const arrProject = [
    {
        color: "#3A3A3A",
        ja_name: "Calendar 1",
        name: "Calendar 1",
        code: 'Ca',
        created_at: moment().utc().format(),
        updated_at: moment().utc().format(),
        selected: true
    }
    // {
    //     color: "#F2685B",
    //     ja_name: "Enecolor",
    //     name: "Enecolor",
    //     code: 'ec',
    //     created_at: moment().utc().format(),
    //     updated_at: moment().utc().format()
    // },
    // {
    //     color: "#28AFB3",
    //     ja_name: "Insight Mind",
    //     name: "Insight Mind",
    //     code: 'im',
    //     created_at: moment().utc().format(),
    //     updated_at: moment().utc().format()
    // }
]
const identities = [
    {
        color: "#D50000",
        is_active: 1,
        name: "red",
        uuid: 'ide_' + uuidv4(),
        is_sync: false
    },

    {
        color: "#F6BF26",
        is_active: 1,
        name: "yellow",
        uuid: 'ide_' + uuidv4(),
        is_sync: false
    },

    {
        color: "#33B679",
        is_active: 1,
        name: "green",
        uuid: 'ide_' + uuidv4(),
        is_sync: false
    },

    {
        color: "#616161",
        is_active: 1,
        name: "gray",
        uuid: 'ide_' + uuidv4(),
        is_sync: false
    },

    {
        color: "#039BE5",
        is_active: 1,
        name: "blue",
        uuid: 'ide_' + uuidv4(),
        is_sync: false
    },

]

