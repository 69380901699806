import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from '@material-ui/core'
import TextInputCustom from "../../Custom/TextInputCustom";
import {ReactComponent as EditSVG} from "../../../asSvg/edit.svg";
import {ReactComponent as CloseRoundSVG} from "../../../asSvg/close.svg";
import {db} from "../../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {useGlobal} from "reactn";
import {Popconfirm} from 'antd';
import useIntlId, {getIntlId} from "../../../hooks/useIntlId";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import TextInputURLCustom from "../../Custom/TextInputURLCustom";
import {milestoneSettingCollRef} from "../../../actions/group";
import {toast} from "react-toastify";
import AddUrl from "./addUrl";

const linkItems = [{
    name: "Slack",
    logo: "/icons/slackIcon.svg",
    id: "slack"
}, {
    name: "Trello",
    logo: "/icons/trelloIcon.svg",
    id: "trello"
}
]

function ProjectInfo({project, setEdit, setProject}) {
    const classes = useStyles()
    const [user] = useGlobal("user")
    const [loading, setLoading] = useState(false)
    const [otherURL, setOtherURL] = useState([])
    const [groupsMs] = useGlobal('groupsMs')
    const [googleStatus] = useGlobal('googleStatus')
    const [, setProjectSelect] = useGlobal('projectSelected')
    const [askDelete, edit, tDelete, name, color] = useIntlId([
        'settings.askDelete',
        'chat.edit',
        'chat.delete',
        'app.profile.name',
        'view.color',
    ])

    useEffect(() => {
        if (project?.otherURL) {
            setOtherURL([...project.otherURL])
        } else {
            setOtherURL(linkItems)
        }
    }, [project])

    function cancel(e) {
        setLoading(false)
    }

    function setProjectSelected(sliceItem) {
        setProjectSelect({...project, otherURL: sliceItem})
    }

    const onDeleteProjects = async () => {
        if (!project?.id) return;
        if (groupsMs?.length === 1 && !googleStatus.is_login) {
            toast.warning(getIntlId('notification.canNot.deleteProject'))
            return;
        }
        setLoading(true);
        try {
            const batch = db.batch()
            // delete Geniam project

            if (!project?.is_google) {
                const projectsRef = db.collection(CALENDAR_COLLECTION)
                    .doc(user?.user_id)
                    .collection('projects')
                    .doc(project?.id)

                batch.set(projectsRef, {is_deleted: true}, {merge: true})

                const listSetting = milestoneSettingCollRef()
                const list = await listSetting.get()
                for (let i = 0; i < list.docs.length; i++) {
                    const val = list.docs[i].data();
                    const {listProjectSetting} = val
                    const nextListProjectSetting = listProjectSetting.filter(p => p.id !== project.id)
                    batch.set(listSetting.doc(val.id.toString()),
                        {listProjectSetting: nextListProjectSetting},
                        {merge:true})
                }

                await batch.commit()
            }
            //delete Google project

        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            setProject({})
            setEdit(false)
        }
    }

    return (
        <div className={classes.root}>
            <div className={`${classes.projectColor} clf-flex-center`}
                 style={{backgroundColor: `${project.color || project.backgroundColor}`}}>
                <div className={classes.wrapText}>
                    {project.name || project.summary}
                </div>
            </div>
            <Grid container spacing={2} style={{justifyContent: 'space-between'}}>
                <Grid item xs={6}>
                    <TextInputCustom
                        name={name}
                        contentName={<div className={classes.wrapText}>{project.name}</div>}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextInputCustom name={color} contentName={project.color} isColor={true}/>
                </Grid>
            </Grid>
            <div className={classes.listItems}>
                <div>
                    {
                        otherURL.map((item, index) => {
                            return (
                                <Grid container key={index} className={classes.divGrid}>
                                    <TextInputURLCustom
                                        contentName={` ${project[item.id] ? project[item.id] : ''}`}
                                        urlImg={item.logo}
                                        isURL={true}
                                        item={item}
                                        project={project}
                                        setProject={setProject}
                                        otherURL={otherURL}
                                        setOtherURL={setOtherURL}
                                        isEditComponent={false}
                                        titleMaxWidth={110}
                                    />
                                </Grid>
                            )
                        })
                    }
                </div>
                <AddUrl project={project} setProject={setProject} setProjectSelected={setProjectSelected}/>
            </div>
            <div className={`${classes.action} clf-flex-center`}>
                <CLFButtonSVG
                    iconComponent={<EditSVG/>}
                    onClick={() => setEdit(true)}
                    name={edit}
                    className={classes.primaryColor}
                />
                {
                    !project?.is_google && !project?.isDefault &&
                    <Popconfirm
                        title={askDelete}
                        onConfirm={onDeleteProjects}
                        onCancel={cancel}
                        okText={'Yes'}
                        cancelText={'No'}
                        overlayClassName={classes.confirmStyle}
                    >
                        <CLFButtonSVG
                            loading={loading}
                            iconComponent={<CloseRoundSVG/>}
                            name={tDelete}
                            className={classes.secondaryColor}
                        />
                    </Popconfirm>
                }
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: '#FFF',
        marginTop: 40,
        padding: '30px 10px',
        borderRadius: 20,
        position: 'relative'
    },
    content: {
        textAlign: "center",
        fontSize: 14
    },
    divGrid: {
        marginBottom: 15
    },
    listItems: {
        marginTop: 20,
    },
    title: {
        fontWeight: "bold"
    },
    action: {
        marginTop: 20
    },
    itemHeader: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        width: 30,
        height: 30,
        marginRight: 5
    },
    textField: {
        marginLeft: 10
    },
    colorCircle: {
        width: 25,
        minWidth: 25,
        height: 25,
        borderRadius: '50%',
        padding: 0,
        border: '1px solid #D5D5D5',
        margin: "auto"
    },
    projectColor: {
        borderRadius: 24,
        maxWidth: 150,
        minHeight: 33,
        color: '#FFF',
        border: 'none',
        width: "calc(100% - 10px)",
        position: 'absolute',
        top: -15,
        left: '50%',
        transform: 'translateX(-50%)'
    },
    wrapText: {
        width: '95%',
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: '1.2rem',
        fontWeight: 'bold',
        whiteSpace: "nowrap",
    },
    saveIcon: {
        fill: theme.props.primaryColor,
        marginLeft: -45,
        marginRight: 10,
        marginTop: 1
    },
    deleteIcon: {
        fill: theme.props.primaryColor,
        marginLeft: -25,
        marginRight: 10,
        marginTop: 1
    },
    confirmStyle: {
        zIndex: '9999 !important'
    },
    primaryColor: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
            marginTop: 1
        }
    },
    secondaryColor: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
            marginTop: 1
        }
    }
}))

export default ProjectInfo;
