import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTheme, withStyles} from '@material-ui/core/styles';
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HeaderCenter from "../MenuAppBar/HeaderCenter";
import {v4 as uuidv4} from "uuid";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import {getGlobal, useGlobal} from "reactn";
import {filter} from "lodash";
import {Tooltip} from "antd";
import useIntlId from "../../hooks/useIntlId";

const styles = theme => ({
    root: {
        backgroundColor: "#f1f1f1",
        minWidth: 20,
        padding: 8,
        paddingLeft: 0,
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            background: "#fff"
        },
        display: "flex"
    },
    root2: {
        backgroundColor: "#f1f1f1",
        minWidth: 20,
        padding: 8,
        paddingLeft: 0,
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            background: "#fff"
        },
        display: "flex"
    },
    tab: {
        minWidth: 0,
        padding: '6px 6px 6px'
    },
    boxMenu: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('md')]: {
            display: "none"
        }

    },
    boxMenuEvent: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }

    },
    buttonToday: {
        display: "flex",
    },
    btnMenu: {
        display: "none",
        color: "#fff",
        [theme.breakpoints.down('md')]: {
            display: "block",
            color: "#000"
        }
    },
    btnMenuEvent: {
        display: "none",
        color: "#fff",
        [theme.breakpoints.down('md')]: {
            display: "block",
            color: '#000'
        }
    },

    img: {
        width: "100%",
        maxWidth: 35,
        height: 21
    },
    imgToday: {
        width: 48
    },
    imgEvent: {
        cursor: 'pointer',
        maxWidth: 35,
        height: 21,
        width: '100%',
        border: '2px solid white',
        boxSizing: "border-box",
        display: "inline-block",
        verticalAlign: "middle",
        [theme.breakpoints.down('md')]: {
            background: "#3a3a3a"
        }
    },
    relative: {
        position: "relative",
    },
    activeTab: {
        border: "solid 2px red",
        padding: 3,
        display: "flex",
        marginLeft: 5
    },
    disableTab: {
        border: "solid 2px transparent",
        padding: 3,
        display: "flex"
    },
    ml5: {
        marginLeft: 5
    },
    menuPopoverContainer: {
        display: "flex",
        flexDirection: "row",
        background: "#fff",
        [theme.breakpoints.down('md')]: {
            color: '#000',
            background: "transparent",
        },
    },
    iconAntd: {
        fontSize: 25, fontWeight: 'bold'
    },
    popoverMd: {
        '& .MuiPaper-rounded': {
            backgroundColor: '#F1F1F1',
            padding: 10
        }
    },
    button: {
        borderRadius: "50% !important",
        width: "37px !important",
        height: "37px !important",
        zIndex: 999,
        marginRight: 5,
        marginBottom: 1,
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        // boxShadow: '0px 0px 0px 2px rgba(0, 0, 0, 0.2)',
        "& svg": {
            width: "35px !important",
            height: "35px !important",
            fill: "#fff"
        }
    },
    flex: {
        display: "flex",
        flexDirection: 'row'
    },
});


function SimpleBottomNavigation(props) {
    const [openMenu, setOpenMenu] = useState(null)
    const open = Boolean(openMenu);
    const id = open ? 'menuleft-popover' : undefined;
    const {classes, numOfLine, setNumOfLine, setCalendarHeight} = props
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [, setCreateEvent] = useGlobal('createEvent');
    const [creatEvent] = useIntlId([
        'milestone.creatEvent'
    ]);

    const [refetchDateNow, setRefetchDateNow] = useGlobal("refetchDateNow")

    const handleClick = event => {
        setOpenMenu(event.currentTarget);
    };

    const handleClose = () => {
        setOpenMenu(null);
    };
    useEffect(() => {
        if (matches) {
            setOpenMenu(null)
        }
        // eslint-disable-next-line
    }, [matches])

    const isPathnameOk = () => {
        return window.location.pathname === '/' || window.location.pathname === '/mileStone'
    }

    function onToDay() {
        if (!moment().startOf('month').isSame(refetchDateNow)) {
            setRefetchDateNow(moment().format('YYYY-MM-DD'))
        }
    }

    const onClick = (e) => {
        const {allMilestoneProject} = getGlobal()
        const newAllProject = filter(allMilestoneProject, p => p?.msSelected)
        if (newAllProject.length !== 0) {
            let uuid = 'mile_' + uuidv4()
            let timeStartDefault = moment().startOf('day').format()
            let timeEndDefault = moment().startOf('day').add(1, 'day').format()
            let event = {
                id: uuid,
                allDay: true,
                start: timeStartDefault,
                end: timeEndDefault,
                resource: {
                    id: newAllProject[0].id,
                    title: newAllProject[0].name,
                    extendedProps: newAllProject[0]
                },
                jsEvent: {
                    target: e.currentTarget
                },
                isCreateButton: true,
                numberId: 0
            }
            setCreateEvent(event)
            onToDay();
        }
    };

    return (
        <div className={classes.relative}>
            <div className="flex justify-center items-center">
                <IconButton className={isPathnameOk() ? classes.btnMenu : classes.btnMenuEvent}
                            onClick={handleClick}>
                    <MenuIcon/>
                </IconButton>

                <Tooltip
                    title={creatEvent}
                    placement={"bottomRight"}
                >
                    <div onClick={onClick}>
                        <IconButton
                            className={classes.button}
                        >
                            <AddIcon/>
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={openMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    root: classes.popoverMd
                }}
            >
                <HeaderCenter numOfLine={numOfLine}
                              setNumOfLine={setNumOfLine}
                              setCalendarHeight={setCalendarHeight}
                              setOpenMenu={setOpenMenu}
                />
            </Popover>
        </div>
    );
}

SimpleBottomNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleBottomNavigation)
