import moment from "moment";
import {getGlobal} from 'reactn'

export function getDate(numberId) {
    const {view, refetchDateNow} = getGlobal()
    let date, endDate
    if (view === "CustomMonth") {
        date = moment(refetchDateNow).startOf("month").add(numberId, "month").format('YYYY-MM-DD')
        endDate = moment(refetchDateNow).endOf("month").add(numberId, "month").format('YYYY-MM-DD')
    } else {
        if (view === "CustomWeek") {
            date = moment(refetchDateNow).startOf("isoWeek").add(numberId, "isoWeek").format('YYYY-MM-DD')
            endDate = moment(refetchDateNow).endOf("isoWeek").add(numberId, "isoWeek").format('YYYY-MM-DD')
        } else {
            date = moment(refetchDateNow).startOf("day").add(numberId, "day").format('YYYY-MM-DD')
            endDate = moment(refetchDateNow).endOf("day").add(numberId, "day").format('YYYY-MM-DD')
        }
    }
    return {date, endDate}
}
