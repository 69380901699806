import {setGlobal} from 'reactn';
import {isMobileOnly} from 'react-device-detect';
import moment from 'moment';
import store from 'store';

const calendarTab2 = store.get('calendarTab2')
const calendarTab1 = store.get('calendarTab1')
const fontSize = store.get('fontSize') || 9
const focus = store.get('focus') || {identity: true, project: true}
const view = isMobileOnly ? 'CustomWeek' : store.get('view')
const segment = store.get('segment')
const projectSortSetting = store.get('projectSortSetting') || {isSort: false, timeView: 6}
const identityShow = store.get('identityShow') || false
const milestoneView = store.get('milestoneView') || 1
const focusColor = store.get('focusColor') || 'project'
const showTitleEvent = store.get('showTitleEvent') || false
const fullHeight = store.get('fullHeight') || ''
const showTip = store.get('showTip') || 'show'
const localEdit = store.get("editFreeBox") || []
const colors = store.get('colors') || {primary: '#1790FF', secondary: '#690003'}
const milestoneColor = store.get('milestoneColor') || 'project'

const gerenateFocus = (num) => {
    let result = {}
    for (let i = 0; i < num; i++) {
        result['focus-' + num] = true
    }
    return result
}
setGlobal({
    user: {},
    loading: false,
    calendarTab2: calendarTab2 ? calendarTab2 : 2,
    calendarTab1: calendarTab1 ? calendarTab1 : 2,
    // refetchDateNow: refetchDateNow ? refetchDateNow : Date.now(),
    refetchDateNow: moment().format('YYYY-MM-DD'),
    groups: [],
    groupsMs: [],  //  groups milestone
    view: view ? view : 'CustomMonth',
    openNewEvent: null,
    EventAdding: {top: 0, left: 0},
    openEditingEvent: {
        open: false,
        anchorEl: null
    },
    // accountPopupAnchorEl: null,
    openDeleteEvent: false,
    EventEditting: {},
    focusText: [],
    tasks: {
        data: [],
        default: 0
    },
    tab: 1,
    segment: segment ? segment : 'one',
    googleStatus: {
        is_login: false,
        userProfile: {},
        initialed: false
    },
    googleCalendarList: [],
    openEventDetails: false,
    EventTask: [],
    times: {
        minTime: {
            value: 7,
            label: '07:00'
        },
        maxTime: {
            value: 24,
            label: '24:00'
        }
    },

    //Task
    timer: null,
    _events: [],
    newUser: false,
    identities: [],
    priorities: [],
    projects: [],
    categories: [],

    // Popup Task
    newEvent: {},
    editEvent: {},
    popup: {
        newEventPopup: false,
        editEventPopup: false,
        top: 0,
        left: 0,
    },

    calendarID: {
        id: 0,
        name: ''
    },
    anchor: {
        top: 0,
        left: 0
    },
    initDialog: false,
    openIconList: false,
    addIdentityDialog: false,
    openAddProject: false,
    GoogleColor: [
        "#7986CB",
        "#33B679",
        "#8E24AA",
        "#E67C73",
        "#F6BF26",
        "#F4511E",
        "#039BE5",
        "#616161",
        "#3F51B5",
        "#0B8043",
        "#D50000"
    ],
    syncToken: [],
    syncEvents: [],
    GChannel: store.get('GChannel') ? store.get('GChannel') : [],
    LChannel: store.get('LChannel') ? store.get('LChannel') : {},
    RightClickEl: null,
    RightEditEvent: {},
    recurringOpen: false,
    recurringEvent: {},
    changeOption: '',
    deleted: [],
    restoring: false,
    restored: null,
    hoverEvent: null,
    hoverTitle: {
        title: '',
        start: '',
        end: ''
    },
    syncProcess: '',
    insync: false,
    sync_uuid: '',
    emojis: [
        {
            name: 'AssignmentTurnedIn',
            emoji: "✅"
        },
        {
            name: 'Group',
            emoji: "👥"
        },
        {
            name: 'AccountCircle',
            emoji: "👤"
        },
        {
            name: 'AssignmentInd',
            emoji: "🔄"
        },
    ],
    openSelectCalendar: false,
    calendarSelected: store.get('calendarSelected') ? store.get('calendarSelected') : [],
    allMilestoneProject: [],
    // height: '305px',
    // heightMap: {},
    // ReloadNeeded: false,
    thickLine: store.get('thickLine') ? store.get('thickLine') : [12, 18, null, null],
    treeData: [],
    openTime: new Date(),
    // focusEvent: {},
    packages: {},
    openPackageDialog: false,
    meetingTimes: {
        minTime: {
            value: 6,
            label: '06:00'
        },
        maxTime: {
            value: 24,
            label: '24:00'
        }
    },
    fontSize: fontSize,
    hiddenDays: [],
    apiCalendar: null,
    meetings: [],
    voteInfo: null,
    votesSelect: null,
    editVoteInfo: null,
    timeWarning: null,
    language: store.get("language") || "ja-jp",
    switchEmail: null,
    eventJoining: false,
    eventTransfer: false,
    editFreeSlot: null,
    notifications: [],
    myFreeTime: null,
    dateSelections: [],
    tabMeeting: 0,
    hoverVote: null,
    openSetting: false,
    hoverPos: null,
    voteBox: null,
    hoverDay: null,
    workTimeDisplay: [],
    geEvents: {},
    ggEvents: {},
    settingTab: 1,
    calendarUser: {},
    clickInfo: null,
    nextSyncTokens: {},
    projectSortSetting: projectSortSetting,
    zoomOAuth: {},
    zoomMeetings: [],
    accessToken: null,
    firebaseToken: null,
    refreshToken: null,
    prevAction: [],
    eventContextMenu: null,
    listSearch: [],
    searchKeyHistory: store.get('searchKeyHistory') ? store.get('searchKeyHistory') : [],
    searchKey: "",
    eventSearchKey: "",
    openFocusColor: [],
    colors: colors,
    focus: focus,
    focusView: gerenateFocus(store.get('numOfLine') || 2),
    fullHeight: fullHeight ? fullHeight : '',
    addProject: false,
    tour: null,
    showTip: showTip,
    openAddTask: false,
    editDialog: false,
    meetingPrimary: true,
    editFreeTime: localEdit.length !== 0,
    dateBusy: [],
    copyCreateEvent: null,
    isDragging: false,
    timeSearch: {
        start: moment(moment().subtract(30, 'days').format("YYYY/MM/DD")),
        end: moment().format("YYYY/MM/DD")
    },
    checkTimeSearch: true,
    projectsSyncWithGoogle: [],
    ggAllProject: [],
    identityShow: identityShow,
    milestoneView: milestoneView,
    focusColor: focusColor,
    createEvent: null,
    showTitleEvent: showTitleEvent,
    listTheme: [],
    settingName: '',
    codeSetting: '',
    syncLoad: false,
    navigationsApps: [],
    globalApps: [],
    focusRemind: null,
    focusHighLight: {},
    showFunctionApp: true,
    colHighLight: 1,
    googleEvents: [],
    geniamEvents: [],
    settingList: [],
    mySetting: {},
    projectList: [],
    projectMilestone: [],
    milestoneColor: milestoneColor,
    userGeniamApps: [],
    userLinks: [],
    eventChangeId: [],
});

if (process.env.REACT_APP_ENV === "production") {
    console.log = () => {
    }
}
