import {concat, remove, uniqBy} from 'lodash'
import {getGlobal} from 'reactn'
import moment from "moment";


export const getWeekEvents = (events = {}, start, end) => {
    const {projectMilestone} = getGlobal();
    let result = []

    projectMilestone.forEach(pro => {
        if (!pro.msSelected)
            return

        result = concat(result, getEventsInRange(events[pro.id]?.events || [], start, end, pro, true))
    })

    return uniqBy(result, "id")
}


export const getSelectedEvents = (events = {}, numberId = 0) => {
    const {projectMilestone, refetchDateNow} = getGlobal();
    let result = []
    const {start, end} = getDateRangeOfCal(refetchDateNow, numberId)
    projectMilestone.forEach(pro => {
        if (!pro.msSelected)
            return

        result = concat(result, getEventsInRange(events[pro.id]?.events || [], start, end, pro))
    })
    return uniqBy(result, "id")
}

const getEventsInRange = (events, start, end, pro, notShowAllDay = false) => {
    let result = []
    events.forEach(event => {
        if (moment(event.start).isBetween(start, end, null, "[]") ||
            moment(event.end).isBetween(start, end, null, "[]") ||
            (moment(event.start).isBefore(start) && moment(event.end).isAfter(end))) {
            result.push(event)
        }
    })

    if (!pro?.isShowMilestone)
        remove(result, e => e.project_uuid === pro.uuid && e?.task === 'mileStone')
    if (!pro?.isShowAllDay || notShowAllDay)
        remove(result, e => e.project_uuid === pro.uuid && e?.task !== 'mileStone' && e?.allDay)

    return result
}


export const getDateRangeOfCal = (date, numOfCal) => {
    const type = getType()
    const start = moment(date).add(numOfCal, type).startOf(type).utc().format()
    const end = moment(start).add(numOfCal, type).endOf(type).utc().format()
    return {
        start,
        end
    }
}

const getType = () => {
    const {view} = getGlobal()
    let type
    switch (view) {
        case "CustomWeek":
            type = "week"
            break
        case "CustomDay":
            type = "day"
            break
        default:
            type = "month"
    }
    return type
}
