import {useEffect, useState} from 'react';
import {useGlobal} from "reactn";
import {chunk, isEqual, remove} from "lodash";
import usePrevious from "react-use/lib/usePrevious";
import {db} from "../firebaseConfig";

function useGoogleCalendarWatch2(callback) {
    const [googleCalendarList] = useGlobal('googleCalendarList')
    const [googleStatus] = useGlobal('googleStatus')
    const preGoogleCalendarList = usePrevious(googleCalendarList)
    const [emails, setEmails] = useState([])

    useEffect(() => {
        if (!googleStatus?.is_login || !googleCalendarList?.length || isEqual(preGoogleCalendarList, googleCalendarList))
            return;
        const emails = googleCalendarList.map(g => g.id)
        remove(emails, e => {
            return e.includes('#contacts@group.v.calendar.google.com') || e.includes('#holiday@group.v.calendar.google.com')
        })
        setEmails(emails)
        // eslint-disable-next-line
    }, [googleCalendarList, googleStatus])

    useEffect(() => {
        // console.log({emails})
        if (!emails?.length) return;

        const emailChunks = chunk(emails.filter(x => x), 10)
        // console.log({emailChunks})
        const unsubs = emailChunks.map(_10email => {
                return db.collection('googleCalendarWatch')
                    .where('email', 'in', _10email)
                    .onSnapshot(snapshot => {
                        snapshot.docChanges().forEach(change => {
                            if (change.type !== 'modified') return;
                            const data = change.doc.data()
                            if (callback) {
                                callback(data)
                            }
                        })
                    })
            }
        )
        return () => {
            unsubs?.length && unsubs.forEach(u => {
                u()
            })
        }
        // eslint-disable-next-line
    }, [emails])
}

export default useGoogleCalendarWatch2;
