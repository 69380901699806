import {iconNameList} from "../../../common/muiIcons";
import {getGlobal} from 'reactn'
import {findIndex} from 'lodash'
//
// const StyledAccountCircle = styled(AccountCircle)`
//     fill: ${props => props.color}
// `
// const StyledGroup = styled(Group)`
//     fill: ${props => props.color}
// `
// const StyledAssignmentInd = styled(AssignmentInd)`
//     fill: ${props => props.color}
// `
// const StyledAssignmentTurnedIn = styled(AssignmentTurnedIn)`
//     fill: ${props => props.color}
// `
const oldIcons = {
    "AccountCircle": "account_circle",
    "Group": "group",
    "AssignmentInd": "assignment_ind",
    "AssignmentTurnedIn": "assignment_turned_in"
}

export const getIcon = (name) => {
    return oldIcons[name] || name
}

export const isMaterialIcon = name => {
    return name && iconNameList.includes(getIcon(name))
}

export const isImageTask = uid => {
    const {tasks} = getGlobal()
    return findIndex(tasks?.data || [], item => item?.type === "file" && item?.file?.id === uid) !== -1
}

export const getTaskUrl = uid => {
    const {tasks} = getGlobal()
    let idx = findIndex(tasks?.data || [], item => item?.type === "file" && item?.file?.id === uid)
    if (idx === -1)
        return null
    return tasks.data[idx]?.file?.url || ''
}

export const publicIcon = (iconName) => {
    return `${process.env.PUBLIC_URL}icons/${iconName}`
}
