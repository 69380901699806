import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ButtonAdd from './ButtonAdd';
import AntdInput from '../../Custom/AntdInput';
import {useAnnouncement} from '../../../context/AnnouncementContext';
import {CloseCircleFilled} from '@ant-design/icons';
import IconButton from '@material-ui/core/IconButton';
import {chunk, cloneDeep, findIndex} from 'lodash';
import {Input} from 'antd';
import {Grid} from '@material-ui/core';
import moment from 'moment';
import useIntlId from '../../../hooks/useIntlId';
import {useGlobal} from 'reactn';

function AnnouncementSet({updateAnnouncementSet}) {
    const {lock, bar} = useAnnouncement()
    const [colors] = useGlobal('colors')
    const [focusRemind, setFocusRemind] = useGlobal('focusRemind')
    const classes = useStyles()
    const [listData, setListData] = useState([])
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [remindSetIntl, timeIntl, dayIntl, noItemIntl, remindContentIntl] = useIntlId(['settings.remind.set', 'event.numOfVote.Adornment', 'time.day', 'settings.announcement.item', 'setting.remindContent'])
    const [barToday, setBarToday] = useState([])
    useEffect(() => {
        setListData(cloneDeep(bar?.announcementSets?.data))
        if (bar?.announcementSets) {
            const {data, startDate, times} = bar?.announcementSets
            if (data?.length && times > 0) {
                const chunked = chunk(data.filter(x => x), times)
                let dayDiff = moment().diff(startDate, "days") % (chunked.length ?? 1)
                dayDiff = dayDiff > 0 ? dayDiff - 1 : 0
                if (chunked[dayDiff] && chunked[dayDiff]?.length) {
                    setBarToday(chunked[dayDiff].map((text, idx) => ({
                        title: text,
                        index: idx + dayDiff * times
                    })))
                }
            }
        }
    }, [bar?.announcementSets])

    const handleAddAnnouncementSets = () => {
        let newData = cloneDeep(listData) || []
        newData.push("")
        let newAnnouncementSets = cloneDeep(bar?.announcementSets)
        if (bar?.announcementSets.data.length === 0) {
            newAnnouncementSets.startDate = moment(Date.now()).format('YYYY/MM/DD')
        }
        newAnnouncementSets.data = [...newData]
        updateAnnouncementSet(newAnnouncementSets)
        // setListData([...newData])
    }
    const onChangeTimes = (value) => {
        let newAnnouncementSet = {...bar?.announcementSets}
        newAnnouncementSet.times = value
        updateAnnouncementSet({...newAnnouncementSet})
    }
    const onChangeText = (e, index) => {
        let newData = cloneDeep(listData) || []
        newData[index] = e.target.value
        setListData([...newData])
    }
    const onSaveContent = (index) => {
        let newData = cloneDeep(listData) || []
        if (newData[index] === "" || newData[index] === undefined) {
            setListData(cloneDeep(bar?.announcementSets?.data))
            return
        }
        onSave()
    }

    const onSave = (data) => {
        setFocusRemind(null)
        let newAnnouncementSets = cloneDeep(bar?.announcementSets)
        if (bar?.announcementSets.data.length === 0) {
            newAnnouncementSets.startDate = moment(Date.now()).format('YYYY/MM/DD')
        }
        newAnnouncementSets.data = data ? data : [...listData]
        updateAnnouncementSet(newAnnouncementSets)
    }
    useEffect(() => {
        if (listData.length > 0) {
            if (!listData.slice(-1).pop()) return setDisabledBtn(true)
        }
        return setDisabledBtn(false)
    }, [listData])

    return (
        <div className={classes.root}>
            <div className={classes.top}>
                <div className={classes.title}>{remindSetIntl}</div>
            </div>
            <Grid container className={classes.announcementContent}>
                <Grid item xs={1} className={classes.action}>
                    <AntdInput size={32} width={150} value={bar?.announcementSets?.times || 1} steps={1} min={0}
                               label={`${timeIntl}/${dayIntl}`}
                               onChange={(va) => onChangeTimes(va)}
                               disabled={lock}
                               isTransform={true}
                    />
                </Grid>
                <Grid xs={11} item className={classes.listContent}>
                    {
                        listData?.length > 0 ? listData.map((item, index) => {
                                return (
                                    <div className={classes.content} key={index}>
                                        {findIndex(barToday, b => b.index === index) !== -1 && item !== '' ? <div
                                            style={{backgroundColor: colors?.primary, width: 10, height: 43}}/> : ''}
                                        <Input
                                            disabled={lock}
                                            id={'announcementSet' + index}
                                            onPressEnter={() => {
                                                onSaveContent(index)
                                                const el = document.getElementById('announcementSet' + index)
                                                if (el) {
                                                    el.blur()
                                                }
                                            }}
                                            onBlur={() => onSaveContent(index)}
                                            className={classes.value} onChange={(e) => onChangeText(e, index)}
                                            placeholder={remindContentIntl}
                                            autoFocus={!item || focusRemind?.index === index}
                                            value={item}/>
                                        <IconButton
                                            disabled={lock}
                                            onClick={() => {
                                                listData.splice(index, 1)
                                                onSave(listData)
                                            }}
                                            className={classes.remove}>
                                            <CloseCircleFilled/>
                                        </IconButton>
                                    </div>
                                )
                            })
                            :
                            <div>{noItemIntl}</div>
                    }
                    <div className={classes.actionCreate} align={'center'}>
                        <ButtonAdd onClick={handleAddAnnouncementSets} disabled={lock} disabledBtn={disabledBtn}/>
                    </div>
                </Grid>
            </Grid>

        </div>
    );
}

export default AnnouncementSet;
const useStyles = makeStyles(theme => ({
    root: {
        padding: "15px 0",
        borderBottom: "solid 1px #fff"
    },
    top: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    action: {
        display: "flex",
        alignItems: "center"
    },
    actionCreate: {
        margin: 5,
    },
    title: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444"
    },
    today: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444",
        padding: "8px 15px 10px",
        backgroundColor: "#fff",
        borderRadius: "13px 13px 0 0"
    },
    announcementContent: {
        minHeight: 182
    },
    listContent: {
        backgroundColor: "#fff",
        borderRadius: "13px",
        padding: "5px 20px",
        "& > div:not(:last-child)": {
            borderBottom: "solid 1px #f1f1f1"
        },
    },
    content: {
        color: "#444",
        // padding: "13px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    btnGroup: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    value: {
        border: "none",
        borderRadius: "13px",
        fontSize: 14,
        padding: "5px 20px",
        marginRight: 6,
        cursor: "pointer"
    },
}))
