import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import styled from 'styled-components';
import {Tooltip} from "antd";
import {ReactComponent as CopySVG} from "../../../asSvg/icons/copyBG.svg";
import moment from "moment";
import copy from "clipboard-copy";
import {toast} from "react-toastify";
import {getIntlId} from "../../../hooks/useIntlId";
import zoneObj from "../../../common/zoneObj";

// const TooltipCustom = styled(Tooltip)`
//
// `

const DivTitleCustom = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DivButtonCopy = styled.div`
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const DivTextCopy = styled.div`
    width: 75%;
    color: #000000;
    font-size: 1rem;
    font-weight: unset;
`;

const DivText = styled.div`

`

function HoverEventCalendar({children, hoverTitle, placement}) {
    const [colors] = useGlobal('colors');
    const [isDragging] = useGlobal('isDragging');
    const [isSameDay, setIsSameDay] = useState(true);
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (visible)
            setVisible(false)
        if (hoverTitle && hoverTitle.start && hoverTitle.end) {
            if (moment(hoverTitle.end).diff(hoverTitle.start, 'days', true) <= 1) {
                setIsSameDay(true)
            } else {
                setIsSameDay(false)
            }
        }
        return () => {
            setVisible(false)
        }
        // eslint-disable-next-line
    }, [hoverTitle])

    const handleCopy = () => {
        let text = ""
        if (isSameDay) {
            text += `${zoneObj(hoverTitle.start).format('MM/DD')} （${zoneObj(hoverTitle.start).format('ddd')})`
            text += "\n"
        } else {
            text += `${zoneObj(hoverTitle.start).format('MM/DD')} `
            text += `(${zoneObj(hoverTitle.start).format('ddd')} ) `
            text += `- ${zoneObj(hoverTitle.end).subtract(1, "day").format('MM/DD')} `

            text += `(${zoneObj(hoverTitle.end).subtract(1, "day").format('ddd')})`
            text += "\n"
        }
        copy(text)
        setVisible(false)
        toast.success(getIntlId('common.copied'))
    }

    const TitleChildren = ({hoverTitle}) => {
        return (
            <DivTitleCustom>
                <DivButtonCopy>
                    <CopySVG fill={colors?.primary} onClick={handleCopy}/>
                </DivButtonCopy>
                <DivTextCopy>
                    {isSameDay ?
                        <div>
                            <DivText>{zoneObj(hoverTitle.start).format('MM/DD')}（{zoneObj(hoverTitle.start).format('ddd')}）</DivText>
                        </div>
                        :
                        <div>
                            <DivText>
                                {zoneObj(hoverTitle.start).format('MM/DD')}
                                ({zoneObj(hoverTitle.start).format('ddd')})
                                {` - `}
                                {zoneObj(hoverTitle.end).subtract(1, "day").format('MM/DD')}
                                ({zoneObj(hoverTitle.end).subtract(1, "day").format('ddd')}）</DivText>

                        </div>
                    }
                    <DivText>{hoverTitle.title}</DivText>
                    {
                        hoverTitle.extendedProps.isAccepted &&
                        <DivText>(Accepted)</DivText>
                    }
                </DivTextCopy>
            </DivTitleCustom>
        )
    }

    if (!children)
        return null;
    return (
        <Tooltip
            title={<TitleChildren hoverTitle={hoverTitle}/>}
            placement={placement}
            onMouseEnter={() => {
                setVisible(true)
            }}
            onMouseLeave={() => {
                // console.log('mouse leave')
                // setGlobal({isDragging: false})
            }}
            visible={visible && !Boolean(isDragging)}
            onVisibleChange={(show) => {
                setVisible(show)
            }}
            overlayClassName={'hover-show-tooltip'}
            destroyTooltipOnHide={{keepParent: false}}
        >
            {children}
        </Tooltip>
    )
};

export default HoverEventCalendar;
