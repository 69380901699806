import qs from 'query-string';

export default function LoadCalendar({calendarUser}) {
    const {viewCalendar} = qs.parse(window.location.search)

    if (viewCalendar) {
        return [0]
    }
    if (calendarUser?.themeCustom && calendarUser?.themeCustom?.rows) {
        let calendarArr = []
        if (calendarUser?.themeCustom?.viewCalendar === "CustomDay") {
            for (let i = 0; i < calendarUser?.themeCustom?.rows; i++) {
                calendarArr.push(i)
            }
            return calendarArr
        }
        let num = calendarUser?.themeCustom?.columns * calendarUser?.themeCustom?.rows
        for (let i = 0; i < num; i++) {
            calendarArr.push(i)
        }
        return calendarArr
    } else {
        return [0]
    }
}
