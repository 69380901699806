import {getGlobal} from 'reactn'
import moment from 'moment'
import {toast} from "react-toastify";
import {db} from "../../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {concat, isEqual, remove, uniqWith} from 'lodash'

export const getCalendarInfo = async (start, end, isMilestone = false) => {
    const {groups, user} = getGlobal()
    let result = []
    if (groups.length === 0) {
        console.log('no groups found')
        return result
    }
    if (user.user_id) {
        try {
            let start_time = moment(start).utc().format()
            let end_time = moment(end).utc().format()
            let projects = []
            groups.forEach(group => {
                if (group.checked && !group.is_sync) {
                    projects.push(group)
                }
            })
            if (projects.length === 0) {
                return result
            }

            const events = await Promise.all(projects.map(async pro => {
                const snapCollRef = isMilestone ?
                    db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/projects/${pro.id}/events`)
                        .where("isDeleted", "==", false)
                        .where("isTasks", "==", false)
                        .where("allDay", "==", true)
                        .where('start', ">=", start_time)
                        .where('start', "<=", end_time)
                    :
                    db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/projects/${pro.id}/events`)
                        .where("isDeleted", "==", false)
                        .where("isTasks", "==", false)
                        .where('start', ">=", start_time)
                        .where('start', "<=", end_time)

                const snapCollRefOr = db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/projects/${pro.id}/events`)
                    .where("isDeleted", "==", false)
                    .where("isTasks", "==", false)
                    .where("allDay", "==", true)
                    .where('end', ">=", start_time)
                    .where('end', "<=", end_time)


                const snapEvents = await snapCollRef.get()
                const snapEventsOr = await snapCollRefOr.get()

                const eventData = snapEvents.docs.map(doc => ({
                    ...doc.data(),
                    borderColor: pro.color,
                    id: doc.id,
                    uuid: doc.id,
                }))

                const eventMilestoneData = snapEvents.docs.map(doc => ({
                    ...doc.data(),
                    borderColor: pro.color,
                    id: doc.id,
                    uuid: doc.id,
                    resourceId: pro.id
                }))

                const eventMilestoneOr = snapEventsOr.docs.map(doc => ({
                    ...doc.data(),
                    borderColor: pro.color,
                    id: doc.id,
                    uuid: doc.id,
                    resourceId: pro.id
                }))

                return isMilestone ? uniqWith(concat(eventMilestoneData, eventMilestoneOr), isEqual)
                    :
                    eventData
            }))
            result = events.flat()

        } catch (e) {
            console.log(e.toString())
        }
    }
    return result.map(e => {
        return {
            ...e,
            id: e.uuid
        }
    })
}

export const renderCal = number => {
    for (let i = 0; i < 36; i++) {
        if (i === number - 1 || i === number || i === number + 1 || i === number + 2) {
            if (window['calendar' + i]) window['calendar' + i].render()
        } else {
            if (window['calendar' + i]) window['calendar' + i].destroy()
        }
    }
}

export const getTaskDefault = () => {
    const {tasks} = getGlobal()
    if (!tasks.data[tasks.default])
        return ''
    return tasks.data[tasks.default].name
}

export const CreateGoogleCalendar = async (name, color) => {
    try {
        let request = window.gapi.client.calendar.calendars.insert({
            summary: name
        })
        let data = await request.then()
        if (data.status !== 200) {
            return null
        }
        const calendarId = data.result.id
        request = window.gapi.client.calendar.calendarList.patch({
            "calendarId": calendarId,
            "colorRgbFormat": true,
            backgroundColor: color
        })
        data = await request.then()
        if (data.status !== 200) {
            return
        }
        return data.result
        // const res = await axios.post('https://www.googleapis.com/calendar/v3/calendars', {
        //     summary: name
        // })
        // const {data} = await axios.patch(`https://www.googleapis.com/calendar/v3/users/me/calendarList/${res.data.id}?colorRgbFormat=true`, {
        //     backgroundColor: color
        // })
        // return data
    } catch (e) {
        toast.error(e.toString())
        return null
    }

}

export const gerenateBackgroundEvents = (start, end) => {
    let result = []
    let startTime = start
    while (moment(startTime).isBefore(end)) {
        result.push({
            start: moment(startTime).startOf("day").format(),
            end: moment(startTime).endOf("day").format(),
            rendering: "background",
            backgroundColor: "white",
            background: true
        })
        startTime = moment(startTime).add(1, "day").format()
    }
    return result
}

export const getWorkTime = (start, end) => {
    const {workTimeDisplay} = getGlobal()
    if (!workTimeDisplay || !workTimeDisplay.length) {
        return []
    }
    const data = workTimeDisplay.map(time => {
        if (moment(end).isBefore(moment(time.startDay).startOf("day")))
            return []
        const startDay = moment(time.startDay).startOf("day").format()
        const endDay = time.endDay ? moment(time.endDay).endOf("day").format() : end
        if (moment(start).isBetween(startDay, endDay, null, "[]") ||
            moment(end).isBetween(startDay, endDay, null, "[]") ||
            moment(startDay).isBetween(start, end, null, "[]")
        ) {
            let events = []
            const startCalc = moment(start).isBefore(startDay) ? startDay : start
            const endCalc = moment(end).isBefore(endDay) ? end : endDay
            let dayStart = moment(startCalc).startOf("day").format()
            while (moment(dayStart).isBefore(endCalc)) {
                let endHours = time?.timeDisplay?.maxTime?.value || 24
                let statTime = moment(dayStart).hours(time?.timeDisplay?.minTime?.value || 0).format()
                let endTime = endHours === 24 ? moment(dayStart).add(1, "day").format() : moment(dayStart).hours(endHours).format()
                events.push({
                    start: statTime,
                    end: endTime,
                    rendering: "background",
                    backgroundColor: "#88ef87",
                    timeWork: true,
                    hours: endHours - (time?.timeDisplay?.minTime?.value || 0) - (time?.excludeTime?.value || 0)
                })
                dayStart = moment(dayStart).add(1, "day").format()
            }
            return removeDayOffs(events, time.dayOffs, time.hiddenDays)
        } else {
            return []
        }
    })
    return data.flat()
}

const removeDayOffs = (events = [], dayOffs = [], hiddenDays = []) => {
    if (!dayOffs.length && !hiddenDays.length) {
        return events
    }
    let result = []
    events.forEach(evt => {
        let isOff = false
        dayOffs.forEach(days => {
            const start = moment(days.start).startOf("day").format()
            const end = moment(days.end).endOf("day").format()
            if (moment(evt.start).isBetween(start, end) || moment(evt.end).isBetween(start, end)) {
                isOff = true
            }
        })
        if (!isOff)
            result.push(evt)
    })
    remove(result, evt => hiddenDays.includes(moment(evt.start).isoWeekday()))
    return result
}
