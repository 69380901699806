import {useIntl} from "react-intl";
import {isArray, isString} from 'lodash'
import {myInfo} from "../common/firebaseRef/calendar";
import store from 'store'

/**
 * @param {string|array} id
 * @param values
 */

const useIntlId = (id, values) => {

    const intl = useIntl()
    // console.log(id)
    return isArray(id) ?
        id.map(i => intl.formatMessage({id: i}, values))
        : intl.formatMessage({id}, values)
}

export const getIntlId = (id) => {
    const language = myInfo('language')
    if (!id)
        return language

    const langMsg = store.get('langMsg')?.[language]
    if (!langMsg) return;

    const getOneIntl = (id) => {
        return langMsg[id]
    }
    if (isString(id))
        return getOneIntl(id)
    if (isArray(id))
        return id.map(i => getOneIntl(i));
}

export default useIntlId
