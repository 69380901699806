import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {MenuItem, MenuList, Popover} from '@material-ui/core'
import DayPicker from 'react-day-picker';
import moment from 'moment'
import 'react-day-picker/lib/style.css';
import {getTimeList} from "../../../common/timeList";
import styled from 'styled-components'
import zoneObj from "../../../common/zoneObj";

const Container = styled.div(props => ({
    background: props.background || "#f1f1f1",
    display: "flex",
    flexDirection: "row",
    height: 27,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: 25,
    padding: "0 5px 0 5px",
}))
const timeList = getTimeList()

function DateTimePicker({
                            dateTime = new Date(),
                            allDay = true,
                            isEnd = false,
                            disabled = false,
                            onChange = () => {
                            },
                            background = null
                        }) {
    const classes = useStyles();
    const [openDate, setOpenDate] = useState(null);
    const [openTime, setOpenTime] = useState(null);
    const handleDayClick = (day) => {
        const year = moment(day).year()
        const month = moment(day).month()
        const date = moment(day).date()
        const nextValue = zoneObj(dateTime).set({
            years: year,
            months: month,
            dates: date
        }).format()
        onChange(isEnd ? moment(nextValue).add(1, "day") : nextValue)
        setOpenDate(null)
    }
    const handleSelectTime = (time) => {
        const hours = Number(time.split(":")[0])
        const minutes = Number(time.split(":")[1])
        const nextValue = moment(dateTime).set({
            hours,
            minutes
        }).format()
        onChange(allDay ? moment(nextValue).format("YYYY-MM-DD") : nextValue)
    };

    const {year, month, date} = moment.locale() === 'en-gb' ?
        {year: ' -', month: " -", date: "  "}
        : moment.locale() === 'vi' ?
            {year: ' -', month: " -", date: "  "}
            :
            {year: '年', month: "月", date: "日"}

    return (
        <Container background={background}>
            <div
                className={classes.time}
                onClick={(event) => {
                    if (disabled) {
                        return
                    }
                    setOpenDate(event.currentTarget)
                }}>

                {moment(dateTime).subtract(allDay && isEnd ? 1 : 0, "seconds").format(`YYYY${year} M${month} DD${date}`)}
                <span
                    className={classes.dateInput}>{moment(dateTime).subtract(allDay && isEnd ? 1 : 0, "seconds").format(`ddd`)}</span>
            </div>
            {
                !allDay ?

                    <input
                        value={moment(dateTime).format("HH:mm")}
                        className={classes.timeInput}
                        onChange={() => {
                        }}
                        readOnly={true}
                        onClick={(event) => {
                            if (disabled) {
                                return
                            }
                            setOpenTime(event.currentTarget)
                        }}
                    />
                    :
                    null
            }
            <Popover
                open={Boolean(openDate)}
                anchorEl={openDate}
                onClose={() => {
                    setOpenDate(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                // disableFocusRipple
            >
                <DayPicker
                    initialMonth={new Date(moment(dateTime).subtract(allDay && isEnd ? 1 : 0, "seconds").format("YYYY-MM-DD"))}
                    selectedDays={new Date(moment(dateTime).subtract(allDay && isEnd ? 1 : 0, "seconds").format("YYYY-MM-DD"))}
                    onDayClick={handleDayClick}
                />
            </Popover>
            <Popover
                open={Boolean(openTime)}
                anchorEl={openTime}
                onClose={() => {
                    setOpenTime(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                // disableFocusRipple
                onEntered={() => {
                    const element = document.getElementById(moment(dateTime).format("HH:mm"))
                    if (element) {
                        element.focus()
                    }
                }}
            >
                <MenuList className={classes.height200}
                    // autoFocusItem={true}
                    //       autoFocus={true}
                    // value={moment(dateTime).format("HH:mm")}
                          variant={"selectedMenu"}
                >

                    {
                        timeList.map(item => <MenuItem key={item}
                                                       id={item}
                                                       onClick={() => {
                                                           handleSelectTime(item)
                                                           setOpenTime(null)
                                                       }}
                                                       onFocus={() => {
                                                           if (item !== moment(dateTime).format("HH:mm")) {
                                                               handleSelectTime(item)
                                                           }
                                                       }}
                        >
                            {item}
                        </MenuItem>)
                    }
                </MenuList>
            </Popover>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    timeInput: {
        border: "none",
        outline: "none",
        textAlign: "center",
        borderRadius: 11,
        width: 45,
        height: 21,
        marginLeft: 3,
        "&:focus": {
            outline: "none"
        },
        [theme.breakpoints.down('md')]: {},
    },
    container: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#F1F1F1",
        height: 27,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: 25,
        padding: "0 5px 0 5px",
    },
    dateInput: {
        fontWeight: "bold",
        marginLeft: 3,
    },
    time: {
        padding: "0 3px"
    },
    height200: {
        height: 200
    }
}));

export default DateTimePicker;
