import React from 'react';
import {useAnnouncement} from '../../../context/AnnouncementContext';
import {makeStyles, Typography} from '@material-ui/core';

function MyPlan(props) {
    const {plan} = useAnnouncement()
    const classes = useStyles()
    if (!plan?.info)
        return null
    return (
        <div className={classes.root}>
            <Typography className={[classes.plan, 'italic']}>
                現在のプランは <span style={{color: plan.info.color}}>{plan.info.name}</span> です。
            </Typography>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: "20px 0"
    },
    plan: {
        fontWeight: 700,
        fontSize: 30,
        textAlign: "center",
    }
}))

export default React.memo(MyPlan)
