import React from 'react';
import {Button} from 'antd';
import {makeStyles} from '@material-ui/core/styles';
import useIntlId from '../../../hooks/useIntlId';

function ButtonAdd({disabled, disabledBtn = false, onClick}) {
    const classes = useStyles()
    const [addIntl] = useIntlId(['app.setting.add'])
    return (
        <Button
            onClick={onClick}
            disabled={disabled || disabledBtn}
            shape="round" className={disabled ? classes.buttonLock : classes.buttonAdd}>
            <img className={classes.iconAdd} src="/icons/add.svg" alt=""/>
            {addIntl}
            {
                disabled && <img className={classes.iconLock} src="/icons/iconKey.svg" alt=""/>
            }
        </Button>
    );
}

export default ButtonAdd;
const useStyles = makeStyles(theme => ({

    buttonAdd: {
        border: "none",
        background: `${theme.props.primaryColor}`,
        textTransform: "uppercase",
        fontWeight: 700,
        color: "#fff !important",
        "& .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active": {
            border: "none",
            background: "#c6c6c6 !important",
            color: "#fff !important",
        },
        "&:hover": {
            background: `${theme.props.primaryColor}`
        }
    },
    buttonLock: {
        border: "none",
        background: "#c6c6c6 !important",
        textTransform: "uppercase",
        fontWeight: 700,
        color: "#fff !important",
        position: "relative",
        "& .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active": {
            border: "none",
            background: "#c6c6c6 !important",
            color: "#fff !important",
        }
    },
    iconAdd: {
        marginRight: 5,
    },
    iconLock: {
        right: 30
    }
}))
