import {getGlobal, setGlobal} from 'reactn'
import axios from "axios";
import {TASKS} from "../config/apiUrls";
import {ggTokenChecked} from "../components/Calendar/google/auth";
import {getDateRangeOfCal} from "../context/common/getSelectedEvents";
import moment from "moment";
import {headerHeight} from "../components/Calendar/handlers/initCal";

export const RefetchGG = async () => {
    const {tab, calendarTab1, calendarTab2} = getGlobal()
    const calendar = tab === 1 ? calendarTab1 : calendarTab2
    await ggTokenChecked()
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            let source = window['calendar' + i].getEventSourceById('google')
            if (source)
                source.refetch()
        }
    }
    // getNumOfProject()
}

export const RefetchOneGG = (i) => {
    if (window['calendar' + i] && window['calendar' + i].view) {
        let source = window['calendar' + i].getEventSourceById('google')
        if (source)
            source.refetch()
    }
}
export const RefetchGeniam = () => {
    const {tab, calendarTab1, calendarTab2} = getGlobal()
    const calendar = tab === 1 ? calendarTab1 : calendarTab2
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            let source = window['calendar' + i].getEventSourceById('geniam')
            if (source)
                source.refetch()
        }
    }
}

export const refetchOne = (i) => {
    if (window['calendar' + i] && window['calendar' + i].view) {
        window['calendar' + i].refetchEvents()
    }
}

export const refetchAll = async () => {
    const {tab, calendarTab1, calendarTab2} = getGlobal()
    const calendar = tab === 1 ? calendarTab1 : calendarTab2
    await ggTokenChecked()
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            window['calendar' + i].refetchEvents()
        }
    }
    // getNumOfProject()
}

export const refetchOneSource = (name, i) => {
    if (name && window['calendar' + i] && window['calendar' + i].view) {
        let source = window['calendar' + i].getEventSourceById(name)
        if (source)
            source.refetch()
    }
}

export const refetchSource = async (name) => {
    const {tab, calendarTab1, calendarTab2, projectSortSetting, allMilestoneProject} = getGlobal()
    let calendar = tab === 1 ? calendarTab1 : calendarTab2
    if (projectSortSetting.isSort) {
        calendar = Number(projectSortSetting.timeView) * (allMilestoneProject?.length || 0)
    }

    if (name === "google") {
        await ggTokenChecked()
    }
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            let source = window['calendar' + i].getEventSourceById(name)
            if (source) {
                source.refetch()
                headerHeight(i)
            }

        }
    }
}

export const addEventSource = (event) => {
    const sourceName = (event.is_google || event.googleEvent) ? 'google' : ((event.isEventVote && (!event.isAccepted || !event.project_uuid)) ? "vote" : 'geniam')
    const {tab, calendarTab1, calendarTab2, refetchDateNow, projectSortSetting, allMilestoneProject} = getGlobal()
    let calendar = tab === 1 ? calendarTab1 : calendarTab2;

    if (projectSortSetting.isSort) {
        event.resourceId = event.project_uuid
        calendar = Number(projectSortSetting.timeView) * (allMilestoneProject?.length || 0)
    }
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            let source = window['calendar' + i].getEventSourceById(sourceName)
            const {
                start,
                end
            } = window['calendar' + i]?.state?.dateProfile?.currentRange || getDateRangeOfCal(refetchDateNow, i)
            if (moment(event.start).isBetween(start, end, null, "[]") ||
                moment(event.end).isBetween(start, end, null, "[]") ||
                (moment(event.start).isBefore(start) && moment(event.end).isAfter(end))
            ) {
                window['calendar' + i].addEvent(event, source)
            }
        }
    }
}
export const removeEvent = id => {
    if (!id) return;
    const {tab, calendarTab1, calendarTab2, projectSortSetting, allMilestoneProject} = getGlobal()
    let calendar = tab === 1 ? calendarTab1 : calendarTab2

    if (projectSortSetting.isSort)
        calendar = Number(projectSortSetting.timeView) * (allMilestoneProject?.length || 0)

    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            const event = window['calendar' + i].getEventById(id)
            if (event)
                event.remove()
        }
    }
}

export const getAllTask = () => {
    const {projects} = getGlobal()
    let _projects_uuid = []
    projects.forEach(value => {
        _projects_uuid.push(value.uuid)
    })
    axios.post(TASKS + '/show-tasks', _projects_uuid).then(res => {
        if (res.data) {
            return setGlobal({_events: res.data})
        }
    }).catch(err => {
        console.log(err)
    })
}
export const removeAllEvents = () => {
    const {calendarTab1, allMilestoneProject, projectSortSetting} = getGlobal()
    let calendar = calendarTab1
    if (projectSortSetting.isSort)
        calendar = Number(projectSortSetting.timeView) * (allMilestoneProject?.length || 0)
    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            window['calendar' + i].removeAllEvents()
        }
    }
}
export const refetchAllEvents = () => {
    const {calendarTab1, allMilestoneProject, projectSortSetting} = getGlobal()
    let calendar = calendarTab1
    if (projectSortSetting.isSort)
        calendar = Number(projectSortSetting.timeView) * (allMilestoneProject?.length || 0)

    for (let i = 0; i < calendar; i++) {
        if (window['calendar' + i] && window['calendar' + i].view) {
            window['calendar' + i].refetchEvents()
        }
    }
}
