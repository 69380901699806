import React, {useEffect, useState} from 'react';
import Tab from '@material-ui/core/Tab';
import {useGlobal} from 'reactn';
import store from 'store';
import moment from 'moment';
import {toast} from 'react-toastify';
import useIntlId from '../../hooks/useIntlId';
import {makeStyles} from '@material-ui/core/styles';
import MenuItemContainer from './MenuItemContainer';
import Views from './Views/Views';
import {ReactComponent as TodaySVG} from '../../asSvg/today.svg';
import {db} from '../../firebaseConfig';
import {CALENDAR_COLLECTION} from '../../config/constants';
import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import InputNumber from '../Custom/InputNumber';
import TabsCustom from '../Custom/TabsCustom';
import WorkSpaceButtons from './EditWorkSpace/WorkSpaceButtons';
import {cloneDeep, findIndex, isEqual} from 'lodash';
import NavApp from "../Navigation/NavApp";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import {useHistory} from "react-router-dom";
// import {v4 as uuidv4} from "uuid";

function HeaderCenter(props) {
    const classes = useStyles();
    const {
        expired = false,
        numOfLine,
        setNumOfLine,
        setCalendarHeight,
        // handleKeyDown,
        // onChangeSearch,
        openSearch
    } = props
    const [calendarTab2, setCalendarTab2] = useGlobal('calendarTab2')
    const [calendarTab1, setCalendarTab1] = useGlobal('calendarTab1')
    const [refetchDateNow, setRefetchDateNow] = useGlobal("refetchDateNow")
    const [view, setView] = useGlobal('view')
    const [user] = useGlobal('user')
    const [tab] = useGlobal('tab')
    const [colors] = useGlobal('colors')
    const [focus, setFocus] = useGlobal('focus')
    const [monthText, dayText, weekText, formatMoth, formatDay, today, viewMode] = useIntlId(['appbar.month', 'appbar.day', 'appbar.week', 'appbar.formatAppbar.month',
        'appbar.formatAppbar.day', 'milestone.today', 'milestone.viewMode'])
    const [value, setValue] = useState(0);
    const [fontSize, setFontSize] = useGlobal('fontSize')
    const [monthValue, setMonthValue] = useState(tab === 2 ? calendarTab2 : tab === 1 ? calendarTab1 : '');
    const [numLines, setNumLines] = useState(numOfLine)
    const [size, setSize] = useState(fontSize)
    const [editWorkSpace, setEditWorkSpace] = useState(false)
    const [listProject] = useGlobal('projectList');
    const [allProject, setAllProject] = useGlobal('allMilestoneProject');

    const history = useHistory()
    const [back] = useIntlId([
        'dialog.action.back'
    ])



    // const onClick = (e) => {
    //     const {allMilestoneProject} = getGlobal()
    //     const newAllProject = filter(allMilestoneProject, p => p?.msSelected)
    //     if (newAllProject.length !== 0) {
    //         let uuid = 'mile_' + uuidv4()
    //         let timeStartDefault = moment().startOf('day').format()
    //         let timeEndDefault = moment().startOf('day').add(1, 'day').format()
    //         let event = {
    //             id: uuid,
    //             allDay: true,
    //             start: timeStartDefault,
    //             end: timeEndDefault,
    //             resource: {
    //                 id: newAllProject[0].id,
    //                 title: newAllProject[0].name,
    //                 extendedProps: newAllProject[0]
    //             },
    //             jsEvent: {
    //                 target: e.currentTarget
    //             },
    //             isCreateButton: true,
    //             numberId: 0
    //         }
    //         setCreateEvent(event)
    //     }
    // };

    const handChangeFocus = async (name, value) => {
        try {
            let newFocus = {...focus}
            newFocus[name] = value
            if (name === 'project') {
                newFocus.identity = !value
            } else {
                newFocus.project = !value
            }
            const userDocRef = db.collection(CALENDAR_COLLECTION).doc(user.user_id)
            await userDocRef.set({focus}, {merge: true})
            setFocus(newFocus)
            store.set('focus', newFocus)

        } catch (e) {
            setFocus(focus)
            console.log(e.toString())
        }
    }
    const handleChangeView = (event, newValue) => {
        if (newValue === value)
            return
        const isNewTab = event.metaKey || event.ctrlKey
        const today = moment().format('YYYY-MM-DD')
        if (newValue === 0) {
            store.set('view', 'CustomMonth')
            if (isNewTab) {
                window.open(window.location.href, "_blank")
                return;
            }
            setValue(newValue);
            setRefetchDateNow(today)
            setView('CustomMonth')
            store.set('view', 'CustomMonth')
        }
        if (newValue === 1) {
            store.set('view', 'CustomWeek')
            if (isNewTab) {
                window.open(window.location.href, "_blank")
                return;
            }
            setValue(newValue);
            setRefetchDateNow(today)
            setView('CustomWeek')
            store.set('view', 'CustomWeek')
        }
        if (newValue === 2) {
            store.set('view', 'CustomDay')
            if (isNewTab) {
                window.open(window.location.href, "_blank")
                return;
            }
            setValue(newValue);
            setRefetchDateNow(today)
            setView('CustomDay')
        }
    };

    useEffect(() => {
        if (view === 'CustomMonth')
            return setValue(0)
        if (view === 'CustomWeek')
            return setValue(1)
        if (view === 'CustomDay')
            return setValue(2)
    }, [view])

    const handleBlur = (type) => {
        let dataCheck = type && type === "add" ? Number(monthValue) + 1 : type && type === "remove" ? Number(monthValue) - 1 : Number(monthValue);

        if (dataCheck < 1 || dataCheck === '' || !dataCheck) {
            setMonthValue(1)
            if (tab === 2) {
                setCalendarTab2(1)
                store.set('calendarTab2', 1)
                removeCal(1)
                // getNumOfProject()
            } else if (tab === 1) {
                setCalendarTab1(1)
                store.set('calendarTab1', 1)
                removeCal(1)
            }
            return
        }
        if (tab === 2) {
            const Calnumber2 = dataCheck > 36 ? 36 : (dataCheck < 1 ? 0 : dataCheck)
            setMonthValue(Calnumber2)
            setCalendarTab2(Calnumber2)
            store.set('calendarTab2', Calnumber2)
            removeCal(Calnumber2)
            // getNumOfProject()
        } else if (tab === 1) {
            const Calnumber1 = dataCheck > 36 ? 36 : (dataCheck < 1 ? 0 : dataCheck)
            setMonthValue(Calnumber1)
            setCalendarTab1(Calnumber1)
            store.set('calendarTab1', Calnumber1)
            removeCal(Calnumber1)
        }
    }

    const removeCal = (number) => {
        for (let i = 35; i >= number; i--) {
            if (window['calendar' + i] && typeof window['calendar' + i].destroy === 'function') {
                window['calendar' + i].destroy()
                window['calendar' + i] = null
            }
        }
    }

    const onChangeMonth = (number) => {
        let newDate
        if (view === "CustomMonth") {
            newDate = moment(refetchDateNow).startOf("month").add(number, "month").format()
        } else {
            if (view === "CustomWeek") {
                let extended = window.location.pathname === '/' ? 1 : 2
                newDate = moment(refetchDateNow).startOf("isoWeek").add(number * extended, "isoWeek").format()
            } else
                newDate = moment(refetchDateNow).startOf("day").add(number, "day").format()
        }
        setRefetchDateNow(newDate)
        store.set('refetchDateNow', newDate)

    }

    const titleCalendar = (() => {
        let t = ''
        switch (view) {
            case 'CustomDay':
                t = moment(refetchDateNow).format(formatDay !== "appbar.formatAppbar.day" ? formatDay : "YYYY年MM月DD日")
                break;
            default:
                t = moment(refetchDateNow).format(formatMoth !== "appbar.formatAppbar.month" ? formatMoth : "YYYY年MM月")
        }
        return t
    })()

    const handleChangeFontSize = (type) => {
        let dataCheck = type && type === "add" ? Number(size) + 1 : type && type === "remove" ? Number(size) - 1 : Number(size);

        if (dataCheck < 5) {
            setSize(fontSize)
            return null
        }
        if (dataCheck > 15) {
            setSize(fontSize)
            return null
        }
        setSize(dataCheck)
        setFontSize(dataCheck)
        store.set('fontSize', dataCheck)
    }

    const handleNumOfLine = (type) => {
        if (!type)
            return

        const increment = type === "add" ? 1 : -1
        const dataCheck = Number(numLines) + increment

        if (dataCheck < 1) {
            setNumLines(numOfLine)
            return toast.warn("minimum 1 calendar display")
        }
        const nextHeight = (window.innerHeight - 140) / (dataCheck)
        if (nextHeight < 300 && type === "add") {
            setNumLines(numOfLine)
            return toast.error("height too small")
        }
        setNumLines(dataCheck)
        setCalendarHeight(nextHeight)
        setNumOfLine(dataCheck)
        store.set('numOfLine', dataCheck)
    }

    function onToDay() {
        if (!moment().startOf('month').isSame(refetchDateNow)) {
            setRefetchDateNow(moment().format('YYYY-MM-DD'))
        }
    }

    const onChangeInput = (type, event) => {
        if (type === "degree") {
            onChangeMonth(-1)
        } else {
            onChangeMonth(1)
        }
    }

    const onClose = () => {
        setEditWorkSpace(false)
    }

    useEffect(() => {
        if (!listProject?.length || !allProject?.length) return;

        const allProjectClone = cloneDeep(allProject)
        allProject.forEach((val) => {
            val.msSelected = false;
        })

        for (let i = 0; i < listProject.length; i++) {
            let idx = findIndex(allProject, {id: listProject[i].id})
            if (idx !== -1) {
                allProject[idx].msSelected = true
            }
        }

        if (isEqual(allProjectClone, allProject)) return;

        setAllProject([...allProject])
        // eslint-disable-next-line
    }, [listProject, allProject])

    return (
        <div className={classes.headerCenter}>
            {/*<Tooltip*/}
            {/*    title={creatEvent}*/}
            {/*    placement={"bottomRight"}*/}
            {/*>*/}
            {/*    <div onClick={onClick}>*/}
            {/*        <IconButton*/}
            {/*            className={classes.buttonCreate}*/}
            {/*        >*/}
            {/*            <AddIcon/>*/}
            {/*        </IconButton>*/}
            {/*    </div>*/}
            {/*</Tooltip>*/}
            <div className={window.location.pathname !== '/search' && classes.headerLeft}>
                {!expired && window.location.pathname !== '/search' &&
                    <NavApp numOfLine={numOfLine}
                            setNumOfLine={setNumOfLine}
                            setCalendarHeight={setCalendarHeight}/>
                }
                {
                    window.location.pathname === '/search' &&
                    <div>
                        <CLFButtonSVG
                            name={back}
                            size={"default"}
                            iconComponent={<KeyboardBackspaceRoundedIcon/>}
                            onClick={() => history.push('/')}
                            // loading={true}
                            minWidth={"unset"}
                        />
                    </div>
                }
            </div>

            <InputNumber
                type={'text'}
                label={titleCalendar}
                size={40}
                onChange={onChangeInput}
            />
            {
                (openSearch && window.location.pathname !== '/search') ? ""
                    :
                    <div className={`clf-flex-center ${classes.marginTop}`}>
                        <MenuItemContainer classMarginBottom={classes.mgBottom10}>
                            <TabsCustom value={value}
                                        onChange={handleChangeView}
                            >
                                <Tab label={monthText}/>
                                <Tab label={weekText}/>
                                <Tab label={dayText}/>
                            </TabsCustom>
                        </MenuItemContainer>

                        <div className={`clf-flex-center fw700 px14 ${classes.mgBottom10}`}>
                            <CLFButtonSVG
                                name={today}
                                size={'large'}
                                iconComponent={<TodaySVG/>}
                                onClick={onToDay}
                                minWidth={'130px'}
                                minHeight={'40px'}
                                margin={"0 5px"}
                                background={colors?.primary}
                                borderColor={colors?.primary}
                                className={classes.buttonAntd}
                            />
                        </div>
                        <WorkSpaceButtons
                            viewMode={viewMode}
                            editWorkSpace={editWorkSpace}
                            setEditWorkSpace={setEditWorkSpace}
                            colors={colors}
                        />

                        {
                            editWorkSpace &&
                            <Views
                                monthValue={monthValue}
                                handleBlur={handleBlur}
                                setMonthValue={setMonthValue}
                                numLines={numLines}
                                setNumLines={setNumLines}
                                handleNumOfLine={handleNumOfLine}
                                size={size}
                                setSize={setSize}
                                focus={focus}
                                handChangeFocus={handChangeFocus}
                                handleChangeFontSize={handleChangeFontSize}
                                editWorkSpace={editWorkSpace}
                                onClose={onClose}
                            />
                        }
                    </div>
            }
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    iconSvg: {
        marginRight: 5
    },
    buttonAntd: {
        textTransform: 'uppercase',
        fontWeight: 700,
        "& svg": {
            marginLeft: 5
        }
    },
    navButton: {
        float: 'left',
        color: '#000',
        margin: "0 20px",
        fontSize: '1.6rem',
        // padding: 5,
        lineHeight: '26px',
        fontWeight: "bold",
        [theme.breakpoints.down('md')]: {
            color: "#000"
        },
    },
    paper: {
        // ...theme.mixins.gutters(),
        width: 'fit-content',
        float: 'left',
        backgroundColor: '#fff !important',
        padding: "0px !important",
        // [theme.breakpoints.down('md')]: {
        //     backgroundColor: '#fff !important',
        // },

    },
    headerCenter: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        // marginRight: -40,
        fontFamily: "Montserrat, sans-serif !important",
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
            flexWrap: "wrap",
            padding: "0 10px",
            justifyContent: 'center'
        }
    },
    titleCalendar: {
        margin: "0 5px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
            color: "#000",
            marginBottom: 5,
        }
    },
    handleView: {
        alignItems: "center",
        // border: "solid 1px #fff",
        boxSizing: "border-box",
        // padding: "0 5px",
        "&.MuiTabs-root": {
            minHeight: "unset !important",
        },
        "& .MuiTab-root": {
            color: "#000",
            minWidth: "auto",
            padding: "5px 15px",
            minHeight: "auto",
            fontWeight: 400,
            fontSize: "1.2rem",
            borderRadius: "20px"
        },
        "& .Mui-selected": {
            background: theme.props.primaryColor,
            color: "#fff",
            fontWeight: "bold",
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "center",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: 'initial'
        },
        // [theme.breakpoints.down('md')]: {
        //     // border: "solid 1px #000",
        //     marginBottom: 5,
        //     "& .MuiTab-root": {
        //         color: "#000",
        //     },
        //     // "& .Mui-selected": {
        //     //     color: "#ff0000",
        //     // },
        // }
    },
    button: {
        minWidth: "auto",
        width: 30,
        height: 30,
        background: "#ebebeb"
    },
    numCalendar: {
        display: 'flex',
        alignItems: "center",
        border: "solid 1px #fff",
        margin: "0 5px",
        boxSizing: "border-box",
        [theme.breakpoints.down('md')]: {
            padding: "9px 0",
            border: "solid 1px #000",
            marginBottom: 5,
        },

    },
    numOfLine: {
        display: 'flex',
        alignItems: "center",
        border: "solid 1px #fff",
        boxSizing: "border-box",
        fontSize: "1.3rem",
        fontWeight: "bold",
        "&>span": {
            margin: "0 5px",
            display: "block",
            color: "#fff"
        },
        [theme.breakpoints.down('md')]: {
            border: "solid 1px #000",
            justifyContent: "center",
            marginBottom: 5,
            padding: "9px 0",
            "&>span": {
                fontSize: 11,
                color: "#000"
            }
        },
    },
    changeFontSize: {
        display: 'flex',
        alignItems: "center",
        border: "solid 1px #fff",
        boxSizing: "border-box",
        marginLeft: 5,
        fontSize: "1.3rem",
        fontWeight: "bold",
        "&>span": {
            margin: "0 5px",
            display: "block",
            color: "#fff"
        },
        [theme.breakpoints.down('md')]: {
            border: "solid 1px #000",
            justifyContent: "center",
            marginBottom: 5,
            padding: "9px 0",
            "&>span": {
                fontSize: 11,
                color: "#000"
            }
        },
    },
    calendarDay: {
        minWidth: 120,
        textAlign: "center",
        padding: 0,
        fontFamily: "Montserrat, sans-serif !important"
    },
    iconArrow: {
        background: theme.props.primaryColor,
        color: "#fff",
        padding: 0,
        "& .MuiSvgIcon-root": {
            fontSize: 35
        },
        [theme.breakpoints.down('md')]: {
            color: "#000"
        }
    },
    textField: {
        backgroundColor: '#dddddd',
        borderRadius: 4,
        width: 40,
        height: 30,
        float: 'left',
        marginTop: 8,
        "& .MuiOutlinedInput-input": {
            padding: 0
        },
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 10,
            minWidth: 20,
            margin: 0,
            height: 27,
        },
    },
    segment: {
        backgroundColor: 'white',
        width: "80px !important",
        // color: "#42a5f5",
        // borderRadius: 6,
        margin: '0 !important',
        "& label": {
            height: "27px !important",
            lineHeight: "27px !important",
            padding: "0 !important"
        }
    },
    segmentContainer: {
        margin: "0 0 0 5px"
    },
    router: {
        backgroundColor: "#f1f1f1",
        // minWidth: 250,
        padding: 8,
        paddingLeft: 0,
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            // minWidth: 210,
            // background: "#fff"
        },
        display: "flex",
    },
    tabBtn: {
        height: 40
    },
    router2: {
        backgroundColor: "#f1f1f1",
        // minWidth: 250,
        padding: 8,
        paddingLeft: 0,
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            // minWidth: 210,
            // background: "#fff"
        },
        display: "flex",
    },
    mgBottom10: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px !important'
        }
    },
    marginTop: {
        [theme.breakpoints.down('md')]: {
            marginTop: '10px !important'
        }
    },
    // buttonCreate: {
    //     borderRadius: "50% !important",
    //     width: "37px !important",
    //     height: "37px !important",
    //     zIndex: 999,
    //     marginRight: 5,
    //     marginBottom: 1,
    //     background: `${theme.props.primaryColor} !important`,
    //     borderColor: `${theme.props.primaryColor} !important`,
    //     // boxShadow: '0px 0px 0px 2px rgba(0, 0, 0, 0.2)',
    //     "& svg": {
    //         width: "35px !important",
    //         height: "35px !important",
    //         fill: "#fff"
    //     }
    // },

}))

export default HeaderCenter;

