import React from 'react';
import {Typography} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";

function Creator({event, user}) {

    const classes = useStyles()
    if (!event) return null
    try {
        if (event && event.extendedProps) {

            return (
                <div className={classes.root}>
                    <Typography className={classes.emailText}>
                        {` Created By :
                        ${
                            event.extendedProps.googleEvent ?
                                event.extendedProps.creator.email
                                :
                                user.email
                        }`}
                    </Typography>
                </div>
            );
        } else {
            return null
        }
    } catch (e) {
        return null
    }

}

const useStyles = makeStyles(theme => ({
    root: {
        // width: '90%',
        marginBottom: 10,
    },
    emailText: {
        color: '#1890ff'
    }
}))
export default Creator;
