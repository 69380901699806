import React from 'react';
import Popover from "@material-ui/core/Popover";
import {Button, Col, Row} from 'antd';
import {makeStyles} from "@material-ui/core/styles";
import TimeFromToCustom from "../Custom/TimeFromToCustom";
import {useGlobal} from "reactn";
import {ReactComponent as CheckDaySVG} from "../../asSvg/icons/checkDay.svg";
import {ReactComponent as UnCheckDaySVG} from "../../asSvg/icons/unCheckDay.svg";
import useIntlId from "../../hooks/useIntlId";

function SettingSearch({
                           settingSearch, setSettingSearch,
                           startDateSearch, endDateSearch,
                           closeSettingSearch, onOpenChangeDate,
                           checkTimeSearch, setCheckTimeSearch
                       }) {
    const classes = useStyles()
    const [searchAllTimes, searchAboutTime, searchScope] = useIntlId(['search.AllTimes', 'search.aboutTime', "search.scope"])
    const [colors] = useGlobal('colors')
    const handleCheckAll = () => {
        setCheckTimeSearch(true)
    }
    const handleCheckAbout = () => {
        setCheckTimeSearch(false)
    }
    return (
        <Popover
            open={Boolean(settingSearch)}
            anchorEl={settingSearch}
            onClose={closeSettingSearch}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div className={classes.root}>
                <Row gutter={16}>
                    <Col className={classes.colCenter} span={8}>
                        <div className={classes.weight}>{searchScope}</div>
                    </Col>
                    <Col span={16}>
                        <div>
                            <Button
                                icon={
                                    checkTimeSearch ?
                                        <CheckDaySVG className={classes.icon}/>
                                        :
                                        <UnCheckDaySVG className={classes.icon}/>
                                }
                                className={classes.button}
                                onClick={handleCheckAll}
                                block
                            >{searchAllTimes}</Button>
                            <Button
                                icon={
                                    !checkTimeSearch ?
                                        <CheckDaySVG className={classes.icon}/>
                                        :
                                        <UnCheckDaySVG className={classes.icon}/>
                                }
                                className={classes.button}
                                onClick={handleCheckAbout}
                                block
                            >{searchAboutTime}</Button>
                            {!checkTimeSearch &&
                                <div className={checkTimeSearch && classes.unEvent}>
                                    <TimeFromToCustom
                                        timeStart={startDateSearch}
                                        timeEnd={endDateSearch}
                                        colorIcon={colors?.primary}
                                        onClick={onOpenChangeDate}
                                    />
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </Popover>
    );
}

export default SettingSearch;
const useStyles = makeStyles(theme => ({
    root: {
        padding: 10,
        minWidth: 400
    },
    button: {
        display: "flex !important",
        alignItems: "center",
        border: "none !important",
        margin: "auto",
        fontWeight: "bold !important",
        color: "#000 !important",
        borderRadius: "25px",
        fontSize: "12px !important",
        paddingLeft: 0,
        "&:focus": {
            color: "#000 !important",
            border: "none"
        },
        "&:hover": {
            color: "#000",
            border: "none"
        },
        boxShadow: 'none !important'
    },
    icon: {
        marginRight: 5,
        fill: theme.props.primaryColor
    },
    unEvent: {
        opacity: 0.4,
        cursor: "not-allowed",
    },
    weight: {
        fontWeight: "bold",
    },
    colCenter: {
        display: 'flex',
        alignItems: 'center'
    }
}))
