import {snapshot} from "valtio";

export const setProxyData = (proxy, obj) => {
    const snap = snapshot(proxy)
    if (typeof obj !== 'object' || typeof snap !== 'object') return;
    for (const key in snap) {
        if (!obj[key]) {
            delete proxy[key]
        }
    }
    for (const key in obj) {
        proxy[key] = obj[key]
    }
}
