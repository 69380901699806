import {getGlobal, setGlobal} from 'reactn'
import {getDateRange} from "../CalendarEventsContext";
import {ggTokenChecked} from "../../components/Calendar/google/auth";
import {cloneDeep, concat, findIndex, remove} from 'lodash'
import {GCalendarEventFormat} from "../../actions/googleCalendar";
import {getEventsFromReccrence} from "./getGoogleEvents";
import {ggEvs} from "../../store/allEvents";

export const getEventsSync = async (id) => {
    if (!id)
        return null;

    const {
        ggEvents,
        refetchDateNow,
        tab,
        calendarTab1,
        calendarTab2,
        googleCalendarList,
        nextSyncTokens,
        projectSortSetting
    } = getGlobal()
    let idx = findIndex(googleCalendarList, {id})
    if (idx === -1)
        return null
    const checked = await ggTokenChecked()
    if (!checked)
        return null
    let numOfCal = tab === 1 ? calendarTab1 : calendarTab2

    if (projectSortSetting.isSort) {
        numOfCal = projectSortSetting?.timeView || 6
    }

    // console.log(numOfCal);

    const {start, end} = getDateRange(refetchDateNow, numOfCal)
    // console.log(start);
    // console.log(end);
    // if (!nextSyncTokens[id]) {
    // create new
    const request = window.gapi.client.calendar.events.list({
        'calendarId': id,
        // "singleEvents": true,
        "timeMin": start,
        "timeMax": end,
        "maxResults": 2500,
        // "alwaysIncludeEmail":true
    })
    const {result, status} = await request.then()
    if (status !== 200)
        return null
    const {items, nextSyncToken} = result
    if (!items.length)
        return null;
    const recurrenceEvents = remove(items, e => e.recurrence)
    const recurenceEdited = remove(items, e => e.recurringEventId)
    const recurring = getEventsFromReccrence(recurrenceEvents, recurenceEdited, end)
    let formatedItems = GCalendarEventFormat(concat(items, recurring), id, googleCalendarList[idx].backgroundColor, false)
    let _ggEvents = cloneDeep(ggEvents)
    ggEvs[id] = {
        id,
        events: formatedItems,
        nextSyncToken,
        name: googleCalendarList[idx].summary,
        color: googleCalendarList[idx].backgroundColor,
    }
    _ggEvents[id] = {
        id,
        events: formatedItems,
        nextSyncToken,
        name: googleCalendarList[idx].summary,
        color: googleCalendarList[idx].backgroundColor,
    }
    nextSyncTokens[id] = nextSyncToken
    setGlobal({
        ggEvents: _ggEvents,
        nextSyncTokens
    }, () => {
        //refetchSource("google")
    })
    return null
}
//
// const isUpdatedEvents = (events, start, end) => {
//     let result = []
//     events.forEach(event => {
//         if (moment(event.start).isBetween(start, end, null, "[]") ||
//             moment(event.end).isBetween(start, end, null, "[]") ||
//             (moment(event.start).isBefore(start) && moment(event.end).isAfter(end))
//         ) {
//             result.push(event)
//         }
//     })
//     return result
// }
