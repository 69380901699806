import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useAnnouncement} from '../../../context/AnnouncementContext';
import {Input, Switch} from 'antd';
import useIntlId from '../../../hooks/useIntlId';
import {getGlobal, useGlobal} from 'reactn';
import {barRef} from '../../../common/firebaseRef/annoucement';
import TargetHighLight from './TargetHighLight';
import PreviewHighLight from "../../PreviewHighLight/PreviewHighLight";
import {db} from "../../../firebaseConfig";

function HighLight({updateHighLight}) {
    const classes = useStyles()
    const {bar, lock} = useAnnouncement()
    const [textHighLight, setTextHighLight] = useState('')
    const [editHighLight, setEditHighLight] = useState(false)
    const [
        highlightIntl,
        highlightTextIntl,
        titleTarget,
        todayTargetIntl,
        weekTargetIntl,
        monthTargetIntl,
        textToday,
        textWeek,
        textMonth,
    ]
        = useIntlId(
        [
            'settings.announcement.highlight',
            'settings.announcement.highlightText',
            'settings.announcement.target',
            'settings.announcement.targetToday',
            'settings.announcement.targetThisweek',
            'settings.announcement.targetThismonth',
            'settings.announcement.targetTodayText',
            'settings.announcement.targetThisweekText',
            'settings.announcement.targetThismonthText'
        ])
    const [checkHighLight, setCheckHighLight] = useState(false)
    const [checkTodayHighLight, setCheckTodayHighLight] = useState(false)
    const [checkWeekTarget, setCheckWeekTarget] = useState(false)
    const [checkMonthTarget, setCheckMonthTarget] = useState(false)
    const [focusHighLight] = useGlobal('focusHighLight')
    const {user} = getGlobal()
    useEffect(() => {
        if (typeof bar?.highlight !== "string") {
            setTextHighLight('')
            setCheckHighLight(false)
        } else {
            setTextHighLight(bar.highlight)
            setCheckHighLight(bar?.isShowHighLight)
        }
        setCheckTodayHighLight(bar?.isShowTodayHighLight)
        setCheckWeekTarget(bar?.isShowWeekTarget)
        setCheckMonthTarget(bar?.isShowMonthTarget)
    }, [bar?.highlight, bar?.isShowHighLight, bar?.isShowTodayHighLight, bar?.isShowWeekTarget, bar?.isShowMonthTarget])

    const handleUpdateHighLight = () => {
        if (editHighLight) {
            updateHighLight(textHighLight || '')
            setEditHighLight(false)
        }
    }

    const handleCheckedHighLight = async (fieldName, value) => {
        switch (fieldName) {
            case 'isShowHighLight':
                setCheckHighLight(value)
                break;
            case 'isShowTodayHighLight':
                setCheckTodayHighLight(value)
                break;
            case 'isShowWeekTarget':
                setCheckWeekTarget(value)
                break;
            case 'isShowMonthTarget':
                setCheckMonthTarget(value)
                break;

            default:
                break;
        }
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                [fieldName]: value
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.flexBox}>
                <div className={classes.title}>{highlightIntl}</div>
            </div>
            <div
                className={(editHighLight || focusHighLight?.target === 'highLight') ? classes.itemFocus : classes.item}>
                <div className={classes.listText}>
                    <div className={classes.content}>
                        {
                            (editHighLight || focusHighLight?.target === 'highLight') ?
                                <div className={`${classes.wrapHighLight}`}>
                                    <Input
                                        onPressEnter={handleUpdateHighLight}
                                        onBlur={handleUpdateHighLight}
                                        disabled={lock}
                                        className={classes.input} autoFocus={true} value={textHighLight}
                                        onChange={(e) => setTextHighLight(e.target.value)}/>
                                    <Switch
                                        className={classes.iconChecked}
                                        checked={checkHighLight}
                                        disabled={lock}
                                        onChange={() => handleCheckedHighLight('isShowHighLight', !checkHighLight)}
                                    />
                                </div>
                                :
                                <div className={`${classes.wrapHighLight}`}>
                                    <div onClick={() => {
                                        if (lock)
                                            return;
                                        setEditHighLight(!editHighLight)
                                    }}
                                         className={classes.textHighLight}>{textHighLight || highlightTextIntl}
                                    </div>
                                    <Switch
                                        className={classes.iconChecked}
                                        checked={checkHighLight}
                                        disabled={lock}
                                        onChange={() => handleCheckedHighLight('isShowHighLight', !checkHighLight)}
                                    />
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className={classes.flexBox}>
                <div className={classes.title}>{titleTarget}</div>
            </div>
            <div className={classes.wrapTargetHighLight}>
                <div className={classes.titleTarget}>{todayTargetIntl}</div>
                <TargetHighLight
                    fieldName={'Today'}
                    handleCheckedHighLight={handleCheckedHighLight}
                    showTime={'CustomDay'}
                    checked={checkTodayHighLight}
                    setchecked={setCheckTodayHighLight}
                    checkName={'isShowTodayHighLight'}
                    Intl={textToday}
                    fieldFocusHighLight={'dayTarget'}
                />
                <div className={classes.titleTarget}>{weekTargetIntl}</div>
                <TargetHighLight
                    fieldName={'Week'}
                    handleCheckedHighLight={handleCheckedHighLight}
                    showTime={'CustomWeek'}
                    checked={checkWeekTarget}
                    setchecked={setCheckWeekTarget}
                    checkName={'isShowWeekTarget'}
                    Intl={textWeek}
                    fieldFocusHighLight={'weeksTarget'}
                />
                <div className={classes.titleTarget}>{monthTargetIntl}</div>
                <TargetHighLight
                    fieldName={'Month'}
                    handleCheckedHighLight={handleCheckedHighLight}
                    showTime={'CustomMonth'}
                    checked={checkMonthTarget}
                    setchecked={setCheckMonthTarget}
                    checkName={'isShowMonthTarget'}
                    Intl={textMonth}
                    fieldFocusHighLight={'monthTarget'}
                />
                <PreviewHighLight/>

                {/*<div className={classes.simulator}>*/}
                {/*    <RecommendBar fullSize={true}/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default HighLight;

const useStyles = makeStyles(theme => ({
    root: {
        padding: "15px 0",
    },
    flexBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    title: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444"
    },
    titleTarget: {
        fontWeight: 600,
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.props.primaryColor
    },
    item: {
        backgroundColor: "#fff",
        borderRadius: "13px",
        marginBottom: 7,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    itemFocus: {
        backgroundColor: theme.props.primaryColor,
        borderRadius: "13px",
        marginBottom: 7,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    number: {
        fontWeight: 700,
        fontSize: 14,
        textTransform: "uppercase",
        color: "#444444",
        width: 40,
        textAlign: "center"
    },
    listText: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
    },
    textHighLight: {
        cursor: "pointer",
        paddingLeft: 10,
        width: "100%"
    },
    simulator: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    icon: {
        width: 40,
        "& svg": {
            fill: theme.props.primaryColor
        }
    },
    flex1: {
        flex: 1,
        display: "flex"
    },
    content: {
        backgroundColor: "#e5e5e5",
        borderRadius: "10px",
        fontSize: 12,
        color: "#444444",
        margin: 2,
        width: "100%",
        padding: "8px 45px 8px 5px",
        "& .ant-input:focus, .ant-input-focused": {
            border: "none",
            boxShadow: "none"
        }
    },
    input: {
        border: "none",
        backgroundColor: "transparent",
        padding: "0 0 0 10px",
        outline: "none",
        fontSize: 12,
        color: "#444444",
    },
    wrapHighLight: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        "& .ant-switch-checked": {
            backgroundColor: theme.props.primaryColor,
        }
    },
    iconChecked: {
        // width: 28,
        // height: 28,
        position: "absolute",
        right: "-40px",
        display: "flex",
        alignItems: "center",
        // borderRadius: "50%",
        // border: `2px solid ${theme.props.primaryColor}`,
        // background: "#fff",
        // cursor: "pointer",
    },
    highLightIcon: {
        fill: theme.props.primaryColor,
        cursor: "pointer",
    },
    boxSimulator: {
        borderRadius: 10,
        padding: 8,
        color: "#1790ff",
        fontWeight: 600,
        fontSize: 16,
        border: "2px solid #1790ff",
    },
    simulatorHighLight: {
        marginRight: 10
    },
    showTime: {
        background: "#fff",
        color: "#1790ff",
        padding: "3px 10px",
        borderRadius: 9,
        fontWeight: 600,
        fontSize: 14,
        minWidth: 110,
        textAlign: "center"
    },
    wrapTargetHighLight: {
        position: "relative",
        paddingLeft: 30,
    }
}))
