import React from 'react';
import {Modal} from "antd";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {makeStyles} from "@material-ui/core/styles";
import useIntlId from "../../../hooks/useIntlId";

const useStyles = makeStyles(theme => ({
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
}))


function DialogDeleteZoomMeeting(props) {
    const {open, setOpen, onDelete, loading} = props;
    const classes = useStyles();
    const [cancel, save] = useIntlId([
        'settings.archiveDialogCancel',
        'dialog.action.done',
    ])

    const onClose = () => {
        setOpen(null)
    };

    const onDone = () => {
        onClose();
        if (onDelete)
            onDelete()
    };

    return (
        <Modal
            visible={Boolean(open)}
            title="Remove Zoom"
            onCancel={onClose}
            zIndex={9000}
            footer={[
                <div className={'clf-flex-center'}>
                    <CLFButtonSVG
                        onClick={onClose}
                        name={cancel}
                        margin={'0px 20px 20px 0px'}
                        className={classes.secondary}
                    />
                    <CLFButtonSVG
                        onClick={onDone}
                        name={save}
                        loading={loading}
                        disable={loading}
                        margin={'0px 20px 20px 0px'}
                        className={classes.primary}
                    />

                </div>
            ]}
        >
            Do you want to remove it?
        </Modal>
    );
}

export default DialogDeleteZoomMeeting;
