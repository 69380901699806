import {auth, db} from "../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../config/constants";
import {getGlobal} from 'reactn'
import {message} from "antd";
import {get} from "lodash";

export const getUser = (prop) => {
    const u = getGlobal().user || auth.currentUser?.toJSON()
    if (!u) {
        message.error('no user. login again!')
        return;
    }
    return prop ? u[prop] : u;
}

// const getUserId = () => getUser('user_id')

export const myEvents = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.collection(`${CALENDAR_COLLECTION}/${user_id}/events`)
}

export const myEvent = (docId) => {
    return myEvent().doc(docId)
}

export const myInfo = getUser;

export const myUserCalendarDocRef = () => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${CALENDAR_COLLECTION}/${user_id}`)
}

export const myUserCalendarCollRef = (docId) => {
    return myUserCalendarDocRef().collection('projects').doc(docId)
}

export const myUserCalendarEventDocRef = (docId) => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${CALENDAR_COLLECTION}/${user_id}/events/${docId}`)
}

export const myUserCalendarProjectDocRef = (docId) => {
    const user_id = get(getGlobal(), 'user.user_id')
    return db.doc(`${CALENDAR_COLLECTION}/${user_id}/projects/${docId}`)
}

