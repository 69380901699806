import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useGlobal} from 'reactn';

const useStyles = makeStyles(theme => ({
    itemP: {
        width: 100,
        height: 35,
        borderRadius: 7,
        marginRight: 5,
        marginBottom: 5,
        color: '#fff',
        display: "flex",
        alignItems: "center"
    },

    divContent: {
        maxWidth: 210,
        display: 'flex',
        flexWrap: "wrap",
        maxHeight: 200,
        overflow: "auto",
    },

    text: {
        paddingLeft: 5,
        textOverflow: 'ellipsis',
        overflow: "hidden",
        whiteSpace: "nowrap",
    }
}))

const WorkSpaceProjectSettingList = ({projects}) => {
    const classes = useStyles()
    const [googleStatus] = useGlobal('googleStatus');

    const [projectList, setProjectList] = useState(projects)

    useEffect(() => {
        let projects = []
        if (!googleStatus.is_login) {
            for (let i = 0; i < projectList.length; i++) {
                if (projectList[i].is_google) {
                    continue;
                }
                projects.push(projectList[i])
            }
        } else {
            projects = projectList.map(p => ({...p, id: p.uuid, title: p.name || p.summary}))
        }
        setProjectList(projects)
        // eslint-disable-next-line
    }, [googleStatus.is_login])

    return (
        <div className={classes.divContent}>
            {
                projectList.map((pro) => {
                    return (
                        <div
                            id={pro.id}
                            className={classes.itemP} style={{backgroundColor: pro.color || pro.backgroundColor}}>
                            <div className={classes.text}>{pro.name || pro.summary}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default WorkSpaceProjectSettingList
