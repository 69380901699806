import axios from "axios";
import {CREATE_GOOGLE_TASKS, DELETE_GOOGLE_TASKS} from "../config/constants";
import {updateGoogleEvent} from "./googleCalendar";
import moment from "moment";
import {identity, pickBy} from "lodash";

export const createGoogleTasksSchedule = async (event, users) => {
    try {
        const {data} = await axios.post(CREATE_GOOGLE_TASKS, {
            payload: {
                ...event,
                start: moment(event.start).format(),
                end: moment(event.end).format(),
                users
            }
        });
        if (data)
            return data.substring(data.lastIndexOf('/') + 1);
        else
            return null
    } catch (e) {
        console.log(e);
        return null
    }
};

export const createGoogleTasksScheduleGoogleCalendar = async (event, user) => {
    try {
        const {data} = await axios.post(CREATE_GOOGLE_TASKS, {
            payload: {
                ...event, user: {
                    user_id: user.user_id,
                    email: user.email,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    time_zone: user.time_zone,
                }
            }
        });
        if (data) {
            // update GoogleTasksID To Event
            event.googleTasksID = data.substring(data.lastIndexOf('/') + 1);
            updateGoogleEvent(event, event.id).catch(e => console.log(e))
        }
    } catch (e) {
        console.log(e)
    }
}

export const handleUpdateGoogleTasksSchedule = (event, user) => {
    let eventUpdate = event;
    axios.post(DELETE_GOOGLE_TASKS, {
        payload: eventUpdate.googleTasksID
    }).then(result => {
        createGoogleTasksSchedule(pickBy(eventUpdate, identity), user)
    }).catch(e => {
        // if (event.googleEvent) {
        //     createGoogleTasksScheduleGoogleCalendar(pickBy(eventUpdate, identity), user)
        // } else {
        //     createGoogleTasksSchedule(pickBy(eventUpdate, identity), user)
        // }
        console.log(e)
    })
};

export const handleDeleteGoogleTasksSchedule = async (googleTasksID) => {
    try {
        await axios.post(DELETE_GOOGLE_TASKS, {
            payload: googleTasksID
        })
    } catch (e) {
        console.log(e);
    }
}

export const checkTimeTitleEvent = (event, oldEvent) => {
    return (event.start !== oldEvent.start || event.end !== oldEvent.end || event.title !== oldEvent.title)
}
