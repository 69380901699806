import {Button, CircularProgress, Grid, IconButton} from '@material-ui/core'
import {Settings} from '@material-ui/icons'
import React, {Suspense} from "react"
import {setGlobal, useGlobal} from 'reactn'
import {CalendarIcon, GoogleProject, GroupCalendar} from "../splitting"
import {makeStyles} from "@material-ui/core/styles";
import ClfLinearProgress from "../Custom/CLFLinearProgress";
import {findIndex} from "lodash";
import {milestoneSettingDocRef} from "../../actions/group";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '5px 10px 5px 0'
    },
    divSetting: {
        width: "96%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0 10px 0 10px",
        background: "#f9f9f9",
        //borderRadius: "0 10px 0px 0",
        padding: "10px 10px 0 10px"
    },
    divGrid: {
        width: '100%'
    },
    UserEmail: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: "bold"
    },
    iconSetting: {
        padding: 10
    },
    name: {
        maxWidth: "calc(100% - 38px)",
        fontWeight: "bold"

    }
}))

function LeftHomeApp() {
    const classes = useStyles()
    const [googleStatus,] = useGlobal('googleStatus')
    const [loading] = useGlobal('loading')
    const [mySetting] = useGlobal('mySetting')
    const [projectList] = useGlobal('projectList');
    // const [user] = useGlobal('user');

    const onOpenSetting = React.useCallback(() => {
        setGlobal({
            openSetting: true,
            settingTab: 1
        })
    }, [])

    const updateProject = async (source) => {
        let newProjectList = [...projectList];
        const idx = findIndex(newProjectList, {id: source.id})
        if (idx > -1) {
            newProjectList.splice(idx, 1)
        } else {
            newProjectList.push(source)
        }

        try {
            await milestoneSettingDocRef(`${mySetting.id}`)
                .set({listProjectSetting: newProjectList}, {merge: true})
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Grid className={classes.root}>
            <Suspense fallback={<ClfLinearProgress/>}>
                <CalendarIcon/>
                <GroupCalendar updateProject={updateProject}/>
                {
                    !googleStatus.is_login &&
                    <Button onClick={onOpenSetting}>
                        Google Sync Setting
                    </Button>
                }

                {
                    googleStatus.is_login &&
                    <div className={classes.divSetting}>
                        <div className={classes.name}>
                            <div>{googleStatus.userProfile.name}</div>
                            <div
                                className={classes.UserEmail}>({googleStatus.userProfile.email})
                            </div>
                            {loading && <CircularProgress color={"primary"} size={20}/>}
                        </div>
                        <IconButton className={classes.iconSetting} disableRipple={true} onClick={onOpenSetting}>
                            <Settings/>
                        </IconButton>
                    </div>
                }
                {
                    googleStatus.is_login &&
                    <GoogleProject updateProject={updateProject}/>
                }
            </Suspense>
        </Grid>
    )
}

export default React.memo(LeftHomeApp)
