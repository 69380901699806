import {db} from '../../firebaseConfig'
import {CALENDAR_COLLECTION} from "../../config/constants";
import {getGlobal} from 'reactn'

export const getGeniamEvents = (callback) => {
    const {user} = getGlobal()
    if (!user.user_id)
        return null
    return db.collection(`${CALENDAR_COLLECTION}/${user.user_id}/events`)
        .where("isDeleted", "==", false)
        .where("isTasks", "==", false)
        // .where('start', ">=", start)
        // .where('start', "<=", end)
        .onSnapshot((snapshot) => {
            if (typeof callback === "function")
                callback(snapshot)
        })
}
