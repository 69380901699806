import React from 'react';
import {InputNumber} from 'antd';
import styled from "styled-components";
import {ReactComponent as ArrowDownBlueSVG} from "../../asSvg/icons/arrowDownBlue.svg";
import {useGlobal} from "reactn";

const Inputs = styled.div`
    display: flex;
    width: ${props => props.width ? `${props.width}px` : "100px"};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    height: ${props => props.size ? `${props.size}px` : "25px"};
    padding: 0 3px;
    font-weight: bold;
    border-radius: 24px;
    font-size: 12px;
    transform: ${props => props.isTransform ? "translateX(-38%) translateY(-20%) rotate(90deg)" : 'unset'};
`

const InputCustom = styled(InputNumber)`
    width: calc(100% - 50px);

    & .ant-input-number-handler-wrap {
        display: none;
    }
;

    & input {
        font-weight: bold;
        text-align: center;
        font-size: 12px;
        padding: 0;
        background: #fff;
        height: 22px;
    }

    & .ant-input-number-disabled {
        background-color: #000;
    }

    & .ant-input-number-input-wrap {
        background-color: #fff;
    }
`

const InputCustomTransform = styled(InputNumber)`
    & .ant-input-number-handler-wrap {
        display: none;
    }

    & input {
        font-weight: bold;
        text-align: center;
        font-size: 12px;
        padding: 0;
        background: #fff;
        height: 22px;
    }

    & .ant-input-number-disabled {
        background-color: #000;
    }

    & .ant-input-number-input-wrap {
        background-color: #fff;
    }
`

const ButtonLeft = styled.button(props => ({
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    width: props.size - 2,
    height: props.size - 2,
    cursor: "pointer",
    transform: "rotate(90deg)",
}))

const ButtonRight = styled.button(props => ({
    border: 'none',
    background: 'none',
    padding: 0,
    margin: 0,
    width: props.size - 2,
    height: props.size - 2,
    cursor: "pointer",
    transform: "rotate(-90deg)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
const StyledArrowDownBlueSVG = styled(ArrowDownBlueSVG)`
    fill: ${props => props.colors};
    width: 100%;
    height: 100%;
`;

function AntdInput({value, steps, onChange, min, label = '', disabled, width = 100, size, isTransform = false}) {
    const [colors] = useGlobal('colors')
    const onClickLeft = () => {
        if ((value - steps) >= min && !disabled)
            onChange(value - steps)
    }

    const onClickRight = () => {
        if (!disabled)
            onChange(value + steps)
    }

    return (
        <Inputs width={width} size={size} isTransform={isTransform}>
            <ButtonLeft
                onClick={onClickLeft}
                size={size}
            >
                <StyledArrowDownBlueSVG colors={disabled ? "#C5C5C5" : colors.primary}/>
            </ButtonLeft>
            {
                isTransform ?
                    <InputCustomTransform
                        size={size}
                        autoFocus={false}
                        value={value}
                        step={steps}
                        min={min}
                        bordered={false}
                        onChange={(data) => {
                            if (data || data === 0) {
                                onChange(data)
                            }
                        }}
                        formatter={value => `${value} ${label}`}
                        parser={value => value.replace(`${label}`, '')}
                        disabled={disabled}
                        // isTransform={isTransform}
                    />
                    :
                    <InputCustom
                        size={size}
                        autoFocus={false}
                        value={value}
                        step={steps}
                        min={min}
                        bordered={false}
                        onChange={(data) => {
                            if (data || data === 0) {
                                onChange(data)
                            }
                        }}
                        formatter={value => `${value} ${label}`}
                        parser={value => value.replace(`${label}`, '')}
                        disabled={disabled}
                        // isTransform={isTransform}
                    />
            }

            <ButtonRight
                onClick={onClickRight}
                size={size}
            >
                <StyledArrowDownBlueSVG colors={disabled ? "#C5C5C5" : colors.primary}/>
            </ButtonRight>
        </Inputs>
    );
}

export default AntdInput;
