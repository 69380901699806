import React, {memo} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid, List, Tab, Tabs} from "@material-ui/core";
import {useGlobal} from "reactn";
import ProjectSettings from "./ProjectSettings";
import SyncSetting from "./googleSync";
import IdentitySettings from "./IdentitySettings";
import VideoConferencing from "./VideoConferencing";
import DialogCustom from "../DialogCustom/DialogCustom";
import AnnouncementBar from "./AnnouncementBar/AnnouncementBar";
import useIntlId from "../../hooks/useIntlId";
import HighLightBar from "./HighLightBar/HighLightBar";
import RemindBar from "./RemindBar/RemindBar";
import NavigationSettingTab from "./Navigation/NavigationSettingTab";
import CalenviewPlan from "./CalenviewPlan";
import {useAnnouncement} from "../../context/AnnouncementContext";

const styles = theme => ({
    rootLeft: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: "#f1f1f1",
        padding: 6,
        borderRadius: "30px 0 0 30px"
    },
    nested: {
        paddingLeft: theme.spacing(4),
        borderTopRightRadius: ' 40px',
        borderBottomRightRadius: '40px',
        backgroundColor: '#E3F2FD'
    },
    root: {
        zIndex: "1300 !important",
        '& .MuiPaper-root': {
            paddingBottom: "15px",
            boxSizing: "content-box",
            overflow: "unset",
        },
        '& .MuiDialogTitle-root': {
            padding: 3,
            textAlign: "right",
        },
        "& .MuiDialog-paperWidthSm": {
            minWidth: 967,
            maxWidth: "auto",
            height: "calc(100% - 64px)",
            borderRadius: 15,
        },
        [theme.breakpoints.down('sm')]: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "auto",
            }
        }
    },
    root2: {
        height: "100%",
        overflowY: "auto",
    },
    paper: {
        textAlign: 'left',
        color: theme.palette.text.secondary,
        height: '100%',
    },
    textTabs: {
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: "bold",
        color: theme.props.primaryColor,
        lineHeight: 1.2,
        minHeight: "unset",
        minWidth: "unset",
        height: 42,
        marginBottom: 4,
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    selected: {
        background: theme.props.primaryColor,
        color: "#fff",
        borderRadius: "25px"
    },
    header: {
        position: "relative",
        lineHeight: "42px",
        display: "block",
        width: "100%",
        fontSize: "1.7rem"
    },

    displayTime: {
        display: 'flex'
    },
    displayTypo: {
        lineHeight: '40px', margin: 0
    },
    divider: {
        margin: "15px 10px 15px 0px", height: 3
    },
    iconAdd: {
        marginLeft: '50%', fontSize: 30,
        marginTop: 10
    },
    settingBox: {
        width: '99%',
        border: '3px solid black',
        padding: '5px',
        margin: '5px',
        display: 'flex'
    },
    boxLeft: {
        width: '35%'
    },
    boxRight: {
        width: '75%'
    },
    divTime: {
        display: 'flex',
        padding: 5,
    },
    divTimeText: {
        fontWeight: "bold",
        width: '12.5%'
    },
    divTimeValue: {
        margin: '0 7px',
        minWidth: '30px',
        fontWeight: 700
    },
    divTimeCancel: {
        fontWeight: 'bold',
        color: 'rgb(248, 147, 32)',
        display: 'flex',
        padding: 5,
        lineHeight: '30px'
    },
    divTimeCancelValue: {
        fontWeight: 700,
        color: 'rgb(248, 147, 32)',
        // marginRight: 10,
        letterSpacing: 0,
        whiteSpace: 'nowrap'
    },
    addDayOff: {
        border: "1px solid rgb(248, 147, 32)",
        color: 'rgb(248, 147, 32)',
        marginLeft: 'auto',
        height: "30px",
        lineHeight: '15px',
        borderRadius: 'inherit',
        minWidth: 75
    },
    buttonDelete: {
        color: 'rgb(248, 147, 32)',
        marginLeft: 'auto',
        height: "30px",
        lineHeight: '15px',
        borderRadius: 'inherit'
    },
    divExclude: {
        fontWeight: 'bold',
        display: 'flex',
        padding: 5,
        lineHeight: '40px'
    },
    remove: {
        padding: 10
    },
    removeDiv: {
        textAlight: 'center',
        padding: 10
    },
    removeBtn: {
        margin: 5
    },
    tabBox: {
        background: "#fff",
        borderRadius: "30px",
        padding: 6,
    },
    paperContent: {
        background: "#f1f1f1",
        borderRadius: "0 25px 25px 25px",
        padding: 7,
    },
    containerPopup: {
        minWidth: "967px",
        height: "100%",
        padding: "15px 20px 10px 20px",
        overflowY: "auto"
    }
});

function ListSetting({classes}) {
    const [activeIndex, setActiveIndex] = useGlobal("settingTab")
    const [openSetting, setOpenSetting] = useGlobal('openSetting')
    const {plan} = useAnnouncement()
    const [
        videoConfIntl,
        projectsIntl,
        idIntl,
        highLightBarIntl,
        announcementBoardIntl,
        //eventsSetting,
        googleSync,
        globalNavi,
        listTabAnnouceBar,
        listTabRemindBar,
        planIntl
    ] = useIntlId([
        'app.profile.videoconferencing',
        'app.profile.projects',
        'app.profile.identities',
        'settings.highLightBar',
        'settings.announcementBoard',
        //'settings.eventsSetting',
        'settings.googleSync',
        'settings.tab.globalNavi',
        'setting.listTab.annouceBar',
        'setting.listTab.remindBar',
        'setting.listTab.plan'
    ])


    const handleChange = (_, activeIndex) => {
        if (plan.info.serviceId < 2) return;
        setActiveIndex(activeIndex)
    };

    const handleClose = () => {
        if (plan.info.serviceId < 2) return;
        setOpenSetting(false);
    };

    return (
        <div>
            <DialogCustom
                className={classes.root}
                open={openSetting}
                onClose={handleClose}
            >
                <div className={classes.root2}>

                    <Grid container className={classes.containerPopup}>
                        <Grid item xs={3}>
                            <div className={classes.paper}>
                                <List
                                    component="nav"
                                    className={classes.rootLeft}
                                >
                                    <VerticalTabs
                                        value={activeIndex}
                                        onChange={handleChange}
                                        className={classes.tabBox}
                                    >
                                        {
                                            [googleSync, videoConfIntl, projectsIntl, idIntl, listTabAnnouceBar, highLightBarIntl, listTabRemindBar, announcementBoardIntl, globalNavi, planIntl].map((item, index) => {
                                                    if (index === 7) return null;
                                                    return (<Tab
                                                            className={`${classes.textTabs + ' ' + (activeIndex === index + 1 ? classes.selected : null)} fontMontserrat`}
                                                            label={item}
                                                            value={index + 1}
                                                            key={index + 1}
                                                        />
                                                    )
                                            })
                                        }
                                    </VerticalTabs>
                                </List>
                            </div>

                        </Grid>
                        <Grid item xs={9}>
                            <div className={`${classes.paper} ${classes.paperContent}`}>
                                {/*{activeIndex === 0 &&*/}
                                {/*<DisplayTimeSetting/>*/}
                                {/*}*/}
                                {/*{activeIndex === 0 &&
                                    <CalendarEventSetting/>}*/}
                                {activeIndex === 1 &&
                                    <SyncSetting/>}
                                {activeIndex === 2 &&
                                    <VideoConferencing/>}
                                {activeIndex === 3 &&
                                    <ProjectSettings/>}
                                {activeIndex === 4 &&
                                    <IdentitySettings/>}
                                {activeIndex === 5 &&
                                    <AnnouncementBar/>}
                                {activeIndex === 6 &&
                                    <HighLightBar/>}
                                {activeIndex === 7 &&
                                    <RemindBar/>
                                }
                                {/*{activeIndex === 8 &&*/}
                                {/*    <AnnouncementBoard/>*/}
                                {/*}*/}
                                {activeIndex === 9 &&
                                    <NavigationSettingTab/>
                                }
                                {activeIndex === 10 &&
                                    <CalenviewPlan/>
                                }
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </DialogCustom>
        </div>
    )
}

const VerticalTabs = withStyles({
    flexContainer: {
        flexDirection: 'column'
    },
    indicator: {
        display: 'none',
    }
})(Tabs)


export default memo(withStyles(styles)(ListSetting))
