import React from 'react';
import {withStyles} from "@material-ui/core"
import moment from 'moment'
import {ReactComponent as CheckBoxActiveSVG} from "../../../asSvg/icons/checkBoxActive.svg";
import {ReactComponent as CheckBoxSVG} from "../../../asSvg/icons/checkBox.svg";
import InputNumber from "../../Custom/InputNumber";
import useIntlId from "../../../hooks/useIntlId";

const styles = theme => ({

    icon: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
    boxDuration: {
        background: "#F1F1F1",
        borderRadius: "40px",
        width: 125,
        display: "flex",
        justifyContent: "space-between",
        height: 27,
        alignItems: "center",
        padding: "0 5px",
    },
    button: {
        width: 21,
        height: 21,
        border: "none",
        backgroundColor: "#444444",
        minWidth: "unset",
        color: "#fff",
        borderRadius: "50%",
        padding: 0,
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            backgroundColor: "#000"
        }
    },
    allDay: {
        display: "flex",
        padding: 5,
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            marginRight: 6
        },
    },
    checkBoxSVG: {
        fill: theme.props.primaryColor,
        marginRight: 4
    }
})

function TitleEvent(props) {
    const {event, setEvent, classes, oldEvent = null} = props
    const [allDayIntl] = useIntlId(['create.allDay']);

    const onClickReduction = () => {
        if (event.duration > 0.5) {
            let num = event.allDay ? (event.duration - 1) : (event.duration - 0.5)
            let end = moment(event.start).add(num, event.allDay ? 'day' : 'hour').format()
            setEvent({
                ...event,
                duration: parseFloat(num),
                end: end
            })
        }
    }
    const onClickIncrease = () => {
        let num = event.allDay ? (event.duration + 1) : (event.duration + 0.5)
        let end = moment(event.start).add(num, event.allDay ? 'day' : 'hour').format()
        setEvent({
            ...event,
            duration: parseFloat(num),
            end: end
        })
    }
    const handleChangeAllDay = () => {
        if (event?.isEventVote)
            return;
        if (event?.allDay) {
            let start = moment(event.start).hour(10).format()
            let end = moment(event.end).hour(11).format()
            if (oldEvent && !oldEvent?.allDay) {
                start = oldEvent?.start || start
                end = oldEvent?.end || end
            }
            if (moment(end).isSameOrBefore(start)) {
                end = moment(start).add(1, "hour").format()
            }
            setEvent({
                ...event,
                start,
                allDay: false,
                end,
                duration: moment(end).diff(start, 'hour', true)
            })
            return
        }
        let start = moment(event.start).startOf("day").format('YYYY-MM-DD')
        let end = moment(event.end).endOf("day").format('YYYY-MM-DD')
        if (moment(end).isSameOrBefore(start)) {
            end = moment(start).endOf("day").add(1, "day").format('YYYY-MM-DD')
        }
        setEvent({
            ...event,
            start,
            allDay: true,
            end,
            duration: moment(end).diff(start, 'day', true)
        })
    }
    if (!event)
        return null
    return (
        <div className={classes.root}>
            <div className={classes.allDay}>
                {
                    event?.allDay ? <CheckBoxActiveSVG className={classes.checkBoxSVG}/>
                        :
                        <CheckBoxSVG className={classes.checkBoxSVG}/>
                }
                {allDayIntl}
            </div>
            <div className={classes.icon}>
                <img src={process.env.PUBLIC_URL + '/icons/time.svg'} alt=""/>
            </div>

            <div className={classes.boxDuration}>
                <InputNumber
                    type={'text'}
                    fill={"#000"}
                    background={"#f1f1f1"}
                    label={`${event.duration} ${event.allDay ? "d" : "h"}`}
                    disabled={event.is_holiday || (event.isEventVote && event.meetingId)}
                    onChange={(type, event) => {
                        if (type === "degree") {
                            onClickReduction(event)
                        } else {
                            onClickIncrease(event)
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default withStyles(styles)(TitleEvent)
