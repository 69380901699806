import React, {useEffect, useState} from 'react';
import {Tooltip, withStyles} from "@material-ui/core";
import IconIdentity from "../google/identityIcon";
import {useGlobal} from "reactn";
// import {filter} from "lodash";
import store from "store";
import {useCalendarEvents} from "../../../context/CalendarEventsContext";
import useIntlId from "../../../hooks/useIntlId";
import {publicIcon} from '../handlers/infoEvent';
import {Button} from 'antd';


const styles = () => ({
    btn: {
        backgroundColor: 'white',
        border: "none",
        boxSizing: "border-box",
        margin: 6,
        padding: "3px 7px 0px 7px",
        '&:hover': {
            cursor: 'pointer'
        },
        width: 36
    },
    root: {
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column"
    },
    title: {
        fontWeight: "bold", fontSize: 14
    },
    buttonIcon2: {
        margin: "5px 0px 5px 5px",
        border: "none !important",
        display: "flex !important",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff !important",
        boxShadow: "none",
        "& img": {
            minWidth: 18
        }
    },
    wrapIdentity: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    btnSetting: {
        marginLeft: 11,
        marginBottom: 5,
    },
})
const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

function IdentityList(props) {
    const {
        classes,
        event,
        setEvent,
        create = false,
        // onClose = null
    } = props
    // const [GoogleColor] = useGlobal('GoogleColor')
    // const [identities] = useGlobal('identities')
    const [, setOpenSetting] = useGlobal('openSetting')
    const [, setTab] = useGlobal('settingTab')
    // const [calendarUser] = useGlobal("calendarUser")
    const {googleIdentities} = useCalendarEvents()
    const lastProjectSelect = store.get('lastProjectSelect')
    const [projectSelect, setProjectSelect] = useState(lastProjectSelect ? lastProjectSelect : "")
    const [tagIdentity] = useIntlId(['app.profile.identities'])

    useEffect(() => {
        if (!googleIdentities.length || event.borderColor === event.backgroundColor)
            return
        const colors = googleIdentities.map(item => item.color)
        if (!colors.includes(event.backgroundColor)) {
            setEvent({
                ...event,
                backgroundColor: event.borderColor,
                colorId: null
            })
        }
        // eslint-disable-next-line
    }, [googleIdentities, event])


    const onChoose = (item) => {
        if (event?.colorId !== item.id) {
            setEvent({
                ...event,
                colorId: item.id,
                backgroundColor: item.color
            })
            setProjectSelect({...projectSelect, backgroundColor: item.color})
        } else {
            setEvent({
                ...event,
                colorId: '',
                backgroundColor: ''
            })
            setProjectSelect({...projectSelect, backgroundColor: ''})
        }
    }

    if (!event)
        return null

    return (
        <div className={classes.root}>
            <div className={classes.title}>{tagIdentity}</div>
            <div className={classes.wrapIdentity}>
                <div>
                    {googleIdentities.map((item, index) => {
                        if (item.id > 11 || !item.name)
                            return null
                        return (
                            <LightTooltip title={item.name || "(no title)"} placement={"top"} key={item.id}>
                                <button
                                    className={classes.btn}
                                    onClick={() => {
                                        if (event.is_holiday)
                                            return
                                        onChoose(item)
                                    }}
                                    key={index}
                                    style={(((parseInt(event.colorId) === item.id || event?.backgroundColor === item.color) && !create) || (projectSelect?.backgroundColor === item.color && create)) ?
                                        {
                                            border: `solid 1px ${item.color}`
                                        }
                                        :
                                        {}}
                                >
                                    <IconIdentity
                                        color={item.color}
                                    />
                                </button>
                            </LightTooltip>
                        )
                    })}
                </div>
                <div className={classes.wrapIdentity}>
                    <Button
                        className={classes.buttonIcon2}
                        onClick={() => {
                            if (event.is_holiday)
                                return
                            setEvent({
                                ...event,
                                colorId: '',
                                backgroundColor: ''
                            })
                            setProjectSelect({...projectSelect, backgroundColor: ''})
                        }}
                    >
                        <img src={publicIcon('backspace.svg')} alt=""/>
                    </Button>
                    <Button
                        className={classes.buttonIcon2} onClick={() => {
                        setTab(4)
                        setOpenSetting(true)
                    }}>
                        <img src={publicIcon('setting.svg')} alt=""/>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(IdentityList)
