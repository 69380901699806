import React, {useEffect, useState} from 'react'
import {getGlobal, setGlobal, useGlobal} from "reactn"
import {Grid, IconButton, TextField, withStyles} from "@material-ui/core"
import SelectColor from '../SelectColor'
import {useDBKeyPress} from "../../UseReact/UseKeyPress"
import {toast} from "react-toastify";
import GoogleSyncPopup from "./GoogleSyncPopup";
import {CreateGoogleCalendar} from "../../Calendar/actions/cal2";
import 'react-overlay-loader/styles.css';
import {db} from "../../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {gerenateCategories, gerenatePriorities} from "./constant";
import moment from "moment";
import {v4 as uuidv4} from 'uuid'
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItemContainer from "../../MenuAppBar/MenuItemContainer";
import TextInputCustom from "../../Custom/TextInputCustom";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {ReactComponent as CloseSVG} from "../../../asSvg/close.svg";
import {ReactComponent as SaveSVG} from "../../../asSvg/copy.svg";
import {cloneDeep, uniqBy} from 'lodash';
import DialogCustom from "../../DialogCustom/DialogCustom";
import useKeyboardJs from 'react-use/lib/useKeyboardJs'
import useIntlId from "../../../hooks/useIntlId";

const styles = theme => ({
    root: {
        position: "relative",
    },
    dialogRoot: {
        "& .MuiPaper-rounded": {
            borderRadius: 14,
            padding: 20,
            backgroundColor: '#FFF'
        },
        '& .MuiPaper-root': {
            paddingBottom: "15px",
            boxSizing: "content-box",
            overflow: "unset",
        },
        '& .MuiDialogTitle-root': {
            padding: 3,
            textAlign: "right",
        },
        "& .MuiDialog-paperWidthSm": {
            minWidth: 600,
            maxWidth: "auto",
            borderRadius: 15,
        },
        [theme.breakpoints.down('sm')]: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "auto",
            }
        }
    },
    content: {
        padding: "40px 40px 20px 40px",
    },
    formControl: {
        width: "100%",
        marginBottom: 10,
    },
    grow: {
        flexGrow: 1
    },
    margin: {
        margin: '0 5px',
        width: 130,
        color: '#3A3A3A',
        border: '1px solid #3A3A3A',
        backgroundColor: 'transparent',
    },
    error: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    selectColor: {
        width: 25,
        minWidth: 25,
        height: 25,
        borderRadius: '50%',
        padding: 0,
        border: '1px solid #D5D5D5',
    },
    btnClose: {
        margin: 4,
        width: 25,
        height: 25,
        minHeight: 0,
        backgroundColor: "transparent",
        boxShadow: "none",
        border: '1px solid #3A3A3A',
        position: "absolute",
        top: 5,
        right: 5,
        '&:hover': {
            backgroundColor: '#e91e63',
        },
    },
    btnAntd: {
        marginRight: 20,
        position: 'relative'
    },
    titleTooltip: {
        fontSize: '1rem',
        whiteSpace: 'nowrap'
    },
    titleText: {
        fontSize: "1.2rem",
        fontWeight: 'bold',
        color: theme.props.primaryColor,
        textTransform: 'uppercase'
    },
    textEdit: {
        width: '90%'
    },
    inputAdornment: {
        position: 'relative'
    },
    iconAdornment: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "-48px"
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor
        }
    },
    iconClose: {
        position: "absolute",
        right: -10,
        top: -10,
        background: "#fff",
        "&:hover": {
            background: "#fff",
        },
        "&:focus": {
            background: "#fff",
        },
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important'
    },
    textNotice: {
        width: '100%',
        display: "block",
        margin: "5px 0",
        fontSize: '1rem',
        padding: '5px 0px',
        textTransform: 'uppercase',
        color: theme.props.primaryColor
    },
    textNoticeError: {
        width: '100%',
        display: "block",
        margin: "5px 0",
        fontSize: '1rem',
        padding: '5px 0px',
        color: "red",
        textTransform: 'uppercase',
    }
})

function AddProjectPopup(props) {

    const {classes, addProject, setAddProject} = props
    const [projects, setProjects] = useGlobal('projects')
    const [userInfo] = useGlobal('user')
    const [googleStatus] = useGlobal('googleStatus')
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        projectColor: '#e91e63',
        categoryName: 'Default',
    })
    const [isEnterPress] = useKeyboardJs('enter')

    const [btnAddDisabled, setBtnAddDisabled] = useState(true)
    const [AddCalendar, projectName, cancel, save] = useIntlId([
        'leftHomeApp.AddCalendar',
        'calendar.create.projectName',
        'settings.archiveDialogCancel',
        'dialog.action.done',
    ])

    useEffect(() => {
        if (!isEnterPress)
            return;
        if (!values || !values.projectName) {
            document.getElementById("projectNameInput")?.focus()
            return
        }
        handleAdd(values, false)
        // eslint-disable-next-line
    }, [isEnterPress])

    function handleSelectColor(event) {
        let _event = event && event.currentTarget
        if (!event) {
            _event = document.getElementById('G_color')
        }
        setAnchorEl(_event)
    }

    const handleClose = () => {
        setValues({
            projectColor: '#e91e63',
            categoryName: 'Default',
        })
        setAddProject(false)
    }
    const handleChange = name => event => {
        if (name === 'code') {
            if (event.target.value.length < 4) {
                setValues({...values, [name]: event.target.value})
            }
        } else {
            setValues({...values, [name]: event.target.value})
        }
    }

    const handleAdd = async (values, is_sync = false) => {

        setBtnAddDisabled(true)
        setLoading(true)
        if (loading)
            return
        try {
            if (!values)
                return;
            const {projectName, projectColor} = values
            let _project
            const uid = 'pro_' + uuidv4()
            const categories = gerenateCategories(uid)
            const priorities = gerenatePriorities(uid)
            const time = moment().utc().format()
            if (is_sync) {
                const data = await CreateGoogleCalendar(projectName, projectColor)
                const {googleCalendarList} = getGlobal()
                if (!data)
                    return toast.error('Create google calendar fail')
                _project = {
                    id: uid,
                    uuid: uid,
                    name: projectName,
                    ja_name: "",
                    color: projectColor,
                    author: userInfo.email,
                    // is_enabled: false,
                    is_sync: true,
                    sync_id: data.id,
                    sync_email: googleStatus.userProfile && googleStatus.userProfile.email,
                    categories,
                    priorities,
                    is_deleted: false,
                    created_at: time,
                    updated_at: time
                }
                let _googleCalendarList = cloneDeep(googleCalendarList)
                _googleCalendarList.push(data)
                setGlobal({googleCalendarList: _googleCalendarList})
            } else {
                _project = {
                    id: uid,
                    uuid: uid,
                    name: projectName,
                    ja_name: "",
                    color: projectColor,
                    author: userInfo.email,
                    // is_enabled: false,
                    selected: true,
                    categories,
                    priorities,
                    is_deleted: false,
                    created_at: time,
                    updated_at: time,
                    is_sync: false
                }
            }
            projects.push({..._project, selected: true})
            setProjects(uniqBy(projects, 'id'))
            await db.collection(CALENDAR_COLLECTION)
                .doc(userInfo.user_id)
                .collection('projects')
                .doc(_project.id)
                .set(_project)
        } catch (e) {
            toast.error(e.toString())
            setBtnAddDisabled(false)
        } finally {
            setAddProject(false)
            setLoading(false)
        }
    }

    useDBKeyPress({priKey: 'altKey', key: 'c'}, handleSelectColor, [values], true)

    useEffect(() => {
        if (!values.projectName) setBtnAddDisabled(true)
        if (values.projectName) setBtnAddDisabled(false)
        // eslint-disable-next-line
    }, [values])

    return (
        <DialogCustom open={addProject} onClose={handleClose}
                      className={classes.dialogRoot}

        >
            <div>
                <Grid container justify={"center"} className={classes.root}>
                    <MenuItemContainer width={165} height={40} justifyContent={'center'}
                                       alignItems={'center'}
                                       margin={'0px 0px 20px 0px'} backgroundColor={'#F1F1F1'}>
                        <div className={classes.titleText}>{AddCalendar}</div>
                    </MenuItemContainer>

                    <TextInputCustom
                        name={projectName}
                        contentName={values?.projectName ? values.projectName : ''}
                        isEditComponent={true}
                        titleMaxWidth={140}
                        marginBottom={20}
                        editComponent={
                            <div className={`clf-flex-center ${classes.textEdit}`}>
                                <TextField
                                    autoFocus
                                    value={values?.projectName ? values.projectName : ''}
                                    onChange={handleChange('projectName')}
                                    fullWidth
                                    id={"projectNameInput"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end"
                                                            className={classes.inputAdornment}>
                                                {/*<Tooltip*/}
                                                {/*    title={<span*/}
                                                {/*        className={classes.titleTooltip}>COLOR<br/>Shortcuts: alt + c</span>}*/}
                                                {/*    placement="top"*/}
                                                {/*    getPopupContainer={() => document.getElementById('G_color')}*/}
                                                {/*>*/}
                                                <IconButton
                                                    disableRipple={true}
                                                    id={'G_color'}
                                                    aria-owns={Boolean(anchorEl) ? 'Geniam-appbar' : undefined}
                                                    onClick={handleSelectColor}
                                                    aria-haspopup="true"
                                                    className={classes.iconAdornment}
                                                >
                                                    <div className={classes.selectColor}
                                                         style={{backgroundColor: values.projectColor}}/>
                                                </IconButton>
                                                {/*</Tooltip>*/}
                                                <SelectColor
                                                    anchorEl={anchorEl}
                                                    setAnchorEl={setAnchorEl}
                                                    setValues={setValues}
                                                    values={values}
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>}
                    />

                    <div className={'clf-flex-center'}>
                        <CLFButtonSVG
                            className={classes.secondary}
                            name={cancel}
                            iconComponent={<CloseSVG/>}
                            onClick={handleClose}
                        />
                        <CLFButtonSVG
                            className={classes.primary}
                            name={save}
                            loading={loading}
                            disable={btnAddDisabled}
                            iconComponent={<SaveSVG/>}
                            onClick={() => {
                                if (values.projectName) {
                                    if (googleStatus.is_login) {
                                        setOpen(true)
                                    } else {
                                        handleAdd(values, false)
                                    }
                                } else {
                                    toast.error('Please complete the information on the form')
                                }
                            }}
                        />
                    </div>
                </Grid>
                <GoogleSyncPopup open={open} setOpen={setOpen} values={values} onAdd={handleAdd}/>
            </div>
        </DialogCustom>
    )
}

export default withStyles(styles)(AddProjectPopup)
