import {getGlobal, setGlobal} from 'reactn'
import {db} from "../../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../../config/constants";
import axios from 'axios'

export default async (code, profile) => {
    try {
        const {data} = await axios.get(`https://asia-northeast1-geniam-c8d4c.cloudfunctions.net/googleGetRefreshToken?code=${code}&redirectUri=${window.location.origin}`)
        const {user} = getGlobal()
        await db.collection(CALENDAR_COLLECTION).doc(user.user_id)
            .set({
                googleAuth: {
                    ...profile,
                    refreshToken: data.refresh_token
                }
            }, {merge: true})

        setGlobal({
            syncLoad: true
        })
    } catch (e) {
        console.log(e);
    }
}
