import React, {useEffect, useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as AddSVG} from "../../../asSvg/icons/add.svg";
import {makeStyles} from "@material-ui/core/styles";
import {Button, TextField} from "@material-ui/core";
import DialogCustom from "../../Custom/DialogCustom";
import {v4 as uuidv4} from "uuid";
import {useGlobal} from "reactn";
import {findIndex} from 'lodash'
import useIntlId from '../../../hooks/useIntlId';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import {checkUrl} from "../../../common/checkUrl";
import {myUserCalendarDocRef, myUserCalendarProjectDocRef} from "../../../common/firebaseRef/calendar";

const urls = [
    {
        id: "slack",
        name: "slack",
        url: "",
        logo: "/icons/slackIcon.svg"
    },
    {
        id: "trello",
        name: "trello",
        url: "",
        logo: "/icons/trelloIcon.svg"
    },
]

function AddUrl({project, setProject, setProjectSelected}) {
    const classes = useStyles()
    const [openAdd, setOpenAdd] = useState(false)
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [projects] = useGlobal("projects")
    const [user] = useGlobal("user")
    const [calendarUser] = useGlobal("calendarUser")
    const [loading, setLoading] = useState(false)
    const [saveIntl, nameIntl, addUrlIntl, invalid] = useIntlId([
        'dialog.action.done',
        'app.profile.name',
        'create.addUrl',
        'appy.noticeLink.invalid',
    ])
    const [isSaveUrl] = useKeyboardJs(['ctrl+enter', 'command+enter'])

    useEffect(() => {
        if (isSaveUrl && openAdd) {
            if (url) {
                onAdd()
            }
        }
        // eslint-disable-next-line
    }, [isSaveUrl])

    const openAddURL = () => {
        setOpenAdd(!openAdd)
    }
    const onAdd = async () => {
        try {
            setLoading(true)
            const id = uuidv4()
            if (!url || !user?.user_id) return;
            let logo = '/icons/linkURL.svg'
            let object = {
                id,
                name: name || "",
                url: url || "",
                logo
            }
            if (project.is_google) {
                // google calendar
                let googleSettings = calendarUser?.googleSettings || {}
                let projectInfo = googleSettings[project.id] || {}
                let {otherURL = []} = projectInfo
                if (!otherURL.length) {
                    otherURL = [...urls, object]
                } else {
                    otherURL = [...otherURL, object]
                }
                projectInfo.otherURL = otherURL
                googleSettings[project.id] = projectInfo
                const ref = myUserCalendarDocRef()
                await ref.set({
                    googleSettings
                }, {merge: true})
                setProject({...project, otherURL})
                setProjectSelected(otherURL)
                return
            }
            let idx = findIndex(projects, {id: project.id})
            if (idx === -1)
                return

            let projectInfo = projects[idx]
            let {otherURL = []} = projectInfo
            if (!otherURL.length) {
                otherURL = [...urls, object]
            } else {
                otherURL = [...otherURL, object]
            }
            const ref = myUserCalendarProjectDocRef(project.id)
            await ref.set({otherURL}, {merge: true})
            setProject({...project, otherURL})
            setProjectSelected(otherURL)
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            setName('')
            setUrl('')
            setOpenAdd(false)

        }

    }
    return (
        <div className={'ml-[10%] text-left'}>
            <IconButton className={classes.iconBtnAddURL}>
                <AddSVG onClick={openAddURL}
                        className={classes.iconAddURL}/>
            </IconButton>
            <DialogCustom open={openAdd}
                          onClose={() => setOpenAdd(false)}
                          title={addUrlIntl}
                          maxWidth={'xs'}
                          fullWidth={true}
                          actions={<Button
                              variant={"outline"}
                              className={classes.button}
                              disabled={!url || !checkUrl(url) || loading}
                              onClick={onAdd}
                          >
                              {saveIntl}
                          </Button>}
            >
                <div className={'w-full p-[20px]'}>
                    <TextField
                        style={{marginBottom: 10}}
                        value={name}
                        label={nameIntl}
                        fullWidth={true}
                        onChange={e => setName(e.target.value)}
                    />
                    <TextField
                        helperText={url ? (!checkUrl(url) ? invalid : '') : ''}
                        error={url ? !checkUrl(url) : ''}
                        value={url}
                        label={"URL"}
                        fullWidth={true}
                        onChange={e => setUrl(e.target.value)}

                    />
                </div>
            </DialogCustom>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    iconBtnAddURL: {
        padding: 0,
    },
    iconAddURL: {
        fill: theme.props.primaryColor,
        transform: 'scale(1.5)',
    },
    button: {
        background: theme.props.primaryColor,
        color: "#fff",
        fontWeight: 700,
        "&:hover": {
            background: theme.props.primaryColor,
            opacity: 0.7,
            color: "#fff",
        }
    }
}))

export default AddUrl;
