import moment from 'moment';

const getFocusKey = (view, time, numberId) => {
    let result
    switch (view) {
        case "CustomDay":
            result = moment(time).add(numberId, "day").format('YYYY-MM-DD')
            break
        case "CustomWeek":
            result = `${moment(time).add(numberId, "weeks").format('YYYY')}-W${moment(time).add(numberId, "weeks").isoWeeks()}`
            break
        default:
            result = moment(time).add(numberId, "month").format('YYYY-MM')
    }
    return result
}

export default getFocusKey
