import {db} from '../firebaseConfig'
import {setGlobal} from 'reactn'
import {GLOBAL_NAVI_APPS_COLL} from '../config/constants';

export const fetchGlobalApps = async () => {
    try {
        const snaps = await db.collection(GLOBAL_NAVI_APPS_COLL)
            .get()
        setGlobal({
            globalApps: snaps.docs.map(doc => ({...doc.data(), id: doc.id}))
        })
    } catch (e) {
        console.log(e);
    }
}
