import React from 'react';
import moment from "moment";
// import "normalize.css";
// import "@blueprintjs/core/lib/css/blueprint.css";
// import "@blueprintjs/icons/lib/css/blueprint-icons.css";
// import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import DateTimePicker from "./DateTimePicker";
import {makeStyles} from '@material-ui/core/styles';
import {IconButton} from "@material-ui/core";
import {ReactComponent as PolygonSVG} from "../../../asSvg/icons/polygon.svg";
import zoneObj from "../../../common/zoneObj";

// const copy = require('clipboard-copy')


function Time(props) {
    const {event, setEvent} = props;
    // const [allOneDay, setAllOneDay] = useState(false)
    const classes = useStyles();
    const handleChangeStart = (value) => {
        if (moment(value).isBefore(event.end)){
            const nextEvent = {
                ...event,
                start: value,
                duration: moment(event.end).diff(value, "day" , true)
            }
            setEvent(nextEvent)
        } else {
            const nextEvent = {
                ...event,
                start: moment(value).format(),
                end: moment(value).add(event.duration,'day').format()
            }
            setEvent(nextEvent)
        }

    }
    const handleChangeEnd = (value) => {
        if (moment(event.start).isBefore(value)){
            const nextEvent = {
                ...event,
                end: moment(value).format(),
                duration: moment(value).diff(event.start, 'day', true)
            }
            setEvent(nextEvent)
        } else {
            const nextEvent = {
                ...event,
                end: moment(value).format(),
                start: moment(value).subtract(event.duration, 'day').format()
            }
            setEvent(nextEvent)
        }
    }

    // const copyTime = () => {
    //     if (`${moment(event?.start).format("MM/DD")}` === `${moment(event?.end).format("MM/DD")}`) {
    //         copy(`${moment(event?.start).format("MM/DD")} ( ${moment(event?.start).format("ddd")} ) ${moment(event?.start).format("HH:mm")} - ${moment(event?.end).format("HH:mm")} `)
    //         toast.success("Copy time")
    //     } else {
    //         copy(`${moment(event?.start).format("MM/DD")} ( ${moment(event?.start).format("ddd")} ) ${moment(event?.start).format("HH:mm")} - ${moment(event?.end).format("MM/DD")} ( ${moment(event?.end).format("ddd")} ) ${moment(event?.end).format("HH:mm")}`)
    //         toast.success("Copy time")
    //
    //     }
    //
    // }
    // const checkAllOneDay = () => {
    //     let time = (moment(event?.end) - moment(event?.start)) / (24 * 3600 * 1000)
    //     if (parseInt(time) === 1)
    //         setAllOneDay(true)
    //     else setAllOneDay(false)
    // }
    // useEffect(() => {
    //     checkAllOneDay()
    // }, [event])


    if (!event)
        return null
    return (
        <div className={`timeCalendar ${classes.root}`}>
            <IconButton className={classes.icon}>
                <img src={process.env.PUBLIC_URL + '/icons/AccessTime.svg'} alt=""/>
            </IconButton>
            <div className={classes.box}>
                <DateTimePicker allDay={event.allDay}
                                disabled={event.is_holiday || (event.isEventVote && event.meetingId)}
                                dateTime={zoneObj(event.start)}
                                onChange={handleChangeStart}/>
                {/*{*/}
                {/*    (!allOneDay) &&*/}
                {/*    <div className={classes.flex}>*/}
                {/*        <img className={classes.polygon} src={process.env.PUBLIC_URL + '/icons/polygon.svg'} alt=""/>*/}
                {/*        <DateTimePicker allDay={event.allDay} disabled={event.is_holiday || event.isEventVote}*/}
                {/*                        dateTime={event.end}*/}
                {/*                        onChange={handleChangeEnd} isEnd={true}/>*/}
                {/*    </div>*/}
                {/*}*/}
                <div className={classes.flex}>
                    <PolygonSVG className={classes.polygonSVG}/>
                    {/*<img className={classes.polygon} src={process.env.PUBLIC_URL + '/icons/polygon.svg'} alt=""/>*/}
                    <DateTimePicker allDay={event.allDay}
                                    disabled={event.is_holiday || (event.isEventVote && event.meetingId)}
                                    dateTime={zoneObj(event.end)}
                                    onChange={handleChangeEnd} isEnd={true}/>
                </div>

            </div>
            {/*<Tooltip title="Copy Time" placement="top">*/}
            {/*    <IconButton>*/}
            {/*        <CopySVG onClick={copyTime} className={classes.iconSVG}/>*/}
            {/*    </IconButton>*/}
            {/*</Tooltip>*/}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    box: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    polygon: {
        margin: 6,
    },
    iconSVG: {
        fill: theme.props.primaryColor
    },
    polygonSVG: {
        margin: 6,
        fill: theme.props.primaryColor
    }
}));

export default Time;
