import React from 'react';
import moment from "moment";
import {ReactComponent as PolygonSVG} from "../../asSvg/icons/polygon.svg";
import styled from 'styled-components';

const DivTimeFromTo = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#f1f1f1",
    borderRadius: "10px",
    justifyContent: "center",
    width: props.width,
    margin: props.margin,
    padding: props.padding,
}))

const DivEventTime = styled.div(props => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    pointerEvent: `${props.disablePointerEvent ? 'none' : ''}`,
    "& span": {
        fontSize: "12px",
        fontWeight: "bold",
        background: "#fff",
        borderRadius: "24px",
        padding: "4px 18px"
    }
}))

const StyledSVG = styled(PolygonSVG)`
    margin: 5px;
    fill: ${props => props.fill}
`;
const Days = styled.div`
    font-size: 12px;
    background: #fff;
    color: #000;
    padding: 5px 8px;
    margin-left: 5px;
    border-radius: 15px;
    font-weight: bold;
`

function TimeFromToCustom({
                              timeStart,
                              timeEnd,
                              colorIcon,
                              disablePointerEvent = false,
                              onClick = null,
                              width = '100%',
                              margin = 'auto',
                              format = 'MM/DD',
                              padding = '6px 7px 6px 10px'
                          }) {

    const handleClick = () => {
        if (onClick && typeof onClick === 'function')
            onClick()
    }

    return (
        <DivTimeFromTo
            onClick={handleClick}
            width={width}
            padding={padding}
            margin={margin}
        >
            <DivEventTime disablePointerEvent={disablePointerEvent}>
                <span>{moment(timeStart).format(format)}</span>
                <StyledSVG fill={colorIcon}/>
                <span>{moment(timeEnd).format(format)}</span>
            </DivEventTime>
            <Days>{moment(timeEnd).diff(timeStart, "days") + 1} {moment(timeEnd).diff(timeStart, "days") + 1 > 1 ? "days" : "day"}</Days>
        </DivTimeFromTo>
    );
}

export default TimeFromToCustom;
