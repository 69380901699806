import React from 'react';
import IconIdentity from "../../Calendar/google/identityIcon";
import {Modal} from "antd";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import useIntlId from "../../../hooks/useIntlId";

function UnArchiveIdentityDialog({identity, setIdentity, onSave, loading, classes}) {
    const [cancel, ok, doYouWantUnarchive] = useIntlId([
        'settings.archiveDialogCancel',
        'create.ok',
        'app.notification.doYouWantUnarchive'
    ])
    return (
        <Modal
            visible={Boolean(identity)}
            title={doYouWantUnarchive}
            onCancel={() => setIdentity(null)}
            zIndex={9999}
            footer={<div className={'clf-flex-center'}>
                <CLFButtonSVG
                    onClick={() => setIdentity(null)}
                    disable={loading}
                    name={cancel}
                    className={classes.secondary}
                />
                <CLFButtonSVG
                    name={ok}
                    loading={loading}
                    onClick={() => onSave({...identity, isArchive: false})}
                    disable={loading}
                    className={classes.primary}
                />
            </div>}
        >
            <IconIdentity color={identity?.color || null}/>
        </Modal>
    );
}

export default UnArchiveIdentityDialog;
