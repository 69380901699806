import moment from 'moment'

const hours = 24
const duration = 15 // minutes

const numOfTime = 60 / duration * hours
export const getTimeList = () => {
    const result = []
    const time = moment().set({
        hours: 0,
        minutes: 0,
        seconds: 0
    }).format()
    for (let i = 0; i < numOfTime; i++) {
        result.push(moment(time).add(duration * i, "minutes").format("HH:mm"))
    }
    return result
}
