import {makeStyles} from "@material-ui/core/styles";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {ReactComponent as EyeSVG} from "../../../asSvg/eyes.svg";
import React from "react";
import {Popover} from "antd";
import {publicIcon} from "../../Calendar/handlers/getIcon";
import {useGlobal} from "reactn";
import WorkSpaceProjectSettingList from "./WorkSpaceProjectSettingList";
import {db} from "../../../firebaseConfig";
import {milestoneSettingCollRef, milestoneSettingDocRef} from "../../../actions/group";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },

    divItem: {
        backgroundColor: "red",
        width: 50,
        height: 30,
        borderRadius: 7,
        margin: 5,
        color: '#000',
        fontWeight: 700,
        fontSize: 20,
        WebkitTextStrokeColor: '#fff',
        WebkitTextStrokeWidth: '1px',
        cursor: 'pointer',
    },

    divTheme: {
        display: 'flex',
        background: '#fff',
        borderRadius: 20,
        height: 40,
        alignItems: "center",
        overflow: "auto",
        padding: '0 5px',
        maxWidth: 310
    },

    buttonAntd: {
        textTransform: 'uppercase',
        fontWeight: 700,
        "& svg": {
            marginLeft: 5
        }
    },
}))

function WorkSpaceButtons({viewMode, editWorkSpace, setEditWorkSpace, colors}) {
    const classes = useStyles()

    const [settingList] = useGlobal('settingList')
    const [mySetting] = useGlobal('mySetting')

    const onSelect = async (setting) => {
        try {
            const batch = db.batch()
            const useSetting = await milestoneSettingDocRef('999').get();
            const snap = await milestoneSettingCollRef().get()
            const docUseRef = milestoneSettingDocRef('999')

            if (!useSetting.exists) {
                const newSetting = {...mySetting};
                newSetting.id = 999;
                await docUseRef.set(newSetting, {merge: true})
            }

            if (mySetting.id === setting.id) {
                const listSetting = snap.docs;
                for (let i = 0; i < listSetting.length; i++) {
                    if (listSetting[i].id === '999') {
                        batch.set(docUseRef, {useSetting: true}, {merge: true})
                        continue;
                    }
                    const docRef = milestoneSettingDocRef(listSetting[i].id)
                    batch.set(docRef, {useSetting: false}, {merge: true})
                }
                await batch.commit();
                return;
            }

            snap.docs.forEach((doc) => {
                const data = doc.data();
                const docRef = milestoneSettingDocRef(doc.id)
                if (data.id === setting.id) {
                    batch.set(docRef, {useSetting: true}, {merge: true})
                } else {
                    batch.set(docRef, {useSetting: false}, {merge: true})
                }
            })

            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={classes.root}>
            <div id={'calendar-viewbox-popover-setting'} style={{marginRight: 10}}>
                <CLFButtonSVG
                    name={viewMode}
                    size={'large'}
                    iconComponent={<EyeSVG/>}
                    minWidth={'130px'}
                    minHeight={'40px'}
                    margin={"0 5px"}
                    background={colors?.primary}
                    borderColor={colors?.primary}
                    className={classes.buttonAntd}
                    onClick={() => {
                        setEditWorkSpace(!editWorkSpace)
                    }}
                />
            </div>

            {
                settingList.length > 0 &&
                <div className={classes.divTheme}>
                    {
                        settingList.map((setting) => {
                            if (setting.id > 8) return;
                            return (
                                <div key={setting.id}>
                                    <Popover
                                        zIndex={9999}
                                        content={<WorkSpaceProjectSettingList projects={setting.listProjectSetting}/>}
                                        destroyTooltipOnHide={{keepParent: false}}
                                    >
                                        {
                                            <div
                                                className={[classes.divItem, 'flex']}
                                                style={{backgroundColor: setting.colors.primary}}
                                                onClick={() => {
                                                    onSelect(setting)
                                                }}
                                            >
                                                {
                                                    setting.useSetting ?
                                                        <img style={{width: 25}}
                                                             className={'flex-auto'}
                                                             src={publicIcon('view/checkActive.svg')}
                                                             alt="activated"/>
                                                        :
                                                        <div style={{textTransform: 'uppercase'}}
                                                             className={'flex-auto'}
                                                        >
                                                            {setting.code}
                                                        </div>
                                                }
                                            </div>
                                        }
                                    </Popover>
                                </div>
                            )
                        })
                    }
                </div>
            }

        </div>
    )
}

export default WorkSpaceButtons
