import {useGlobal} from "reactn";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Input} from "antd";
import useIntlId from "../../../hooks/useIntlId";

const useStyles = makeStyles(theme => ({
    inputText: {
        border: 'none',
        borderColor: '#fff',
        outline: 'none',
        select: 'focus',
        background: '#f1f1f1',
        borderRadius: 6
    },
    buttonSave: {
        width: '40%',
        borderRadius: 8,
        fontWeight: 'bold',
        color: '#fff',
        textTransform: 'uppercase',
        '&:hover': {
            border: "none",
            color: '#fff',
        },
    },
    divRow: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20
    },
    divTitle: {
        textAlign: 'center',
        color: '#1790FF',
        fontWeight: 700,
        fontSize: 14,
        marginBottom: 15,
        width: 300
    },
    titleInput: {
        color: '#1790FF',
        paddingLeft: 10
    }
}));

function PopperSetting({onSave, setting}) {

    const classes = useStyles()
    const [, setName] = useGlobal('settingName')
    const [, setCode] = useGlobal('codeSetting')

    const [settingNameIntl, saveIntl, codeName] = useIntlId([
        'app.notification.nameSetting',
        'dialog.action.save',
        'milestone.viewSetting.codename'
    ])

    return (
        <div style={{}}>
            <div className={classes.divTitle}>SAVE YOUR SETTING</div>

            <div className={classes.titleInput}>{settingNameIntl}</div>
            <Input
                maxLength={16}
                defaultValue={setting.name || ''}
                placeholder={settingNameIntl}
                className={classes.inputText}
                onChange={(value) => setName(value.target.value)}
            />
            <div style={{paddingTop: 10}} className={classes.titleInput}>{codeName}</div>
            <Input
                maxLength={2}
                defaultValue={setting.code || ''}
                placeholder={codeName}
                className={classes.inputText}
                onChange={(value) => setCode(value.target.value)}
            />
            <div className={classes.divRow}>
                <Button
                    style={{backgroundColor: '#1790FF'}} className={classes.buttonSave}
                    onClick={onSave}
                >
                    {saveIntl}
                </Button>
            </div>
        </div>
    )
}

export default PopperSetting
