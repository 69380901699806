import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {InputNumber} from 'antd';

const useStyles = makeStyles(theme => ({
    root: {
        width: '95%',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center'
    },
    number: {
        display: 'flex',
        width: '100%',
        maxWidth: '94px',
        height: '30px',
        backgroundColor: '#fff',
        borderRadius: '40px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    img: {},
    img2: {
        minWidth: 10
    },
    input: {
        color: theme.props.primaryColor
    }
}))

function NumberComponent({imgComponent, value, setValue, handleBlur}) {
    const classes = useStyles();

    return (
        <div className={`${classes.root} clf-flex clf-left-10`}>
            {imgComponent}
            <div className={classes.number}>
                <img src='/imgs/newSvgs/-.svg' alt='' className={`clf-cursor-pointer ${classes.img2}`}
                     onClick={() => handleBlur('remove')}/>
                <InputNumber
                    min={1}
                    max={36}
                    value={value}
                    onChange={value => setValue(value)}
                    onStep={(value, info) => {
                        if (info.type === 'up') {
                            handleBlur('add')
                        } else {
                            handleBlur('remove')
                        }
                        // setValue(value)
                    }}
                    onBlur={() => handleBlur('auto')}
                    className={`clf-no-border clf-input ${classes.input}`}
                />
                <img src='/imgs/newSvgs/+.svg' alt='' className={`clf-cursor-pointer ${classes.img2}`}
                     onClick={() => handleBlur('add')}/>
            </div>
        </div>
    );
}

export default NumberComponent;
