import {concat, findIndex, get, remove} from 'lodash'
import moment from "moment"
import toast from '../utils/toast'
import {getGlobal, setGlobal} from 'reactn'
import {CALENDAR_COLLECTION} from "../config/constants";
import {db} from "../firebaseConfig";
import {ggTokenChecked} from "../components/Calendar/google/auth";
import updateWatchChannelMyself from "../common/updateWatchChannelMyself";
import momentTz from 'moment-timezone';
import store from 'store'
import {msgError} from "../utils/msg";
import {getIntlId} from "../hooks/useIntlId";
import zoneObj from "../common/zoneObj";

export const RefreshGoogleToken = async () => {
    try {
        const res = await window.gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse()
        store.set('googleAccessToken', res.access_token)
        return res.access_token
    } catch (e) {
        return null
    }
}
//
// const SetListWatch = (nextSyncToken) => {
//     const token = store.get('googleAccessToken')
//     const request = window.gapi.client.calendar.calendarList.watch({
//         "id": v4(),
//         token,
//         "type": "web_hook",
//         "address": GOOGLE_CALENDAR_WATCH
//     })
//     request.then(onFulfilled => {
//             setGlobal({LChannel: {...onFulfilled.result, nextSyncToken}})
//             store.set('LChannel', JSON.stringify({...onFulfilled.result, nextSyncToken}))
//         }, onRejected =>
//             console.log(onRejected)
//     )
// }
// export const SyncListCalendar = nextSyncToken => {
//     const {LChannel, googleCalendarList} = getGlobal()
//     if (nextSyncToken) {
//         const request = window.gapi.client.calendar.calendarList.list({
//             syncToken: nextSyncToken
//         })
//         request.then(onFulfilled => {
//             const {result} = onFulfilled
//             setGlobal({LChannel: {...LChannel, nextSyncToken: result.nextSyncToken}})
//             store.set('LChannel', JSON.stringify({...LChannel, nextSyncToken: result.nextSyncToken}))
//             if (result.items.length !== 0) {
//                 result.items.forEach(async item => {
//                     if (!includes(item.id, '#contacts@group')) {
//                         if (item.deleted) {
//                             RemoveGroup(item.id)
//                             remove(googleCalendarList, c => c.id === item.id)
//                             setGlobal({googleCalendarList: [...googleCalendarList]}, () => {
//                                 RefetchGG()
//                             })
//                         } else {
//                             let cal_idx = findIndex(googleCalendarList, {'id': item.id})
//                             if (cal_idx !== -1) {
//                                 let refresh = false
//                                 if (item.backgroundColor !== googleCalendarList[cal_idx].backgroundColor ||
//                                     item.selected !== googleCalendarList[cal_idx].selected
//                                 ) {
//                                     refresh = true
//                                 }
//                                 googleCalendarList[cal_idx] = {
//                                     ...item
//                                 }
//                                 setGlobal({googleCalendarList: [...googleCalendarList]}, () => {
//                                     if (refresh) {
//                                         RefetchGG()
//                                     }
//                                 })
//                             } else {
//                                 googleCalendarList.unshift(item)
//                                 setGlobal({googleCalendarList: [...googleCalendarList]})
//                             }
//                         }
//                     }
//                 })
//
//             }
//         }, onRejected => {
//             console.log(onRejected);
//         })
//     }
// }
// export const GetChannelWatch = (calendar_id) => {
//     const {GChannel} = getGlobal()
//     let idx = findIndex(GChannel, {"calendar_id": calendar_id})
//
//     if (idx !== -1 && moment().add(1, "day").isBefore(parseInt(GChannel[idx].expiration))) {
//
//         return
//     }
//
//     const request = window.gapi.client.calendar.events.watch({
//         "calendarId": calendar_id,
//         "resource": {
//             "id": v4(),
//             "type": "web_hook",
//             "token": `userId=${getGlobal().user.user_id}&session=${v4()}`,
//             "address": GOOGLE_CALENDAR_WATCH,
//         }
//     })
//     request.then(onFulfilled => {
//         const {result} = onFulfilled
//         if (idx !== -1) {
//             GChannel[idx] = {...result, calendar_id: calendar_id}
//         } else {
//             GChannel.push({...result, calendar_id: calendar_id})
//         }
//         setGlobal({GChannel: uniqBy(GChannel, 'calendar_id')})
//         store.set('GChannel', JSON.stringify(GChannel))
//     }, onRejected => {
//         // console.log(onRejected);
//     })
//
// }
// export const getEventsInfo = async (start, end, isMilestone = false) => {
//     const {googleStatus, googleCalendarList} = getGlobal()
//     let result = []
//     if (googleStatus.is_login) {
//         // try {
//         const checked = await ggTokenChecked()
//         if (!checked)
//             return result
//         let count = 0
//         const batch = window.gapi.client.newBatch()
//         googleCalendarList.forEach(group => {
//             if (group.selected) {
//                 count++
//                 const request = window.gapi.client.calendar.events.list({
//                     'calendarId': group.id,
//                     "singleEvents": true,
//                     "timeMin": start,
//                     "timeMax": end,
//                     "maxResults": 2500,
//                     // "alwaysIncludeEmail":true
//                 })
//                 batch.add(request)
//             }
//         })
//         if (count === 0)
//             return result
//         const data = await batch.then()
//         if (data.result) {
//             Object.keys(data.result).forEach((item) => {
//                 let calendarItems = data.result[`${item}`].result.items
//                 if (calendarItems && calendarItems.length !== 0) {
//                     let index = findIndex(googleCalendarList, {id: calendarItems[0].organizer.email})
//                     if (index !== -1) {
//                         let isHoliday = data.result[`${item}`].result.accessRole === 'reader'
//                         let formatedItems = GCalendarEventFormat(calendarItems, googleCalendarList[index].id, googleCalendarList[index].backgroundColor, isHoliday)
//                         result = isMilestone ? remove(concat(result, formatedItems), c => c.allDay).map(milestone => ({
//                             ...milestone,
//                             resourceId: milestone.project_uuid,
//                             created_at: milestone.created,
//                         })) : concat(result, formatedItems)
//                     }
//                 }
//             })
//         }
//         // } catch (e) {
//         //     console.log(e)
//         //     toast.error(e.toString())
//         // }
//     }
//
//     return result
// }
// export const getChannelWatches = list => {
//     let next = [...list]
//     remove(next, g => {
//         return g.id.includes('#contacts@group.v.calendar.google.com') || g.id.includes('#holiday@group.v.calendar.google.com')
//     })
//     if (next.length !== 0) {
//         next.forEach(n => {
//             GetChannelWatch(n.id)
//         })
//     }
// }
export const GCalendarList = async (firstload = false) => {
    const {googleStatus, calendarUser} = getGlobal()
    // const googleCalendars = store.get("googleCalendars") || []
    if (googleStatus.is_login) {
        try {
            // check token exists
            const checked = await ggTokenChecked()
            if (!checked)
                return
            const request = window.gapi.client.calendar.calendarList.list()
            const {status, result} = await request.then()
            if (status !== 200) {
                return
            }

            // if (LChannel.id) {
            //     if (moment(LChannel.expiration) < moment().add(1, "day")) {
            //         SetListWatch(result.nextSyncToken)
            //     } else {
            //         setGlobal({LChannel: {...LChannel, nextSyncToken: result.nextSyncToken}})
            //         store.set('LChannel', JSON.stringify({
            //             ...LChannel,
            //             nextSyncToken: result.nextSyncToken
            //         }))
            //     }
            // } else {
            //     SetListWatch(result.nextSyncToken)
            // }
            let groups = result.items
            remove(groups, g => {
                return g.id.includes('#contacts@group.v.calendar.google.com') || g.accessRole === "freeBusyReader"
            })
            const {googleList = []} = calendarUser
            let newList = []

            googleList.forEach(id => {
                let idx = findIndex(groups, {id})
                if (idx !== -1) {
                    newList.push(groups[idx])
                    groups.splice(idx, 1)
                }
            })
            newList = concat(newList, groups).map(l => {
                return {
                    ...l
                }
            })
            setListCalendar(newList)
            newList.map(async project => {
                if (project.accessRole !== "owner" && (
                    project?.summaryOverride === "true" ||
                    project?.summaryOverride === "false" ||
                    project?.summaryOverride?.includes("appointment") ||
                    project?.summaryOverride?.includes("milestone"))
                ) {
                    await checkedCalendar(project.id, project.selected)
                }
            })
        } catch (e) {
            console.log(e);
        }

    } else {
        console.log('No GGtoken be find')
    }
}

//
// const getSummaryOverride = (value) => {
//     if (!value || value === "true")
//         return 'appointment,milestone'
//     if (value === "false")
//         return 'appointment'
//     return value
// }
//

const setListCalendar = (list) => {
    remove(list, g => g.id.includes("#holiday"))
    setGlobal({
        googleCalendarList: list,
        // loading: false,
    }, () => {
        // bug: some time new value not be set
        if (getGlobal().googleCalendarList?.length !== list.length) {
            // try set again one time
            setListCalendar(list)
        }
    })
}

// export const GCalendarEvents = async (id, color, start, end) => {
//     // const { syncToken = []} = getGlobal()
//     const max = 2500
//     let result = [], token = null
//     try {
//         let encode_id
//         let holiday = false
//         if (id.includes('#holiday')) {
//             encode_id = encodeURIComponent(id)
//             holiday = true
//         } else {
//             encode_id = id
//         }
//         const request = window.gapi.client.calendar.events.list({
//             'calendarId': id,
//             "singleEvents": true,
//             "timeMin": start,
//             "timeMax": end,
//             maxResults: max
//         })
//         const data = await request.then()
//         console.log(data)
//         // const res = await axios.get(GET_GOOGLE_CALENDAR_EVENTS + `/${encode_id}/events?timeMin=${start}&timeMax=${end}&maxResults=${max}&singleEvents=true`)
//         result = GCalendarEventFormat(data.result.items, id, color, holiday)
//         token = data.result.nextSyncToken
//
//     } catch (e) {
//         console.log(e)
//     }
//
//     return {events: result, token: token}
//
// }
//
// export const ListCalendar = async () => {
//     const {calendarSelected} = getGlobal()
//     let googleAccessToken = store.get('googleAccessToken')
//     if (googleAccessToken) {
//         try {
//             const res = await axios.get(GET_GOOGLE_CALENDAR_LIST)
//             if (res.data && res.data.items && res.data.items.length !== 0) {
//                 let resuit = []
//                 let arr = res.data.items
//                 remove(arr, g => {
//                     return g.id.includes('#contacts@group.v.calendar.google.com')
//                 })
//                 arr.forEach(cal => {
//                     let index = findIndex(calendarSelected, {"id": cal.id})
//                     resuit.push({...cal, checked: index === -1 ? false : true})
//                 })
//                 setGlobal({googleCalendarList: resuit})
//             }
//         } catch (e) {
//             console.log(e)
//         }
//     }
// }

export const GCalendarEventFormat = (events, group_uuid, color, holiday = false) => {
    const {GoogleColor} = getGlobal()
    let result = []
    events.forEach(e => {
        if (e.start && e.end) {
            let allDay = e.start.date ? true : false
            let start = e.start.dateTime || e.start.date
            let end = e.end.dateTime || e.end.date
            let resourceId = allDay ? group_uuid : null
            let title = removeEmoji(e.summary, e?.extendedProperties?.private?.task || null).trim(" ")
            let event = {
                ...e,
                id: e.id,
                uuid: e.id,
                start,
                end,
                title: title || '(no title)',
                colorId: e?.colorId ? Number(e.colorId) : null,
                backgroundColor: e?.colorId ? GoogleColor[e.colorId - 1] : color,
                borderColor: color,
                allDay,
                resourceId,
                duration: (moment(end) - moment(start)) / (60000 * 60), // minutes
                calendar_uuid: group_uuid,
                project_uuid: group_uuid,
                googleEvent: true,
                editable: !holiday && !Boolean(e?.extendedProperties?.private?.meetingId),
                draggable: !holiday && !Boolean(e?.extendedProperties?.private?.meetingId),
                holiday: holiday,
                task: e?.extendedProperties && e.extendedProperties?.private?.task ? e.extendedProperties.private.task : null,
                isSuccess: e?.extendedProperties && e?.extendedProperties?.private?.is_success ? Boolean(parseInt(e.extendedProperties.private.is_success)) : false,
                pro_uuid: e?.extendedProperties?.private?.project_uuid || null,
                isMileStone: Boolean(e?.extendedProperties?.private?.task === "mileStone"),
                isEventVote: Boolean(e?.extendedProperties?.private?.meetingId) || Boolean(e?.extendedProperties?.private?.shareUrl),
                linkZoom: e?.extendedProperties && e?.extendedProperties?.private?.linkZoom ? e.extendedProperties.private.linkZoom : null,
                zoomMeetingId: e?.extendedProperties && e?.extendedProperties?.private?.zoomMeetingId ? e.extendedProperties.private.zoomMeetingId : null,
                meetingId: e?.extendedProperties?.private?.meetingId || null,
                isMilestoneHide: Boolean(e?.extendedProperties?.private?.isMilestoneHide === "true"),
                isMilestoneNextMonth: Boolean(e?.extendedProperties?.private?.isMilestoneNextMonth === "true"),
                conferenceData: e?.conferenceData || null,
                googleTasksID: get(e, "extendedProperties.private.googleTasksID", null),
                achievementRate: Number(get(e, "extendedProperties.private.achievementRate", 0))
            }
            if (e.recurrence && Array.isArray(e.recurrence)) {
                event.recurrence = e.recurrence[0]
            }
            if (event.isEventVote && event.meetingId) {
                event.isAccepted = true
            }
            result.push(event)
        }
    })
    return result
};

const removeEmoji = (title, task) => {
    const {emojis} = getGlobal()
    let result = title || ""
    if (task && title) {
        let index = findIndex(emojis, {'name': task})
        if (index !== -1) {
            result = title.split(emojis[index].emoji)[0]
        }
    }
    return result
}
const getEmoji = (task) => {
    const {emojis} = getGlobal()
    let result = ''
    let index = findIndex(emojis, {'name': task})
    if (index !== -1) {
        result = emojis[index].emoji
    }
    return result
}
export const RemoveGoogleCalendar = () => {
    const {groups} = getGlobal()
    let nextGroups = [...groups]
    remove(nextGroups, g => {
        return g.is_google === true
    })
    setGlobal({groups: nextGroups})

}

export const AddGoogleEvent = async (event) => {
    // console.log(event);
    try {
        let id
        await ggTokenChecked()
        let calendarId = event.project_uuid
        const batch = window.gapi.client.newBatch()
        let data = parseResource(event)
        const request = window.gapi.client.calendar.events.insert({
            'calendarId': calendarId,
            'resource': data
        })
        batch.add(request)
        // await batch.then()
        const {result} = await batch.then();
        Object.keys(result).forEach(key => {
            let data = result[key]
            if (data && data.status === 200) {
                id = data.result.id;
                if (event.conferenceData)
                    updateGoogleEvent(event, id)
            }
        });
        updateWatchChannelMyself(calendarId)
        return {...event, id: id}
    } catch (e) {
        return null
    }
};

export const changeGoogleEvent = async (event,oldRes) => {
    try {
        let id
        await ggTokenChecked()
        let calendarId = event.project_uuid
        const batch = window.gapi.client.newBatch()
        let data = parseResource(event)
        const request_insert = window.gapi.client.calendar.events.insert({
            'calendarId': calendarId,
            'resource': data
        })
        const request_patch = window.gapi.client.calendar.events.patch({
            "calendarId": oldRes.project_uuid,
            "eventId": event.id,
            "status": "cancelled"
        })
        batch.add(request_insert)
        batch.add(request_patch)
        const {result} = await batch.then();
        Object.keys(result).forEach(key => {
            let data = result[key]
            if (data && data.status === 200) {
                id = data.result.id;
                if (event.conferenceData)
                    updateGoogleEvent(event, id)
            }
        });
        updateWatchChannelMyself(calendarId)
        return {...event, id: id}
    } catch (e) {
        return null
    }
};

export const updateGoogleEvent = async (event, id) => {
    let data = parseResource(event)
    try {
        await ggTokenChecked()
        const batch = window.gapi.client.newBatch()
        const request = window.gapi.client.calendar.events.patch({
            "calendarId": event.project_uuid,
            "eventId": id,
            "resource": data,
            "sendNotifications": true,
            "conferenceDataVersion": 1
        });

        batch.add(request)
        await batch.then()
        // updateBusyColl(event)
        updateWatchChannelMyself(event.project_uuid)
        // console.log('update ok')
        return event
    } catch (e) {
        toast.error("UPDATE GOOGLE EVENT ERROR ", {autoClose: 2000})
        return null
    }
}
export const changeTimeGoogleEvent = async (event) => {
    let data = parseResource(event)
    try {
        await ggTokenChecked()
        const batch = window.gapi.client.newBatch()
        const request = window.gapi.client.calendar.events.patch({
            "calendarId": event.project_uuid,
            "eventId": event.id,
            "resource": {
                start: data.start,
                end: data.end
            },
            "sendNotifications": true,
            "conferenceDataVersion": 1
        });

        batch.add(request)
        await batch.then()
        // updateBusyColl(event)
        updateWatchChannelMyself(event.project_uuid)
        // console.log('update ok')
        return event
    } catch (e) {
        // toast.error("UPDATE GOOGLE EVENT ERROR ", {autoClose: 2000})
        return null
    }
}
export const getColorId = (color) => {
    let id = null
    if (color) {
        const {GoogleColor} = getGlobal()
        GoogleColor.forEach((value, index) => {
            if (value.toLowerCase() === color.toLowerCase()) {
                id = index + 1
            }
        })
    }
    return id
};

export const parseResource = event => {
    let emoji = getEmoji(event.task)
    if (event.colorId && event.colorId > 11)
        event.colorId = null

    let data = {
        "summary": event.title + emoji,
        "colorId": event.colorId || getColorId(event.backgroundColor),
        "extendedProperties": {
            "private": {
                "task": event.task || '',
                "is_success": event.isSuccess ? 1 : 0,
                "meetingId": event?.meetingId || "",
                "isMilestoneHide": event?.isMilestoneHide ? 'true' : 'false',
                "isMilestoneNextMonth": event?.isMilestoneNextMonth ? 'true' : 'false',
                "linkZoom": event?.linkZoom || '',
                "zoomMeetingId": event?.zoomMeetingId || '',
                "googleTasksID": event?.googleTasksID || '',
                "achievementRate": event?.achievementRate || 0
            }
        },
        "description": event.description || '',
        "location": event.location || ''
    };
    if (event.allDay) {
        let startDate = zoneObj(event.start).format('YYYY-MM-DD')
        let endDate = zoneObj(event.end).format('YYYY-MM-DD')
        data.start = {
            date: startDate,
            dateTime: null
        }
        data.end = {
            date: endDate !== startDate ? endDate : moment(event.end).add(1, "day").format('YYYY-MM-DD'),
            dateTime: null
        }
    } else {
        let startDate = moment(event.start).format()
        let endDate = moment(event.end).format()
        data.start = {
            dateTime: startDate,
            date: null
        }
        data.end = {
            dateTime: endDate,
            date: null
        }
    }

    // recurring
    if (event.recurrence) {
        data.recurrence = [event.recurrence]
        const timeZone = momentTz.tz.guess() || 'Asia/Tokyo';
        if (event.allDay) {
            data.start.timeZone = null
            data.end.timeZone = null
        } else {
            data.start.timeZone = timeZone
            data.end.timeZone = timeZone
        }
    } else {
        data.recurrence = null
    }
    // check if create new Zoom meeting then add to google event Zoom meeting data, not old
    if (event.conferenceData && event.conferenceData.join_url) {
        let zoomValue = event.conferenceData;
        data.conferenceData = {
            "entryPoints": [
                {
                    "entryPointType": "video",
                    "uri": zoomValue.join_url,
                    "label": zoomValue.join_url,
                    "meetingCode": zoomValue.id,
                    "password": zoomValue.password
                }
            ],
            "conferenceSolution": {
                "key": {
                    "type": "addOn"
                },
                "name": "Zoom Meeting",
                "iconUri": "https://lh3.googleusercontent.com/ugWKRyPiOCwjn5jfaoVgC-O80F3nhKH1dKMGsibXvGV1tc6pGXLOJk9WO7dwhw8-Xl9IwkKZEFDbeMDgnx-kf8YGJZ9uhrJMK9KP8-ISybmbgg1LK121obq2o5ML0YugbWh-JevWMu4FxxTKzM2r68bfDG_NY-BNnHSG7NcOKxo-RE7dfObk3VkycbRZk_GUK_1UUI0KitNg7HBfyqFyxIPOmds0l-h2Q1atWtDWLi29n_2-s5uw_kV4l2KeeaSGws_x8h0zsYWLDP5wdKWwYMYiQD2AFM32SHJ4wLAcAKnwoZxUSyT_lWFTP0PHJ6PwETDGNZjmwh3hD6Drn7Z3mnX662S6tkoPD92LtMDA0eNLr6lg-ypI2fhaSGKOeWFwA5eFjds7YcH-axp6cleuiEp05iyPO8uqtRDRMEqQhPaiRTcw7pY5UAVbz2yXbMLVofojrGTOhdvlYvIdDgBOSUkqCshBDV4A2TJyDXxFjpSYaRvwwWIT0JgrIxLpBhnyd3_w6m5My5QtgBJEt_S2Dq4bXwCAA7VcRiD61WmDyHfU3dBiWQUNjcH39IKT9V1fbUcUkfDPM_AGjp7pwgG3w9yDemGi1OGlRXS4pU7UwF24c2dozrmaK17iPaExn0cmIgtBnFUXReY48NI8h2MNd_QysNMWYNYbufoPD7trSu6nS39wlUDQer2V"
            },
            "conferenceId": zoomValue.id,
            "notes": "Passcode: " + zoomValue.password,
            "parameters": {
                "addOnParameters": {
                    "parameters": {
                        "realMeetingId": zoomValue.id,
                        "meetingCreatedBy": zoomValue.host_email,
                        "meetingUuid": zoomValue.uuid,
                        "meetingType": zoomValue.type
                    }
                }
            }
        }
    } else if (event.conferenceData && event.conferenceData.conferenceId) {
        data.conferenceData = event.conferenceData
    } else
        data.conferenceData = '';
    // console.log({data});
    return data
}
const getEventForSync = async (uuid) => {
    try {
        // get all events of project
        const {user} = getGlobal()
        const snapEvents = await db.collection(CALENDAR_COLLECTION)
            .doc(user.user_id)
            .collection('events')
            .where("project_uuid", "==", uuid)
            .where("isTasks", "==", false)
            .where("isDeleted", "==", false)
            .get()
        const events = snapEvents.docs.map(doc => ({...doc.data(), id: doc.id}))
        // const events = await axios.get(CAL_API + `/event/eventForSync?project=${uuid}`)
        return events
    } catch (e) {
        console.log(e)
        return null
    }
}
const updateProjectSync = async (projectId, googleCalendarId, email) => {
    const {user} = getGlobal()
    const data = await db.collection(CALENDAR_COLLECTION)
        .doc(user.user_id)
        .collection('projects')
        .doc(projectId)
        .set({
            sync_id: googleCalendarId,
            sync_email: email,
            is_sync: true
        }, {merge: true})
    return data
}
export const Sync = async uuid => {
    const {projects, googleStatus} = getGlobal()
    let idx = findIndex(projects, {'uuid': uuid})
    if (idx !== -1) {
        try {
            setGlobal({insync: true});
            setGlobal({syncProcess: 'Create Calendar '});
            const backgroundColor = projects[idx].color;
            const eventList = await getEventForSync(projects[idx].id || projects[idx].uuid);
            await ggTokenChecked();
            let request = window.gapi.client.calendar.calendars.insert({
                summary: projects[idx].name
            });
            let data = await request.then();
            if (data.status !== 200) {
                msgError(getIntlId('event.toast.createGoogleCalendar'))
                return
            }
            const calendarId = data.result.id;
            request = window.gapi.client.calendar.calendarList.patch({
                "calendarId": calendarId,
                "colorRgbFormat": true,
                backgroundColor
            });

            setGlobal({syncProcess: 'Update calendar'})

            data = await request.then()
            if (data.status !== 200) {
                msgError(getIntlId('event.toast.createGoogleCalendar'))
                return
            }
            // sync all events to google
            setGlobal({syncProcess: 'Sync events'})
            if (eventList.length === 0) {
                await updateProjectSync(projects[idx].uuid, calendarId, googleStatus.userProfile && googleStatus.userProfile.email ? googleStatus.userProfile && googleStatus.userProfile.email : null)
                setGlobal({syncProcess: 'complete...', insync: false})
                return
            }
            const batch = window.gapi.client.newBatch()
            eventList.forEach(event => {
                let data = parseResource(event);
                let request = window.gapi.client.calendar.events.insert({
                    'calendarId': calendarId,
                    'resource': data
                })
                batch.add(request)
            })
            const {status} = await batch.then()
            if (status === 200) {
                await updateProjectSync(projects[idx].uuid, calendarId, googleStatus.userProfile && googleStatus.userProfile.email ? googleStatus.userProfile && googleStatus.userProfile.email : null)
                await setGlobal({syncProcess: 'remove geniam calendar'})
                // global update status
                // UpdateSync(uuid, calendarId, googleStatus.userProfile.email, data.result)
                // GCalendarList()
                setGlobal({syncProcess: 'complete...', insync: false})
                return
            }
            setGlobal({insync: false})
            msgError(getIntlId('milestone.syncProject.fail'))
        } catch (e) {
            console.log(e);
            setGlobal({insync: false})
            msgError(getIntlId('milestone.syncProject.fail'))
        } finally {
           await GCalendarList()
        }
    }
}
//
// export const setNumOfProjects = (events, numberId) => {
//     const {numOfProjects} = getGlobal()
//     numOfProjects[numberId] = groupBy(events, "project_uuid")
//     setGlobal({numOfProjects: {...numOfProjects}})
// }
// export const getNumOfProject = async () => {
//     // const {googleStatus, googleCalendarList, view, calendarTab2, tab} = getGlobal()
//     // if (!googleStatus.is_login || googleCalendarList.length === 0)
//     //     return
//     // let start = moment().startOf("day").utc().format()
//     // let end
//     //
//     // if (view === "CustomMonth") {
//     //     end = moment(start).startOf("month").add(calendarTab2, "month").utc().format()
//     // }
//     // if (view === "CustomWeek") {
//     //     end = moment(start).startOf("week").add(calendarTab2, "week").utc().format()
//     // }
//     // if (view === "CustomDay") {
//     //     end = moment(start).startOf("day").add(calendarTab2, "day").utc().format()
//     // }
//     //
//     // try {
//     //     const batch = window.gapi.client.newBatch()
//     //     googleCalendarList.forEach(group => {
//     //         const request = window.gapi.client.calendar.events.list({
//     //             'calendarId': group.id,
//     //             "singleEvents": true,
//     //             "timeMin": start,
//     //             "timeMax": end,
//     //             "maxResults": 2500,
//     //             // "alwaysIncludeEmail":true
//     //         })
//     //         batch.add(request)
//     //     })
//     //
//     //     const data = await batch.then()
//     //     if (data.result) {
//     //         Object.keys(data.result).forEach((item) => {
//     //             let calendarItems = data.result[`${item}`].result.items
//     //             if (calendarItems.length !== 0 && calendarItems[0].organizer.email) {
//     //                 let idx = findIndex(googleCalendarList, {id: calendarItems[0].organizer.email})
//     //                 if (idx !== -1) {
//     //                     googleCalendarList[idx].numOfEvents = calendarItems.length
//     //                 }
//     //             }
//     //         })
//     //     }
//     //     setGlobal({
//     //         googleCalendarList: orderBy(googleCalendarList, ["selected", "numOfEvents"])
//     //     })
//     // } catch (e) {
//     //     console.log(e)
//     //     toast.error(e.toString())
//     // }
//
// }

export const checkedCalendar = async (calendarId, selected) => {
    try {
        await ggTokenChecked();
        const request = window.gapi.client.calendar.calendarList.update({
            "calendarId": calendarId,
            "summaryOverride": "",
            "selected": selected
        })

        const {status, result} = await request.then()
        if (status !== 200) {
            console.log(result)
            // toast.error("Update gg calendar fail")
            return
        }
        return result
    } catch (e) {
        console.log(e);
    }
}
