import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from 'antd';
import {withRouter} from "react-router-dom";
import useIntlId from "../../../hooks/useIntlId";
import {publicIcon} from "../handlers/getIcon";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: '0 auto 15px auto',
        display: "flex",
        flexDirection: "column"
    },
    mileStoneImg: {
        width: 25,
        height: 25
    },
    buttonIcon: {
        marginRight: 5,
        marginBottom: 5,
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f1f1f1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#f1f1f1",
            color: "unset"
        },
        "&:focus": {
            backgroundColor: "#f1f1f1",
            color: "unset"
        },
        "&.ant-btn[disabled]": {
            opacity: 0.5
        }
    },
    buttonIconSelected: {
        marginRight: 5,
        marginBottom: 5,
        border: `3px solid ${theme.props.primaryColor}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "none",
        "& img": {
            width: '100%',
            height: '100%',
            borderRadius: '50%'
        },
        "&:hover": {
            border: `3px solid ${theme.props.primaryColor}`,
            backgroundColor: "#f1f1f1",
            color: "unset"
        },
        "&:focus": {
            border: `3px solid ${theme.props.primaryColor}`,
            backgroundColor: "#f1f1f1",
            color: "unset"
        },
        "&.ant-btn[disabled]": {
            opacity: 0.5
        }
    },
    buttonIcon2: {
        marginLeft: 5,
        marginBottom: 5,
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        boxShadow: "none",
        "& img": {
            minWidth: 18
        }
    },
    flexRow: {
        display: "flex",
        flexDirection: "row"
    },
    iconActive: {
        color: theme.props.primaryColor
    },
    flexWrap: {
        flexWrap: "wrap"
    },
    addIconSVG: {
        padding: 0,
        width: 28,
        height: 28,
        marginTop: 2,
        border: '1px solid #F1F1F1',
        "& svg": {
            fill: theme.props.primaryColor,
        },
    },
    element: {
        paddingTop: '10px'
    },
    title: {
        fontWeight: "bold",
        fontSize: 14,
    },
    label: {
        marginBottom: 10,
        fontSize: 12,
    },
    flexDirection: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10
    },
    listTagCustom: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
}))

function Task(props) {
    // const [tasks] = useGlobal('tasks')
    // const [colors] = useGlobal('colors')
    // const [, setTab] = useGlobal('settingTab')
    const {event, setEvent} = props
    const classes = useStyles();
    const [tagFunction] = useIntlId(['event.tagFunciton'])

    // useEffect(() => {
    //     if (event && event.task === 'focus' && isCreate) {
    //         if (getVisibleFocusEvents(event.start, event.end, numberId)) {
    //             setFocusCheck(true)
    //             event.task = ""
    //             setEvent({...event})
    //         } else {
    //             setFocusCheck(false)
    //         }
    //     }
    // }, [event])

    // const onOpenSetting = React.useCallback(() => {
    //     setGlobal({
    //         openSetting: true,
    //         settingTab: 0,
    //         openAddTask: true
    //     })
    // }, []);
    //
    // const onChoose = (item) => {
    //     if (item?.type !== "file")
    //         return setEvent({
    //             ...event,
    //             task: item.icon
    //         })
    //     return setEvent({
    //         ...event,
    //         task: item?.file?.id || ""
    //     })
    // }

    const handleFocusTaskMilestone = (value) => {
        let task
        if (value === 'milestone')
            task = event?.task === "mileStone" ? '' : "mileStone"
        if (value === 'focus')
            task = event?.task === "focus" ? '' : "focus"

        setEvent({
            ...event,
            task: task
        })
    }

    if (!event)
        return null

    return (
        <div className={classes.root}>
            {/*<div>*/}
            {/*<div className={classes.title}>{tagEvent}</div>*/}
            {/*<div className={classes.flexDirection}>*/}
            {/*<div className={classes.listTagCustom}>*/}
            {/*<TaskCustom*/}
            {/*    items={tasks.data}*/}
            {/*    callBack={(item) => onChoose(item)}*/}
            {/*    event={event}*/}
            {/*    disabled={event?.is_holiday || event?.isMileStone}*/}
            {/*    color={colors?.primary}*/}
            {/*    isGetPopup={true}*/}
            {/*>*/}
            {/*    {*/}
            {/*        !event?.isMileStone &&*/}
            {/*        <IconButton*/}
            {/*            className={classes.addIconSVG}*/}
            {/*            onClick={onOpenSetting}*/}
            {/*        >*/}
            {/*            <AddLargeSVG/>*/}
            {/*        </IconButton>*/}
            {/*    }*/}
            {/*</TaskCustom>*/}
            {/*<div className={classes.flexRow}>*/}
            {/*<Button*/}
            {/*    className={classes.buttonIcon2}*/}
            {/*    onClick={() => {*/}
            {/*        if (event?.isMileStone)*/}
            {/*            return*/}
            {/*        setEvent({*/}
            {/*            ...event,*/}
            {/*            task: null*/}
            {/*        })*/}
            {/*    }*/}
            {/*    }>*/}
            {/*    <img src={process.env.PUBLIC_URL + 'icons/backspace.svg'} alt=""/>*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*    className={classes.buttonIcon2}*/}
            {/*    onClick={() => {*/}
            {/*        setTab(0)*/}
            {/*        setGlobal({openSetting: true})*/}
            {/*        if (onClose)*/}
            {/*            onClose()*/}
            {/*    }}>*/}
            {/*    <img src={process.env.PUBLIC_URL + 'icons/setting.svg'} alt=""/>*/}
            {/*</Button>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            <div className={classes.label}>{tagFunction}</div>
            <div className={classes.listTagCustom}>
                <div className={'clf-flex-center'}>
                    <Button
                        // disabled={focusCheck}
                        className={`${event?.task === 'focus' ? classes.buttonIconSelected : classes.buttonIcon}`}
                        onClick={() => handleFocusTaskMilestone('focus')}
                        shape="circle"
                        icon={<img src={publicIcon('focus.svg')} alt=""/>}>
                    </Button>
                    <Button
                        className={`${event?.task === 'mileStone' ? classes.buttonIconSelected : classes.buttonIcon}`}
                        onClick={() => handleFocusTaskMilestone('milestone')}
                        shape="circle"
                        icon={<img src={publicIcon('iconMilestone.svg')} alt=""/>}>
                    </Button>
                </div>
                <div className={classes.flexRow}>
                    <Button
                        className={classes.buttonIcon2}
                        onClick={() => {
                            setEvent({
                                ...event,
                                task: null
                            })
                        }
                        }>
                        <img src={publicIcon('backspace.svg')} alt=""/>
                    </Button>

                </div>
            </div>
        </div>
    )
}

export default withRouter(Task)
