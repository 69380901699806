import React, {useEffect, useState} from 'react';
import store from "store";
import {setGlobal, useGlobal} from 'reactn';
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";
import {useHistory} from "react-router-dom";
import {filter, findIndex, mapKeys, orderBy} from "lodash";
import styled from 'styled-components';
import MenuAppBar from "../MenuAppBar/MenuAppBar";
import LeftHomeApp from "../Application/LeftHomeApp";
import useIntlId from "../../hooks/useIntlId";


const StyledSpan = styled.button(props => ({
    backgroundColor: props.item?.backgroundColor ? `${props.item?.backgroundColor}` : "#3788d8",
    width: 15,
    height: 15,
    marginRight: 15,
    borderRadius: '50%',
    border: 'none'
}))


function Index(props) {
    const classes = useStyles(props);
    const [numOfLine, setNumOfLine] = useState(store.get('numOfLine') ? store.get('numOfLine') : 2)
    const [, setCalendarHeight] = useState((window.innerHeight - 140) / (numOfLine))
    const [listSearch] = useGlobal("listSearch")
    // const [refetchDateNow, setRefetchDateNow] = useGlobal("refetchDateNow")
    const history = useHistory()
    const [projects] = useGlobal("projects")
    const [geEvents,] = useGlobal("geEvents")
    const [ggEvents,] = useGlobal("ggEvents")
    const [timeSearch] = useGlobal("timeSearch")
    const [checkTimeSearch] = useGlobal("checkTimeSearch")
    const projectSearchHidden = filter(projects, p => p.is_deleted || p.is_sync)
    const keySearch = window.location.search.slice(11)
    const [notFound] = useIntlId(['search.notFound'])
    const getListSearch = () => {

        if (keySearch !== "" && listSearch.length === 0) {
            let listEvent = []
            let search = []
            mapKeys(geEvents, function (value, key) {
                if (findIndex(projectSearchHidden, p => p.id === value.id) === -1)
                    listEvent.push(value);
            })
            mapKeys(ggEvents, function (value, key) {
                listEvent.push(value);
            })
            listEvent.forEach(event => {
                event.events.forEach(ev => {
                    if (ev.allDay) {
                        if (checkTimeSearch) {
                            if (ev.title.toLowerCase().includes(keySearch)) {
                                search.push(ev)
                            }
                        } else {
                            if (ev?.title?.toLowerCase().includes(keySearch) && moment(timeSearch.start).isBefore(ev.start) && moment(ev.end).isBefore(timeSearch.end)) {
                                search.push(ev)
                            }
                        }
                    }
                })
            })
            setGlobal({
                listSearch: (orderBy(search, (a) => moment(a.start).utc().format(), 'desc'))
            })
        }
    }

    useEffect(() => {
        getListSearch()
        // eslint-disable-next-line
    }, [geEvents, ggEvents])

    const {year, month, date} = moment.locale() === 'en-gb' ?
        {year: ' -', month: " -", date: " -"}
        : moment.locale() === 'vi' ?
            {year: ' -', month: " -", date: " -"}
            :
            {year: '年', month: "月", date: "日"}


    return (
        <div>
            <MenuAppBar
                numOfLine={numOfLine}
                setNumOfLine={setNumOfLine}
                setCalendarHeight={setCalendarHeight}
            />

            <div className={`${classes.root} clearfix`}>
                <div className={classes.left}>
                    <LeftHomeApp/>
                </div>
                <div className={classes.right}>
                    {
                        listSearch.length !== 0 ?
                            <div>
                                {
                                    listSearch.map((item, index) => {
                                        return (
                                            <div key={index} className={classes.itemSearch}
                                                 onClick={() => {
                                                     history.push('/')
                                                     // setRefetchDateNow(item.start)
                                                     setGlobal({
                                                         editDialog: true
                                                     })
                                                     let idEvent = `event-0-${item.id}`

                                                     function click() {
                                                         if (document.getElementById(idEvent)) {
                                                             document.getElementById(idEvent).click()
                                                         }
                                                     }

                                                     setTimeout(click, 2000)
                                                 }}
                                            >
                                                <div className={classes.itemDay}>{moment(item?.start).date()}</div>
                                                <div className={classes.flexBox}>
                                                    {
                                                        moment(item?.start).date() === moment(item?.end).date() ?
                                                            <div className={classes.itemTime}>
                                                                {moment(item?.start).format("HH:mm")} ~ {moment(item?.end).format("HH:mm")} &nbsp;
                                                                {moment(item?.start).format(`YYYY${year} M${month} DD${date} ddd`)}
                                                            </div>
                                                            :
                                                            <div className={classes.itemTime}>
                                                                {moment(item?.start).format("HH:mm")} &nbsp; {moment(item?.start).format(`YYYY${year} M${month} DD${date} ddd`)}
                                                                &nbsp; ~&nbsp;
                                                                {moment(item?.end).format("HH:mm")} &nbsp; {moment(item?.end).format(`YYYY${year} M${month} DD${date} ddd`)}

                                                            </div>
                                                    }
                                                    <div className={classes.itemTitle}>
                                                        <StyledSpan item={item}/>{item.title}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                }
                            </div>
                            :
                            <div>{notFound}</div>
                    }


                </div>
            </div>

        </div>
    );
}

export default Index;
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 64
    },
    left: {
        padding: '10px 0px 0px 20px',
        display: 'block',
        boxSizing: 'border-box',
        width: '250px',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
            display: "none",
        }
    },
    right: {
        display: 'block',
        boxSizing: 'border-box',
        float: `right`,
        width: 'calc(100% - 250px)',
        margin: 'auto',
        marginTop: 25,
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    itemSearch: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "0 40px",
        padding: "8px 5px",
        borderBottom: "solid 1px #dadce0",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#dadce0"
        },
        [theme.breakpoints.down('sm')]: {
            margin: "0 15px",
        },
    },
    itemDay: {
        fontSize: 21,
        fontWeight: "bold",
        width: 60
    },
    itemTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10,
        },
    },
    itemTime: {
        minWidth: 410,
        textAlign: "left",
        marginLeft: 10,
        [theme.breakpoints.down('sm')]: {
            minWidth: "unset",
            marginBottom: 8
        },
    },
    flexBox: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "column",
        },
    }
}))
