import {CLFButtonSVG} from 'react-antd-button-svg-icons';
import React from 'react';
import useIntlId from '../../hooks/useIntlId';
import {ReactComponent as SaveSVG} from '../../asSvg/copy.svg';
import commonStyles from './commonStyles';

export default function SaveButton({onClick, ...others}) {
    const classes = commonStyles()
    const [saveIntl] = useIntlId(['dialog.action.save'])
    return (
        <CLFButtonSVG
            minHeight={"31px"}
            className={classes.primary}
            name={saveIntl}
            iconComponent={<SaveSVG/>}
            onClick={onClick}
            {...others}
        />
    )
}
