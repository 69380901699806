import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

function DialogCustom({open, onClose, title, actions, children, ...props}) {
    const classes = useStyles()
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                className={classes.dialog}
                {...props}
                disableEnforceFocus={true}
            >
                <IconButton disableRipple={true} className={classes.closeIcon} onClick={onClose}>
                    <img src={process.env.PUBLIC_URL + 'icons/close.svg'} alt=""/>
                </IconButton>
                {
                    title && <DialogTitle>{title}</DialogTitle>
                }
                <DialogContent className={classes.content}>
                    {children}
                </DialogContent>
                {
                    actions && <DialogActions>
                        {actions}
                    </DialogActions>
                }
            </Dialog>
        </div>
    );
}

export default DialogCustom;
const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            overflow: "unset",
        },
        '& .MuiDialogActions-root': {
            justifyContent: 'center'
        }
    },
    root: {
        padding: '20px',
        height: 'auto',
        overflow: 'auto',
        position: "relative",
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            minWidth: "auto",
        },
    },
    closeIcon: {
        position: "absolute",
        top: -15,
        right: -15,
        backgroundColor: "#fff",
        cursor: "pointer",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        zIndex: 99,
        "&:hover": {
            backgroundColor: "#fff",
        },
        "&:focus": {
            backgroundColor: "#fff",
        }
    },
    content: {
        padding: 0
    }
}))
