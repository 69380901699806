import React, {useEffect, useReducer, useState} from 'react';
import {Col, Input, Row, Spin, Typography} from 'antd';
import ButtonSelectColor from "./ButtonSelectColor";
import IconIdentity from "../../Calendar/google/identityIcon";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import {useGlobal} from "reactn";
import {map, maxBy, remove, xorBy} from 'lodash'
import {CALENDAR_COLLECTION} from "../../../config/constants";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import {ReactComponent as CloseSVG} from "../../../asSvg/close.svg";
import {ReactComponent as SaveSVG} from "../../../asSvg/copy.svg";
import TabsCustom from "../../Custom/TabsCustom";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {db} from "../../../firebaseConfig";
import useIntlId from "../../../hooks/useIntlId";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const reducerIdentity = (state, action) => {
    switch (action.type) {
        case 'upDated': {
            return {
                ...state,
                id: action.data.id,
                color: action.data.color,
                name: action.data.name,
                isArchive: action.data.isArchive,
                isDeleted: action.data.isDeleted,
                isGoogle: action.data.isGoogle,
                err: action.data.err
            };
        }
        case 'color': {
            return {...state, color: action.color};
        }
        case 'name': {
            return {...state, name: action.name};
        }
        case 'isArchive': {
            return {...state, isArchive: action.isArchive};
        }
        case 'isDeleted': {
            return {...state, isDeleted: action.isDeleted};
        }
        case 'isGoogle': {
            return {...state, isGoogle: action.isGoogle};
        }
        case 'err': {
            return {...state, err: action.err};
        }
        case 'GoogleIdsList': {
            return {...state, GoogleIdsList: action.GoogleIdsList};
        }
        case 'reset': {
            return {
                id: null,
                color: '#000000',
                name: '',
                isArchive: false,
                isDeleted: false,
                isGoogle: true,
                err: '',
                GoogleIdsList: []
            };
        }
        default:
    }
};

const useStyles = makeStyles(theme => ({
    dialogAntd: {
        textAlign: 'center',
        "& .MuiPaper-rounded": {
            borderRadius: 24
        },
    },
    titleDialog: {
        background: "#F1F1F1",
        borderRadius: 24,
        maxWidth: 181,
        minHeight: 35,
        fontWeight: 'bold',
        color: theme.props.primaryColor,
        fontSize: "1.2rem",
        margin: 'auto'
    },
    addInfo: {
        background: "#F1F1F1",
        borderRadius: 24,
        margin: '20px 3px 3px 3px !important',
        width: '100%',
    },
    icon: {
        margin: 'auto',
        paddingLeft: 'unset !important',
        paddingRight: 'unset !important',
        marginTop: '5px'
    },
    textError: {
        margin: "auto",
        paddingBottom: 5
    },
    actions: {
        margin: 'auto',
        padding: '8px 8px 16px 8px'
    },
    tabs: {
        width: '100%',
        background: '#F1F1F1'
    },
    tab: {
        width: '50%'
    },
    inputText: {
        border: 'none',
        borderRadius: 24
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
        },
        "& .anticon svg": {
            fill: "#FFF"
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor
        }
    }
}));

function DialogAddIdentity({open, setOpen, classes}) {
    const clx = useStyles()
    const [loading, setLoading] = useState(false);
    const [user] = useGlobal('user')
    const [calendarUser, setCalendarUser] = useGlobal("calendarUser")
    const [GoogleColor] = useGlobal("GoogleColor")
    const {Text} = Typography;
    const [state, dispatch] = useReducer(reducerIdentity, {
        id: null,
        color: '#000000',
        name: '',
        isArchive: false,
        isDeleted: false,
        isGoogle: true,
        err: '',
        GoogleIdsList: []
    });

    const [addIdentities, ggIdentities, geniamIdentities, cancel, save, maxColor] = useIntlId([
        'title.add.identity',
        'app.profile.googleidentities',
        'app.profile.geniamidentities',
        'settings.archiveDialogCancel',
        'dialog.action.done',
        'app.identities',
    ])

    useEffect(() => {
        if (open)
            getColorGooglesList()
        // eslint-disable-next-line
    }, [state.isGoogle, open])

    useEffect(() => {
        // check google identities full slot to disable button
        if (getFreeGoogleIds()) {
            dispatch({type: 'isGoogle', isGoogle: false})
        }
        // eslint-disable-next-line
    }, [calendarUser?.identitySettings, open])

    const getColorGooglesList = () => {
        if (state.isGoogle) {
            const {identitySettings = []} = calendarUser
            let identityData = [...identitySettings]
            remove(identityData, {isDeleted: true})
            remove(identityData, identity => identity.id > 11)
            const googleIdList = Array.from(GoogleColor, (a, i) => ({'color': a.toUpperCase()}))
            let newGoogleColor = map(xorBy(googleIdList, identityData, 'color'), arr => arr.color.toUpperCase())
            dispatch({type: 'GoogleIdsList', GoogleIdsList: newGoogleColor})
            dispatch({type: 'color', color: newGoogleColor[0] && newGoogleColor[0].toUpperCase()})
        } else {
            dispatch({type: 'color', color: '#000000'})
        }
    }

    const getFreeGoogleIds = () => {
        const {identitySettings = []} = calendarUser
        let googleIds = Array.from({length: 11}, (_, i) => i + 1)

        if (!identitySettings?.length)
            return true

        identitySettings.forEach(identity => {
            if (!identity.isDeleted && identity.id <= 11) {
                remove(googleIds, i => i === identity.id)
            }
        })

        return googleIds.length === 0
    }

    const handleOk = async () => {
        let {identitySettings = []} = calendarUser
        let identityData = [...identitySettings]
        setLoading(true)
        try {
            if (state.isGoogle) {
                remove(identityData, {isDeleted: true})
                remove(identityData, identity => identity.id > 11)
                const googleIdList = Array.from(GoogleColor, (a, i) => ({'id': i + 1, 'color': a.toUpperCase()}))
                let newArr = xorBy(googleIdList, identityData, 'id')
                if (newArr.length !== 0) {
                    identitySettings.push({
                        id: newArr[0].id,
                        color: newArr[0].color,
                        name: state.name,
                        isArchive: state.isArchive,
                        isDeleted: state.isDeleted,
                    })
                }
            } else {

                if (!identitySettings?.length) {
                    identitySettings = GoogleColor.map((color, index) => ({
                        id: index + 1,
                        color: color,
                        name: "",
                        isArchive: false,
                        isDeleted: false
                    }))
                }
                const maxIdentity = (Number(maxBy(identitySettings, function (identity) {
                    return identity?.id
                })?.id))

                const identityId = (maxIdentity < 11 ? 11 : maxIdentity) + 1

                identitySettings.push({
                    id: identityId,
                    color: state.color,
                    name: state.name,
                    isArchive: state.isArchive,
                    isDeleted: state.isDeleted
                })
            }
            setCalendarUser({
                ...calendarUser,
                identitySettings
            })
            const userRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}`)
            await userRef.set({
                identitySettings: identitySettings
            }, {merge: true})
        } catch (e) {
            console.log(e);
            toast.error('Add error')
        } finally {
            setLoading(false)
            setOpen(false)
            dispatch({type: 'reset'})
        }
    };

    const handleCancel = () => {
        dispatch({type: 'reset'})
        setOpen(false)
    };

    const handleChange = (event) => {
        dispatch({type: 'name', name: event.target.value})
    };

    const onChangeIdentity = (name) => {
        if (name && getFreeGoogleIds()) {
            setTimeout(() => {
                dispatch({type: 'err', err: ''})
            }, 2000)
            return dispatch({type: 'err', err: maxColor})
        }
        dispatch({type: 'isGoogle', isGoogle: name})
    }


    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            onClose={handleCancel}
            maxWidth={'xs'}
            fullWidth
            className={clx.dialogAntd}
        >
            <div>
                <Spin tip={'Loading...'} spinning={loading}>
                    <DialogTitle>
                        <div className={`${clx.titleDialog} clf-flex-center`}>{addIdentities}</div>
                    </DialogTitle>
                    <DialogContent>
                        {
                            state.err && <Text type="danger" className={classes.textError}>{state.err}</Text>
                        }
                        <TabsCustom value={Boolean(state.isGoogle)}
                                    onChange={(event, newValue) => onChangeIdentity(newValue)}
                                    className={clx.tabs}
                        >
                            <Tab value={true} label={ggIdentities} className={clx.tab}/>
                            <Tab value={false} label={geniamIdentities} className={clx.tab}/>
                        </TabsCustom>
                        <Row gutter={[16, 16]} align={'middle'} className={clx.addInfo}>
                            <Col span={1} className={clx.icon}>
                                <IconIdentity color={state.color}/>
                            </Col>
                            <Col span={14}>
                                <Input
                                    className={clx.inputText}
                                    placeholder="Identity name"
                                    value={state.name}
                                    onChange={handleChange}
                                    autoFocus
                                />
                            </Col>
                            <Col span={7}>
                                <ButtonSelectColor state={state} dispatch={dispatch}/>
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions className={clx.actions}>
                        <CLFButtonSVG
                            onClick={handleCancel}
                            name={cancel}
                            margin={'0px 20px 0px 0px'}
                            iconComponent={<CloseSVG/>}
                            size={'default'}
                            className={clx.secondary}
                        />
                        <CLFButtonSVG
                            iconComponent={<SaveSVG/>}
                            size={'default'}
                            onClick={handleOk}
                            name={save}
                            // loading={loading}
                            disable={loading}
                            margin={'0px 20px 0px 0px'}
                            className={clx.primary}
                        />
                    </DialogActions>
                </Spin>
            </div>
        </Dialog>
    );
}

export default DialogAddIdentity;
