import React from 'react'
import {useGlobal} from 'reactn'
import LoginTab from "./LoginTab";
import GoogleList from "./googleList";


function SyncSetting(props) {
    const [googleStatus] = useGlobal('googleStatus')

    return (
        <div>
            {googleStatus.is_login ?
                <GoogleList/>
                :
                <LoginTab/>
            }
        </div>
    )
}

export default SyncSetting
