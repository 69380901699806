import {db} from '../firebaseConfig'

export default async (email) => {
    if (!email)
        return
    const docPath = (email) => {
        return db.doc(`googleCalendarWatch/${email}`)
    }
    try {
        await docPath(email).set({updatedAt: Date.now()}, {merge: true})
    } catch (e) {

    }

}
