import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import '../index.css';
import '../global.css';
import 'antd/dist/antd.css';
import "@Andom-Geniam/git-globalnavi/dist/index.css"
import {LinearProgress} from '@material-ui/core';
import {isEmpty} from 'lodash'

export const ThemeProviderContext = ({children}) => {
    const theme = useThemeContext();
    if (isEmpty(theme))
        return <LinearProgress/>;

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export const useThemeContext = () => {
    const [colors] = useGlobal('colors');
    const [theme, setTheme] = useState(null)

    useEffect(() => {
        if (!colors) return;

        setTheme(createTheme({
            props: {
                primaryColor: colors?.primary,
                secondaryColor: "#690003",
                // or add more props to handle theme
                MuiButtonBase: {
                    // The properties to apply
                    // disableRipple: true, // No more ripple, on the whole application 💣!
                },
            },
            transitions: {
                // So we have `transition: none;` everywhere
                create: () => 'none',
            }
        }))
    }, [colors]);

    return theme
}

// export const handleRevertColors = () => {
//     const {calendarUser, colors} = getGlobal();
//     let oldColors = calendarUser.colors || {};
//     if (oldColors?.primary === colors?.primary)
//         return null;
//     if (oldColors?.primary) {
//         setGlobal({
//             colors: oldColors
//         })
//     } else {
//         setGlobal({
//             colors: {primary: '#1790FF', secondary: '#0029FF'}
//         })
//     }
// }
