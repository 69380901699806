import React from 'react';

// import {makeStyles} from '@material-ui/core';

function AddAppDialog({open, setOpen}) {
    // const [loading, setLoading] = useState(false)
    // const toggleApp = id => {
    //     let idx = selected.indexOf(id)
    //     if (idx !== -1) {
    //         selected.splice(idx, 1)
    //     } else {
    //         selected.push(id)
    //     }
    //     setSelected([...selected])
    // }
    // const onAdd = async () => {
    //     if (!selected?.length)
    //         return;
    //     try {
    //         setLoading(true)
    //         const newApps = globalApps.filter(app => selected.includes(app.id))
    //         const ref = getNaviCollRef(user.user_id)
    //         await ref.set({
    //             data: uniqBy([...list, ...newApps], 'id')
    //         }, {merge: true})
    //         msgSuccess('successfully')
    //         setOpen(false)
    //     } catch (e) {
    //         console.log(e);
    //         msgError(e.toString())
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    // add array collection

    // const addUrls = async () => {
    //     if (!user?.user_id)
    //         return;
    //
    //     const Array = []
    //     const batch = db.batch()
    //     Array.forEach(arr => {
    //         const ref = getURLCollRef(user.user_id).doc(arr.id)
    //         batch.set(ref, arr, {merge: true})
    //     })
    // }

    //     // await batch.commit()
    //     // await Promise.all(Array.map(async arr => {
    //     //
    //     //     arr.test = []
    //     //         const ref = getURLCollRef(user.user_id).doc(arr.id)
    //     //         await ref.set(arr, {merge: true})
    //     // }))
    // }

    if (!open)
        return null
    return (
        <div>
            {/*<Dialog open={open}*/}
            {/*        onClose={() => setOpen(false)}*/}
            {/*        maxWidth={"xs"}*/}
            {/*        fullWidth={true}*/}
            {/*>*/}
            {/*    <DialogTitle>*/}
            {/*        カレンビューにアプリリンクを追加*/}
            {/*    </DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <div>*/}
            {/*            {*/}
            {/*                globalApps.map((item) => {*/}
            {/*                    // this app*/}
            {/*                    if (item.id === APP_GLOBAL_ID)*/}
            {/*                        return null*/}
            {/*                    // added app*/}
            {/*                    if (list.find(s => s.id === item.id))*/}
            {/*                        return null*/}
            {/*                    return (*/}
            {/*                        <div>*/}
            {/*                            <FormControlLabel*/}
            {/*                                control={*/}
            {/*                                    <Checkbox*/}
            {/*                                        checked={selected.includes(item.id)}*/}
            {/*                                        onClick={() => toggleApp(item.id)}*/}
            {/*                                    />*/}
            {/*                                } label={item.name}/>*/}
            {/*                        </div>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button*/}
            {/*            onClick={() => setOpen(false)}*/}
            {/*            disabled={loading}*/}
            {/*        >Cancel</Button>*/}
            {/*        <Button*/}
            {/*            disabled={loading || !selected.length}*/}
            {/*            onClick={onAdd}*/}
            {/*        >Ok</Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
        </div>
    );
}

//
// const useStyles = makeStyles(theme => ({
//     root: {}
// }))

export default AddAppDialog;
