// import './firstRun/wdyr'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axiosConfig from './config/axios'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter} from "react-router-dom";
import GoogleAuth from "./components/Calendar/google/auth";
import './setGlobal'
import './firebaseConfig'
import {CalendarUserProvider} from "./context/CalendarUserContext";
import {LanguageMessageProvider} from "./context/LanguageMessageContext";
import ErrorBoundary from "./components/ErrorBounDary";
import {ThemeProviderContext} from "./context/ThemeContext";
import {AnnouncementProvider} from "./context/AnnouncementContext";
import {StripeProvider} from 'react-stripe-elements';
import {STRIPE_PUBLISH_KEY} from './config/constants';

axiosConfig()
GoogleAuth()

ReactDOM.render((
        <>
            <ThemeProviderContext>
                <BrowserRouter>
                    <StripeProvider apiKey={STRIPE_PUBLISH_KEY}>
                        <ErrorBoundary>
                            <CalendarUserProvider>
                                <LanguageMessageProvider>
                                    <AnnouncementProvider>
                                        <App/>
                                    </AnnouncementProvider>
                                </LanguageMessageProvider>
                            </CalendarUserProvider>
                        </ErrorBoundary>
                    </StripeProvider>
                </BrowserRouter>

                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    autoClose={2000}
                    position="bottom-center"
                />
            </ThemeProviderContext>
        </>
    ),
    document.getElementById('root')
)
;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();


reportWebVitals()
