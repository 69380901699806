import {List, ListItem, ListItemText} from '@material-ui/core';
import {Row} from 'antd';
import {useGlobal} from 'reactn';
import {cloneDeep, findIndex} from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as ListAllImage} from '../../../asSvg/icons/checkDay.svg';
import React, {useEffect, useState} from 'react';
import useIntlId from '../../../hooks/useIntlId';

const useStyles = makeStyles(theme => ({
    root: {
        maxHeight: 200,
        overflow: 'auto',
        position: "relative",
        background: "#fff",
        borderRadius: 10,
        padding: 10,
        margin: 10,
        marginBottom: 20
    },
    divTitle: {
        color: '#fff',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '80%',
        whiteSpace: 'nowrap'
    },
    divCheck: {
        width: 20,
        height: 20,
        backgroundColor: '#696969',
        borderRadius: 5
    },
    divProject: {
        margin: 10,
        paddingLeft: 5,
        borderRadius: 6,
        width: '22%',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 5,
        cursor: 'pointer'
    },

    divSl: {
        display: 'flex',
        width: 110,
        justifyContent: 'space-between',
        marginLeft: '43%',
        cursor: 'pointer'
    }
}))

function ListAllProject(props) {
    const {projectsSelect, setProjectSelect} = props
    const classes = useStyles();
    const [syncProcess] = useGlobal('syncProcess')
    const [inSync] = useGlobal('insync')
    const [allMilestoneProject] = useGlobal('allMilestoneProject');
    const [isSelect, setIsSelect] = useState(false);
    const [projects, setProjects] = useState(allMilestoneProject)

    const [selectAll] = useIntlId([
        'milestone.viewSetting.selectAll',
    ])

    useEffect(() => {
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].msSelected === false) {
                return;
            }
        }
        setIsSelect(true)
        // eslint-disable-next-line
    }, [isSelect])

    useEffect(() => {
        let list = cloneDeep(projects) || []
        list.forEach(pro => {
            const idx = findIndex(projectsSelect, {id: pro.id})
            if (idx !== -1) {
                pro.msSelected = projectsSelect[idx].msSelected
            } else {
                pro.msSelected = false;
            }
        })
        list.sort((x, y) => y.msSelected - x.msSelected);
        setProjects([...list])
        // eslint-disable-next-line
    }, [allMilestoneProject, projectsSelect])

    const selectAllProject = () => {
        setIsSelect(!isSelect)
        let newList = cloneDeep(projects)

        if (!isSelect) {
            newList.forEach((val) => {
                val.msSelected = true
            })
            setProjectSelect(newList)
        } else {
            newList.forEach((val) => {
                val.msSelected = false
            })
            setProjectSelect([])
        }
        setProjects(newList)
    }

    const handleSelectProject = (project) => {

        let list = cloneDeep(projectsSelect);

        let index = findIndex(projects, {id: project.id})

        let idx = findIndex(list, {id: project.id})

        if (idx > -1) {
            list.splice(idx, 1)
        } else {
            list.push(project)
        }

        if (index !== -1) {
            projects[index].msSelected = !projects[index].msSelected
        }

        setProjectSelect(list)
        setProjects(projects)

    }

    return (
        <div className={classes.root}>
            <div
                className={classes.divSl}
                onClick={selectAllProject}
            >
                <div style={{color: '#1790FF'}}>{selectAll}</div>
                <div
                    className={classes.divCheck}
                    style={{backgroundColor: isSelect ? '#1790FF' : '#C0C0C0'}}
                >
                    {
                        isSelect &&
                        <ListAllImage/>
                    }
                </div>
            </div>
            <List component="div" disablePadding>
                <Row>
                    {
                        projects.length > 0 &&
                        projects.map((pro, index) => {
                            if (pro.id && !pro.is_sync)
                                return (
                                    <div key={pro.id + index} className={classes.divProject}
                                         id={pro.id}
                                         style={{
                                             backgroundColor: pro.backgroundColor || pro.color,
                                             '&:hover': {
                                                 backgroundColor: pro.backgroundColor,
                                             },
                                         }}
                                         onClick={() => handleSelectProject(pro)}
                                    >
                                        <div className={classes.divTitle}>{pro.summary || pro.name}</div>
                                        <div className={classes.divCheck}
                                        >
                                            {
                                                pro.msSelected &&
                                                <ListAllImage/>
                                            }
                                        </div>
                                    </div>
                                )
                            return null
                        })}
                    {inSync &&
                        <ListItem>
                            <ListItemText primary={syncProcess}/>
                        </ListItem>
                    }
                </Row>
            </List>
        </div>
    )
}

export default ListAllProject
