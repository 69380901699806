import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import {Cached, Delete, Unarchive} from "@material-ui/icons";
import {GCalendarList, Sync} from "../../actions/googleCalendar";
import {useGlobal} from "reactn";
import {concat, findIndex, orderBy, remove} from "lodash";
import {withRouter} from 'react-router-dom'
import {onLogOut} from '../../common/googleCal/logOutGoogle'
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";
import {Modal} from "antd";
import {CheckCircleFilled} from "@ant-design/icons";
import MenuItemContainer from "./MenuItemContainer";
import {CLFButtonSVG} from "react-antd-button-svg-icons";
import {ItemCheckedCustom} from "item-checked-custom";
import {db} from "../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../config/constants";
import {milestoneSettingDocRef} from "../../actions/group";
import useIntlId, {getIntlId} from "../../hooks/useIntlId";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '40px 0px'
    },
    list: {
        maxWidth: 360
    },
    archiveIcon: {
        fontSize: 20
    },
    title: {
        fontSize: "1.3rem",
        color: '#000',
        textAlign: 'center',
        marginBottom: 10
    },
    titleItem: {
        '& span': {
            fontSize: "1.2rem !important",
            fontWeight: 700
        }
    },
    moveText: {
        "&:hover": {
            cursor: "move"
        }
    },
    email: {
        color: theme.props.primaryColor,
        fontSize: '1.3rem',
        fontWeight: 'bold',
        marginLeft: 10
    },
    userEmail: {
        background: '#F1F1F1',
        borderRadius: 25,
        fontWeight: 700,
        textTransform: 'lowercase',
        fontSize: '1.3rem',
        color: '#000',
        width: '350px',
        height: '35px'
    },
    gridRoot: {
        width: '100%',
        padding: 10
    },
    gridItem: {
        background: '#FFF',
        borderRadius: 15,
        padding: '10px 0',
        margin: '0 10px',
        height: '100%'
    },
    box: {
        position: "relative",
        borderRadius: "5px",
        width: 20,
        height: 20,
        marginRight: 7,
        "& img": {
            position: "absolute",
            left: "50%",
            right: 0,
            bottom: 0,
            top: "50%",
            maxWidth: "100%",
            transform: "translate(-50%,-50%)",
        }
    },
    listItem: {
        display: 'block',
        padding: 15
    },
    reloadIcon: {
        color: theme.props.primaryColor,
        fontSize: '1.8rem',
    },
    listItemIcon: {
        justifyContent: 'flex-end'
    },
    primary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`,
        "& svg": {
            fill: theme.props.primaryColor,
        }
    },
    secondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`,
        "& svg": {
            fill: theme.props.secondaryColor,
        }
    },
}));
//
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

function GoogleList(props) {
    const [googleStatus] = useGlobal('googleStatus')
    const [projects, setProjects] = useGlobal('projects')
    const [projectsSyncWithGoogle] = useGlobal('projectsSyncWithGoogle')
    const [syncProcess] = useGlobal('syncProcess')
    const [, setSyncLoad] = useGlobal('syncLoad')
    const [ggAllProject, setGGAllProject] = useGlobal('ggAllProject');
    const [insync] = useGlobal('insync')
    const [sync_uuid, setSync_uuid] = useGlobal('sync_uuid')
    const [archive, setArchive] = useState([])
    const [loading, setLoading] = useState(false)
    const [unArchive, setUnArchive] = useState(null)
    const [deleting, setDeleting] = useState(null)
    const [openLogOut, setOpenLogOut] = useState(false)
    const classes = useStyles()
    const [user] = useGlobal('user')
    const [mySetting] = useGlobal('mySetting')
    const [projectList] = useGlobal('projectList')
    const [groupsMs] = useGlobal('groupsMs')

    const [email, deleteSuccess] = useIntlId([
        'settings.email',
        'toast.deleteUser'
    ])

    useEffect(() => {
        if (projectsSyncWithGoogle?.length) {
            setSyncLoad(false)
        }

        // eslint-disable-next-line
    }, [projectsSyncWithGoogle])


    const updateProject = async (source) => {
        let newProjectList = [...projectList];
        const idx = findIndex(newProjectList, {id: source.id})
        if (idx > -1) {
            newProjectList.splice(idx, 1)
        } else {
            newProjectList.push(source)
        }

        try {
            await milestoneSettingDocRef(`${mySetting.id}`)
                .set({listProjectSetting: newProjectList}, {merge: true})
        } catch (e) {
            console.log(e);
        }
    }

    const toggleCalendar = async (source) => {

        const newList = concat(ggAllProject, groupsMs)

        const msFilter = newList.filter(pro => pro.msSelected)

        if(msFilter.length === 1 && source.id === msFilter[0].id) {
            toast.warning(getIntlId('milestone.viewSetting.noticeProject'))
            return;
        }

        let projectUpdate = {...source}
        let index = findIndex(ggAllProject, g => g.id === projectUpdate.id);
        let checked = !Boolean(projectUpdate.msSelected)

        if (index !== -1) {
            ggAllProject[index].msSelected = checked
            setGGAllProject([...ggAllProject])
            updateProject(source)
        }
    }

    // const onSave = () => {
    //     setOpenSetting(false)
    // }

    useEffect(() => {
        if (!googleStatus.is_login || ggAllProject.length === 0)
            return
        let nextArchive = []
        projects.forEach((pro) => {
            if (pro.is_sync && pro.sync_id && pro.sync_email === googleStatus?.userProfile?.email) {
                let idx = findIndex(ggAllProject, {id: pro.sync_id})
                if (idx === -1) {
                    nextArchive.push(pro)
                }
            }
        })
        setArchive(nextArchive)
        // eslint-disable-next-line
    }, [projects, ggAllProject])

    const onUnArchive = async () => {

        if (!unArchive || !unArchive.sync_id) {
            toast.error('error load calendar try late')
            return
        }
        try {
            const project = {...unArchive}
            setLoading(true)
            const request = window.gapi.client.calendar.calendarList.insert(
                {
                    "id": project.sync_id,
                    "colorRgbFormat": true,
                    "backgroundColor": project.color,
                    "foregroundColor": "#000000",
                }
            )
            await request.then()

            await GCalendarList()
            remove(archive, s => s.sync_id === project.sync_id)
            setArchive([...archive])
            toast.success("saved")
        } catch (e) {
            console.log(e)
            toast.error(e.toString())
        } finally {
            setUnArchive(null)
            setLoading(false)
        }
    }

    const onDelete = async () => {
        if (!deleting || !deleting.sync_id || !deleting.uuid)
            return
        try {
            const pro = {...deleting}
            setLoading(true)
            // Is_Delete firestore true
            const idx = projects.findIndex((p) => p.uuid === pro.uuid);
            await db.collection(CALENDAR_COLLECTION)
                .doc(user.user_id)
                .collection("projects")
                .doc(projects[idx].uuid)
                .set({
                    is_deleted: true
                }, {merge: true})

            projects.splice(idx, 1);
            setProjects([...projects])
            toast.success(deleteSuccess)
        } catch (e) {
            toast.error("Delete fail !!!")
        } finally {
            setDeleting(null)
            setLoading(false)
        }
    }

    const SortGoogleCalendar = () => {
        let sortProjects;
        sortProjects = remove(orderBy(ggAllProject, g => !g?.msSelected), cal => !cal.id.includes("#holiday"));

        return sortProjects
    };

    return (
        <div className={classes.root}>
            <MenuItemContainer width={555} height={39} justifyContent={'space-between'} alignItems={'center'}
                               marginBottom={20} margin={'auto'}>
                <div className={classes.email}>{email}</div>
                <div className={`clf-flex-center ${classes.userEmail}`}>{googleStatus.userProfile.email}</div>
                <CLFButtonSVG
                    onClick={() => setOpenLogOut(true)}
                    name={<FormattedMessage id={'settings.logout'}/>}
                    size={'default'}
                    className={classes.primary}
                />

                {
                    openLogOut &&
                    <LogoutDialog open={openLogOut} setOpen={setOpenLogOut} loading={loading}/>
                }
            </MenuItemContainer>

            {/*syncLoad ? <ClfLinearProgress marginTop={'30px'} width={'50%'}/>*/}
            {/*:*/}
            <Grid container className={classes.gridRoot}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>
                        <FormattedMessage id={'settings.googleCalendarSelect'}/>
                    </Typography>
                    <div className={`${classes.gridItem}`}>
                        <List className={classes.listItem}>
                            {SortGoogleCalendar().map((calendar, index) => {
                                return (
                                    <ItemCheckedCustom
                                        id={index}
                                        key={`${index}`}
                                        onClick={() => toggleCalendar(calendar)}
                                        name={calendar.summary}
                                        color={calendar.backgroundColor}
                                        checked={calendar.msSelected}
                                        isRevert={true}
                                        hiddenAvatar={true}
                                        isBold={true}
                                        isTextColor={true}
                                        height={'30px'}
                                        margin={'unset'}
                                        offTooltip={true}
                                    />
                                )
                            })}
                            {/*<ListItem className={`clf-flex-center`}>*/}
                            {/*    <CLFButtonSVG*/}
                            {/*        onClick={onSave}*/}
                            {/*        iconComponent={<SaveSvg/>}*/}
                            {/*        name={<FormattedMessage id={'settings.ok'}/>}*/}
                            {/*        size={'default'}*/}
                            {/*        className={classes.primary}*/}
                            {/*    />*/}
                            {/*</ListItem>*/}
                        </List>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.title}>
                        <FormattedMessage id={'settings.listProjectSync'}/>
                    </Typography>
                    <div className={`${classes.gridItem}`}>
                        <List className={classes.listItem}>
                            {projects.map((p, index) => {
                                if (p.is_sync) {
                                    let idx = findIndex(ggAllProject, {id: p.sync_id})
                                    if (idx === -1) return null
                                    return (
                                        <ListItem key={index} style={{padding: 0}}>
                                            <ListItemText className={classes.titleItem}
                                                          style={{color: `${p.color}`}}
                                                          primary={p.name}/>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <CheckCircleFilled
                                                    style={{
                                                        color: `${p.color}`,
                                                        padding: 0,
                                                        fontSize: '1.8rem'
                                                    }}/>
                                            </ListItemIcon>
                                        </ListItem>
                                    )
                                }
                                return (
                                    <ListItem key={index} style={{padding: 0}}>
                                        <ListItemText className={classes.titleItem}
                                                      style={{color: `${p.color}`}}
                                                      primary={p.name}/>
                                        {
                                            insync && p.uuid === sync_uuid &&
                                            <ListItemText secondary={syncProcess}/>
                                        }
                                        {!p.isDefault && <ListItemIcon className={classes.listItemIcon}>
                                            <IconButton
                                                disabled={insync}
                                                onClick={async () => {
                                                    setSync_uuid(p.uuid)
                                                    Sync(p.uuid)
                                                }}
                                                style={{padding: 0}}
                                            >
                                                {
                                                    insync && p.uuid === sync_uuid ?
                                                        <CircularProgress
                                                            color={"primary"}
                                                            size={30}
                                                        /> :
                                                        <Cached className={classes.reloadIcon}/>
                                                }

                                            </IconButton>
                                        </ListItemIcon>
                                        }
                                    </ListItem>
                                )
                            })}
                        </List>
                        <Typography className={classes.title}>
                            <FormattedMessage id={'settings.archiveProject'}/>
                        </Typography>
                        <List className={classes.list}>
                            {
                                archive.map(p => (
                                    <ListItem key={p.sync_id} style={{padding: '5px 15px'}}>
                                        <ListItemText style={{color: `${p.color}`}} primary={p.name}/>
                                        <ListItemIcon>
                                            <IconButton
                                                onClick={() => setUnArchive(p)}
                                                disabled={loading}
                                            >
                                                <Unarchive/>
                                            </IconButton>
                                            <IconButton
                                                disabled={loading}
                                                onClick={() => setDeleting(p)}
                                            >
                                                <Delete/>
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                ))
                            }
                            {
                                Boolean(unArchive) &&
                                <ArchiveDialog unArchive={unArchive} setUnArchive={setUnArchive}
                                               onDone={onUnArchive}
                                               loading={loading}/>
                            }
                            {
                                Boolean(deleting) &&
                                <DeleteProjectDialog project={deleting} setProject={setDeleting}
                                                     onDone={onDelete}
                                                     loading={loading}/>
                            }
                        </List>

                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

function ArchiveDialog({unArchive, setUnArchive, onDone, loading}) {
    const classes = useStyles();

    const handleClose = () => {
        setUnArchive(null)
    }
    const onOk = () => {
        onDone()
    }
    return (
        <Modal
            visible={Boolean(unArchive)}
            title={<FormattedMessage id={'settings.archiveDialogDeleteArchive'}/>}
            onCancel={handleClose}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CLFButtonSVG
                    onClick={handleClose}
                    disable={loading}
                    name={<FormattedMessage id={'settings.archiveDialogCancel'}/>}
                    className={classes.secondary}
                />
                <CLFButtonSVG
                    name={<FormattedMessage id={'settings.archiveDialogOk'}/>}
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                    className={classes.primary}
                />
            </div>}
        >
            <FormattedMessage id={'settings.archiveDialogTitle'}/>
        </Modal>
    )
}

function DeleteProjectDialog({project, setProject, onDone, loading}) {
    const classes = useStyles();

    const handleClose = () => {
        setProject(null)
    }
    const onOk = () => {
        onDone()
    }
    return (
        <Modal
            visible={Boolean(project)}
            title={<FormattedMessage id={'settings.archiveDialogDeleteProject'}/>}
            onCancel={handleClose}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CLFButtonSVG
                    onClick={handleClose}
                    disable={loading}
                    name={<FormattedMessage id={'settings.archiveDialogCancel'}/>}
                    className={classes.secondary}
                />
                <CLFButtonSVG
                    name={<FormattedMessage id={'settings.archiveDialogOk'}/>}
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                    className={classes.primary}
                />
            </div>}
        >
            <FormattedMessage id={'settings.archiveDialogDelete'}/>
        </Modal>
    )
}

function LogoutDialog({open, setOpen, loading}) {
    const [googleStatus] = useGlobal('googleStatus')
    const classes = useStyles();
    const [titleOk, titleCancel, logOutGG, logOutGGIntl, textIntl] = useIntlId([
        'settings.ok',
        'settings.archiveDialogCancel',
        'settingGoogleSync.title',
        'settingGoogleSync.question',
        'settingGoogleSync.text'
    ])

    const handleClose = () => {
        setOpen(false)
    }
    const onOk = () => {
        onLogOut()
        handleClose()
    }
    return (
        <Modal
            visible={Boolean(open)}
            title={logOutGG}
            onCancel={handleClose}
            zIndex={9000}
            footer={<div className={'clf-flex-center'}>
                <CLFButtonSVG
                    onClick={handleClose}
                    disable={loading}
                    name={titleCancel}
                    className={classes.secondary}
                />
                <CLFButtonSVG
                    name={titleOk}
                    loading={loading}
                    onClick={onOk}
                    disable={loading}
                    className={classes.primary}
                />
            </div>}
        >
            {logOutGGIntl}
            <Typography color={"secondary"}>
                ( {textIntl} {googleStatus?.userProfile?.email || ''})
            </Typography>
        </Modal>
    )
}


export default withRouter(GoogleList)
