import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.props.primaryColor,
        margin: 'auto',
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: `${theme.props.primaryColor === '#1790FF' ? '#FAAD14' : '#1790FF'}`,
        }
    }
}))

function ClfLinearProgress({marginTop = null, width = null}) {
    const classes = useStyles()
    return (
        <LinearProgress style={{
            marginTop,
            width
        }} className={classes.root}/>
    );
}

export default ClfLinearProgress;
