import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, TextField} from '@material-ui/core';
import {Apps,} from '@material-ui/icons'
import {setGlobal, useGlobal} from 'reactn';
import AddAppDialog from './AddAppDialog';
import {Button, Checkbox} from 'antd';
import RemoveAppDialog from './RemoveAPPDialog';
import {ReactComponent as AddLargeSVG} from '../../../asSvg/icons/addLarge.svg';
import {v4 as uuidv4} from 'uuid';
import {getGlobalUrls,} from '../../../common/firebaseRef/globalnavi';
import moment from 'moment';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import isURL from 'validator/es/lib/isURL';
import faviconFetch from 'favicon-fetch';
import useIntlId from '../../../hooks/useIntlId';
import {APP_GLOBAL_ID} from '../../../config/constants';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import {isEmpty, trim} from 'lodash';
import {useAnnouncement} from '../../../context/AnnouncementContext';
import PaymentList from '../../Cart/PaymentList';

function NavigationSettingTab() {
    const [addURL, save, edit, confirmDelete, ok, cancel, unlockIntl]
        = useIntlId(['create.addUrl', 'dialog.action.done', 'chat.edit',
        'setting.confirmDelete', 'create.ok', 'settings.archiveDialogCancel', 'settings.announcement.unlock'])
    const [user,] = useGlobal('user')
    const [globalApps] = useGlobal('userGeniamApps')
    const [userLinks] = useGlobal('userLinks')
    const [openAdd, setOpenAdd] = useState(false)
    const [deleteApp, setDeleteApp] = useState(null)
    const classes = useStyles()
    const {lock,} = useAnnouncement()
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [isSaveUrl] = useKeyboardJs(['ctrl+enter', 'command+enter'])
    const [openEdit, setOpenEdit] = React.useState(null);
    const [openDelete, setOpenDelete] = React.useState(null);
    const [openAddURL, setOpenAddURL] = React.useState(false);
    const [openPurcharse, setOpenPurcharse] = useState(false)

    const handleClickConfirm = item => () => {
        setOpenDelete(item)
    };

    const handleCancelConfirm = () => {
        setOpenDelete(null)
    };

    const handleClickOpenEdit = item => () => {
        setOpenEdit(item);
    };
    const handleCloseEdit = () => {
        setOpenEdit(null);
    };

    const handleClickOpen = () => {
        setOpenAddURL(true);
    };
    const handleClose = () => {
        setOpenAddURL(false);
    };
    const onSave = async () => {
        try {
            const id = uuidv4()
            if (!user?.user_id)
                return;
            let data = {
                id: id,
                name: name,
                url: url,
                createAt: moment().utc().format(),
                [APP_GLOBAL_ID]: false,
                checked: false
            }
            const ref = getGlobalUrls(user.user_id)
            await ref.doc(id).set(data, {merge: true})
        } catch (e) {
            console.log(e)
        } finally {
            setName(null)
            setUrl(null)
            setOpenAddURL(false);
        }
    }
    const onEditURL = async () => {
        try {
            const id = openEdit.id
            if (!user?.user_id || !openEdit?.url)
                return;
            const ref = getGlobalUrls(user?.user_id)
            await ref.doc(id).set({
                name: openEdit.name,
                url: openEdit.url
            }, {merge: true})
        } catch (e) {
            console.log(e)
        } finally {
            setOpenEdit(null)
        }
    }

    const onDeleteURL = async () => {
        try {
            const id = openDelete.id
            if (!user?.user_id || !openDelete?.url)
                return;
            const ref = getGlobalUrls((user?.user_id))
            await ref.doc(id).delete()
        } catch (e) {
            console.log(e)
        } finally {
            setOpenDelete(null)
        }
    }
    const handleChangeGlobal = async (item, value, geniamApp) => {
        try {
            if (!item || !user?.user_id) return null;
            const ref = getGlobalUrls(user.user_id).doc(item.id)
            await ref.set({
                global: value, geniamApp: geniamApp,
            }, {merge: true})
        } catch (e) {
            console.log(e)
        }
    };
    const handleFavoriteAppGlobal = async (item, value, geniamApp) => {
        try {
            if (!item || !user?.user_id) return null;
            const ref = getGlobalUrls(user.user_id).doc(item.id)
            await ref.set({
                favorite: {
                    milestone: value
                }, geniamApp: geniamApp,
            }, {merge: true})
        } catch (e) {
            console.log(e)
        }
    };
    const handleShortLinkAppGlobal = async (item, value, geniamApp) => {
        try {
            if (!item || !user?.user_id) return null;
            const ref = getGlobalUrls(user.user_id).doc(item.id)
            await ref.set({
                shortLink: {
                    milestone: value
                }, geniamApp: geniamApp,
            }, {merge: true})
        } catch (e) {
            console.log(e)
        }
    };
    useEffect(() => {
        if (isSaveUrl) {
            if (url) {
                onSave()
            }
        }
        // eslint-disable-next-line
    }, [isSaveUrl])

    return (
        <div className={classes.root}>
            <AddAppDialog open={openAdd} setOpen={setOpenAdd}/>
            <RemoveAppDialog app={deleteApp} setApp={setDeleteApp}/>
            <div className={classes.moreServices}>
                <div className={classes.elements}>
                    <div className={classes.titleServices}>
                        Geniam サービス
                    </div>
                    <div>
                        <div className={'flex justify-end pr-[110px]'}>
                            <img
                                className={classes.showApp}
                                src={"icons/moveIcon.svg"} alt={"showApp"}
                            />
                            <img
                                className={classes.blackIcon}
                                src={"icons/blackLink.svg"} alt={"linkIcon"}/>
                            <Apps className={classes.allApps}/>
                        </div>
                    </div>

                    {
                        globalApps?.length > 0 && globalApps.map((item, index) => {
                            if (!item?.id)
                                return null
                            return (
                                <div className={classes.group} key={index}>
                                    <IconButton
                                        onClick={() => {
                                            window.open(item.url, "_blank")
                                        }}>
                                        <img className={classes.iconAppy}
                                             src={item.icon} alt={""}/>
                                    </IconButton>
                                    <p className={classes.name}>{item.name}</p>
                                    <p className={classes.text}
                                       onClick={() => {
                                           window.open(item.url, "_blank")
                                       }}>
                                        {item.text}</p>
                                    <div className={classes.checkBoxsAppy}>
                                        <Checkbox
                                            id={item.id}
                                            checked={item.shortLink?.[APP_GLOBAL_ID]}
                                            onChange={() => handleShortLinkAppGlobal(item, !item.shortLink?.[APP_GLOBAL_ID], true)}
                                            className={classes.checkBox}
                                            disabled={lock}/>

                                        <Checkbox
                                            id={item.id}
                                            checked={item.favorite?.[APP_GLOBAL_ID]}
                                            onChange={() => handleFavoriteAppGlobal(item, !item.favorite?.[APP_GLOBAL_ID], true)}
                                            className={classes.checkBox}
                                            disabled={lock}
                                        />
                                        <Checkbox
                                            id={item.id}
                                            checked={item.global}
                                            onChange={() => handleChangeGlobal(item, !item.global, true)}
                                            className={classes.checkBox}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className={classes.titleServices}>その他のリンク</div>
                    {
                        userLinks.map((item, index) => {
                            if (isEmpty(item))
                                return null
                            return (
                                <div className={classes.group} key={index}>
                                    <IconButton
                                        onClick={() => {
                                            window.open(item.url, "_blank")
                                        }}>
                                        <img className={classes.iconAppy}
                                             src={item?.url ? faviconFetch({uri: item?.url}) : item?.icon}
                                             alt={""}/>
                                    </IconButton>
                                    <p className={classes.name}>{item.name}</p>
                                    <p className={classes.text}
                                       onClick={() => {
                                           window.open(item.url, "_blank")
                                       }}>
                                        {item.url}
                                    </p>
                                    <div className={classes.checkBoxContainer}>
                                        <Checkbox
                                            id={item.id}
                                            checked={item.shortLink?.[APP_GLOBAL_ID]}
                                            onChange={() => handleShortLinkAppGlobal(item, !item.shortLink?.[APP_GLOBAL_ID], false)}
                                            className={classes.checkBox + ' ' + classes.hidden}
                                            disabled={true}
                                        />
                                        <Checkbox
                                            id={item.id}
                                            checked={item.favorite?.[APP_GLOBAL_ID]}
                                            onChange={() => handleFavoriteAppGlobal(item, !item.favorite?.[APP_GLOBAL_ID], false)}
                                            className={classes.checkBox}
                                            disabled={lock}
                                        />
                                        <Checkbox
                                            id={item.id}
                                            checked={item.globalCallback}
                                            onChange={() => handleChangeGlobal(item, !item.globalCallback, false)}
                                            className={classes.checkBox + ' ' + classes.hidden}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className={classes.changeBtns}>
                                        <IconButton
                                            variant="outlined"
                                            onClick={handleClickOpenEdit(item)}
                                            className={classes.editBtn}>
                                            <img src={"/image/edit.svg"} alt=""/>
                                        </IconButton>
                                        <IconButton
                                            variant="outlined"
                                            onClick={handleClickConfirm(item)}
                                            className={classes.deleteBtn}>
                                            <DeleteSharpIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <Dialog open={openEdit} onClose={handleCloseEdit}>
                        <DialogTitle>{edit}</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="name"
                                type="text"
                                fullWidth
                                value={openEdit?.name}
                                variant="standard"
                                onChange={(e) => setOpenEdit({...openEdit, name: e.target.value})}
                            />
                            <TextField
                                margin="dense"
                                label="URL"
                                type="text"
                                fullWidth
                                value={openEdit?.url}
                                variant="standard"
                                onChange={(e) => setOpenEdit({...openEdit, url: e.target.value})}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onEditURL}>{edit}</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        className={classes.dialogConfirm}
                        open={Boolean(openDelete)}
                        onClose={handleCancelConfirm}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {confirmDelete}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleCancelConfirm}>{cancel}</Button>
                            <Button onClick={onDeleteURL} autoFocus>{ok}</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {!lock ?
                    <div className={classes.addBtn}>
                        <IconButton
                            variant="outlined"
                            onClick={handleClickOpen}>
                            <AddLargeSVG className={classes.addIconSVGTimeSetting}/>
                        </IconButton>
                    </div>
                    :
                    <IconButton
                        variant="outlined"
                        className={classes.disableAddBtn}>
                        <AddLargeSVG/>
                    </IconButton>
                }

                <div style={{paddingTop: "10px"}}>
                    {
                        lock &&
                        <Button
                            onClick={() => setGlobal({
                                openSetting: true,
                                settingTab: 10
                            })}
                            className={classes.btnUnlock}>
                            <img className={classes.iconButton} src="/icons/keyWhite.svg" alt=""/>
                            {unlockIntl}</Button>
                    }
                </div>


                <PaymentList open={openPurcharse} setOpen={setOpenPurcharse}/>

            </div>
            {
                openAddURL &&
                <Dialog open={openAddURL} onClose={handleClose}>
                    <DialogTitle>{addURL}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            label="URL"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions className={classes.dialogFooter}>
                        {
                            url === "" || !isURL(trim(`${url}`)) ?
                                <Button className={classes.saveBtn} disabled>{save}</Button>
                                :
                                <Button className={classes.saveBtn} onClick={onSave}>{save}</Button>
                        }
                    </DialogActions>
                </Dialog>
            }
        </div>
    )
        ;
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 15px',
        background: '#fff',
        borderRadius: 15,
        padding: 15
    },
    left: {
        display: "flex",
        alignItems: 'center',

    },
    deleteBtn: {
        height: 50,
        width: 50,
    },
    checkBoxContainer: {
        display: "flex",
        // marginRight: 95
        marginRight: 14
    },
    hidden: {
        visibility: "hidden"
    },
    checkBoxsAppy: {
        display: "flex",
        paddingRight: 114
    },
    checkBox: {
        marginLeft: "25px !important",
    },
    divText: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        overflow: "hidden",
    },
    elements: {
        display: "flex",
        flexDirection: "column",
        rowGap: 5
    },
    changeBtns: {
        display: "flex"
    },
    editBtn: {
        "& .MuiIconButton-label": {
            height: 25,
            width: 25,
        },
    },
    saveBtn: {
        border: 0,
        borderRadius: 4,
        background: theme.props.primaryColor,
        textTransform: "uppercase",
        cursor: "pointer",
        color: "#FFF",
    },
    iconAppy: {
        width: 25,
        height: 25
    },
    dialogFooter: {
        justifyContent: "center"
    },
    addIconSVGTimeSetting: {
        fill: theme.props.primaryColor,
    },
    disableAddBtn: {
        backgroundColor: "#b3b3b3",
        borderRadius: 50,
        cursor: "not-allowed",
        display: "block",
        margin: "auto",
        padding: "0px !important",
        "&:hover": {
            backgroundColor: "#b3b3b3"
        }
    },
    addBtn: {
        textAlign: "center"
    },
    text: {
        paddingRight: 20,
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        "&:hover": {
            color: theme.props.primaryColor
        },
        cursor: "pointer",
        marginBottom: "0px !important"
    },
    moreServices: {
        columnGap: 15,
        fontWeight: "bold",
        background: "#FFFFFF",
        borderRadius: 15,
        padding: "10px 0",
        display: "flex",
        flexDirection: "column"
    },
    titleServices: {
        textAlign: "center",
        paddingBottom: 10
    },
    allApps: {
        fontSize: 25,
        marginLeft: 15
    },
    blackApp: {
        paddingLeft: 17,
        paddingRight: 114
    },
    group: {
        borderBottom: '1px solid #f1f1f1',
        alignItems: "center",
        display: "flex",
    },
    title: {
        color: '#444444',
        fontSize: 14,
        fontWeight: 700,
        textAlign: "center"
    },
    name: {
        maxWidth: 160,
        padding: "0px 20px",
        minWidth: 150,
        marginBottom: "0px !important",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    content: {
        justifyContent: "space-between",
        borderBottom: "1px solid #f1f1f1",
        alignItems: "center"
    },
    iconBtn: {
        fontSize: 30
    },
    leftSpace: {
        flexGrow: 1
    },
    appList: {
        overflow: "hidden",
        transition: 'all 0.5s',
        width: 'auto',
        display: "flex",
        alignItems: 'center',
        justifyContent: "space-around"
    },
    btnUnlock: {
        margin: "auto",
        border: "none",
        display: "flex",
        backgroundColor: `${theme.props.primaryColor} !important`,
        fontWeight: 700,
        color: '#fff',
        textTransform: "uppercase",
        borderRadius: "13px",
        padding: "8px 20px",
        height: "unset",
        "&:hover": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        },
        "&:focus": {
            backgroundColor: `${theme.props.primaryColor} !important`,
            color: '#fff !important',
        }
    },
    iconButton: {
        marginRight: 10,
    },
    blackIcon: {
        marginLeft: 24
    }
}))
export default NavigationSettingTab;
