import React, {useEffect, useState} from 'react'
import {Dialog, Fade, IconButton} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import {Delete} from "@material-ui/icons"
import moment from 'moment'
import {newEvent, updateEvent} from "../../actions/calendar"
import Popover from '@material-ui/core/Popover'
import {toast} from 'react-toastify'
import {setGlobal, useGlobal} from 'reactn'
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import {refetchOne, removeEvent} from "../../actions/refetchSourcce";
import {getPosition} from "../../common/element";
import DeleteEvent from "../Calendar/deleteEventMessage";
import Time from "../Calendar/popup/time";
import Task from "../Calendar/popup/task";
import Creator from "../Calendar/popup/Creator";
import TitleEvent from "../Calendar/popup/title";
import Project from "../Calendar/popup/project";
import IdentityList from "../Calendar/popup/identity";
import {ggTokenChecked} from "../Calendar/google/auth";
import {db} from "../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../config/constants";
import {AddGoogleEvent, updateGoogleEvent} from "../../actions/googleCalendar";
import {cloneDeep, findIndex} from "lodash";
import useDebounce from "react-use/lib/useDebounce";
import {useCalendarEvents} from "../../context/CalendarEventsContext";
import Memo from "../Calendar/popup/memo";
import {Button} from 'antd';
import Tooltip from "@material-ui/core/Tooltip";
import {ReactComponent as CopySVG} from "../../asSvg/icons/copy.svg";
import useIntlId from "../../hooks/useIntlId";
import AchievementRate from "../Calendar/popup/AchievementRate";

const copy = require('clipboard-copy')

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} />;
})

const useStyles = makeStyles(theme => ({
    typography: {
        margin: theme.spacing(2),
    },
    root: {
        // border: '5px solid black',
        minWidth: 350,
        padding: '25px',
        //maxWidth: 600,
        // width: 600,
        // height: 550,
        overflow: 'auto',
        background: "#FFFFFF",
        maxHeight: "85vh",
        [theme.breakpoints.down('xs')]: {
            width: 340,
            minWidth: "auto"
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 530

        },
        [theme.breakpoints.up('lg')]: {
            // width: 530
        },
        [theme.breakpoints.up('xl')]: {
            // width: 600
        },
    },
    element: {
        paddingTop: '10px'
    },
    closeIcon: {
        padding: 8,
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        backgroundColor: "#fff",
        color: "#970505",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        zIndex: 1000,
        "&:hover": {
            backgroundColor: "#fff",

        }
    },
    popper: {
        zIndex: "888 !important", backgroundColor: "white"
    },
    btnContainer: {
        display: 'flex', margin: 'auto', paddingBottom: '20px'
    },
    btnSave: {
        fontWeight: "bold",
        margin: "10px 0",
        border: "none",
        background: `${theme.props.primaryColor} !important`,
        color: '#FFF !important',
        '&:hover': {
            // background: '#3991e3 !important',
            background: theme.props.primaryColor,
            opacity: 0.8,
            border: "none",
            color: '#FFF'
        },
        '&:focus': {
            background: theme.props.primaryColor,
            opacity: 0.8,
            border: "none",
            color: '#FFF'
        }
    },
    clear: {
        clear: "both"
    },
    pt10: {
        paddingTop: 10
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#0e72ed',
        textTransform: 'none',
        fontSize: '12px',
        minWidth: 130,
        '&:hover': {
            backgroundColor: '#0e72ed',
            opacity: 0.7,
        },
    },
    zoomMeeting: {
        display: "flex",
        alignItems: "center",
    },
    iconZoom: {
        color: "#0e72ed",
        margin: 5,
    },
    copyField: {
        width: "100%",
        margin: "10px 0px",
        // "& ."
        "&& .MuiOutlinedInput- root": {
            borderRadius: 0
        },
        "&& .MuiOutlinedInput-adornedStart": {
            paddingLeft: 0
        },
        "&& .MuiOutlinedInput-adornedEnd": {
            height: "40px",
            padding: 0
        },
        "&& .MuiOutlinedInput-notchedOutline": {
            border: '2px solid black'
        },
        "& .MuiInputAdornment-positionStart": {
            margin: 0
        }
    },
    zoomJoin: {
        marginRight: 10,
        cursor: 'pointer'
    },
    pTop10: {
        paddingTop: 10,
    },
    alignRight: {
        textAlign: "right"
    },
    textFieldName: {
        border: "none",
        borderBottom: "1px solid #C4C4C4",
        display: "block",
        width: "100%",
        padding: "6px 0",
        marginBottom: 12,
        "&:focus": {
            outline: "none",
            borderBottomColor: theme.props.primaryColor
        }
    },
    avatar: {
        width: 40,
        height: 40,
        border: "2px solid #339933",
        margin: '2px'

    },
    avatar1: {
        width: 40,
        height: 40,
        border: "2px solid #666",
        margin: '2px'
    },
    btnDeleteContainer: {
        display: 'flex', marginTop: 10
    },
    btnDelete: {
        marginLeft: 'auto'
    },
    popover: {
        "& .MuiPopover-paper": {
            overflow: "inherit"
        },
        "& .MuiDialog-paper": {
            overflow: "inherit"
        }
    },
    iconSVG: {
        fill: theme.props.primaryColor,
        marginLeft: 7,
        width: 32,
        height: 32,
        cursor: "pointer",
    },
    flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    title: {
        fontWeight: "bold",
        fontSize: 15,
    }
}));

function EditEvent({editInfo, setEditInfo, numberId}) {
    const classes = useStyles()
    const [event, setEvent] = useState(null)
    const [oldEvent, setOldEvent] = useState(null)
    const [projects] = useGlobal('allMilestoneProject')
    const [user] = useGlobal('user')
    const [openDelete, setOpenDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [focusCheck, setFocusCheck] = useState(false);
    // global State
    const [isSavePressed] = useKeyboardJs('ctrl+enter')
    const [isDelPressed] = useKeyboardJs('del')
    const [isBackspace] = useKeyboardJs('ctrl +backspace')
    const [openDetail] = useState(false)
    const [element, setElement] = useState(null)
    const {revertEvent} = useCalendarEvents()
    const [titleEvent, savedAndCopied, saveIntl] = useIntlId([
        'create.titleEvent',
        'toast.success.calendar.savedAndCopied',
        'dialog.action.done'
    ]);

    const EditEventContainer = !editInfo?.el ? Dialog : Popover

    useEffect(() => {
        if (editInfo.event) {
            let duration = (moment(editInfo.event.end) - moment(editInfo.event.start)) / (24 * 60 * 60 * 1000)
            let event = {
                id: editInfo.event.id,
                uuid: editInfo.event.extendedProps.uuid || editInfo.event.id,
                title: editInfo.event.title,
                allDay: editInfo.event.allDay,
                start: editInfo.event.start,
                end: editInfo.event.end,
                task: editInfo.event.extendedProps.task || '',
                project_uuid: editInfo.event.extendedProps.project_uuid,
                resourceId: editInfo.event.extendedProps.project_uuid,
                borderColor: editInfo.event.borderColor,
                backgroundColor: editInfo.event.backgroundColor,
                colorId: editInfo.event.extendedProps.colorId,
                is_google: Boolean(editInfo.event.extendedProps.googleEvent),
                googleEvent: Boolean(editInfo.event.extendedProps.googleEvent),
                identity: editInfo.event.extendedProps.identity || '',
                duration,
                is_holiday: Boolean(editInfo.event.extendedProps.holiday),
                isSuccess: Boolean(editInfo.event.extendedProps.isSuccess),
                description: editInfo.event.extendedProps.description || '',
                location: editInfo.event.extendedProps.location || '',
                isEventVote: Boolean(editInfo.event.extendedProps.isEventVote),
                isAccepted: Boolean(editInfo.event.extendedProps.isAccepted),
                meetingId: editInfo.event.extendedProps?.meetingId || null,
                voted: editInfo.event.extendedProps?.voted || {},
                isMileStone: Boolean(editInfo.event.extendedProps.isMileStone),
                isMilestoneHide: Boolean(editInfo.event.extendedProps.isMilestoneHide),
                isMilestoneNextMonth: Boolean(editInfo.event.extendedProps.isMilestoneNextMonth),
                achievementRate: Number(editInfo.event.extendedProps.achievementRate || 0)
            }
            setEvent(event)
            setOldEvent(event)
            if (editInfo.el) setElement(getPosition(editInfo.el))
        } else {
            setEvent(null)
            setElement(null)
        }
        // eslint-disable-next-line
    }, [editInfo])

    useEffect(() => {
        if (isSavePressed && editInfo.event) {
            if (event.title && event.project_uuid) {
                onUpdate()
            } else {
                toast.error('title & project Required !')
            }
        }
        // eslint-disable-next-line
    }, [isSavePressed])

    useEffect(() => {
        if ((isDelPressed || isBackspace) && editInfo.event && event && !event.is_holiday && !openDetail) {
            setOpenDelete(true)
        }
        // eslint-disable-next-line
    }, [isDelPressed, isBackspace])

    useDebounce(
        () => {
            if (window['calendar' + numberId] && event) {
                removeEvent(event.id)
                // comment check focus event trung
                // if (oldEvent && event && (oldEvent?.start !== event?.start || oldEvent?.end !== event?.end) && event.task === 'focus') {
                //     if (getVisibleFocusEvents(event.start, event.end, numberId)) {
                //         setFocusCheck(true)
                //         event.task = ""
                //         setEvent({...event})
                //     } else {
                //         setFocusCheck(false)
                //     }
                // }
                window['calendar' + numberId].addEvent(event)
            }
        },
        500,
        [event]
    )

    const handleClose = () => {
        setEvent(null)
        setEditInfo(null)
        setElement(null)
        removeEvent(event.id)
        refetchOne(window['i'])
    }

    const isMoved = () => {
        let index = findIndex(projects, {'uuid': event.project_uuid})
        let old_idx = findIndex(projects, {'uuid': oldEvent.project_uuid})
        if ((index !== -1) && (old_idx !== -1)) {
            if (Boolean(projects[index].is_google) !== Boolean(projects[old_idx].is_google)) {
                return true
            }
            return false
        }
        return false
    };

    const onUpdate = async () => {
        if (event.is_holiday) {
            setEditInfo(null)
            return
        }
        try {
            setLoading(true)
            // updatedEvent(event, oldEvent)
            setGlobal({eventChangeId: [event.project_uuid, oldEvent.project_uuid]})
            removeEvent(event.id)
            if (isMoved()) {
                // move beetwen geniam and google calencar
                if (oldEvent.is_google) {
                    // google to geniam calendar
                    await ggTokenChecked()
                    const batch = window.gapi.client.newBatch()
                    const request = window.gapi.client.calendar.events.delete({
                        "calendarId": oldEvent.project_uuid,
                        "eventId": event.id
                    })
                    batch.add(request)
                    await batch.then()
                    await newEvent(event)
                    setLoading(false)
                    setEditInfo(null)
                    return
                }
                await db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${event.id || event.uuid}`)
                    .set({isDeleted: true}, {merge: true})

                await AddGoogleEvent(event)
                setLoading(false)
                setEditInfo(null)
                return
            }
            if (event.is_google) {
                await ggTokenChecked()
                const batch = window.gapi.client.newBatch()
                if (oldEvent.project_uuid && event.project_uuid !== oldEvent.project_uuid) {
                    // if (event.project_uuid !== oldEvent.project_uuid) {
                    const request = window.gapi.client.calendar.events.move({
                        "calendarId": oldEvent.project_uuid,
                        "eventId": event.id,
                        "destination": event.project_uuid
                    })
                    batch.add(request)
                    await batch.then()
                }
                // let _ggEvents = snapshot(ggEvs)
                // const idx = _ggEvents[event.project_uuid].events.findIndex(e => e.id === event.id)
                // if (idx > -1) {
                //     ggEvs[event.project_uuid].events[idx] = event
                //
                // }
                //setGlobal({ggEvents: _ggEvents, eventChangeId: [event.project_uuid]})
                //removeEvent(event.id)
                await updateGoogleEvent(event, event.id)
                setLoading(false)
                setEditInfo(null)
                return
            }
            if (event.project_uuid) {
                if (oldEvent.project_uuid && event.project_uuid !== oldEvent.project_uuid) {
                    // move event
                    // delete event in old project
                    await db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/events/${event.id}`)
                        .set({
                            isDeleted: true
                        }, {merge: true})
                    // add event for new project
                    //removeEvent(event.id)
                    await newEvent(event)
                    setLoading(false)
                    setEditInfo(null)
                    return
                }
            }
            //removeEvent(event.id)
            await updateEvent(event)
            setLoading(false)
            setEditInfo(null)
        } catch (e) {
            console.log(e);
            toast.error(e.toString())
            setLoading(false)
            setEditInfo(null)
            revertEvent(event, oldEvent)
        } /*finally {
            //removeEvent(event.id)
        }*/
    }

    const copyTime = () => {
        if (`${moment(event?.start).format("MM/DD")}` === `${moment(event?.end).subtract(1, 'seconds').format("MM/DD")}`) {
            copy(`${moment(event?.start).format("MM/DD")} ( ${moment(event?.start).format("ddd")} )`)
            toast.success(savedAndCopied)
        } else {
            copy(`${moment(event?.start).format("MM/DD")} ( ${moment(event?.start).format("ddd")}) - ${moment(event?.end).subtract(1, 'seconds').format("MM/DD")} ( ${moment(event?.end).subtract(1, 'seconds').format("ddd")} )`)
            toast.success(savedAndCopied)
        }
        onUpdate()
    }
    return (
        <div>
            <EditEventContainer
                open={Boolean(editInfo?.event)}
                anchorReference="anchorPosition"
                anchorPosition={element}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                TransitionComponent={Transition}
                disableRestoreFocus
                className={classes.popover}
                disableEnforceFocus
                maxWidth={'lg'}
            >
                <IconButton disableRipple={true} className={classes.closeIcon}
                            onClick={handleClose}>
                    <img src={process.env.PUBLIC_URL + 'icons/close.svg'} alt=""/>
                </IconButton>
                {Boolean(editInfo) &&
                    <div className={classes.root}>
                        <LoadingOverlay>
                            <Loader loading={loading} text={''}/>
                            <input type="text"
                                   autoFocus={true}
                                   className={classes.textFieldName}
                                   value={event?.title ? event.title : ''}
                                   onChange={e => {
                                       setEvent({
                                           ...event,
                                           title: e.target.value
                                       })
                                   }}
                                   disabled={event?.is_holiday}
                                   placeholder={titleEvent}
                            />
                            {/*<Grid container alignItems={"center"}>*/}
                            {/*    <Grid item xs={9}>*/}

                            {/*        <TextField*/}
                            {/*            autoFocus={true}*/}
                            {/*            id="outlined-bare"*/}
                            {/*            className={classes.textFieldName}*/}
                            {/*            value={event?.title ? event.title : ''}*/}
                            {/*            variant="filled"*/}
                            {/*            onChange={e => {*/}
                            {/*                setEvent({*/}
                            {/*                    ...event,*/}
                            {/*                    title: e.target.value*/}
                            {/*                })*/}
                            {/*            }}*/}
                            {/*            fullWidth*/}
                            {/*            disabled={event?.is_holiday}*/}
                            {/*            placeholder={"Title event"}*/}
                            {/*        />*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item xs={3} className={classes.alignRight}>*/}
                            {/*        <IconButton className={classes.closeIcon}*/}
                            {/*                    onClick={handleClose}>*/}
                            {/*            <Close/>*/}
                            {/*        </IconButton>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}

                            <div className={classes.clear}/>

                            <Creator
                                event={editInfo.event}
                                user={user}
                            />

                            <TitleEvent
                                event={event}
                                setEvent={setEvent}
                            />
                            <div className={classes.pTop10}>
                                <Time
                                    event={event}
                                    setEvent={setEvent}
                                />
                            </div>
                            <div className={classes.pTop10}>
                                <AchievementRate
                                    event={event}
                                    setEvent={setEvent}
                                />
                            </div>
                            <Memo event={event} setEvent={setEvent}/>
                            <Project
                                event={event}
                                setEvent={setEvent}
                                projects={projects}
                                onClose={handleClose}
                            />

                            <div className={classes.element}>
                                <IdentityList
                                    event={event}
                                    setEvent={setEvent}
                                    onClose={handleClose}
                                />
                            </div>

                            <div className={classes.element}>
                                <Task
                                    event={event}
                                    setEvent={setEvent}
                                    onClose={handleClose}
                                    // focusCheck={focusCheck}
                                    // setFocusCheck={setFocusCheck}
                                />
                            </div>

                            <div className={classes.flex}>
                                <Button
                                    disabled={!(event && event.title && event.project_uuid) || loading}
                                    // type="primary"
                                    className={classes.btnSave}
                                    block
                                    shape="round"
                                    onClick={() => {
                                        onUpdate()
                                    }}
                                > {saveIntl} </Button>

                                <Tooltip title={savedAndCopied} placement="top">
                                    <CopySVG onClick={copyTime} className={classes.iconSVG}/>
                                </Tooltip>
                            </div>

                            <div className={classes.btnDeleteContainer}>
                                <IconButton
                                    disableRipple={true}
                                    className={classes.btnDelete}
                                    onClick={() => {
                                        setOpenDelete(true)
                                    }}
                                    disabled={event && event.is_holiday}
                                >
                                    <Delete/>
                                </IconButton>
                            </div>
                            {openDelete &&
                                <DeleteEvent
                                    event={oldEvent}
                                    setOpen={setOpenDelete}
                                    open={openDelete}
                                    setEditInfo={setEditInfo}
                                />
                            }

                        </LoadingOverlay>
                    </div>
                }
            </EditEventContainer>
        </div>
    )
}

export default EditEvent;

