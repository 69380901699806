import React, {useState} from 'react';
import {Toolbar} from '@material-ui/core';
import Setting from "./Setting";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from 'prop-types';
import HeaderCenter from "./HeaderCenter";
import {useHistory} from "react-router-dom";
import {setGlobal, useGlobal} from "reactn";
import moment from "moment";
import {filter, findIndex, mapKeys, orderBy, sortBy, uniqBy} from 'lodash';
import {useDebounce} from "react-use";
import store from 'store';
import {publicIcon} from "../Calendar/handlers/getIcon";


function MenuAppBar(props) {
    const {
        // expired = false,
        numOfLine,
        setNumOfLine,
        setCalendarHeight,
        classes,
        viewEvent = null,
        setViewEvent = null,
    } = props
    const [openSearch, setOpenSearch] = useState(false)
    const history = useHistory()
    const [geEvents,] = useGlobal("geEvents")
    const [ggEvents,] = useGlobal("ggEvents")
    const [projects] = useGlobal("projects")
    const [searchKeyHistory] = useGlobal("searchKeyHistory")
    const [timeSearch, setTimeSearch] = useGlobal("timeSearch")
    const [checkTimeSearch, setCheckTimeSearch] = useGlobal("checkTimeSearch")
    const projectSearchHidden = filter(projects, p => p.is_deleted || p.is_sync)

    const [globalApps] = useGlobal('userGeniamApps')


    const idx1 = findIndex(globalApps, {"id": "calenview"})
    const idx2 = findIndex(globalApps, {"id": "milestone"})

    const openMilestone = () => {
        window.open(globalApps[idx2].url, "_blank")
    }

    const openCalenview = () => {
        window.open(globalApps[idx1].url, "_blank")
    }


    const onChangeSearch = (e) => {
        let listEvent = []
        let search = []
        mapKeys(geEvents, function (value, key) {
            if (findIndex(projectSearchHidden, p => p.id === value.id) === -1)
                listEvent.push(value);
        })
        mapKeys(ggEvents, function (value, key) {
            listEvent.push(value);
        })
        listEvent.forEach(event => {
            event.events.forEach(ev => {
                if (ev.allDay) {
                    if (checkTimeSearch) {
                        if (ev?.title?.toLowerCase().includes(e)) {
                            search.push(ev)
                        }
                    } else {
                        if (ev?.title?.toLowerCase().includes(e) && moment(timeSearch.start).isBefore(ev.start) && moment(ev.end).isBefore(timeSearch.end)) {
                            search.push(ev)
                        }
                    }
                }
            })
        })
        setGlobal({
            listSearch: (orderBy(search, (a) => moment(a.start).utc().format(), 'desc'))
        })
    }

    const handleKeyDown = async (e) => {

        if (e.key === "Enter") {

            if (e.target.value.toLowerCase() !== "" || e.target.value?.replace(/\s+/g, '') !== "") {
                let searchList = [
                    ...searchKeyHistory,
                    {
                        title: e.target.value.toLowerCase(),
                        created: new Date(),
                        isSearchKeyHistory: true,
                    }]
                let searchLists = sortBy(searchList, 'created').reverse().splice(0, 10)
                await setGlobal({
                    searchKey: e.target.value.toLowerCase(),
                    searchKeyHistory: uniqBy(searchLists, 'title')
                })

                store.set('searchKeyHistory', JSON.stringify(uniqBy(searchLists, 'title')))
                history.push(`/search?searchkey=${e.target.value.toLowerCase()}`)
            }
        }
        if (e.key === "Escape") {
            setOpenSearch(false)
            setGlobal({
                searchKey: "",
            })
        }
    }
    useDebounce(() => {
        if (window.location.pathname !== "/search") {
            onChangeSearch()
        }
    }, 500, [])

    return (
            <Toolbar className={classes.toolbar}>

                <div className={classes.divButton}>
                    <div
                        className={classes.button1}
                        onClick={() => openCalenview()}
                    >
                        <img style={{margin: 5}} src={publicIcon('calenview.svg')} alt=""/>
                    </div>

                    <div
                        className={classes.button2}
                        onClick={() => openMilestone()}
                    >
                        <img style={{margin: 5}} src={publicIcon('milestone1.svg')} alt=""/>
                    </div>
                </div>
                <div className={classes.flexHeader}>
                    <div>
                        <div className={classes.hiddeMd}>
                            {
                                window.location.pathname !== '/search' &&
                                <HeaderCenter
                                    numOfLine={numOfLine}
                                    setNumOfLine={setNumOfLine}
                                    setCalendarHeight={setCalendarHeight}
                                    openSearch={openSearch}
                                />
                            }
                        </div>
                    </div>
                    <Setting setOpenSearch={setOpenSearch}
                             openSearch={openSearch}
                             onChangeSearch={onChangeSearch}
                             handleKeyDown={handleKeyDown}
                             timeSearch={timeSearch}
                             setTimeSearch={setTimeSearch}
                             checkTimeSearch={checkTimeSearch}
                             setCheckTimeSearch={setCheckTimeSearch}
                             viewEvent={viewEvent} setViewEvent={setViewEvent}
                    />
                </div>
            </Toolbar>
    )
}

const styles = theme => ({
    root: {
        backgroundColor: '#F1F1F1 !important',
        // padding: '10px 0',
        zIndex: 999,
        height: '60px',
        boxShadow: "none !important"
    },
    toolbar: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'left',
        padding: "0 30px 0 5px",
        flex: 1,
        minHeight: "unset",
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    margin: {
        fontSize: 14,
        float: 'left',
        marginTop: 10,
        [theme.breakpoints.down('md')]: {
            width: 45,
            fontSize: 10,
            minWidth: 20,
        },
    },
    menuButton: {
        float: 'left',
    },
    paper: {
        // ...theme.mixins.gutters(),
        width: 'fit-content',
        float: 'left',
        backgroundColor: '#3A3A3A !important',
        padding: 0,

    },
    segment: {
        backgroundColor: 'white',
        width: "80px !important",
        color: "#42a5f5",
        borderRadius: 6,
        margin: '0 !important',
        "& label": {
            height: "27px !important",
            lineHeight: "27px !important",
            padding: "0 !important"
        }
    },

    divButton: {
        display: "flex",
        justifyContent: "space-evenly",
        background: "white",
        borderRadius: 20,
        margin: 5
    },
    button1: {
        display: "flex",
        justifyContent: 'center',
        background: '#fff',
        margin: 5,
        width: 50,
        height: 30,
        borderRadius: 15,
        alignItems: "center",
        cursor: 'pointer',
    },
    button2: {
        display: "flex",
        justifyContent: 'center',
        background: theme.props.primaryColor,
        margin: 5,
        width: 50,
        height: 30,
        borderRadius: 15,
        alignItems: "center",
        cursor: 'pointer',
    },

    headerRight: {
        textAlign: "right",
        // [theme.breakpoints.down('sm')]: {
        //     position: "absolute",
        //     top: 5,
        //     right: 5,
        // },
    },
    headerLeft: {
        textAlign: "left",
        display: "flex",
        // [theme.breakpoints.down('sm')]: {
        //     position: "absolute",
        //     top: 5,
        //     left: 5,
        // },
    },
    hiddeMd: {
        [theme.breakpoints.down('md')]: {
            display: "none"
        },
    },
    hiddenPC: {
        [theme.breakpoints.up('md')]: {
            display: "none"
        },
    },
    flexHeader: {
        display: "flex",
        // width: "calc(100% - 60px)",
        width: '100%',
        alignItems: "center",
        justifyContent: "space-between",
    },
    revertIcon: {
        transform: 'rotate(180deg)'
    }
});

MenuAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default React.memo(withStyles(styles)(MenuAppBar));
