import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
// import CKEditor from "ckeditor4-react";
import useDebounce from "react-use/lib/useDebounce";
import {IconButton} from '@material-ui/core';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {ReactComponent as SaveSvg} from "../../../asSvg/saveBGPrimary.svg";
import useIntlId from "../../../hooks/useIntlId";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
        fontSize: 12,
    },
    icon: {
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&.MuiIconButton-root": {
            padding: "3px !important"
        },
        "& svg": {
            fill: theme.props.primaryColor
        }
    },
    content: {
        display: "flex",
        width: "100%"
    },
    editor: {
        width: "calc(100% - 40px)"
    },
    lineHeight: {
        lineHeight: "36px",
        cursor: "pointer",
        padding: "0px 12px",
        borderBottom: "1px solid #C4C4C4",
    },
    descrText: {
        cursor: "pointer",
        padding: 10,
        backgroundColor: "#F1F1F1",
        textAlign: 'left',
    }
}));

function Memo({event, setEvent, callBack = null}) {
    const classes = useStyles();
    const [description, setDescription] = useState(event?.description || null);
    const [open, setOpen] = useState(false)
    const [descriptionIntl] = useIntlId([
        'event.userDescription'
    ])

    useEffect(() => {
        if (callBack && typeof callBack === 'function')
            setDescription(event?.description)
        // eslint-disable-next-line
    }, [event?.description])

    useDebounce(() => {
            if (!event || event?.is_holiday || !description || description === event.description)
                return null;
            setEvent({
                ...event, description
            })
            if (callBack && typeof callBack === 'function')
                callBack(description)
        }, 500, [description]
    );

    const onEditDescription = () => {
        setOpen(!open)
    }

    if (!event || event?.is_holiday)
        return null;

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <IconButton
                    onClick={onEditDescription}
                    className={classes.icon}>
                    {
                        open && <SaveSvg/>
                    }
                    {
                        !open &&
                        <img
                            src={process.env.PUBLIC_URL + '/icons/addDescription.svg'}
                            alt=""
                        />
                    }
                </IconButton>
                <div className={classes.editor}>
                    {
                        open ?
                            <CKEditor
                                editor={ClassicEditor}
                                data={description}
                                // onReady={ editor => {
                                //     // You can store the "editor" and use when it is needed.
                                //     console.log( 'Editor is ready to use!', editor );
                                // } }
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    // console.log( { event, editor, data } );
                                    setDescription(data)
                                }}
                                /*onBlur={(event, editor) => {
                                    setOpen(false)
                                }}*/
                                // onFocus={ ( event, editor ) => {
                                //     console.log( 'Focus.', editor );
                                // } }
                                config={{
                                    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
                                    startupFocus: true,
                                }}
                                onReady={editor => {
                                    if (editor)
                                        editor.editing.view.focus();
                                }}
                            />
                            : (
                                description ?
                                    <div className={classes.descrText}
                                         onClick={() => setOpen(true)}
                                         dangerouslySetInnerHTML={{__html: description}}/>
                                    :

                                    <div className={classes.lineHeight}
                                         onClick={() => setOpen(true)}>{descriptionIntl}</div>
                            )
                    }

                </div>
            </div>
        </div>
    );
}

export default Memo;
