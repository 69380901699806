import React, {createContext, useEffect} from 'react'
import {getGlobal, setGlobal, useGlobal} from 'reactn'
import Fire, {auth} from "../firebaseConfig";
import {API_ZOOM, APP_GLOBAL_ID, CALENDAR_COLLECTION, ZOOM_COLLECTION} from "../config/constants";
import {toast} from "react-toastify";
import {omitByDeep} from "../common/exLodash";
import {findIndex, isEmpty, isFunction, isPlainObject, isUndefined, orderBy} from 'lodash'
import {COOKIE_GENIAM_REFRESH_TOKEN_KEY, setCookie} from "../common/cookies";
import qs from "query-string";
import {getUserData, signInFirebase} from "../actions/user";
import {useHistory} from 'react-router-dom'
import {GCalendarList} from "../actions/googleCalendar";
import {refetchSource} from "../actions/refetchSourcce";
import {removeStorageGoogleUser} from "../common/googleCal/logOutGoogle";
import axios from "axios";
import ClfLinearProgress from "../components/Custom/CLFLinearProgress";
import {getGlobalUrls} from "../common/firebaseRef/globalnavi"
import {fetchGlobalApps} from "../common/fetchGlobalApps"
import {useMyGEProjects, useMyGGProjects, useMySettingList} from "../common/user";

export const CalendarUserContext = createContext({})

export const CalendarUserProvider = ({children}) => {
    const calendarUser = useCalendarUser()
    const [googleStatus] = useGlobal('googleStatus');
    const [user] = useGlobal('user');
    const query = qs.parse(window.location.search);
    const path = window.location.pathname;
    const history = useHistory();
    const [globalApps] = useGlobal('globalApps')

    useMySettingList(user?.user_id)

    useEffect(() => {
        const {
            token,
            refreshToken
        } = query;

        if (token || refreshToken) {
            const opts = {expires: 1826};
            if (process.env.NODE_ENV === 'production') opts.domain = '.geniam.com'
            // store.set('accessToken', token)
            setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, refreshToken, opts);
            setGlobal({
                accessToken: token,
                refreshToken
            }, () => {
                getUserData().then(data => {
                        if (!data)
                            setGlobal({langDefault: "ja-jp"})
                    }
                ).catch(e => {
                    console.log(e);
                });
            });
            // store.set('refreshToken', refreshToken)

            delete query.token;
            delete query.refreshToken;
            history.push(`${path}?${qs.stringify(query)}`)
        } else {
            getUserData().then(data => {
                    if (!data)
                        setGlobal({langDefault: "ja-jp"})
                }
            ).catch(e => {
                console.log(e);
            });
        }

        auth.onAuthStateChanged(user => {
            if (!user) {
                signInFirebase()
            }
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!query)
            return null;
        if (query.showZoomSetting) {
            setGlobal({
                openSetting: true,
                settingTab: 2
            });
            delete query.code;
            history.push(`${path}`);
        }
        if (query.code) {
            getInfoConnected(query, history, path);
        }
        // eslint-disable-next-line
    }, [user?.user_id, query?.code]);

    // useEffect(() => {
    //     if (!user?.user_id) return;
    //     getMetamorLinked()
    // }, [user?.user_id])

    const getInfoConnected = async (query, history, path) => {
        if (!query.code || !user?.user_id) {
            return;
        }
        try {
            const {data} = await axios.post(
                API_ZOOM + `/zoomOauth`, {
                    code: query.code,
                    redirect_uri: process.env.REACT_APP_LINK_COPY,
                    user: {
                        email: user.email
                    }
                });
            if (data) {
                toast.success('Connect Zoom OAuth Success')
            }
            window.close();
        } catch (e) {
            console.log(e);
            toast.error(e?.response?.data || 'Cannot connect to Zoom! Please try again later');
            window.close();
        }
    };

    useEffect(() => {
        if (!user?.user_id || !globalApps?.length)
            return null
        const urlSub = getGlobalUrls(user.user_id)
            .onSnapshot(snapshot => {
                const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
                const userGeniamApps = globalApps.map(item => {
                    let idx = findIndex(data, {id: item.id})
                    if (idx === -1) {
                        return {
                            ...item,
                            global: true,
                            shortLink: {[APP_GLOBAL_ID]: false},
                            geniamApp: true,
                            favorite: {[APP_GLOBAL_ID]: false}
                        };

                    }
                    return {
                        ...item, ...data[idx],
                        geniamApp: true,
                        global: !isUndefined(data[idx].global) ? data[idx].global
                            : (isUndefined(data[idx].checked) ? true : data[idx].checked),

                        shortLink: !isUndefined(data[idx].shortLink) ? data[idx].shortLink
                            : {[APP_GLOBAL_ID]: Boolean(data[idx][APP_GLOBAL_ID])}
                        ,

                        favorite: !isUndefined(data[idx].favorite) ? data[idx].favorite
                            : {[APP_GLOBAL_ID]: Boolean(data[idx].show)}
                    }
                })
                let orderGeniamApps = orderBy(userGeniamApps, "index", "asc")

                const userLinks = orderBy(data.filter(item => !item.geniamApp))
                let orderUserLinks = orderBy(userLinks, "index", "asc")
                setGlobal({
                    userGeniamApps: orderGeniamApps,
                    userLinks: orderUserLinks
                })
            })
        return () => {
            if (urlSub)
                urlSub()
        }
    }, [user?.user_id, globalApps])

    useEffect(() => {
        fetchGlobalApps()
    }, [])

    // console.log(calendarUser)
    if (!googleStatus.initialed || isEmpty(calendarUser)) return <ClfLinearProgress/>
    return (
        <CalendarUserContext.Provider value={calendarUser}>
            {children}
        </CalendarUserContext.Provider>
    )
}

export const useCalendarUser = () => {
    const [calendarUser = {}, setCalendarUser] = useGlobal('calendarUser')
    const [displaySettings, setDisplaySetting] = useGlobal("workTimeDisplay")
    const [user] = useGlobal('user')
    const [googleStatus, setGGStatus] = useGlobal('googleStatus');
    const [, setZoomOAuth] = useGlobal('zoomOAuth');

    useMyGEProjects()
    useMyGGProjects()

    useEffect(() => {
        if (!user || !user.user_id || !googleStatus.initialed) return;

        const unsub = Fire.firestore()
            .doc(CALENDAR_COLLECTION + `/${user.user_id}`)
            .onSnapshot(
                snapshot => {
                    if (!snapshot.exists) return;
                    const data = {id: snapshot.id, ...snapshot.data()}
                    setCalendarUser(data)
                    // check user is sync with google
                    const {googleStatus} = getGlobal()
                    if (data.googleAuth) {
                        if ((!googleStatus.is_login || googleStatus.userProfile?.email !== data.googleAuth.email)) {
                            googleStatus.is_login = true
                            googleStatus.userProfile = data.googleAuth
                            setGGStatus({...googleStatus}, () => {
                                GCalendarList()
                            })
                        }
                    } else {
                        setGlobal({
                            googleStatus: {
                                is_login: false,
                                userProfile: {},
                                initialed: true,
                            },
                            googleCalendarList: [],
                            ggEventLoaded: true,
                            focusText: snapshot.data().focusText
                        },)
                        removeStorageGoogleUser()
                    }
                },
                error => {
                    alert(error.message)
                }
            )

        const unsubDisplay = Fire.firestore()
            .collection(`${CALENDAR_COLLECTION}/${user.user_id}/settings`)
            .onSnapshot(
                snapshot => {
                    if (!snapshot.size) return setDisplaySetting([]);
                    const data = orderBy(snapshot.docs.map(snap => ({...snap.data(), id: snap.id})), 'endDay', 'asc')
                    setDisplaySetting(data, () => {
                        refetchSource("timeWork")
                    })
                },
                error => {
                    alert(error.message)
                }
            )

        const unsubZoomData = Fire.firestore().doc(ZOOM_COLLECTION + `/${user.user_id}`)
            .onSnapshot(snapshot => {
                if (!snapshot.exists) return setZoomOAuth({})

                setZoomOAuth({...snapshot.data(), id: snapshot.id})
            });

        return () => {
            // console.log('unsub useCalendarUser')
            unsub && unsub()
            unsubDisplay && unsubDisplay()
            unsubZoomData && unsubZoomData()
        }
        // eslint-disable-next-line
    }, [user.user_id, googleStatus.initialed]);

    const mergeCalendarUser = (data, {onOk, onFailed}) => {
        if (!user || !user.user_id) return;
        if (!isPlainObject(data)) return;
        data = omitByDeep(data, isUndefined);

        Fire.firestore()
            .doc(CALENDAR_COLLECTION + `/${user.user_id}`)
            .set(data, {merge: true})
            .then((val) => {
                toast.success('updated')
                if (isFunction(onOk)) onOk(val)
            })
            .catch(e => {
                toast.error(e.message)
                if (isFunction(onFailed)) onFailed(e)
            })
    }

    return {calendarUser, mergeCalendarUser, displaySettings}
}
