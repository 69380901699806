import React, {useEffect, useState} from 'react';
import {useAnnouncement} from '../../context/AnnouncementContext';
import {setGlobal, useGlobal} from 'reactn';
import {chunk, cloneDeep, concat, filter, findIndex} from 'lodash';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {GridContextProvider, GridDropZone, GridItem, swap} from "react-grid-dnd";
import {barRef} from '../../common/firebaseRef/annoucement';
import Slider from 'react-slick';
import {Button} from 'antd';
import {AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined} from '@ant-design/icons';
import {db} from "../../firebaseConfig";
import vector from '../../asSvg/Vector.svg'

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 1200,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: function (currentSlide, nextSlide) {
        // console.log("before change", currentSlide, nextSlide);
    }
};

function PreviewHighLight(props) {
    const {bar} = useAnnouncement()
    const [calendarUser] = useGlobal('calendarUser')
    const [user] = useGlobal('user')
    const [openSetting] = useGlobal('openSetting')
    const [alignItems, setAlignItems] = useState(bar?.alignItems || "flex-start")
    const classes = useStyles()
    const [dataHighLight, setDataHighLight] = useState([
        {
            id: "highLight",
            data: [],
        },
        {
            id: "monthTarget",
            data: [],
        },
        {
            id: "weeksTarget",
            data: [],
        },
        {
            id: "dayTarget",
            data: [],
        },
        {
            id: "remind",
            data: []
        }
    ])
    const [listHighLightFilter, setListHighLightFillter] = useState([])
    useEffect(() => {
        if (!bar?.alignItems)
            return
        setAlignItems(bar?.alignItems)
    }, [bar?.alignItems])
    useEffect(() => {
        setListHighLightFillter(
            cloneDeep(filter(dataHighLight, i => i.data.length).sort(function (a, b) {
                return a.index - b.index
            }))
        )
    }, [dataHighLight, openSetting])
    useEffect(() => {
        let newList = []
        let month = []
        let day = []
        let weeks = []
        let dataHighLight = [
            {
                id: "highLight",
                data: [],
            },
            {
                id: "monthTarget",
                data: [],
            },
            {
                id: "weeksTarget",
                data: [],
            },
            {
                id: "dayTarget",
                data: [],
            },
            {
                id: "remind",
                data: []
            }
        ]
        if (bar?.highlight && bar.highlight !== "" && bar?.isShowHighLight) {
            // newList.push({title: bar?.highlight, nameHighLight: 'highLight'})
            newList.push(bar?.highlight)
        }
        let idxTodayHighLight = findIndex(calendarUser?.focusText, {"date": moment().format('YYYY-MM-DD')})
        let idxWeekTarget = findIndex(calendarUser?.focusText, {"date": moment().format('YYYY') + "-W" + moment().isoWeeks()})
        let idxMonthTarget = findIndex(calendarUser?.focusText, {"date": moment().format('YYYY-MM')})
        if (idxTodayHighLight !== -1 && bar?.isShowTodayHighLight && calendarUser?.focusText[idxTodayHighLight]?.focusText) {
            // newList.push({title: calendarUser?.focusText[idxTodayHighLight].focusText, nameHighLight: 'todayHighLight'})
            day.push({
                title: calendarUser?.focusText[idxTodayHighLight].focusText,
                date: calendarUser?.focusText[idxTodayHighLight].date
            })
        }
        if (idxWeekTarget !== -1 && bar?.isShowWeekTarget && calendarUser?.focusText[idxWeekTarget]?.focusText) {
            // newList.push({title: calendarUser?.focusText[idxWeekTarget].focusText, nameHighLight: 'week'})
            weeks.push({
                title: calendarUser?.focusText[idxWeekTarget].focusText,
                date: calendarUser?.focusText[idxWeekTarget].date
            })
        }
        if (idxMonthTarget !== -1 && bar?.isShowMonthTarget && calendarUser?.focusText[idxMonthTarget]?.focusText) {
            // newList.push({title: calendarUser?.focusText[idxMonthTarget].focusText, nameHighLight: 'month'})
            month.push({
                title: calendarUser?.focusText[idxMonthTarget].focusText,
                date: calendarUser?.focusText[idxMonthTarget].date
            })
        }
        let announcementSets = []
        if (bar?.announcementSets) {
            const {data, startDate, times} = bar?.announcementSets
            if (data?.length && times > 0) {
                const chunked = chunk(data.filter(x => x), times)
                let dayDiff = moment().diff(startDate, "days") % (chunked.length ?? 1)
                dayDiff = dayDiff > 0 ? dayDiff - 1 : 0
                if (chunked[dayDiff] && chunked[dayDiff]?.length) {
                    announcementSets = chunked[dayDiff].map((text, idx) => ({
                        title: text,
                        index: idx + dayDiff * times
                    }))
                }
            }
        }
        const todayRemind = filter(bar?.reminds || [], (item) => item.content && moment(item.time).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'))
            .map(item => ({...item, title: item.content, content: ''}))
        let listRemind = concat(announcementSets, todayRemind)
        // setListHighLight(newList)

        let indexHighLight = findIndex(dataHighLight, {id: "highLight"})
        if (indexHighLight !== -1 && bar?.highLightBar) {
            dataHighLight[indexHighLight].data = newList
            dataHighLight[indexHighLight].index = Number.isInteger(bar?.indexHighLight) ? bar?.indexHighLight : 0
        }
        let indexMonth = findIndex(dataHighLight, {id: "monthTarget"})
        if (indexMonth !== -1 && bar?.highLightBar) {
            dataHighLight[indexMonth].data = month
            dataHighLight[indexMonth].index = Number.isInteger(bar?.indexMonthTarget) ? bar?.indexMonthTarget : 3
        }
        let indexWeeks = findIndex(dataHighLight, {id: "weeksTarget"})
        if (indexWeeks !== -1 && bar?.highLightBar) {
            dataHighLight[indexWeeks].data = weeks
            dataHighLight[indexWeeks].index = Number.isInteger(bar?.indexWeeksTarget) ? bar?.indexWeeksTarget : 2
        }
        let indexDay = findIndex(dataHighLight, {id: "dayTarget"})
        if (indexDay !== -1 && bar?.highLightBar) {
            dataHighLight[indexDay].data = day
            dataHighLight[indexDay].index = Number.isInteger(bar?.indexDayTarget) ? bar?.indexDayTarget : 1
        }
        let indexRemind = findIndex(dataHighLight, {id: "remind"})
        if (indexRemind !== -1 && bar?.remindBar) {
            dataHighLight[indexRemind].data = listRemind
            dataHighLight[indexRemind].index = Number.isInteger(bar?.indexRemind) ? bar?.indexRemind : 4
        }
        setDataHighLight((prevHighLight) => {
            if (isDiffHighLight(prevHighLight, [...dataHighLight]))
                return [...dataHighLight]
            return prevHighLight
        })

    }, [calendarUser?.focusText, bar, openSetting])

    useEffect(() => {
        setGlobal({
            colHighLight: filter(dataHighLight, i => i?.data?.length).length
        })
    }, [dataHighLight, bar, openSetting])
    const isDiffHighLight = (prev, next) => {
        if (prev?.length !== next?.length)
            return true
        let result = false
        prev.forEach((item, index) => {
            const nextItem = next[index]
            if (item.id !== nextItem.id ||
                item.index !== nextItem.index ||
                item?.data?.length !== nextItem?.data?.length ||
                item?.data[0]?.title !== nextItem?.data[0]?.title ||
                item?.data[0]?.date !== nextItem?.data[0]?.date
            ) {
                result = true
            }
            if (item.id === "highLight" && item?.data[0] !== nextItem?.data[0]) {
                result = true
            }
        })
        return result
    }
    const onChange = async (sourceId, sourceIndex, targetIndex, targetId) => {
        try {
            const nextState = swap(cloneDeep(listHighLightFilter), sourceIndex, targetIndex)
            nextState.forEach((item, index) => {
                item.index = index
            })
            setDataHighLight([...nextState])
            let temp = nextState.length
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            let barNew = {...bar}
            let idxHighLight = findIndex(nextState, {id: "highLight"})
            if (idxHighLight !== -1) {
                barNew.indexHighLight = nextState[idxHighLight].index
            } else {
                barNew.indexHighLight = temp
                temp++
            }
            let indexDay = findIndex(nextState, {id: "dayTarget"})
            if (indexDay !== -1) {
                barNew.indexDayTarget = nextState[indexDay].index
            } else {
                barNew.indexDayTarget = temp
                temp++
            }
            let idxWeeks = findIndex(nextState, {id: "weeksTarget"})
            if (idxWeeks !== -1) {
                barNew.indexWeeksTarget = nextState[idxWeeks].index
            } else {
                barNew.indexWeeksTarget = temp
                temp++
            }
            let idxMonth = findIndex(nextState, {id: "monthTarget"})
            if (idxMonth !== -1) {
                barNew.indexMonthTarget = nextState[idxMonth].index
            } else {
                barNew.indexMonthTarget = temp
                temp++
            }
            let idxRemind = findIndex(nextState, {id: "remind"})
            if (idxRemind !== -1) {
                barNew.indexRemind = nextState[idxRemind].index
            } else {
                barNew.indexRemind = temp
                temp++
            }
            batch.set(AnnouncementBar, {
                ...barNew,
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }

    }
    const OpenSettingHighlight = (item, highLight) => {
        if (openSetting) return
        let data = {}
        data.target = highLight
        data.time = item?.date
        setGlobal({
            focusHighLight: {...data},
            openSetting: true,
            settingTab: 7
        })
        // setOpenSetting(true)
        // setActiveIndex(6)
        // setFocusHighLight(e)
    }
    if (!isNaN(bar?.rotationInterval)) {
        settings.autoplaySpeed = bar.rotationInterval * 1000
    }
    const handleClick = re => () => {
        if (openSetting) return
        setGlobal({
            openSetting: true,
            settingTab: 8,
            focusRemind: {...re}
        })
    }
    const updateAlignItem = async (value) => {
        try {
            const batch = db.batch()
            const AnnouncementBar = barRef(user?.user_id)
            batch.set(AnnouncementBar, {
                ...bar,
                alignItems: value
            }, {merge: true})
            await batch.commit()
        } catch (e) {
            console.log(e);
        }
    }

    if (!bar.highLightBar && !bar.remindBar)
        return null
    return (
        <>
            {
                openSetting &&
                <div>
                    <Button
                        type={alignItems === "flex-start" ? "primary" : ""}
                        onClick={
                            () => {
                                setAlignItems("flex-start")
                                updateAlignItem("flex-start")
                            }
                        }
                    >
                        <AlignLeftOutlined/>
                    </Button>
                    <Button
                        type={alignItems === "center" ? "primary" : ""}

                        onClick={
                            () => {
                                setAlignItems("center")
                                updateAlignItem("center")
                            }
                        }
                    >
                        <AlignCenterOutlined/>
                    </Button>
                    <Button
                        type={alignItems === "flex-end" ? "primary" : ""}
                        onClick={
                            () => {
                                setAlignItems("flex-end")
                                updateAlignItem("flex-end")
                            }
                        }
                    >
                        <AlignRightOutlined/>
                    </Button>
                </div>
            }
        <div className={classes.root}>

            <div style={{width: "100%"}}>
                <GridContextProvider
                    onChange={onChange}

                >
                    <GridDropZone
                        id={'highlight'}
                        boxesPerRow={listHighLightFilter.length}
                        rowHeight={50}
                        style={{height: 50}}
                        disableDrag={!openSetting}
                    >
                        {
                            cloneDeep(listHighLightFilter).map((highLight, idx) => {
                                return (
                                    <GridItem key={highLight.id} className={classes.GridItem}>
                                        {
                                            highLight?.data?.length > 1 &&
                                            <Slider {...settings}>
                                                {highLight?.data.map((re, index) => {

                                                    return (
                                                        <div
                                                            onClick={handleClick(re)}
                                                                className={classes.ItemContent}
                                                                key={index}>
                                                                <div
                                                                    style={{
                                                                        alignItems: alignItems,
                                                                        display: "flex",
                                                                        flexDirection: "column"
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={classes.title}>
                                                                        {re.title} {re.content ? `- ${re.content}` : ""}
                                                                    </div>
                                                                    {
                                                                        highLight?.id === "remind" &&
                                                                        <div
                                                                            className={classes.subTitle}>Remind</div>
                                                                    }
                                                                </div>
                                                            </div>)
                                                    })}
                                                </Slider>
                                            }
                                            {highLight?.data?.length === 1 && highLight?.data?.map((item, index) =>
                                                (<div
                                                        key={index}
                                                        style={{alignItems: alignItems}}
                                                        className={classes.ItemContent}
                                                        onClick={() => OpenSettingHighlight(item, highLight.id)}
                                                    >
                                                        {/*<div>{item?.date}</div>*/}
                                                        <div className={classes.title}>{item?.title || item}</div>
                                                        {
                                                            highLight?.id === "highLight" ?
                                                                <div className={classes.subTitle}>Highlight</div>
                                                                :
                                                                highLight?.id === "remind" ?
                                                                    <div
                                                                        className={classes.subTitle}>Remind</div>
                                                                    :
                                                                    <div className={classes.subTitle}>{item.date}</div>
                                                        }
                                                    </div>
                                                )
                                            )}
                                            {
                                                idx + 1 < listHighLightFilter.length &&
                                                <img
                                                    className={classes.arrow}
                                                    src={vector}
                                                />
                                            }
                                        </GridItem>
                                    )
                                }
                            )
                        }
                    </GridDropZone>
                </GridContextProvider>
            </div>
        </div>
        </>
    )
}

export default React.memo(PreviewHighLight)
const useStyles = makeStyles(theme => ({
    GridItem: {
        overflow: 'hidden'
    },
    ItemContent: {
        cursor: "pointer",
        backgroundColor: theme.props.primaryColor,
        color: "white",
        // height: 50,
        overflow: "hidden",
        //borderRadius: '10px',
        justifyContent: "center",
        display: "flex !important",
        flexDirection: "column",

        "& a": {
            whiteSpace: 'nowrap'
        },
        padding: "5px 20px 5px 10px"
    },
    arrow: {
        position: "absolute",
        top: "-5px",
        right: 0,
        height: '120%'
    },
    slideContainer: {
        // marginLeft: 30,
        width: "100%"
    },
    title: {
        marginBlockEnd: "unset",
        fontWeight: 700,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: 16,
        lineHeight: "23px",
        maxWidth: "calc(100% - 10px)"
    },
    subTitle: {
        fontSize: 12,
        lineHeight: "13px",
        textAlign: "left"
    },
    root: {
        backgroundColor: theme.props.primaryColor,
        width: "100%",
        "& .ant-btn-primary": {
            borderColor: theme.props.primaryColor,
            background: theme.props.primaryColor,
            "&:hover": {
                color: "#fff !important"
            }
        },
        "&:hover": {
            "& .ant-btn:hover, .ant-btn:focus": {
                color: theme.props.primaryColor,
                borderColor: theme.props.primaryColor
            }
        }
    },

}))
