import {db} from "../firebaseConfig";
import {getGlobal} from 'reactn'

export let LOGIN_URL = process.env.REACT_APP_LOGIN_URL
export let REGISTER_URL = process.env.REACT_APP_REGISTER_URL
export let SWITCH_ACCOUNT = process.env.REACT_APP_LOGIN_URL + '/select-account'
export let API_GENIAM = process.env.REACT_APP_API_GENIAM
export let API_ZOOM = process.env.REACT_APP_API_ZOOM
export let CREATE_GOOGLE_TASKS = process.env.REACT_APP_GOOGLE_TASKS + '/createTask';
export let DELETE_GOOGLE_TASKS = process.env.REACT_APP_GOOGLE_TASKS + '/deleteTask';
export let IS_PRODUCTION = process.env.REACT_APP_ENV === 'production'
export let CALENDAR_COLLECTION = IS_PRODUCTION ? "calendar" : "calendar-stg"
export let ZOOM_COLLECTION = IS_PRODUCTION ? "metamors" : "metamors-stg"
export let MILESTONE_COLLECTION = IS_PRODUCTION ? "milestones" : "milestones-stg"
export let USER_COLLECTION = IS_PRODUCTION ? "users" : "users-stg"
export let MEETING_COLLECTION = IS_PRODUCTION ? "meetings" : "meetings-stg"
export let IDENTITY_COLLECTION = IS_PRODUCTION ? "identities" : "identities-stg"
export let RECOMMEND_COLLECTION = IS_PRODUCTION ? "recommend" : "recommend-stg"
export const HEALTHCHECK = API_GENIAM + '/healthcheck'
export const ACCESS_TOKEN_KEY = 'accessToken'
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const ID_TOKEN_KEY = 'idToken';
export const LOGIN_KEY = 'login';
export const REGISTER_KEY = 'register';
export const ACCOUNTS_KEY = 'accounts';
export const USER_INFO_KEY = 'userInfo';
export const REDIRECT_URL_KEY = 'service_redirect_url';
export const USER_AVATAR_UPLOAD_URL = API_GENIAM + '/user/avatar';
export const LOGOUT_URL = API_GENIAM + "/logout";
export const METAMOR_URL = process.env.REACT_APP_METAMOR_URL
export let GET_USER_INFO = API_GENIAM + '/getuserdata'
export let CHANGE_ACCOUNT_INFO = API_GENIAM + '/change-account-info'
export let GET_USER_EMAIL = API_GENIAM + '/getemail'
export const CALENDAR_API_KEY = "AIzaSyB1nGsOlnZRfv5YPnVUPS_icNqgJ_Vk3xE"

export const CREATED_ACTION = "CREATED"
export const UPDATED_ACTION = "UPDATED"
export const DELETED_ACTION = "DELETED"
export const CHANGE_TIME_ACTION = "CHANGE_TIME"

export const USER_COLL = IS_PRODUCTION ? "users" : "users-stg"
export const SUBSCRIPTION_COLL = 'subscriptions'
export const STRIPE_PUBLISH_KEY = IS_PRODUCTION ? 'pk_live_ndRdZMAryciJI126GNjn2wuE' : 'pk_test_P3e8GwkyphGqmyIk5dZgPp6J'

export const APPOINTMENT_DOMAIN = IS_PRODUCTION ? 'https://appy.geniam.com' : 'https://appy-stg.geniam.com'
export const APP_GLOBAL_ID = "milestone"

export const HX_CLOUD_DOMAIN = IS_PRODUCTION ? 'https://hx-cloud.geniam.com' : 'https://hx-cloud-stg.geniam.com'
export const MILESTONE_DOMAIN = IS_PRODUCTION ? "https://milestone.geniam.com/" : "https://milestone-stg.geniam.com/"
export const ZOOM_REDIRECT_OAUTH = IS_PRODUCTION ? "https://calendar.geniam.com" : "https://calendar-stg.geniam.com"

export const GLOBAL_NAVI_APPS_COLL = IS_PRODUCTION ? "GlobalNavigation" : "GlobalNavigation-stg"
export const SUBSCRIPTION_SERVICES = IS_PRODUCTION ? "https://myaccount.geniam.com/account/subscription" : "https://myaccount-stg.geniam.com/account/subscription"

export const myCal = () => {
    const uid = getGlobal().user?.user_id
    if (!uid) return Promise.reject('user not found')
    return db.collection(CALENDAR_COLLECTION)
        .doc(uid)
}
