import {db} from "../../firebaseConfig";
import {CALENDAR_COLLECTION} from "../../config/constants";
import {getGlobal} from "reactn";
import {find} from "lodash/collection";

export const getGeniamProjects = (userId, callback) => {
    return db.collection(CALENDAR_COLLECTION)
        .doc(userId)
        .collection("projects")
        .where("is_deleted", "==", false)
        .onSnapshot(querySnapshot => {
                if (callback) {
                    callback(null, querySnapshot)
                }
            },
            error => {
                if (callback) {
                    callback(error, null)
                }
            }
        )

}

export const getGeniamProjectColor = (uuid) => {
    const {groupsMs} = getGlobal()
    return find(groupsMs, p => p.uuid === uuid, null)?.color
}
