import {proxy, snapshot} from "valtio";
import {watch} from "valtio/utils";
import {cloneDeep, findIndex, remove} from "lodash";
import {getGlobal, setGlobal} from "reactn";
import {refetchSource} from "../actions/refetchSourcce";


export let geEvs = proxy({})
export let ggEvs = proxy({})
// const allEvents = derive({
//         GeGGEvents: (get) => ({...get(geEvs), ...get(ggEvs)})
//     })
const allEvents = proxy({})
watch(get => {
    const allEvs = {...get(geEvs),...get(ggEvs)}
    for (const key in allEvs){
        allEvents[key] = allEvs[key]
    }

})

export const changeTime = (event) => {
    const geEvents = snapshot(geEvs)
    const ggEvents = snapshot(ggEvs)
    const {project_uuid, is_google, id, googleEvent} = event
    if (is_google || googleEvent) {
        let idx = findIndex(ggEvents[project_uuid]?.events || [], {id})
        if (idx !== -1) {
            let _event = cloneDeep(ggEvents[project_uuid].events[idx])
            _event.start = event.start
            _event.end = event.end
            _event.allDay = event.allDay
            ggEvs[project_uuid].events[idx] = _event
        }
        return
    }
    if (geEvents[project_uuid]?.events?.length) {
        let idx = findIndex(geEvents[project_uuid].events, {id})
        if (idx !== -1) {
            let _event = cloneDeep(geEvents[project_uuid].events[idx])
            _event.start = event.start
            _event.end = event.end
            _event.allDay = event.allDay
            geEvs[project_uuid].events[idx] = _event
        }
    }

}

export const createEvent = (event) => {
    const {googleCalendarList} = getGlobal()
    let geEvents = cloneDeep(snapshot(geEvs))
    let ggEvents = cloneDeep(snapshot(ggEvs))
    const {project_uuid, is_google, googleEvent} = event
    if (is_google || googleEvent) {
        let idx = findIndex(googleCalendarList, {id: project_uuid})
        if (idx === -1)
            return;
        if (ggEvents[project_uuid]) {
            ggEvents[project_uuid].events.push(event)
        } else {
            ggEvents[project_uuid] = {
                id: project_uuid,
                events: [event],
                name: googleCalendarList[idx].summary,
                color: googleCalendarList[idx].backgroundColor
            }
        }
        ggEvs[project_uuid] = ggEvents[project_uuid]
        return
    }
    if (geEvents[project_uuid]) {
        geEvents[project_uuid].events.push(event)
    } else {
        geEvents[project_uuid] = {
            id: project_uuid,
            events: [event]
        }
    }
    geEvs[project_uuid] = geEvents[project_uuid]

}

export const deleteEvent = (event, callback) => {
    const {voteEvents} = getGlobal()
    const geEvents = snapshot(geEvs)
    const ggEvents = snapshot(ggEvs)
    const {is_google, id, googleEvent} = event
    if (!event.project_uuid) {
        remove(voteEvents, {id})
        setGlobal({
            voteEvents: [...voteEvents]
        }, () => {
            refetchSource('vote')
        })
        return;
    }
    if (is_google || googleEvent) {
        for (const key in ggEvents) {
            const idx = findIndex(ggEvents[key].events, {id})
            if (idx > -1) {
                let _events = ggEvents[key].events.filter( e => e.id !== id)
                ggEvs[key].events = _events
            }
        }
        if (callback && typeof callback === "function") {
            callback(event)
        }
        return
    }
    for (const key in geEvents) {
        const idx = findIndex(geEvents[key].events, {id})
        if (idx > -1) {
            let _events = geEvents[key].events.filter( e => e.id !== id)
            geEvs[key].events = _events
        }
    }
    if (callback && typeof callback === "function") {
        callback(event)
    }
}

export default allEvents
