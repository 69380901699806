import {lazy} from 'react';

export const MenuAppBar = lazy(() => import('./MenuAppBar/MenuAppBar'))
export const NavApp = lazy(() => import('./Navigation/NavApp'))
export const Setting = lazy(() => import('./MenuAppBar/Setting'))
export const AccountPopup = lazy(() => import('./MenuAppBar/AccountPopup'))

//setting
export const CalendarEventSetting = lazy(() => import('./MenuAppBar/CalendarEventSetting'))
export const SyncSetting = lazy(() => import('./MenuAppBar/googleSync'))
export const TextSelectFrom = lazy(() => import('./MenuAppBar/TextSelectFrom'))
export const TextSelectTo = lazy(() => import('./MenuAppBar/TextSelectTo'))
export const ListSetting = lazy(() => import('./MenuAppBar/ListSetting'))
export const SelectCalendarPopup = lazy(() => import('./Calendar/google/SelectCalendarPopUp'))
export const ProjectSettings = lazy(() => import('./MenuAppBar/ProjectSettings/index'))
export const IdentitySettings = lazy(() => import('./MenuAppBar/IdentitySettings/index'))
export const ZoomSettings = lazy(() => import('./MenuAppBar/VideoConferencing'))

// home
export const ContentHomeApp = lazy(() => import('./Application/ContentHomeApp'))
export const LeftHomeApp = lazy(() => import('./Application/LeftHomeApp'))
export const TitleShow = lazy(() => import('./Calendar/Tooltip'))
export const CalendarIcon = lazy(() => import('./Calendar/CalendarIcon'))
export const AddCalendar = lazy(() => import('./AddGroupCalendar/AddCalendar'))
export const UpdateRecuringPopup = lazy(() => import('./Calendar/UpdateRecuringPopup'))
export const GoogleProject = lazy(() => import('./GroupCalendar/GoogleProject'))
export const GroupCalendar = lazy(() => import('./GroupCalendar/GroupCalendar'))

//MileStone
export const Milestone = lazy(() => import('../Page/MilestoneHome'))
export const ContentMilestone = lazy(() => import('./MileStone/ContentMilestone'))
export const ProjectsSortView = lazy(() => import('./MileStone/ProjectsSortView'))
export const MilestoneSortComponent = lazy(() => import('./MileStone/MilestoneSortComponent'))
export const MilestoneComponent = lazy(() => import('./MileStone/MilestoneComponent'))
// export const ButtonSwitch = lazy(() => import('./MileStone/HandleComponent/ButtonSwitch'))

// calendar
export const CalendarComponent = lazy(() => import('./Calendar/calendar'))
export const AddIdentity = lazy(() => import('./Calendar/AddIdentityDialog'))
// project
export const AddProjectPopup = lazy(() => import('./TaskListApp/AddProjectPopup/AddProjectPopup'))

// MeetingUnAuth Meeting

// Search
export const Search = lazy(() => import('./Search/index'))


//Habits

// export const HabitsHome = lazy(() => import('../Page/HabitsHome'))
