import axios from "axios";
import {CAL_API} from "../config/apiUrls";
import {getGlobal, setGlobal} from 'reactn'
import toast from '../utils/toast'
import {concat, findIndex, remove, uniqBy,} from "lodash";
import {CALENDAR_COLLECTION, USER_COLLECTION} from "../config/constants";
import {db} from "../firebaseConfig";
import {getUserProjectsColl} from "../common/firebaseRef/meetings";
import store from 'store'
import {getUser} from "../common/firebaseRef/calendar";

export const updateColor = (uuid, color) => {
    const {groups} = getGlobal()
    let idx = findIndex(groups, {'uuid': uuid})
    if (idx !== -1 && groups[idx].color !== color) {
        groups[idx].color = color
        setGlobal({groups: [...groups]})
    }
}
export const updateGroupName = (uuid, name) => {
    const {groups} = getGlobal()
    let idx = findIndex(groups, {'uuid': uuid})
    if (idx !== -1 && groups[idx].name !== name) {
        groups[idx].name = name
        setGlobal({groups: [...groups]})
    }
}
export const DeleteGroup = (uuid) => {
    const {groups} = getGlobal()

    axios.post(CAL_API + '/calendar/delete', {
        uuid
    }).then(res => {
        toast.success("DELETE SUCCESS")
        let nextGroups = [...groups]
        remove(nextGroups, g => {
            return g.uuid === uuid
        })
        console.log(nextGroups)
        setGlobal({groups: nextGroups})
    })
        .catch(e => {
            console.log(e)
        })
}
export const AddGroups = (group) => {
    const {groups} = getGlobal()
    let nextGroups = [...groups]
    nextGroups.push(group)
    setGlobal({groups: uniqBy(nextGroups, 'uuid')})
}
export const AddMoreGroups = Groups => {
    const {groups} = getGlobal()
    let nextGroups = concat(groups, Groups)
    setGlobal({groups: uniqBy(nextGroups, 'uuid')})
}
export const ChangeBorderEvents = (calendar_id, color) => {
    const {groups} = getGlobal()
    let idx = findIndex(groups, {'uuid': calendar_id})
    if (idx !== -1) {
        groups[idx].events.forEach(e => {
            e.borderColor = color
            if (!e.colorId) {
                e.backgroundColor = color
            }
        })
    }

    setGlobal({groups: [...groups]})
}

export const UpdateSync = (uuid, sync_id, sync_email, cal) => {
    const {projects, calendarSelected, googleCalendarList} = getGlobal()
    let nextGroups = [...projects]
    let index = findIndex(nextGroups, {'uuid': uuid})
    if (index !== -1) {
        nextGroups[index] = {...nextGroups[index], is_sync: true, sync_id, sync_email}
        calendarSelected.push(cal)
        googleCalendarList.unshift({...cal, checked: false})
        setGlobal({projects: nextGroups, calendarSelected: [...calendarSelected], googleCalendarList})
        store.set('calendarSelected', JSON.stringify(calendarSelected))
    }
}
export const RemoveGroup = uuid => {
    const {groups, GChannel} = getGlobal()
    let nextGroups = [...groups]
    remove(nextGroups, g => {
        return g.uuid === uuid
    })
    setGlobal({groups: nextGroups})
    remove(GChannel, g => g.calendar_id === uuid)
    setGlobal({GChannel: [...GChannel]})
    store.set('GChannel', JSON.stringify([...GChannel]))
}

export const isReadOnly = (group) => {
    if (group.accessRole && group.accessRole === 'reader')
        return true
    return false
}

export const projectsCalendarCollRef = (userId) => {
    userId = userId || getUser('user_id')
    return db.collection(`${CALENDAR_COLLECTION}/${userId}/projects`)
}

export const projectsCollRef = (userId) => {
    return db.collection(`${USER_COLLECTION}/${userId}/projects`)
}

export const milestoneSettingCollRef = (userId) => {
    userId = userId || getUser('user_id')
    return db.collection(`${USER_COLLECTION}/${userId}/milestoneSetting`)
}
export const milestoneSettingDocRef = (docId) => {
    return milestoneSettingCollRef().doc(docId)
}

export const updateUsersProjects = async (projectID, userID, selected) => {
    try {
        const projectRef = projectsCollRef(userID).doc(projectID)
        const snaps = await projectRef.get()
        if (!snaps.exists)
            return null;
        await projectRef.set({msSelected: selected}, {merge: true})

    } catch (e) {
        console.log(e)
    }
}

export const updateProjectGeniam = async (projectID, userId, selected) => {
    try {
        const projectRef = getUserProjectsColl(userId).doc(projectID)
        const snaps = await projectRef.get()
        if (!snaps.exists)
            return null;
        await projectRef.set({msSelected: selected}, {merge: true})
    } catch (e) {
        console.log(e)
    }
}
