import MenuItemContainer from "../MenuItemContainer";
import {makeStyles} from "@material-ui/core/styles";
import {CirclePicker} from 'react-color';
import useIntlId from "../../../hooks/useIntlId";

const useStyles = makeStyles(theme => ({
    handleView: {
        width: '100%',
        height: '100%',
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0 5px",
        color: "#fff",
        display: 'flex',
        justifyContent: 'center',
        "&.MuiTabs-root": {
            minHeight: "unset !important",
        },
        "& .MuiTab-root": {
            color: "#000",
            minWidth: "auto",
            padding: "4px 15px",
            minHeight: "auto",
            fontWeight: "bold",
            fontSize: "1.2rem",
            borderRadius: "20px"
        },
        "& .Mui-selected": {
            width: "100%",
            background: theme.props.primaryColor,
            color: "#fff",
            height: '100%'
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "center",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: 'initial'
        }
    },
    tabIndex: {
        minWidth: '140px !important'
    },
    actions: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnPrimary: {
        background: `${theme.props.primaryColor} !important`,
        borderColor: `${theme.props.primaryColor} !important`
    },
    btnSecondary: {
        background: `${theme.props.secondaryColor} !important`,
        borderColor: `${theme.props.secondaryColor} !important`
    },
    title: {
        fontWeight: 700,
        color: `${theme.props.primaryColor}`,
        textTransform: "uppercase",
        textAlign: "center",
        padding: 5,
        borderRadius: "5px",
        marginBottom: 7
    },
}));

export const colorsDefault = [
    '#FF1717',
    '#FF5C00',
    '#FF9900',
    '#E1CB02',
    '#7EE100',
    '#00E14D',
    '#00E1E1',
    '#1790FF',
    '#0029FF',
    '#7000FF',
    '#BE0072',
    '#BE0022',
    '#690003',
    '#634100',
    '#0E6300',
    '#002863',
    '#3D0063',
    '#292929',
    '#5C5C5C',
    '#939393'
]

function ColorPickerComponent({color, setColor, setOpen}) {
    const classes = useStyles();
    const [themeIntl] = useIntlId([
        'edit.workspace.theme',
    ])

    const onChangeComplete = async (value, name) => {
        if (name === 'primary') {
            setColor({...color, primary: value.hex.toUpperCase()})
        } else {
            setColor({...color, secondary: value.hex.toUpperCase()})
        }
    }

    return (
        <MenuItemContainer backgroundColor={'#F1F1F1'} width={'100%'} height={'auto'} display={'grid'}>
            <MenuItemContainer padding={'10px'} height={'auto'} display={'grid'}>
                <div className={classes.title}>{themeIntl}</div>
                <CirclePicker
                    colors={colorsDefault}
                    color={color?.primary}
                    width={'100%'}
                    circleSize={19}
                    circleSpacing={20}
                    onChangeComplete={(value) => onChangeComplete(value, 'primary')}
                />
            </MenuItemContainer>
        </MenuItemContainer>
    );
}

export default ColorPickerComponent;
