import axios from 'axios'
import {API_ZOOM, ZOOM_COLLECTION} from "../config/constants";
import Fire from '../firebaseConfig';

const jwt = require('jsonwebtoken');

const clientId = process.env.REACT_APP_ENV === 'production' ? "MTYfIbJeTNyHKKV7j7L98A" : "lUCopqPS2myRN6S4qHKtg";
// https://zoom.us/oauth/authorize?client_id=lUCopqPS2myRN6S4qHKtg&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3023
export const handleConnectZoomOAuth = async () => {
    try {
        const redirect_uri = process.env.REACT_APP_ENV === 'production' ? 'https://calendar.geniam.com' : 'https://calendar-stg.geniam.com';
        window.open(
            `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}`,
            "zoomLogin",
            "width=700,height=600,top=150,left=200"
        )
    } catch (e) {
        console.log(e);
    }
};

export const handleCreateZoomMeeting = async (type, event, title, zoneUserShare) => {
    try {
        const zoomOAuthDocRef = Fire.firestore().doc(ZOOM_COLLECTION + `/${event.user}`);
        const snapshot = await zoomOAuthDocRef.get();
        if (!snapshot.exists)
            return null;

        const barrierToken = jwt.sign({
            username: event.user,
            exp: Math.floor(Date.now() / 1000) + 3600
        }, 'shhhhh');

        const {data} = await axios.post(API_ZOOM + '/create', {
            type: type,
            timeStart: event.start,
            timeEnd: event.end,
            schedule: {
                ...event,
                service_name: "Calendar meetings",
                title: title,
            },
            timeZone: zoneUserShare
        }, {
            'headers': {
                'Authorization': `Bearer ${barrierToken}`
            }
        });
        return data
    } catch (e) {
        console.log(e);
        return null
    }
};

export const handleUpdateZoomMeeting = async (startTime, endTime, zoomLink, userId, title) => {
    try {
        const zoomOAuthDocRef = Fire.firestore().doc(ZOOM_COLLECTION + `/${userId}`);
        const snapshot = await zoomOAuthDocRef.get();
        if (!snapshot.exists)
            return null;

        const barrierToken = jwt.sign({
            username: userId,
            uid: userId,
            exp: Math.floor(Date.now() / 1000) + (60 * 5)
        }, 'shhhhh');

        let meetingId = zoomLink.substring(zoomLink.lastIndexOf('/') + 1, zoomLink.lastIndexOf('?'));

        await axios.post(API_ZOOM + '/update', {
            type: 'meetings',
            startTime,
            endTime,
            meetingId: `${meetingId}`,
            userId,
            title
        }, {
            'headers': {
                'Authorization': `Bearer ${barrierToken}`
            }
        })
    } catch (e) {
        console.log(e);
    }
};
