import React from 'react';
import {Grid, withStyles} from "@material-ui/core";
import {isReadOnly} from "../../../actions/group";
import {CheckOutlined} from "@material-ui/icons";
import {useGlobal} from "reactn";
import styled from "styled-components";
import {Button, Tooltip} from "antd";
import {publicIcon} from "../handlers/getIcon";

const ProjectItem = styled.button`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px 10px;
    border-radius: 25px;
    height: 27px;

    &:hover {
        cursor: pointer;
    }
;
    border: none;
    color: #fff;
    background-color: ${props => props.item ? `${props.item.color || props.item.backgroundColor}` : "#000"};

`
const ProjectItemActive = styled(ProjectItem)`
    border: ${props => props.item ? `solid 2px ${props.item.color || props.item.backgroundColor}` : "none"};
    color: ${props => props.item ? `${props.item.color || props.item.backgroundColor}` : "#000"};
    background-color: #fff;
    padding: 4px 15px 4px 10px;
`

function Project(props) {
    const {
        classes,
        event,
        setEvent,
        projects,
        // onClose = null
    } = props

    const [, setOpenSetting] = useGlobal('openSetting')
    const [, setTab] = useGlobal('settingTab')
    if (!event)
        return null
    return (
        <div className={classes.root}>
            <Grid container>
                {projects.map((item, index) => {
                    if (!item.msSelected) return null;
                    if ((!event.isEventVote || !event.project_uuid) && ((!Boolean(item.is_sync) && !isReadOnly(item)) || (!Boolean(item.is_sync) && event.is_holiday))) {
                        return (
                            <Grid item key={index} xs={3} className={classes.itemProject}>
                                <Tooltip title={item.name || item.summary} overlayStyle={{zIndex: 9999}}
                                         color={item.color}>
                                    {
                                        item.uuid === event.project_uuid ?
                                            <div className={classes.relative}>
                                                <ProjectItemActive
                                                    disabled={event.is_holiday}
                                                    onClick={() => {
                                                        setEvent({
                                                            ...event,
                                                            project_uuid: item.uuid,
                                                            resourceId: event?.allDay ? item.uuid : null,
                                                            borderColor: item.color,
                                                            is_google: Boolean(item.is_google)
                                                        })
                                                    }}
                                                    item={item}
                                                >
                                                    {item.name || item.summary}
                                                </ProjectItemActive>
                                                <CheckOutlined className={classes.iconActive}/>
                                            </div>
                                            :
                                            <ProjectItem
                                                disabled={event.is_holiday}
                                                onClick={() => {
                                                    setEvent({
                                                        ...event,
                                                        project_uuid: item.uuid,
                                                        resourceId: event?.allDay ? item.uuid : null,
                                                        borderColor: item.color,
                                                        is_google: Boolean(item.is_google)
                                                    })
                                                }}
                                                item={item}
                                            >
                                                {item.name || item.summary}
                                            </ProjectItem>
                                    }
                                </Tooltip>
                            </Grid>
                        )
                    }
                    if (event.isEventVote) {
                        return (
                            <Grid item key={index} xs={3} className={classes.itemProject}>
                                <Tooltip title={item.name || item.summary} overlayStyle={{zIndex: 9999}}
                                         color={item.color}>
                                    {
                                        item.uuid === event.project_uuid ?
                                            <div className={classes.relative}>
                                                <ProjectItemActive
                                                    disabled={event.is_holiday}
                                                    onClick={() => {
                                                        setEvent({
                                                            ...event,
                                                            project_uuid: item.uuid,
                                                            borderColor: item.color,
                                                            is_google: Boolean(item.is_google)
                                                        })
                                                    }}
                                                    item={item}
                                                >
                                                    {item.name || item.summary}
                                                </ProjectItemActive>
                                                <CheckOutlined className={classes.iconActive}/>
                                            </div>
                                            :
                                            <ProjectItem
                                                disabled={event.is_holiday}
                                                onClick={() => {
                                                    setEvent({
                                                        ...event,
                                                        project_uuid: item.uuid,
                                                        borderColor: item.color,
                                                        is_google: Boolean(item.is_google)
                                                    })
                                                }}
                                                item={item}
                                            >
                                                {item.name || item.summary}
                                            </ProjectItem>
                                    }
                                </Tooltip>
                            </Grid>
                        )
                    }
                    return null
                })}
            </Grid>
            <div className={classes.wrapProject}>
                <Button
                    className={classes.buttonIcon2}
                    onClick={() => {
                        setEvent({
                            ...event,
                            project_uuid: null
                        })
                    }
                    }>
                    <img src={publicIcon('backspace.svg')} alt=""/>
                </Button>
                <Button
                    className={classes.buttonIcon2}
                    onClick={() => {
                        setOpenSetting(true)
                        setTab(3)
                    }}>
                    <img src={publicIcon('setting.svg')} alt=""/>
                </Button>
            </div>
        </div>
    )
}

const styles = theme => ({
    btn: {
        backgroundColor: 'white',
        border: 'none',
        // width: "calc(25% - 8px)",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "4px 10px",
        borderRadius: "25px",
        height: 27,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    services: {
        backgroundColor: 'white',
        color: 'black',
        // width: "calc(25% - 8px)",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "4px 10px",
        borderRadius: "25px",
        height: 27,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    root: {
        display: 'flex', paddingBottom: 10,
        justifyContent: "space-between",
        fontSize: 12,
    },
    gridContainer: {
        width: '100%'
    },
    itemProject: {
        padding: 3,
        boxSizing: "border-box"
    },
    relative: {
        position: "relative",
    },
    iconActive: {
        position: "absolute",
        top: "50%",
        width: 18,
        height: 18,
        marginTop: -9,
        right: 5,
        color: "#1790FF"
    },
    wrapProject: {
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-between",
    },
    buttonIcon2: {
        marginLeft: 5,
        marginBottom: 5,
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        boxShadow: "none",
        "& img": {
            minWidth: 18
        }
    },
})


export default withStyles(styles)(Project)
