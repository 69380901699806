import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DateRangerPicker from "../DatePicker/DateRangerPicker";
import {makeStyles} from "@material-ui/core/styles";

function DialogChangeTime({classes, open, setOpen, setEventTime, startDate, endDate, checkTimeSearch}) {
    const cls = useStyles()
    const onClose = () => {
        setOpen(false)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={cls.root}
        >
            <DateRangerPicker
                setEvent={setEventTime}
                setOpen={setOpen}
                classes={classes}
                startDate={startDate}
                endDate={endDate}
                checkTimeSearch={checkTimeSearch}
            />
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "unset"
        }
    }
}))

export default DialogChangeTime;
