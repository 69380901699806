import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles'
import {Button, Input} from 'antd'
import useIntlId from "../../../hooks/useIntlId";
import {publicIcon} from "../handlers/infoEvent";

function AchievementRate({event, setEvent}) {
    const [manualInput, setManualInput] = useState(false)
    const classes = useStyles()
    const [rateIntl] = useIntlId([
        'milestone.achievementRate'
    ])
    const [rate, setRate] = useState(0)

    useEffect(() => {
        if (event?.achievementRate)
            setRate(event.achievementRate)

    }, [event])

    return (
        <div className={classes.root} onMouseLeave={() => {
            setRate(event?.achievementRate || 0)
        }}>
            <span className={classes.title}>
                {rateIntl}
            </span>
            <div className={classes.content}>
                {
                    new Array(20).fill(0).map((item, index) => {
                        const color = event?.borderColor ?? "#000"
                        const styles = {
                            backgroundColor: rate > index ? color : "#fff",
                        }
                        if (rate > index) {
                            styles.borderRight = "1px solid #fff"
                        } else {
                            styles.border = `1px solid ${color}`
                        }
                        return (
                            <div key={index} className={classes.percent} style={styles}
                                 onClick={() => {
                                     setEvent({
                                         ...event,
                                         achievementRate: rate
                                     })
                                 }}
                                 onMouseEnter={() => {
                                     setRate(index + 1)
                                 }}
                            />
                        )
                    })
                }
            </div>

            {!manualInput && <span className={classes.percentText} onClick={() => setManualInput(true)}>
                {(rate ?? 0) * 5} %
            </span>
            }

            {manualInput &&
                <Input size="small" bordered="false" suffix="%" className={classes.percentInput}
                       onChange={(e) => {
                           const inputValue = e.target.value
                           if (isNaN(inputValue) || inputValue < 0 || inputValue > 100) return;
                           setEvent({...event, achievementRate: e.target.value / 5})
                       }
                       }
                       onBlur={() => setManualInput(false)}
                />
            }
            <Button
                className={classes.buttonIcon2}
                onClick={() => {
                    setRate(0)
                    setEvent({
                        ...event,
                        achievementRate: 0
                    })
                }}
            >
                <img src={publicIcon('backspace.svg')} alt=""/>
            </Button>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: 'center',
        height: 25,
        margin: "5px 0px"
    },
    title: {
        fontWeight: 700,
        fontSize: 13,
        lineHeight: 1,
        color: "#000",
        marginRight: 15
    },
    content: {

        flexGrow: 1,
        height: '100%',
        display: "flex"
    },
    percent: {
        width: "20%",
        cursor: "pointer"
    },
    percentText: {
        fontWeight: 700,
        fontSize: 10,
        lineHeight: 1,
        color: "#000",
    },
    percentInput: {
        maxWidth: "15%"
    },
    buttonIcon2: {
        margin: "5px 0px 5px 5px",
        border: "none !important",
        display: "flex !important",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff !important",
        boxShadow: "none",
        "& img": {
            minWidth: 18
        }
    },
}))

export default AchievementRate;
